/**
 * Global settings
 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Remove tap highlight on iOS
* {
  -webkit-tap-highlight-color: $transparent;
}


/**
 * Media
 */
img {
  height: auto;
}


/**
 * Forms
 */
// Input field reset
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

select {
  background: transparent;
  border: 0;
}
