.card-application,
[class*='card-application--'] {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $color-gray-lighter;
}

.card-application-outer {
  background-color: $color-gray-lighter;
}

.card-application-outer__title,
[class*='card-application-outer__title--'] {
  @extend %text-uppercase;

  color: $lochmara;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px; /* 107.692% */
  letter-spacing: 0.75px;
  padding-top: 6rem;
  margin-top: 0;
  margin-bottom: 0;

  @each $slug, $color in $segment-colors {
    &[class*='--#{$slug}'] {
      color: $color;
    }
  }
}

[class*='card-application--'][class*='--no-background'] {
  background-color: transparent;
}

[class*='card-application--'][class*='--small'] {
  min-height: 200px;
  background-color: $white;

  @include mq('small-l') {
    display: flex;
  }
}

.card-application,
[class*='card-application--']:not([class*='--small']) {
  @include mq('medium') {
    position: relative;
    display: flex;

    &[class*='--reverse'] {
      flex-direction: row-reverse;
    }
  }

  .single-segment &,
  .page-template-trainings &,
  .page-template-where-to-find-us & {
    padding-bottom: $spacing;

    @include mq('medium') {
      padding-bottom: $spacing * 2;
    }
  }
}

.card-application__visual {
  position: relative;
  z-index: 1;

  [class*='card-application--'][class*='--small'] & {
    @include mq('small-l') {
      width: 60%;
    }
  }

  .card-application &,
  [class*='card-application--']:not([class*='--small']) & {
    @include mq('medium') {
      flex-shrink: 0;
      width: 75%;
    }

    @include mq('large') {
      width: 65%;
    }

    @include mq('xlarge') {
      width: 55%;
    }
  }
}

.card-application__visual__picture {
  display: block;
  width: 100%;
  height: 100%;

  @include mq('medium') {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.card-application__icon {
  position: relative;
  z-index: 3;
  display: block;
  width: $application-icon-width;
  height: $application-icon-width;
  margin: (-$application-icon-width / 2) auto 0;
  background-color: $color-primary;
  fill: $white;

  @include mq('tiny', 'small-l') {
    width: $application-icon-width-small;
    height: $application-icon-width-small;
    margin: (-$application-icon-width-small / 2) auto 0;
  }

  [class*='card-application--'][class*='--small'] & {
    @include mq('small-l') {
      flex-shrink: 0;
      margin: ($application-icon-width / 2) 0 0 (-$application-icon-width / 2);
    }
  }

  .card-application &,
  [class*='card-application--']:not([class*='--small']) & {
    @include mq('tiny', 'medium') {
      position: absolute;
      left: calc(50% - #{$application-icon-width-small / 2});
      margin-top: (-$application-icon-width-small / 2) - $spacing;
    }

    @include mq('small-l', 'medium') {
      margin-top: (-$application-icon-width / 2) - $spacing;
    }

    @include mq('medium') {
      position: absolute;
      top: $application-icon-width;
      left: calc(53% - #{$application-icon-width / 2});
      margin: 0;
    }

    @include mq('large') {
      left: calc(48% - #{$application-icon-width / 2});
    }

    @include mq('xlarge') {
      left: calc(43% - #{$application-icon-width / 2});
    }
  }

  [class*='card-application--'][class*='--reverse']:not([class*='--small']) & {
    @include mq('medium') {
      right: calc(53% - #{$application-icon-width / 2});
      left: auto;
    }

    @include mq('large') {
      right: calc(48% - #{$application-icon-width / 2});
    }

    @include mq('xlarge') {
      right: calc(43% - #{$application-icon-width / 2});
    }
  }

  @each $slug, $color in $segment-colors {
    [class*='card-application--'][class*='--#{$slug}'] & {
      background-color: $color;
    }
  }
}

.card-application__content {
  position: relative;
  z-index: 2;
  align-self: center;
  padding: $spacing;
  background-color: $white;

  [class*='card-application--'][class*='--small'] & {
    @include mq('small-l') {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 40%;
    }
  }

  .card-application &,
  [class*='card-application--']:not([class*='--small']) & {
    box-shadow: 0 15px 30px 0 rgba($black, 0.15);

    @include mq('tiny', 'medium') {
      margin: -$spacing $spacing $spacing;
      padding-top: $application-icon-width;
    }

    @include mq('medium') {
      flex-shrink: 0;
      width: calc(47% - #{$spacing * 2});
      margin: $spacing * 2 0 $spacing * 2 -22%;
      padding: $spacing * 3;

      .home & {
        width: 47%;
      }
    }

    @include mq('large') {
      width: calc(52% - #{$spacing * 2});
      margin: $spacing * 2 0 $spacing * 2 -17%;

      .home & {
        width: 52%;
      }
    }

    @include mq('xlarge') {
      width: 100%;
      max-width: 700px;
      margin: $spacing * 2 0 $spacing * 2 -12%;

      .home & {
        width: 100%;
      }
    }
  }

  [class*='card-application--'][class*='--reverse']:not([class*='--small']) & {
    @include mq('medium') {
      margin-right: -22%;
      margin-left: 0;
      padding-right: $spacing * 3;
      padding-left: $spacing * 2;
    }

    @include mq('large') {
      margin-right: -17%;
    }

    @include mq('xlarge') {
      margin-right: -12%;

      .home & {
        margin-right: calc(-10% - #{$spacing * 2});
      }
    }
  }
}

.card-application__content__title {
  margin: 0 0 0.5em;
  font-size: 2rem;

  [class*='card-application--'][class*='--small'] & {
    font-size: 2rem;

    small {
      font-size: 1.2rem;
    }
  }

  @each $slug, $color in $segment-colors {
    [class*='card-application--'][class*='--#{$slug}'] & {
      color: $color;
    }
  }

  small {
    display: block;
    margin-bottom: 0.5rem;
  }

  @include mq('small-xl') {
    font-size: 3.6rem;
  }
}

.card-application__content__cta {
  margin-top: $spacing * 2;
}
