.cookie,
[class*='cookie--'] {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  display: none;
  width: 100%;
  padding: 3rem 0;
  background: $cello;
}

.cookie__inner {
  display: flex;
  flex-direction: column;

  @include mq(ml) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.cookie__text {
  margin: 0 0 2rem;
  color: $white;

  a:hover {
    color: $white;
  }

  @include mq(ml) {
    margin-right: 2rem;
  }

  @include mq(xxlarge) {
    margin-right: 8rem;
  }
}

.cookie__actions {
  flex-shrink: 0;
}

.cookie__action,
[class*='cookie__action--'] {
  // display: block;
  min-width: 12rem;
  border: .1rem solid $alabaster2;
  padding: .5rem 0 .3rem;
  background: $cello;
  color: $white;
  font-weight: bold;
  font-family: $av-next-cond;
  text-align: center;

  &[class*='--active'] {
    background: $white;
    color: $cello;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
}
