.toggle-downloads,
[class*="toggle-downloads--"] {
  @extend %list-nostyle;

  color: $color-primary;
}

.toggle-downloads__cat {
  padding: $cell-spacing;
  background-color: $color-gray-lighter;

  & + & {
    margin-top: $cell-spacing / 2;
  }

  @include mq('medium') {
    display: flex;
  }
}

.toggle-downloads__cat__title {
  margin-bottom: 1em;

  @include mq('medium') {
    min-width: 220px;
    margin-bottom: 0;
  }

  @include mq('large', 'xlarge') {
    min-width: 260px;
  }

  @include mq('xlarge') {
    min-width: 320px;
  }
}

.toggle-downloads__cat__list {
  @extend %list-nostyle;

  @include mq('medium') {
    flex-grow: 1;
  }
}

.toggle-downloads__cat__item {
  display: flex;
  align-items: center;
  padding: .25em 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.toggle-downloads__cat__item__label {
  flex-grow: 1;
  color: $color-primary;

  svg {
    fill: $color-primary;
  }

  @include mq('medium') {
    svg {
      margin-right: .5em;
    }
  }
}

.toggle-downloads__cat__item__link {
  @include mq('tiny', 'small-l') {
    span {
      display: none;
    }
  }
}

.toggle-downloads__cta {
  display: flex;
  justify-content: center;
}

.toggle-downloads__cta__link {
  padding: $spacing;
  border: 1px solid $color-gray-dark;
  margin: $cell-spacing * 1.5 0 $cell-spacing * 2;
  transition: background-color .2s ease-out, border .2s ease-out;

  span {
    @extend %fw-bold;
    color: $color-primary;
    transition: color .2s ease-out;
  }

  svg {
    position: relative;
    top: -2px;
    fill: $color-primary;
    transition: fill .2s ease-out;
  }

  &:hover {
    border: 1px solid $color-primary;
    background-color: $color-primary;

    span {
      color: $white;
    }

    svg {
      fill: $white;
    }
  }

}
