.products-list {
  margin-top: 2rem;
  position: relative;

  @include mq(medium) {
    margin-top: 4rem;
  }
}

.products-list .loader {
  @include center-x;
  z-index: 80;
  top: 5rem;
}

.products-list__title,
.products-list__subtitle {
  text-align: center;
  text-transform: uppercase;
}

.products-list__title {
  margin-bottom: 0;
}

.products-list__subtitle {
  color: $picton-blue;
}

.grid-products {
  margin: 2rem auto 4rem auto;

  @include mq(medium) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cta-product {
      margin-bottom: 2rem;
      width: calc(50% - 10px);
    }
  }

  @include mq($until: small) {
    .cta-product {
      flex-direction: column;
    }
    .cta-product__image {
      max-width: 160px;
    }
    .cta-product__text {
      align-self: flex-start;
      padding: 0 3rem;
    }
  }

}
