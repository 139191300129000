[class*='header-spirit--'],
.header-spirit {
}

.header-spirit__title {
  margin: 6rem 0;
  font-size: 4.8rem;
  letter-spacing: 1px;
  line-height: 4rem;
  text-align: center;

  strong {
    color: $picton-blue;
  }

  @include mq(large) {
    margin: 12rem 0;
    font-size: 9.6rem;
    line-height: 8rem;
  }
}

.header-spirit__media {
  position: relative;
  overflow: hidden;
  height: 29rem;
  background-color: $cello;

  @include mq(large) {
    height: 63rem;
  }

  @include mq(xlarge) {
    height: 82rem;
  }
}

.header-spirit__background {
  @include get-all-space();
  @include object-fit(cover);
  width: 100%;
  height: 100%;
}

.header-spirit__loop {
  @include fit;

  user-select: none;
  pointer-events: none;
}

.header-spirit__play {
  @include center-xy;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 9rem;
  border-radius: 9rem;
  background-color: white;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover,
  &:focus {
    transform: translate(-50%, -50%) scale(1.1);

    svg {
      fill: $picton-blue;
    }
  }
}

.header-spirit__play__icon {
  width: 2.5rem;
  fill: $cello;
  transition: fill 0.2s;
}
