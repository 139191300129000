.contact-careers {
  position: relative;
  overflow: hidden;
  margin-bottom: $spacing * 3;
  background-color: $careers-gray;
  border-radius: 15px;

  @include mq(large) {
    margin-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 6;
  }
}

.contact-careers__content {
  padding: 3rem;

  @include mq(large) {
    padding: 7.5rem;
    max-width: 45rem;
  }

  @include mq(xlarge) {
    padding: 10rem;
    max-width: 50rem;
  }
}

.contact-careers__label {
  margin-bottom: 0.8rem;
  color: $picton-blue;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-transform: uppercase;

  @include mq(large) {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.contact-careers__title {
  margin-top: 0;
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  line-height: 2.8rem;

  @include mq(large) {
    margin-bottom: $spacing * 2;
    font-size: 4.8rem;
    line-height: 5.6rem;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 4;
  }

  strong {
    color: $picton-blue;
  }
}

.contact-careers__link {
  display: inline-flex;
  padding: 1.2rem 2.4rem;
  color: $cello;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: white;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: $picton-blue;
  }
}

.contact-careers__pictures {
  position: absolute;
  right: 0;

  display: flex;
  justify-content: space-between;
  width: 60rem;
  height: 80rem;
  transform: rotate(15deg);

  @include mq(large) {
    top: -30%;
    width: 48rem;
    height: 70rem;
  }

  @include mq(xlarge) {
    top: -25%;
    width: 60rem;
    height: 80rem;
  }

  @include mq($until: large) {
    display: none;
  }
}

.contact-careers__pictures__column {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.contact-careers__picture-outer {
  @include aspect-ratio(18, 27);

  overflow: hidden;
  margin-bottom: $spacing;
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 10px;
}

.contact-careers__picture {
  @include get-all-space();
  @include object-fit(cover);

  width: 100%;
  height: 100%;
}
