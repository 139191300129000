.en16005-noproduct {
  position: relative;
  overflow: hidden;
  margin: $spacing * 2 0;
}

h3.en16005-noproduct {
  font-size: 2.4rem;
  line-height: 2.2rem;
  margin: 0 0 $spacing / 2;
}

.en16005-noproduct__text {
  margin-bottom: $spacing * 2;
}

.en16005-noproduct__video {
  position: relative;
  margin-top: $spacing * 2;
}

.en16005-noproduct__video__cta {
  @include center-xy;
}

@include mq(ml) {
  .en16005-noproduct__text {
    margin-bottom: $spacing * 4;
  }
}

@include mq(xlarge) {
  .en16005-noproduct {
    margin: 12rem 0 20rem;
  }

  .en16005-noproduct__content {
    padding: $spacing * 8.5 55% $spacing * 8.5 $spacing * 3;
  }

  .en16005-noproduct__video {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50vw;
    height: 100%;

    img {
      @include image-fit;

      z-index: -1;
      width: 100%;
      height: 100%;
    }
  }
}
