.product-duos {
  padding: $spacing * 6 0;
  background-color: $alabaster2;
}

.product-duos__list {
  @extend %list-nostyle;
}

.product-duos__item {
  @include mq($until: medium) {
    margin-right: -20px;
    margin-left: -20px;

    & + & {
      margin-top: $spacing * 3;
    }
  }

  @include mq(medium) {
    & + & {
      margin-top: $spacing * 4;
    }
  }
}
