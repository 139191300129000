.product-slider {
  @extend %list-nostyle;

  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (69 / 146) * 100%;
  }
}

.product-slider__item {
  @include get-all-space;

  &.product-slider-slidy__item {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

.product-slider__item__legend {
  @include center-x;

  z-index: 2;
  bottom: 0;
  width: 100%;
  padding: $spacing * 3 0;
  background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
}

.product-slider__item__legend-inner {
  padding: 0 $spacing * 4;
}

.product-slider__item__legend__caption {
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %ff-av-next-cond;

  color: $white;
  font-size: 1.5rem;
  line-height: 1.2em;
}

.product-slider__item__legend__description {
  @extend %ff-open-sans;

  margin-top: $spacing / 4;
  color: $white;
  font-size: 1.3rem;
  line-height: 1.3em;
}

.product-slider__item__picture {
  @include image-fit;
  width: 100%;
  height: 100%;
}

.product-slider-slidy-outer {
  position: relative;
}

[class*='product-slider-slidy-controls__item--'] {
  @include center-y;

  position: absolute;
  z-index: 9;
  width: 20px;
  border: 0;
  padding: 0;
  background: transparent;

  @include mq(medium) {
    width: 30px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (11 / 6) * 100%;
  }

  &[class*='--prev'] {
    left: -$spacing * 3;
  }

  &[class*='--next'] {
    right: -$spacing * 3;
  }
}

.product-slider-slidy-controls__item__arrow {
  @include get-all-space;

  fill: $cello;
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  [class*='product-slider-slidy-controls__item--']:hover & {
    fill: $color-secondary;
  }

  [class*='product-slider-slidy-controls__item--'][class*='--next'] & {
    transform: rotate(180deg);
  }
}