$color-sg-gray: #ccc;

.sg {
  margin: $spacing * 2 $spacing;
}

.sg__title {
  color: $color-secondary;
}

.sg__subtitle {
  @extend .h2;

  margin: 0;
  padding-top: 1.5em;
  border-top: 1px solid $color-sg-gray;
  font-size: 2rem;

  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 1em;
    margin-right: 5px;
    background-color: $color-primary;
    vertical-align: middle;
  }
}

.sg-container,
[class*="sg-container"] {
  padding: $spacing 0;
}

[class*="sg-container--"][class*="--full"] {
  margin: 0 (-$spacing);
}

[class*="sg-container--"][class*="small"] {
  max-width: calc(320px - #{2 * $spacing});
}

[class*="sg-container--"][class*="medium"] {
  max-width: calc(768px - #{2 * $spacing});
}

[class*="sg-container--"][class*="large"] {
  max-width: calc(1024px - #{2 * $spacing});
}

[class*="sg-container--"][class*="xlarge"] {
  max-width: calc(1280px - #{2 * $spacing});
}

[class*="sg-container--"][class*="xxlarge"] {
  max-width: calc(1440px - #{2 * $spacing});
}
