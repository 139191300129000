.select-block,
[class*='select-block--'] {
  padding: 3rem 0 ;
  background: $alabaster;

  @include mq(medium) {
    padding: 5rem 0;
  }
}

.select-block__title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-transform: none;
  text-align: left;

  @include mq(medium) {
    margin-bottom: 3rem;
  }
}

.select-block__list {
  max-width: 33rem !important;
  margin: 0 auto;
  text-align: center;
}

.select-block__list__label {
  @extend %fw-semibold;
  color: $cello;
  text-align: left;
}

.select-block__list__select {
  position: relative;
  margin-bottom: 3rem;
}

.select-block__list__select__icon {
  z-index: 10 !important;
}
