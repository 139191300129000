.highlights-grid {
  padding: $spacing 0;
}

.highlights-grid__items {
  @extend %list-nostyle;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq('medium') {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

}

.highlights-grid__item {
  position: relative;
  box-shadow: 0 5px 20px 0 rgba($black, .3);
  // max-width: 30rem;

  &::before {
    @include get-all-space;
    content: '';
    z-index: 1;
    background: linear-gradient(to bottom, transparent 60%, rgba($black, .6) 100%);
  }

  @include mq($until: 'medium') {
    width: 100%;
    margin: $spacing / 2 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq('medium') {
    width: calc(100% / 3 - #{$spacing / 2});
  }

  @include mq('large') {
    width: calc(100% / 3 - #{$spacing})
  }

}

.highlights-grid__item__cover {
  @include fit(cover);
}

.highlights-grid__item-inner {
  @extend %text-nodecoration;
  display: block;
}

.highlights-grid__item__content {
  position: relative;
  padding: $spacing / 4 $spacing;
  margin: $spacing * 4 0 $spacing;
  z-index: 2;

  @include mq('large') {
    margin: $spacing * 8 0 $spacing;
  }

  &::before {
    @include center-y;
    content: '';
    z-index: 0;
    left: 0;
    display: block;
    width: .4rem;
    height: 100%;
    background-color: $color-secondary;
    transition: .3s width, background-color .3s;
  }

  & > * {
    position: relative;
    color: $white;
  }

  .highlights-grid__item:hover & > * {
    color: $color-primary;
  }

  .highlights-grid__item:hover &::before {
    width: 100%;
    background-color: $white;
  }
}

.highlights-grid__item__content__title {
  margin-top: $spacing / 4;
}
