.process {
  margin-bottom: $spacing * 3;
  background-color: $careers-gray-2;

  @include mq(large) {
    margin-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    margin-bottom: $spacing * 8;
  }
}

.process-inner {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 3;

  @include mq(large) {
    padding-top: $spacing * 4.5;
    padding-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    padding-top: $spacing * 8;
  }
}

.process__headline {
  margin-bottom: $spacing * 0.5;
  color: $picton-blue;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  @include mq(large) {
    margin-bottom: $spacing;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.process__title {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline: auto;
  max-width: 60rem;
  font-size: 3.8rem;
  line-height: 4rem;
  text-align: center;

  @include mq(large) {
    font-size: 6.4rem;
    line-height: 7.2rem;
  }

  strong {
    color: $picton-blue;
  }
}

.process__subtitle {
  margin-bottom: $spacing * 0.5;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;

  @include mq(large) {
    margin-bottom: $spacing;
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
}

.process__steps {
  margin-top: $spacing * 3;
  margin-inline: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.8rem;
  list-style-type: none;
  counter-reset: steps;

  @include mq(medium) {
    margin-top: $spacing * 4.5;
    display: flex;
    justify-content: space-between;
  }

  @include mq(large) {
    font-size: 1.8rem;
    line-height: 3rem;
  }
}

.process__step {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $cello;
  counter-increment: steps;

  @include mq($until: medium) {
    margin-bottom: $spacing * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq(medium) {
    max-width: 20%;
    padding-right: $spacing * 1.5;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      bottom: 5.4rem;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(
        to right,
        $picton-blue 50%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top;
      background-size: 12px 2px;
      background-repeat: repeat-x;
    }

    &:last-child {
      padding-right: 0;

      &::before {
        display: none;
      }
    }
  }
}

.process__step__title {
  margin-bottom: $spacing * 2;
  display: block;

  &::before {
    content: counter(steps);
    display: block;
    font-weight: 700;
  }
}

.process__step__icon {
  display: flex;
  max-width: 11rem;
  width: 100%;
  height: 11rem;
  background-color: $careers-gray;
  border-radius: 11rem;
  z-index: 1;

  @include mq($until: medium) {
    margin-left: $spacing * 0.5;
  }

  @include mq(medium) {
    margin-top: auto;
  }

  svg {
    margin: auto;
    width: 5.5rem;
    height: 5.5rem;
  }
}
