.cta-more {
  display: block;
  border-top: 1px solid $color-gray-light;
  padding: $spacing 0;
  background-color: $color-gray-lighter;
  color: $color-primary;

  .cta__arrow {
    margin-left: $spacing * .5;
    transform: rotate(-180deg);
    transition: transform $duration-default $ease-default;
  }

  &.is-open {
    .cta__arrow {
      transform: rotate(0deg);
    }
  }
}
