@include mq('large') {
  .js {
    .single-application {
      [class*="header-border--"] ~ * {
        opacity: 0;
        transition: opacity .25s;
      }
    }

    .single-product,
    .single-accessory {
      .header-products {
        overflow: hidden;
        height: 0;
        opacity: 0;

        ~ * {
          // opacity: 0;
          transition: opacity .25s;
        }
      }
    }
  }
}


//   .js & {

//     // & ~ *:nth-child(3),
//     // & ~ *:nth-child(4) {
//     //   opacity: .5;
//     // }
//   }
// }
