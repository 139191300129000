.page-template-leads-generation {
  // background-color: aqua;
}

.leads-generation__wrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-inline: auto;
  width: 90%;

  @include mq('medium') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 110rem;
    gap: $spacing * 2;
  }
}

.fc-wysiwyg,
.fc-icons,
.fc-video {
  margin-bottom: $spacing * 3;
}

.fc__title {
  margin-bottom: $spacing;
  color: $cello;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-transform: none;

  @include mq('medium') {
    font-size: 3.6rem;
  }
}

.fc__htmltext {
  font-size: 1.5rem;
}

.fc-video-trigger {
  position: relative;
  overflow: hidden;
  margin-bottom: 2.4rem;
  display: flex;
  width: 100%;
  aspect-ratio: 31/18;
  border-radius: 8px;
  background-color: $picton-blue;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  figure {
    margin: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.2s ease-in-out;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 4rem;
    height: 4rem;
    fill: $white;
  }

  @include mq('medium') {
    width: 31rem;
  }
}
