.page-header {
  position: relative;
}

.header-shoebox {
  padding: $spacing * 1.5;
  background-color: $color-gray-lighter;
  text-align: center;

  @include mq('medium') {
    padding: $spacing * 2;
  }
}

.header-shoebox__title {
  margin: 0;
  font-size: 6.4rem;
  line-height: 1em;

  @include mq($until: 'medium') {
    text-align: center;
    font-size: 3.6rem;
  }

  small {
    display: block;
    margin-bottom: 1.4rem;
    font-family: $open-sans;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1em;

    @include mq($until: 'medium') {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
  }
}


.header-shoebox__application-selector {
  position: relative;
}

.header-shoebox__application-selector__background {
  display: block;
  width: 100%;
  padding-top: calc(100% * 9 / 16);
  background: $c-region-southeast;
}

.header-shoebox__application-selector__list {
  bottom: 0;
  left: 0;
  list-style-type: none;
  position: absolute;
  right: 0;
  top: 0;
}

.header-shoebox__application-selector__list__el {
  position: absolute;
  display: flex;

  svg {
    display: block;
    width: 3.5rem;
    height: 3.5rem;

    background: $cello;
  }

  span {
    display: block;
    height: 3.5rem;
    padding: 1.3rem 1rem;
    background: $white;
    font-family: $av-next-cond;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: bold;
    color: $cello;
    text-transform: uppercase;
  }
}

