.product-tutorials {
  @include owl;

  padding-top: $spacing * 2;
  padding-bottom: $spacing * 4;
  background-color: $alabaster2;

  @include mq(large) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 8;
  }
}
