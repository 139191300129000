.teams__item__intro {
  .block-intro__headline {
    margin: 0 auto;
    font-size: 1.4rem;
  }

  .block-intro__title {
    margin: $spacing * 0.4 auto $spacing * 0.8;
  }

  @include mq(medium) {
    .block-intro__headline {
      font-size: 2rem;
    }
  }
}

.teams__item__cta {
  margin-top: $spacing;

  a {
    margin: auto;
  }

  @include mq(large) {
    margin-top: $spacing * 2.5;
  }
}


.teams__item__subteam__title {
  @extend %text-center;

  margin: $spacing * 2 auto 0;
  color: $color-gray-medium;
  text-transform: uppercase;

  .teams__item__cta + & {
    padding-top: $spacing * 2;
    border-top: 0.1rem solid $gallery;
  }

  @include mq(large) {
    margin: $spacing * 4.4 auto $spacing;
  
    .teams__item__cta + & {
      padding-top: $spacing * 3;
    }
  }
}