.map {
  margin-bottom: 0;
  padding-bottom: 4rem;
  background: $alabaster2;

  @include mq('large') {
    overflow: hidden;
    padding-bottom: 0;
    border-top: 1px solid $alto;
    background: transparent;
  }
}

.map-container {
  background: $alabaster2;

  @include mq($until: 'large') {
    margin: 0;
  }

  @include mq('large') {
    position: relative;
    margin-bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    background: $white;
  }
}

// Map Interactive

.map__interactive {
  position: relative;
  width: 100%;
  max-width: 760px;
  height: 250px;
  margin: 0 auto;
  overflow: hidden;

  @include mq('small-l') {
    height: 280px;
  }

  @include mq('small-l') {
    height: 320px;
  }

  @include mq('large') {
    height: 540px;
    max-width: none;
    flex: 1;
    overflow: visible;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100vw;
      height: 100%;
      background: $alabaster2;
    }
  }
}

.map__interactive__svg {
  @include center-xy;

  background: transparent;
  transform: translate(-50%, -50%) scale(0.5);

  @include mq('small') {
    transform: translate(-50%, -50%) scale(0.6);
  }

  @include mq('small-l') {
    transform: translate(-50%, -50%) scale(0.75);
  }

  @include mq('medium') {
    transform: translate(-50%, -50%) scale(0.9);
  }

  @include mq('large') {
    z-index: 1;
    transform: translate(-45%, -50%) scale(0.9);
  }

  @include mq('xlarge') {
    transform: translate(-45%, -50%) scale(1);
  }

  @include mq('xxlarge') {
    transform: translate(-35%, -50%) scale(1.2);
  }

  @include mq('xxxlarge') {
    transform: translate(-25%, -50%) scale(1.2);
  }
}

@include mq('large') {
  .map__interactive [data-area-pin] {
    cursor: pointer;

    #shape,
    #logo {
      transition: fill 0.2s;
    }

    #shape {
      fill: $color-primary;
    }
    #logo {
      fill: $white;
    }

    &:hover {
      #shape {
        fill: $color-secondary;
      }
      #logo {
        fill: $color-primary;
      }
    }
  }
}

// Map Select

.map__card__select {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  background-color: $alabaster;
  color: $cello;
  text-transform: uppercase;
}

.map__card__select,
.map__card__select ~ .style-select {
  @include mq('medium') {
    display: none;
  }

  .ss-selected-option {
    padding: 1rem 0;
    background-color: $color-primary;
    color: $white;

    &::after {
      position: relative;
      top: 0;
      right: 0;
      display: inline-block;
      margin-left: 1rem;
    }
  }
}

.map__headquarters {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  box-shadow: 0 -10px 10px -10px rgba($cello, 0.2);

  @include mq('medium') {
    display: flex;
    z-index: 1;
  }

  @include mq('large') {
    left: 100%;
    right: unset;
    flex-direction: column;
    transform: none;
    box-shadow: 10px 10px 20px rgba($cello, 0.1);
  }

  @include mq('large') {
    top: $spacing * 2;
  }
}

.map__headquarters__button {
  width: 6rem;
  height: 6rem;
  border: 0;
  border-radius: 0;
  color: $cello;
  background: $white;
  transition: background 0.1s, color 0.1s;

  &:hover,
  &:focus {
    color: $white;
    background: $picton-blue;
  }

  & + & {
    border-left: 1px solid $alto;
  }

  @include mq('large') {
    & + & {
      border-top: 1px solid $alto;
      border-left: 0;
    }
  }
}

// Map Card

.map__card {
  position: relative;
  border: 1px solid $alabaster2;
  margin: 0 1rem;
  box-shadow: 0 10px 15px 0 rgba($color-gray, 0.2);
  background-color: $white;
  color: $color-primary;

  @include mq('large') {
    z-index: 2;
    width: 40%;
    margin: 0;
    box-shadow: none;
    border: 0;
  }

  @include mq('xlarge') {
    width: 30%;
  }
}

.map__card__regions {
  position: relative;
  min-height: 37rem;

  @include mq('medium') {
    z-index: 2;
    min-height: 50rem;
  }

  @include mq('large') {
    height: 100%;
    min-height: 54rem;
  }
}

.map__card__region {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  padding-top: $spacing;

  &.is-selected {
    visibility: visible;
  }

  @include mq('medium') {
    padding: $spacing * 3;
  }

  @include mq('large') {
    padding: $spacing * 4 0;
  }
}

.map__card__region__title {
  margin-bottom: 1.5rem;
  padding: 0 $spacing / 2;

  @include mq('medium') {
    padding: 0;
  }
}

.map__card__region__buttons,
.map__card__region__countries {
  @extend %list-nostyle;
}

.map__card__region__buttons {
  display: flex;
  justify-content: flex-start;
  padding: 0 $spacing / 2;

  @include mq('medium') {
    padding: 0;
  }
}

.map__card__region__button-outer {
  & + & {
    margin-left: 1rem;
  }
}

.map__card__region__button {
  color: $alto;
  background: $alabaster;
  border: 0;
  border-radius: 4px;
  transition: background 0.1s, color 0.1s;

  &:hover,
  &:focus {
    color: $white;
    background: $picton-blue;
  }
}

.map__card__region__countries {
  position: relative;
  flex: 1;
  min-height: 250px;
}

.map__card__region__country {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  visibility: hidden;
}

.map__card__region__country__infos {
  flex: 1;
  margin: $spacing * 2 0 0;
  padding: 0 $spacing / 2;

  dt {
    display: inline-block;
    width: 30%;
    vertical-align: top;
  }
  dd {
    display: inline-block;
    width: 69%;
    margin: 0 0 $spacing 0;
  }

  @include mq('medium') {
    padding: 0;
  }
}

.map__card__infos__link {
  margin: $spacing $spacing / 2;
  padding: 0;
  text-align: center;

  @include mq('medium') {
    margin: 0;
    text-align: left;

    a {
      display: inline-block;
      width: auto;
    }
  }
}

// .map__card__company {
//   position: relative;
//   z-index: 1;
//   height: 90px;
//   border-bottom: 1px solid $color-gray;

//   @include mq("medium") {
//     width: 30%;
//     height: 100%;
//     border-bottom: 0;
//     box-shadow: 10px 0 20px 0 rgba($color-gray, 0.2);
//   }
// }

// .map__card__company-inner {
//   position: absolute;
//   top: 50%;
//   left: 25px;
//   transform: translateY(-50%);
//   opacity: 0;
//   transition: opacity 0.3s;
//   position: static;
//   transform: none;
//   opacity: 1;

//   @include mq("medium") {
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }
// }

// .map__card__countries {
//   display: none;

//   @include mq("medium") {
//     display: flex;
//     align-items: flex-start;
//   }
// }

// .map__card__countries__item {
//   @extend %fw-bold;

//   padding: 0 0.5rem;
//   font-size: 1.1rem;
//   line-height: 1.5;
//   color: #fff;
//   background: $picton-blue;
//   text-transform: uppercase;
//   border-radius: 3px;

//   & + & {
//     margin-left: 0.5rem;
//   }
// }

// .map__card__infos {
//   position: relative;
//   overflow: hidden;
//   height: 310px;

//   @include mq("medium") {
//     width: 70%;
//     height: 100%;
//   }
// }

// .map__card__infos-inner {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-end;
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   transform: translateX(-100%);
//   transition: transform 0.3s;
//   position: static;
//   display: block;
//   transform: none;
// }

// .map__card__infos__address,
// .map__card__infos__contact {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: 100%;

//   @include mq("medium") {
//     width: 50%;
//     height: 80%;
//   }
// }

// .map__card__infos__address__title,
// .map__card__infos__address__data {
//   margin-left: 25px;
//   text-transform: none;
// }

// .map__card__infos__contact-inner {
//   display: flex;
//   margin-left: 25px;
// }

// .map__card__infos__contact__values {
//   margin-left: 3rem;
// }

// .map__card__infos__contact__labels > *,
// .map__card__infos__contact__values > * {
//   margin: 0.5rem 0;
// }

// .map__card__infos__link {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 60px;
//   margin-top: 10%;
//   background-color: $white;

//   @include mq("medium") {
//     height: 20%;
//     margin-top: 0;
//   }
// }

// Interactive related

@each $country in $countries-list {
  .map[data-active='#{$country}'] {
    .map__interactive ##{$country} {
      #shape {
        fill: $color-secondary;
      }
      #logo {
        fill: $color-primary;
      }
    }

    .map__card__company-inner[data-area='#{$country}'] {
      opacity: 1;
      transition: opacity 0.3s 0.3s;
    }

    .map__card__infos-inner[data-area='#{$country}'] {
      transform: translateX(0%);
      transition: transform 0.3s 0.3s;
    }

    .map__card__region[class*='country--'][class*='--#{$country}'] {
      visibility: visible;
    }

    .map__card__region__country[data-country='#{$country}'] {
      visibility: visible;
    }

    .map__card__region__button[data-country='#{$country}'],
    .map__headquarters__button[class*='country--'][class*='--#{$country}'] {
      color: $white;
      background: $picton-blue;
      border-color: $picton-blue;
    }
  }
}
