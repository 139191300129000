.launchpage-timeline__background {
  position: sticky;
  z-index: -1;
  top: 0;
  overflow: hidden;
  height: 0;
  padding-bottom: 60%;
  transform: rotate(0deg);

  img {
    @include get-all-space;
    @include object-fit(cover);

    position: fixed;
    width: 100%;
    max-width: none;
    height: 100%;
  }
}

.launchpage-timeline-inner {
  background: $white;

  .launchpage-content__title {
    margin-top: 0;
  }

  .launchpage-content__text {
    @extend .lead;
  }
}

.launchpage-timeline__list {
  @extend %list-nostyle;

  margin-top: $spacing * 3;
}

.launchpage-timeline__item {
  & + & {
    margin-top: $spacing * 3;
  }
}

.launchpage-timeline__item__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 60%;

  img {
    @include get-all-space;
    @include object-fit(cover);

    width: 100%;
    max-width: none;
    height: 100%;
    transform: scale(1.1);
    transition: transform $ease-lp 1.5s;
  }
}

.launchpage-timeline__item__title {
  @extend %text-notransform;

  margin: $spacing 0 0.6rem;
  color: $cello;
  text-transform: none;
}

@include mq(medium) {
  .launchpage-timeline__list {
    display: flex;
    margin-top: $spacing * 6;
  }

  .launchpage-timeline__content {
    .launchpage-content-inner {
      padding-right: 20%;
    }
  }

  .launchpage-timeline__item {
    flex: 1;

    & + & {
      margin: 0 0 0 $spacing * 1.5;
    }

    .launchpage-timeline__list:not(:hover) &:first-child,
    &:hover {
      .launchpage-timeline__item__picture,
      .launchpage-timeline__item__title,
      .launchpage-timeline__item__abstract {
        opacity: 1;

        img {
          transform: scale(1);
        }
      }
    }
  }

  .launchpage-timeline__item__picture {
    opacity: 0.1;
    transition: opacity $ease-lp 1.5s;
  }

  .launchpage-timeline__item__title {
    margin: 2.6rem 0 1rem;
    opacity: 0.6;
  }

  .launchpage-timeline__item__abstract {
    opacity: 0.6;
    transition: opacity $ease-lp 2s;
  }
}

@include mq(large) {
  .launchpage-timeline__background {
    height: 100vh;
    padding-bottom: 0;
  }

  .launchpage-timeline__content {
    .launchpage-content-inner {
      padding-right: 50%;
    }

    .launchpage-content__title {
      font-size: 5.6rem;
    }
  }

  .launchpage-timeline__item {
    & + & {
      margin: 0 0 0 $spacing * 2.5;
    }
  }
}
