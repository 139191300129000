.result-row {
  border-top: 1px solid $color-gray;
  padding: 3rem 0 4rem;
}

.result-row > *:first-child {
  margin-top: 0;
}

.result-row > *:last-child {
  margin-bottom: 0;
}

.result-row__title {
  @extend %text-nodecoration;
}

.result-row .searchwp-highlight {
  color: $color-primary;
  border-bottom: 1px solid $color-primary;
}
