.tech-corner-ondemand-popup {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  padding: $spacing;

  &.is-visible {
    display: flex;
  }
}

.techcorner-ondemand-popup__background {
  @include get-all-space;

  background-color: rgba($cello,  0.85);
}

.ondemand-popup-inner {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: $spacing * 2;
  border-radius: 1rem;
  background: $white;
  box-shadow: 0 1.5rem 3rem 0 rgba(0, 0, 0, 0.05);

  @include mq(medium) {
    max-width: 34rem;
  }

  @include mq(xxlarge) {
    max-width: 48rem;
    padding: $spacing * 3;
  }
}

.ondemand-popup__button {
  @extend %button-nostyle;

  position: absolute;
  top: 0;
  right: 0;
  padding: $spacing;

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: $color-primary;
    transition: fill 0.2s;
  }

  &:hover,
  &:focus-visible {
    .icon {
      fill: $color-secondary;
    }
  }
}

.ondemand-popup__title {
  @extend %text-uppercase;

  margin-bottom: $spacing * 1.5;
  text-align: center;

  .icon {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 auto $spacing;
    fill: none;
    stroke: $color-primary;
  }
}

.ondemand-popup__form__label {
  @extend %fw-bold;
  @extend %text-uppercase;

  display: block;
  margin-bottom: 1rem;
  color: $cello;
  font-size: 1.2rem;
  letter-spacing: 0.075rem;

  & ~ & {
    margin-top: $spacing;
  }
}

.ondemand-popup__form__select {
  display: block;
  width: 100%;
  height: 4rem;
  margin-top: $spacing * 0.6;
  padding: 0;
  color: $color-gray;
  font-size: 1.6rem;
  border-bottom: 0.1rem $color-primary solid;
}

.ondemand-popup__form__input,
.ondemand-popup__form__textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 4rem;
  margin-bottom: 2.5rem;
  color: $cello;
  font-size: 1.6rem;
  border: 0;
  border-bottom: 0.1rem solid $alto;
  resize: vertical;

  &::placeholder {
    color: $color-gray;
  }
}

.ondemand-popup__form__checkbox {
  position: relative;
  display: block;
  margin-bottom: 4rem;
  padding-left: 4rem;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  input:checked + label::after {
    opacity: 1;
  }
}

.ondemand-popup__form__checkbox__label {
  &::before,
  &::after {
    @include center-y;

    content: '';
    left: 0;
  }

  &::before {
    width: 2.5rem;
    height: 2.5rem;
    border: 0.1rem solid $alto;
  }

  &::after {
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background: $cello;
    opacity: 0;
    border-radius: 0.3rem;
    transition: opacity 0.15s;
  }
}

.ondemand-popup__form__submit {
  @extend %text-uppercase;

  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  background-color: $picton-blue;
  color: $white;
  font: bold 1.5rem $av-next-cond;
  line-height: 1;
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s;
  border: 0;
  border: 0.1rem solid $white;

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin: -0.4rem 0 0 1rem;
    fill: $white;
    transition: fill 0.2s;
  }

  
  &:hover,
  &:focus-visible {
    color: $cello;
    border: 0.1rem solid $cello;
    background-color: $white;
  
    svg {
      fill: $cello;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include mq(medium) {
    padding: 2rem 3rem;
  }
}

.ondemand-popup__form__feedback {
  margin: 2rem 0 0;
  text-align: center;
  font-size: 1.6rem;
  color: $cello;

  &.error {
    @extend %text-left;

    color: $alizarin-crimson;
  }

  ul {
    margin: $spacing * 0.5 0 0;
    color: $color-primary;
  }
}