[class^='link'],
[class*='link--'] {
  @extend %text-nodecoration;

  color: $color-primary;
}

// .link-menu
.link-menu,
[class*='link-menu--'] {
  position: relative;
  display: flex;
  align-items: center;
  transition: color 0.2s;

  .external.icon {
    display: inline-block;
    width: 1.2rem;
    height: 2.4rem;
    margin-left: 1rem;
    fill: currentColor;
  }

  &:hover {
    color: $color-secondary;
  }
}

.link-menu__icon {
  display: none;

  .has-submenu & {
    display: block;
  }

  @include mq($until: 'large') {
    [class*='link-menu--'][class*='--arrowed'] & {
      @include center-y;
      // right: $spacing / 2;
      right: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 0;
      fill: currentColor;
    }
  }

  @include mq('large') {
    [class*='link-menu--'].has-submenu & {
      display: inline-block;
      width: 2rem;
      margin-top: 0.2rem;
      fill: $picton-blue;
      vertical-align: middle;
      transform: rotate(90deg);
      transition: transform 0.25s;
      pointer-events: none;

    }

    .menu__content__primary__link.has-submenu & {
      margin: 0 0 0.2rem;
    }

    [class*='link-menu--'].has-submenu.is-open & {
      transform: rotate(270deg);
    }
  }
}

// .link-submenu
// [class*="link-submenu--"],
// .link-submenu {
//   position: relative;
//   display: block;
//   line-height: 3em;
//   transition: padding-left 0.3s 0.1s ease-out, color 0.3s 0.3s ease-out;
//   padding: 0.5rem 2rem;
//   border-top: 1px solid $color-gray-light;

//   @include mq("large") {
//     padding: 0.5rem 1rem;
//   }

//   &::after {
//     content: "";
//     position: absolute;
//     z-index: -1;
//     top: 50%;
//     right: 0;
//     display: block;
//     width: 4px;
//     height: 33.33%;
//     background-color: $color-primary;
//     transform: translateY(-50%);
//     transition: height 0.3s, width 0.3s 0.3s ease-out;
//   }

//   &:hover,
//   &.is-open {
//     padding-left: 1rem;
//     color: $white;
//     transition: padding-left 0.3s 0.1s ease-out, color 0.3s 0.3s ease-out;
//   }

//   &:hover::after,
//   &.is-open::after {
//     width: 100%;
//     height: 100%;
//   }

//   @each $segment, $color in $segment-colors {
//     &[class*="--#{$segment}"]::after {
//       background-color: $color;
//     }
//   }
// }

[class*='link-submenu--'],
.link-submenu {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  line-height: 4.8rem;
  transition: 0.2s;
  padding: 0 2rem;

  .arrow {
    fill: currentColor;
  }

  .picto {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
    margin-right: 1em;
    stroke: currentColor;
  }

  &:hover,
  &:focus {
    color: $picton-blue;
  }

  &.is-new {
    justify-content: flex-start;
  }

  @include mq('large') {
    padding: 0.5rem 1rem;
    line-height: 1;
  }
}

.link-submenu__label {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  color: inherit;

  .external.icon {
    display: block;
    width: 1.2rem;
    height: 2.4rem;
    margin-left: 1rem;
    fill: currentColor;
  }

  @include mq($until: 'large') {
    font-size: 1.6rem;
  }
}

.link-submenu__label__status {
  @extend %text-uppercase;
  @extend %fw-bold;

  display: inline-block;
  padding: .2rem .7rem .2rem .8rem;
  font-size: 1rem;
  color: $cello;
  background-color: $gallery2;
  border-radius: .5rem;
  line-height: 2em;
  margin-left: $spacing / 3;
}

.link-submenu__flag {
  margin-left: 1rem;
  padding: 0 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.5;
  color: $white;
  background: $cello;
  text-transform: uppercase;
  border-radius: 3px;
}

// .link-list{--title}
[class*='link-list'],
.link-list {
  transition: color 0.2s;

  &[href]:hover,
  &:focus-visible {
    color: $color-secondary;

    @each $segment, $color in $segment-colors {
      &[class*='--#{$segment}'] {
        color: $color;
      }
    }
  }
}

// .link-secondary
.link-secondary {
  transition: color 0.2s;

  &:hover {
    color: $color-secondary;
  }
}

// .link-more
.link-more,
[class*='link-more--'] {
  position: relative;
  padding-bottom: 0.2rem;

  .icon {
    width: 0.5rem;
    height: 0.5rem;
    vertical-align: middle;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: $color-primary;
    transition: width 0.3s;
  }

  [data-clickable]:hover &::after,
  &:hover::after {
    width: 100%;
  }

  @each $segment, $color in $segment-colors {
    &[class*='--#{$segment}'] {
      color: $color;

      .icon {
        fill: $color;
      }

      &::after {
        background-color: $color;
      }
    }
  }
}

[class*='link-more--'][class*='--corpo'] {
  color: $color-corpo;

  .icon {
    fill: $color-corpo;
  }

  &::after {
    background-color: $color-corpo;
  }
}

.link-back-outer {
  .single-application .site-content & {
    height: 5rem + $spacing * 2;
    margin-bottom: -5rem - $spacing * 2;
    line-height: 5rem + $spacing * 2;
  }
}

.link-back {
  color: $bombay;
  transition: color 0.3s;

  .icon {
    width: 1.1em;
    height: 1.1em;
    fill: $color-primary;
    vertical-align: middle;
    transform: rotate(180deg);
  }

  &:hover {
    color: $color-primary;
  }
}

.link--icon {
  color: $bombay;
  vertical-align: bottom;
  transition: color 0.3s;

  .icon {
    width: 1.1em;
    height: 1.1em;
    margin-right: 0.4em;
    fill: $color-primary;
    vertical-align: middle;
  }

  &:hover {
    color: $color-primary;
  }
}

.link-top-outer,
[class*='link-top-outer--'] {
  text-align: right;
}

[class*='link-top-outer--'][class*='--colored'] {
  background-color: $color-gray-lighter;
}

.link-top {
  display: inline-block;
  margin: $spacing;

  .icon-outer {
    position: relative;
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin-left: 1rem;
    background-color: $color-primary;
    vertical-align: middle;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.6rem;
    height: 1.6rem;
    fill: $white;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

// .link-top-outer {
//   background-color: $color-gray-lighter;
// }

[class*='link--'][class*='--underlined'] {
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 5px;
    margin: 0 auto;
    background-color: $cello;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::after,
  &.is-active::after,
  .is-active &::after {
    opacity: 1;
  }

  @each $segment, $color in $segment-colors {
    &[class*="--#{$segment}"]::after {
      background-color: $color;
    }
  }
}

.link--bg,
[class*='link--'][class*='--bg'] {
  position: relative;
  padding: 0 0.8rem;
  color: $white;

  &::before,
  &:after {
    @include get-all-space;
    content: '';
    z-index: -1;
    background: $cello;
  }

  &:after {
    background: $picton-blue;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease-out;
  }

  &:hover::after,
  &:focus::after {
    transform: scaleX(1);
  }
}

.link--bg--light,
[class*='link--'][class*='--bg'][class*='--light'] {
  color: $cello;

  &::before {
    background: $white;
  }
}
