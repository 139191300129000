.products,
[class^='products--'] {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 2rem 0;
  list-style: none;
  border-top: 1px solid $gallery;
  border-bottom: 1px solid $gallery;

  &[class*='--related'] {
    border: 0;
  }
}

[class^='products--'][class*='--related'] {
  margin-bottom: $spacing * 3;

  @include mq('tiny', 'medium') {
    display: block;
  }
}

.products__product,
[class^='products__product--'] {
  position: relative;
  flex-grow: 0;
  width: 100%;
  padding: 0 2rem;
  text-align: center;

  @include mq('small') {
    flex-grow: 1;
    max-width: 33.33%;

    .card-solution & {
      max-width: 40%;
    }
  }

  [class*='products--'][class*='--related'] & {
    @include mq('tiny', 'medium') {
      margin: 0 auto;
      border: 0;
    }

    @include mq('medium') {
      max-width: 25%;
    }
  }

  & + & {
    border-left: 1px solid $color-gray-light;

    @include mq($until: medium) {
      margin-top: 2rem !important;

      .card-products__products & {
        margin-top: 0 !important;
      }
    }

    @include mq('small') {
      margin-top: 0;
    }
  }
}

.products__product-inner {
  @extend %text-nodecoration;
}

[class^='products__product--'][class*='--cta'] {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq('tiny', 'medium') {
    margin-top: $spacing * 4;
  }
}

.product__name {
  color: $color-primary;
}

.product__picture-outer {
  position: relative;
  width: 100%;
  // height: 15rem;
  height: auto;
  min-height: 8rem;

  .card-solution__content & {
    height: auto;
    min-height: 8rem;
  }
}

.product__picture {
  @include image-fit(contain);

  .products--related &,
  .card-products__products & {
    @include image-fit(contain, center);

    width: 100%;
    height: 100%;
  }
}

.product__headline {
  position: absolute;
  top: -0.7rem;
  left: 1.8rem;

  @include mq('large') {
    left: 2.8rem;
  }
}
