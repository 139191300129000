.launchpage-progress-bar {
  position: sticky;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.launchpage-progress-bar__progress {
  @include get-all-space;

  width: 0;
  background: $color-secondary;
}
