.submenu--about {
  @include mq('large') {
    z-index: 101;
    top: $menu-top-height;
    left: initial;
    right: 0;
    display: none;
    width: auto;
    border: 1px solid $color-gray-light;
    border-top: none;
    background-color: $white;

    &.is-open {
      display: block;
    }

    .ie11 & {
      left: auto;
      width: 30rem;
    }
  }
}

.submenu__link {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 3em;
  transition: padding-left 0.3s 0.1s ease-out, color 0.3s 0.3s ease-out;
  padding: 0.5rem 2rem;
  border-top: 1px solid $color-gray-light;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 0;
    display: block;
    width: 4px;
    height: 33.33%;
    background-color: $color-primary;
    transform: translateY(-50%);
    transition: height 0.3s, width 0.3s 0.3s ease-out;
  }

  &:hover,
  &.is-open {
    padding-left: 1rem;
    color: $white;
    transition: padding-left 0.3s 0.1s ease-out, color 0.3s 0.3s ease-out;
  }

  &:hover::after,
  &.is-open::after {
    width: 100%;
    height: 100%;
  }

  @include mq('large') {
    padding: 0.5rem 2rem 0.5rem 1rem;
    white-space: nowrap;

    .arrow {
      display: none;
    }
  }
}
