.contact__title {
  margin-top: $spacing * 4;
  margin-bottom: $spacing * 3;

  @include mq(large) {
    margin-bottom: $spacing * 4;
  }
}

.contact__subtitle {
  margin: $spacing * 2 auto $spacing * 4;
  max-width: 50em;
}

// .contact__form {
//   display: none;

//   &.is-visible {
//     display: block;
//   }
// }

.contact__form__header {
  padding: 1em 0;
  margin-bottom: $spacing * 2;
  background-color: $color-gray-lighter;
}

.contact__form__header-inner {
  position: relative;
}

.contact__form__header__back {
  position: absolute;
  fill: $color-primary;

  @include mq('tiny', 'small-l') {
    display: none;
  }
}

.contact__form__header__title {
  margin: 0;
}

.contact__form__items {
  display: flex;
  gap: 0 4rem;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-item,
  [class*='form-item--'] {
    flex-basis: 100%;

    @include mq('medium') {
      flex-basis: calc(50% - #{$spacing});
    }
  }

  [class*='form-item--'][class*='--full'] {
    flex-basis: 100%;
  }

  [class*='form-item--'][class*='--inline'] {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &:last-child {
      margin-bottom: $spacing * 1.5;
    }

    .form-label {
      line-height: 1.5;
    }

    > *:nth-child(1) {
      //flex-shrink: 0;
      margin: 0 $spacing / 2;
      cursor: pointer;
    }
  }

  [class*='form-item--'][class*='--inline-reverse'] {
    > *:nth-child(1) {
      order: 1;
    }

    > *:nth-child(2) {
      order: 0;
    }
  }

  [class*='form-item--'][class*='--light'] {
    .form-label {
      font-weight: 400;
    }
  }

  html.js & [class*='form-item--'][class*='--hidden'] {
    display: none;
  }
}

.contact__form__items {
  .form-item:first-child {
    position: relative;
    margin-bottom: 30px;

    &::after {
      @include center-xy;

      content: '';
      z-index: -1;
      top: 30px;
      background: $gallery;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
    }

    .icon {
      z-index: 1;
    }

    @include mq('medium') {
      flex-basis: 100%;

      &::after {
        width: calc(100% + 60px);
        height: calc(100% + 30px);
      }
    }
  }

  // EU only:
  .form-eu & .form-item:first-child,
  .form-eu & .type-radio {
    --padding: 1rem;

    border: 0.1rem solid $color-primary;
    margin-left: calc(var(--padding) * -1);
    margin-right: calc(var(--padding) * -1);
    padding: var(--padding);
    width: calc(100% + calc(var(--padding) * 2));

    .icon {
      right: var(--padding);
    }

    @include mq('medium') {
      --padding: 2.4rem;

      padding: 3.3rem var(--padding);
    }
  }

  .form-eu & .form-item:first-child {
    margin-bottom: 0;
    border-bottom: 0;

    &::after {
      content: none;
    }
  }

  .form-eu & .type-radio {
    padding-top: 0;
    border-top: 0;

    &:not(.visible) {
      padding-bottom: 0;

      > .form-label,
      .form-radio-outer {
        display: none;
      }
    }

    .form-radio-outer {
      display: block;
      margin-top: 2rem;

      label {
        position: relative;
        margin-left: 1.6rem;
        cursor: pointer;
      }

      @each $segment, $color in $segment-colors {
        label[for="#{$segment}"] {
          padding-left: 1.8rem;

          &::before {
            @include center-y;

            content: '';
            left: 0;
            width: 0.5rem;
            height: 3rem;
            background-color: $color;
          }
        }
      }
    }
  }
}

// Account application form

.contact__form__more {
  display: flex;
  margin-bottom: $spacing * 1.5;
  cursor: pointer;
}

.contact__form__more__trigger {
  margin-right: $spacing / 2;
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: $cello;
  // cursor: pointer;

  &::before {
    @include center-xy;

    content: '';
    display: block;
    width: 1rem;
    height: 1px;
    background-color: $white;
  }

  &::after {
    @include center-xy;

    content: '';
    display: block;
    width: 1px;
    height: 1rem;
    background-color: $white;
  }
}
