.product-related-products {
  padding: $spacing * 3 0 $spacing * 3;

  @include mq(large) {
    padding: $spacing * 6 0 $spacing * 2;
  }

  .related-products__title {
    @extend %text-uppercase;
    margin: 0;
  }

  [class*='products--'][class*='--related'] {
    margin: $spacing * 1.5 0 0;
    padding: 0;
  }
}
