.product-package {
  @include owl;

  position: relative;
  overflow: hidden;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 4;
  background: linear-gradient(180deg, rgba($color-primary, 1) 0%, rgba($color-primary, 1) 35%, rgba($white, 1) 35%);
  color: $white;

  &.no-slider {
    background: $cello;
  }

  @include mq(large) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 8;
  }
}

.product-package__title {
  @extend %text-center;
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  font-size: 2.4rem;
  line-height: 1.2em;

  @include mq(large) {
    font-size: 3.6rem;
  }
}

.product-package__subtitle {
  @extend %text-center;
  @extend %fw-light;
  @extend %ff-av-next-cond;

  font-size: 1.8rem;
  line-height: 1.2em;

  @include mq(large) {
    font-size: 2.4rem;
  }
}

.product-package__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: $spacing * 2;

  @include mq(large) {
    margin-top: $spacing * 4;
  }
}

.product-package__item {
  display: flex;
  align-items: flex-start;

  &::before {
    content: '•';
    display: block;
    margin-right: 7px;
  }

  @include mq($until: large) {
    width: 100%;

    & + & {
      margin-top: $spacing / 2;
    }
  }

  @include mq(large) {
    width: 50%;
    padding-right: $spacing * 2;

    &:nth-child(n+3) {
      margin-top: $spacing / 2;
    }
  }
}

.product-package__item__text {
  max-width: 520px;
  font-size: 1.5rem;
  line-height: 1.3em;

  @include mq(large) {
    max-width: 440px;
  }
}

.product-package__picture-outer {
  position: relative;
  margin-top: $spacing * 2;
  background-color: $white;

  @include mq(large) {
    margin-top: $spacing * 4;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.product-package__picture {
  @include image-fit;
}


.product-package__slider {
  position: relative;
  width: 100%;
  margin: $spacing auto 0;
}

.product-package__slider__list {
  @extend %list-nostyle;

  position: relative;
  // width: calc(100% - #{$spacing * 5});
  margin: $spacing * 1.5 auto 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.product-package__slider__item {
  @include get-all-space;

  display: none;
  background-color: $white;
  &.is-active {
    display: block;
    z-index: 9;
  }
}

.product-package__slider__item__picture {
  @include get-all-space;
  @include object-fit(cover, center center);

  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

[class*='product-package-slidy-controls__item--'] {
  @include center-y;

  z-index: 99;
  width: 20px;
  border: 0;
  padding: 0;
  background: transparent;

  @include mq(medium) {
    width: 30px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (11 / 6) * 100%;
  }

  &[class*='--prev'] {
    left: 0;
  }

  &[class*='--next'] {
    right: 0;
  }
}

.product-package-slidy-controls__item__arrow {
  @include get-all-space;

  fill: $color-primary;
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  [class*='product-package-slidy-controls__item--']:hover & {
    fill: $color-secondary;
  }

  [class*='product-package-slidy-controls__item--'][class*='--next'] & {
    transform: rotate(180deg);
  }
}

.product-package__slider__dots {
  @include center-x;

  z-index: 99;
  margin-top: 3rem;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.product-package__slider__dots__dot {
  width: 6px;
  height: 6px;
  background-color: #c4c4c4;
  border-radius: 50%;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  &.is-active {
    background-color: $color-primary;
  }

  & + & {
    margin-left: $spacing / 2;
  }
}


.product-package__item__legend {
  @include center-x;

  z-index: 2;
  bottom: 0;
  width: 100%;
  padding: $spacing * 2 0;
  transform: translate(-50%, 100%);
}

.product-package__item__legend-inner {
  padding: 0 $spacing * 4;
}

.product-package__item__legend__caption {
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %ff-av-next-cond;

  color: $cello;
  font-size: 1.5rem;
  line-height: 1.2em;
}

.product-package__item__legend__description {
  @extend %ff-open-sans;

  margin-top: $spacing / 4;
  color: $cello;
  font-size: 1.3rem;
  line-height: 1.3em;
}
