.tech-corner-shorts {
  overflow: hidden;
  padding: $spacing 0;

  @include mq(medium) {
    padding: $spacing * 3.2 0;
  }
}

.tech-corner-shorts__heading {
  position: relative;
  padding-right: $spacing * 5;

  @include mq(xxlarge) {
    padding-right: $spacing * 6;
  }
}

.tech-corner-shorts__heading__title {
  margin-top: 0;
  margin-bottom: $spacing * 0.5;

  em {
    color: $color-secondary;
    font-style: normal;
  }

  @include mq(xxlarge) {
    margin-bottom: $spacing;
  }
}

.tech-corner-shorts__heading__subtitle {
  @extend %ff-av-next-cond;

  margin: 0;
  color: $color-primary;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.25;

  @include mq(xxlarge) {
    font-size: 2rem;
  }
}

.tech-corner-shorts__heading__button {
  @extend %button-nostyle;

  position: absolute;
  right: 4.4rem;
  bottom: 0;
  display: grid;
  place-content: center;
  width: 4.4rem;
  height: 4.4rem;
  border: 0.1rem solid $color-primary;
  transition: $duration-default $ease-default;
  transition-property: color, background;

  .icon {
    width: 1rem;
    height: 1.5rem;
    fill: currentcolor;
    transform: rotate(-180deg);
    transition: transform $duration-default $ease-default;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus-visible {
    color: $white;
    background: $color-primary;

    .icon {
      transform: rotate(-180deg) translateX(0.2rem);
    }
  }

  & + & {
    right: 0;
    border-left: none;

    .icon {
      transform: none;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus-visible {
      .icon {
        transform: translateX(0.2rem);
      }
    }
  }

  @include mq(xxlarge) {
    right: 5.8rem;
    width: 5.8rem;
    height: 5.8rem;
  }
}

.tech-corner-shorts__list {
  @extend %list-nostyle;

  display: flex;
  margin-top: $spacing;

  .flickity-viewport {
    overflow: visible;
    width: 100%;
  }

  @include mq(medium) {
    margin-top: $spacing * 1.5;
  }

  @include mq(medium) {
    margin-top: $spacing * 2.4;
  }
}

.tech-corner-shorts__item {
  @extend %fw-normal;

  position: relative;
  display: grid;
  grid-template-columns: 1fr 3.6rem;
  grid-template-rows: max-content min-content max-content;
  gap: 0 $spacing * 1.4;
  width: 50%;

  & + & {
    margin-left: $spacing * 0.5;
  }

  @include mq(medium) {
    flex-shrink: 0;
    width: 22rem;
  }
}

.tech-corner-shorts__item__poster {
  position: relative;
  margin: 0;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 9/16;
  margin-bottom: $spacing * 0.5;
  grid-column: 1/3;
  grid-row: 1/2;
  border-radius: 0.8rem;
  background: $color-gray-lighter;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $duration-default $ease-default;
  }

  .tech-corner-shorts__item:hover &,
  .tech-corner-shorts__item:focus-within & {
    img {
      transform: scale(1.05);
    }
  }
}

.tech-corner-shorts__item__title {
  @extend %fw-normal;

  grid-column: 1/2;
  grid-row: 2/3;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.25;
  text-transform: none;

  a {
    @extend %text-nodecoration;

    display: -webkit-box;
    overflow: hidden;
    color: $color-primary;

    &::after {
      @include get-all-space;

      content: '';
      z-index: 1;
    }
  }
}

.tech-corner-shorts__item__picture {
  position: relative;
  margin: 0;
  width: 3.6rem;
  aspect-ratio: 1;
  grid-column: 2/3;
  grid-row: 2/4;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.tech-corner-shorts__item__duration {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0 0.6rem;
  color: $white;
  background: $color-primary;
  border-radius: 0.3rem;

  .icon {
    width: 1rem;
    height: 1rem;
    fill: $color-secondary;
  }
}
