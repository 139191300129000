.careers-hero {
  h1 {
    display: none;
  }
}

.careers-hero-inner {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  @extend %text-uppercase;

  max-width: 96rem;

  position: relative;
  display: flex;
  align-items: center;
  padding-top: $spacing * 5;
  padding-bottom: $spacing * 5;

  color: $cello;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 1px;

  @include mq(large) {
    margin-inline: auto;
    padding-top: $spacing * 9;
    padding-bottom: $spacing * 9;

    font-size: 10rem;
  }
}

.careers-hero__words {
  overflow: hidden;
  position: relative;
  color: $picton-blue;
  width: 100%;
  height: 4rem;

  @include mq(large) {
    height: 10rem;
  }

  ul {
    @extend %list-nostyle;
  }
}

.careers-hero__title {
  white-space: nowrap;
}

.careers-hero__word {
  position: absolute;
  top: 0;
  left: 0;
}
