.references {
  padding-top: 5rem;
}

.references__title {
  margin: 0;
  font-size: 2rem;
  text-align: center;
}

.references__list {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
  list-style: none;

  @include mq($until: 'large') {
    flex-wrap: wrap;
  }
}

.references__list__el {
  flex-basis: 25%;
  width: 25%;

  img {
    display: block;
    width: 80%;
    margin: 0 auto;

    @include mq($until: 'large') {
      width: 50%;
    }
  }

  @include mq($until: 'large') {
    flex-basis: 50%;
    width: 50%;

    &:nth-child(n+3) {
      margin-top: 5rem;
    }
  }
}
