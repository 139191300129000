.keypoints-grid {
  padding: $spacing 0;
}

.keypoints-grid__items {
  @extend %list-nostyle;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include mq('medium') {
    flex-direction: row;
  }

}

.keypoints-grid__item {
  color: $color-primary;
  margin: $spacing * 1.5 auto;
}

.keypoints-grid__item > * {
  display: block;
}

.keypoints-grid__item small,
.keypoints-grid__item strong {
  color: $color-secondary;
}
