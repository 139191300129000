[class*='popup-flatscan--'],
.popup-flatscan {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.popup-flatscan__bg {
  @include get-all-space;

  background-color: rgba($white, .9);
  cursor: pointer;
}

.popup-flatscan-inner {
  position: relative;
  width: 320px;
  max-width: calc(100% - #{$spacing * 2});
  max-height: calc(100% - #{$spacing * 2});
  background-color: #233668;

  @include mq(large) {
    width: 640px;
  }
}

.popup-flatscan__picture-outer {
  position: relative;
  width: 100%;

  background-color: $color-gray-dark;

  @include mq($until: large) {
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: (260 / 320) * 100%;
    }
  }

  @include mq(large) {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
  }
}

.popup-flatscan__picture {
  @include object-fit(cover);

  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-position: top right;
}

.popup-flatscan__content {
  position: relative;
  padding: $spacing / 2 $spacing * 2 $spacing * 2;

  @include mq(large) {
    padding: $spacing * 3;
  }
}

.popup-flatscan__content__logo {
  width: 100%;
  max-width: 240px;

  &.us {
    max-width: 320px;
  }
}

.popup-flatscan__content__text {
  @extend %ff-av-next-cond;

  margin-top: $spacing;
  color: $white;
  font-size: 1.8rem;
  line-height: 1.4em;
}

.popup-flatscan__content__btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $spacing;
}

.popup-flatscan__content__btns__agree {
  @extend %text-nodecoration;
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  position: relative;
  display: block;
  width: 100%;
  max-width: 150px;
  padding: $spacing * .75 $spacing * 2 $spacing * .75 $spacing * .75;
  background-color: $color-secondary;
  color: $white;
  font-size: 1.3rem;
  line-height: 1em;
  letter-spacing: .15em;
  transition: color .2s ease-out;

  &:hover {
    color: $color-primary;
  }
}

.popup-flatscan__content__btns__agree__icon {
  @include center-y;

  right: 15px;
  width: 15px;
  height: 15px;
  fill: $white;
  transition: fill .2s ease-out;

  .popup-flatscan__content__btns__agree:hover & {
    fill: $color-primary;
  }
}

.popup-flatscan__content__btns__later {
  @extend %text-nodecoration;

  display: block;
  margin-top: $spacing;
  border-bottom: 1px solid $white;
  padding-bottom: $spacing / 4;
  color: $white;
  font-size: 1.2rem;
  line-height: 1em;
  transition: color .2s ease-out, border .2s ease-out;

  &:hover {
    border-bottom: 1px solid $color-secondary;
    color: $color-secondary;
  }
}

.popup-flatscan__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  fill: $white;
  cursor: pointer;
  transition: fill .2s ease-out;

  &:hover {
    fill: $color-secondary;
  }
}
