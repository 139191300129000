.page-template-solution {
  background-color: $alabaster;
}

.solution__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10rem;


  .card-solution {
    &:first-child {
      .card-solution__content {
        height: auto;
      }

      @include mq('medium') {
        width: 100vw;
        margin-right: -$spacing * 2;
        margin-bottom: 12rem;
        margin-left: -$spacing * 2;

        .card-solution__visual {
          height: 65rem;

          &.has-video {
            &::before {
              top: initial;
              bottom: 4rem;
              left: 4rem;
              transform: none;
            }

            &::after {
              top: initial;
              bottom: 4rem;
              left: 4rem;
              transform: translate(100%, -65%);
            }
          }
        }

        .card-solution__content {
          max-width: 500px;

          margin: -45rem 0 0 38%;
        }
      }
    }
  }

  @include mq('xlarge') {
    .card-solution {
      &:first-child {
        margin-right: -$spacing * 6;
        margin-left: -$spacing * 6;
      }
    }
  }
}
