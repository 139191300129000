.event-card {
  width: 100%;
}

.event-card__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 53%;

  img {
    @include fit(cover);
  }
}

.event-card__text {
  background: $white;
  padding: 3rem;

  p {
    margin: 0;
  }
}

.event-card__year {
  display: none;
}

@include mq(medium) {
  .event-card {
    // display: flex;
    // flex-wrap: wrap;
    // max-width: 25rem;
    width: 100%;
    height: 45rem;
    position: relative;
  }

  .event-card__image {
    position: absolute;
    bottom: 50%;
    width: 25rem;
    padding-bottom: 30%;
    left: 10rem;
  }

  .event-card__text {
    position: absolute;
    width: 25rem;
    top: 50%;
  }

  .event-card__year {
    @include center-y;
    display: block;
    // z-index: 10;
    margin-top: -2rem;
  }

  // line
  .event-card:before {
    @include center-y;
    content: '';
    display: block;
    width: 100%;
    background: $cello;
    height: 0.1rem;
    z-index: 100;
  }

  // box
  .event-card:after {
    @include center-y;
    content: '';
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    background: $cello;
  }

  .event-card.odd {
    .event-card__image {
      bottom: auto;
      top: 50%;
      left: 10rem;
    }

    .event-card__text {
      top: auto;
      bottom: 50%;
      left: 0;
    }

    .event-card__year {
      margin-top: 2rem;
    }
  }

  .event-card.last {
    &:before {
      display: none;
    }
  }
}
