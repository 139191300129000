.page-template-php {
  overflow-x: hidden;
}

.page-header {
  position: relative;

  @include mq('large') {
    overflow: hidden;
    max-width: 100vw;
  }
}

.panels-shoebox {
  // overflow: hidden;
  position: relative;
  z-index: 10;
  // height: calc(100vw * 7.77 / 16); // 16/7.77 ratio w: 1440 h: 700
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16.5) * 100%; // .5 to canvas with image
  }

  @include mq($until: 'large') {
    overflow: initial;
  }
}


.panels-shoebox__curtain,
[class*='panels-shoebox__curtain--'] {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 50.1%;
  background: $white;
  transform-origin: top center;

  &[class*='--right'] {
    top: auto;
    bottom: 0;
    transform-origin: bottom center;
  }
}

.panels-shoebox__curtain-title-ctn {
  overflow: hidden;
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 130px;
  pointer-events: none;

  transform: translate(-50%, -50%);
}

.panels-shoebox__curtain-title {
  overflow: hidden;
  margin: 0;
  font-size: 2rem;
  text-align: center;
  pointer-events: none;

  opacity: 0;

  @include mq($from: 'ml') {
    font-size: 8rem;
  }
}

.panels-shoebox__curtain-subtitle {
  display: block;
  width: 100%;
  text-align: center;
  color: $cello;
  font-size: 1.2rem;
  text-align: center;
  pointer-events: none;

  opacity: 0;

  @include mq($from: 'ml') {
    font-size: 3rem;
  }
}

.panels-shoebox__background-ctn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: 0 0 ;
  pointer-events: none;
  will-change: transform;
}

.panels-shoebox__contact {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 5px;
  display: flex;
  align-items: center;
  border: 1px solid $cello;
  padding: 1rem;
  background-color: $white;
  text-decoration: none;
  transform: translateY(5px);
  transition: opacity .3s ease, background-color .3s ease, transform .3s ease;

  &.is-fixed {
    position: fixed;
    transform: translateY(6rem);
  }

  .icon {
    width: 1.5rem;
    height: 1rem;
    margin-right: 5px;
    fill: $cello;
    transition: fill .3s ease;
  }

  .zoomed & {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    background-color: $cello;

    .icon {
      fill: $white;
    }
  }

  @include mq(small-l) {
    top: 0;
    right: 2rem;
    left: auto;

    &.is-fixed {
      transform: translateY(7.5rem);
    }

    // Office shoebox has a point top right
    .page-id-13461 &,
    .page-id-13636 & {
       right: auto;
       left: 2rem;
     }
  }

  @include mq(medium) {
    padding: 2rem;
  }

  @include mq(large) {
    transform: translateY(2rem);

    &.is-fixed {
      transform: translateY(2rem);
    }
  }
}

.panels-shoebox__contact__label {
  @extend %fw-bold;
  color: $cello;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  transition: color .3s ease;

  .panels-shoebox__contact:hover & {
    color: $white;
  }

  @include mq(medium) {
    font-size: 1.3rem;
  }
}

.panels-shoebox__background {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#shoebox-image-holder {
  width: 100%;
  height: 100%;
}

.panels-shoebox-item__infos__title {
  margin: 0;
}

.panels-shoebox__image-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.panels-shoebox__image-list__el {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  img {
    object-fit: cover;
  }

  // &.is-active {
  //   transform: scale(1);
  // }
}

.panels-shoebox__background__chunk {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
