.hero-pictos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.hero-pictos__title {
  margin-top: 1.6rem;
  text-align: center;
  max-width: 80%;
  margin-bottom: 1.6rem;

  @include mq(large) {
    font-size: 6.4rem;
  }
}

.hero-pictos__headline {
  font-size: 1.3rem;
  font-weight: 700;
  color: $cello;
  text-transform: uppercase;
}

.hero-pictos__content {
  display: flex;
  gap: 2rem;

  @include mq(large) {
    gap: 3rem;
  }
}

.hero-pictos__content img {
  width: 9rem;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}
