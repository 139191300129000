.features {
  margin-bottom: $spacing * 3;

  @include mq('medium') {
    display: flex;
    flex-wrap: wrap;
  }
}

.features__item {
  border-bottom: 1px solid #b2b4b9;
  padding-bottom: $spacing;

  @include mq($until: medium) {
    margin-bottom: $spacing * 2;
  }

  @include mq(medium) {
    flex-basis: calc(50% - #{$spacing * 1.5});

    &:nth-child(odd) {
      margin-right: $spacing * 1.5;
    }

    &:nth-child(even) {
      margin-left: $spacing * 1.5;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(large) {
    flex-basis: calc(50% - #{$spacing * 3});
    padding-bottom: $spacing * 2;

    &:nth-child(odd) {
      margin-right: $spacing * 3;
    }

    &:nth-child(even) {
      margin-left: $spacing * 3;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 4;
    }
  }
}

.features__item__image {
  width: 100%;
  height: auto;
}

.features__item__title {
  @extend %ff-open-sans;

  margin: $spacing 0 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3em;

  @include mq(large) {
    font-size: 2.2rem;
  }
}

.features__item__description {
  @include owl;

  margin-top: $spacing * .75;
  font-size: 1.3rem;
  line-height: 1.6em;

  @include mq(large) {
    font-size: 1.5rem;
  }
}
