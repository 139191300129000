.header-products,
[class*='header-products--'] {
  padding-bottom: 1rem;
  background-color: $alabaster;
  background-image: linear-gradient(to top, $alabaster 0%, $white 100%);

  @include mq($until: 'large') {
    padding-top: 2rem;
  }
}

/**
 * Display (name + picture)
 */
.header-products__display {
  display: flex;
  flex-direction: column;
  min-height: 24rem;

  // @include mq('medium') {
  //   min-height: 44rem;
  // }

  @include mq('large') {
    flex-direction: row;
    align-items: center;
  }
}

.header-products__display__name__items {
  @include mq($until: 'large') {
    display: flex;
    justify-content: center;

    > div + div {
      margin-left: 2rem;
    }
  }
}

/**
 * Name
 */
.header-products__display__name {
  position: relative;

  @include mq('large') {
    width: 30%;
    margin-right: 10%;
    flex-shrink: 0;
  }
}

.header-products__display__name__title {
  margin: 0;
  font-size: 3.6rem;

  @include mq('large') {
    font-size: 5rem;
  }
}

.header-products__display__name__subtitle {
  @extend %ff-open-sans;

  max-width: 260px;
  margin: 0 auto $spacing * 1.5;
  color: $color-primary;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.7;

  @include mq('large') {
    margin: $spacing / 2 0;
  }
}

.header-products__display__name__extras-infos,
.header-products__display__name__colors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq(large) {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }
}

.header-products__display__name__colors {
  @include mq($until: large) {
    margin-top: $spacing / 2;
  }
}

.header-products__display__name__extras-infos__label,
.header-products__display__name__colors__label {
  font-size: 1rem;
  color: $color-primary;

  @include mq(medium) {
    font-size: 1.3rem;
    margin-right: 10px;
  }
}

.header-products__display__name__colors__list {
  @extend %list-nostyle;
  display: flex;
  align-items: center;
}

.header-products__display__name__colors__list li {
  width: 30px;
  height: 30px;
  margin: 0 5px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 30px;
    height: 30px;
    stroke: $bombay;
    stroke-width: 1px;
  }
}

/**
 * Picture
 */
.header-products__display__picture {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  // Fix picture being too small: #786
  // @include mq('large') {
    // flex-grow: 0;
  // }
}

.header-products__display__picture__image {
  transition: opacity $duration-default * 1.5 $ease-default;
  transform-origin: 100% 0;
  flex: none;

  .is-loading > &,
  &.is-hidden {
    opacity: 0;
  }
}

.header-products__display__picture__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: $spacing * -0.5 0 0 $spacing * -1;

  .is-loading > & {
    opacity: 1;
  }
}

.header-products-container {
  @include mq('large') {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

/**
 * Controller
 */
.header-products__controller {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;

  @include mq('large') {
    justify-content: flex-end;
    justify-self: flex-end;
    // 40% + 5% - margin/spacing
    padding: 0 5% 0 calc(45% - #{$spacing * 2});
    margin-left: auto;
    padding: 0;
  }
}

.header-products__controller__thumb {
  max-width: 25%;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.6s;

  @include mq($until: 'large') {
    width: 6rem;
  }

  &.is-active,
  &:hover {
    opacity: 1;
  }

  img {
    max-height: 25rem;
  }
}

.header-products__back {
  .link-back-outer {
    margin: 2rem 0 0;

    @include mq($until: 'large') {
      text-align: center;
      margin: 2rem 0 3rem;
    }
  }
}

.header-products__popup-trigger {
  position: relative;
  margin-bottom: $spacing * 2;

  @include mq($until: large) {
    display: flex;
    justify-content: center;
    margin-top: $spacing * 3;
  }

  &.is-open {
    .header-products__popup {
      display: block !important;
    }
  }

  @include mq('large') {
    margin-right: auto;
  }
}

.header-products__popup-trigger__thumbnail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 160px;
  border: 1px solid $alto;
  padding: $spacing / 2;
  border-radius: 10px;
  cursor: pointer;

  //  @include mq(large) {
  //   position: absolute;
  //   bottom: 0;
  // }

  &:hover {
    border: 1px solid $color-secondary;
  }
}

.header-products__popup-trigger__thumbnail__picture-outer {
  position: relative;
  width: calc(100% - #{$spacing});

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.header-products__popup-trigger__thumbnail__picture {
  @include fit(contain);

  transform: scale(1.3);
}

.header-products__popup-trigger__thumbnail__title {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-left: $spacing / 2;
}

.header-products__popup-trigger__thumbnail__title__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-open-sans;

  margin-right: $spacing / 2;
  color: $color-primary;
  font-size: 1rem;
  line-height: 1em;
  white-space: nowrap;
}

.header-products__popup-trigger__thumbnail__title__icon {
  width: 25px;
  height: 25px;
  transform: translateX(-5px);

  .icon {
    width: 100%;
    height: 100%;
    fill: $color-primary;
  }
}

.header-products__popup {
  @include get-all-space;

  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  background-color: rgba($color-primary, 0.9);
  opacity: 0;
  display: none;
}

.header-products__popup-inner {
  @include center-xy;

  width: calc(100% - #{$spacing * 4});
  height: calc(100% - #{$spacing * 10});
  background-color: $white;
  margin-left: 0 !important;

  &::before {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: -1rem;
    width: 2rem;
    height: 0.2rem;
    transform: translateY(-1rem) rotate(45deg);
    background-color: $white;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: -1rem;
    width: 0.2rem;
    height: 2rem;
    transform: translate(-0.9rem, -1.9rem) rotate(45deg);
    background-color: $white;
  }

  @include mq(medium) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.header-products__popup__picture-outer {
  position: relative;
  width: 100%;
  height: 50%;
  min-height: 25rem;

  @include mq(medium) {
    width: 60%;
    height: 100%;
  }

  @include mq(large) {
    width: 65%;
  }
}

.header-products__popup__picture {
  @include get-all-space;
  @include object-fit(contain, center center);

  width: 100%;
  height: 100%;
}

.header-products__popup__content {
  // margin-top: $spacing * 5;
  margin: $spacing;

  @include mq('medium') {
    margin-left: 6%;
    margin-right: $spacing * 2;
  }

  @include mq('large') {
    margin-right: $spacing * 3;
  }
}

.header-products__popup__content__title {
  @extend %ff-av-next-cond;
  @extend %text-uppercase;

  color: $color-primary;
  font-size: 3.6rem;
  line-height: 1.4em;

  @include mq($until: medium) {
    margin-top: $spacing * 2;
  }

  strong {
    @extend %fw-bold;

    display: block;
    font-size: 1.25em;
  }
}

.header-products__popup__content__text {
  @extend %ff-open-sans;

  color: $color-primary;
  margin-top: $spacing * 2;
  font-size: 1.5rem;
  line-height: 1.3em;
}

.header-products__popup-trigger__label {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: absolute;
  top: 0;
  left: 50%;
  color: $color-primary;
  transform: translate(-50%, -120%);

  @include mq(medium) {
    margin-right: 10px;
    left: initial;
    transform: translate(-10px, -120%);
  }
}

.header-products__label,
[class*='header-products__label--'] {
  @extend %text-uppercase;
  @extend %fw-bold;

  display: block;
  width: fit-content;
  padding: 0.2rem 0.7rem 0.2rem 0.8rem;
  font-size: 1.1rem;
  background-color: $cello;
  color: $white;
  border-radius: 0.5rem;
  margin: 0 auto $spacing / 2;

  @include mq('large') {
    margin-left: 0;
  }
}

.header-products__label--discontinued {
  background-color: $alizarin-crimson;
}
