.switcher,
[class*=switcher--], {
  @extend %list-nostyle;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing / 2 0;

  @include mq(large) {
    flex-direction: row;
  }
}

[class*="switcher--"][class*="--gray"] {
  background-color: $gallery;
}

.switcher__switch {
  margin: $spacing / 2 $spacing $spacing / 2;
}
