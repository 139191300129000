/// Modifier generator
/// @author tm
/// @param {String} $module - Module name, for classnames
/// @param {Map} $map - Modifier name + value OR Modifier name + map(property: value)
/// @param {String} $property [null] - Property (if simple map, with no properties, is used above
/// @content [common rules for the module class]
/// @example scss - Modifier generator (single property)
///   $tags: (
///     'sport': yellow,
///     'media': green
///   );
///   @include modifier-generator('tags', $tags, 'color') {
///     margin: 1em;
///   }
///
///   // .tags,
///   // [class*="tags--"] {
///   //   margin: 1em;
///   // }
///   //
///   // .tags--sport {
///   //   color: yellow;
///   // }
///   //
///   // .tags--media {
///   //   color: green;
///   // }
///
///   $labels: (
///     'news': (
///       'color': blue,
///       'font-size': 12px
///     ),
///     'pro': (
///       'color': pink,
///       'font-size': 16px
///     )
///   );
///   @include modifier-generator('labels', $labels) {
///     margin: 1em;
///   }
///
///   // .labels,
///   // [class*="labels--"] {
///   //   margin: 1em;
///   // }
///   //
///   // .labels--news {
///   //   color: blue;
///   //   font-size: 12px;
///   // }
///   //
///   // .labels--pro {
///   //   color: pink;
///   //   font-size: 16px;
///   // }

@mixin modifier-generator($module, $map, $property: null) {
  .#{$module},
  [class*='#{$module}--'] {
    @content;
  }

  @each $name, $value in $map {
    @if type-of($value) == 'map' {
      .#{$module}--#{$name} {
        @each $prop, $val in $value {
          #{$prop}: $val;
        }
      }
    } @else {
      @if $property == null {
        @error "Invalid or missing property parameter";
      } @else {
        .#{$module}--#{$name} {
          #{$property}: $value;
        }
      }
    }
  }
}

/**
 * RWD
 */

@mixin hi-res($img) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 196dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: url($img);
    @content;
  }
}

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars(
  $size: 6px,
  $foreground-color: $black,
  $background-color: $white
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
}

@mixin background-cover {
  background-position: center center;
  background-size: cover;
}

@mixin center-xy {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@mixin center-x {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

@mixin center-y {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

@mixin get-all-space {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    object-position: $position;
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin fit($type: cover, $position: null) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  @include object-fit($type, $position);
}

@mixin proto-right-arrow($size, $color) {
  width: 0;
  height: 0;
  border-left: $size solid $color;
  border-right: $size solid transparent;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
}

@mixin proto-bottom-arrow($size, $color) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
  border-bottom: $size solid transparent;
}

@mixin proto-left-arrow($size, $color) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid $color;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
