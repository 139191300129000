//Avoid typekit FOUT
html {
  &.js {
    /*opacity: 0;*/
    transition: opacity .2s ease-in-out;

    &.wf-active,
    &.wf-inactive {
      opacity: 1;
    }
  }
}

body {
  color: $nevada;

  > svg {
    display: none;
    // fill: currentColor;

    #light {
      opacity: .3;
    }
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
[class*="h3--"][class*="--subtitle"],
h4,
.h4,
h5,
.h5 {
  color: $color-primary;
}


a {
  color: $color-secondary;
  fill: $color-secondary;
  transition: .2s color $ease-default,
    .2s fill $ease-default;

  &:hover,
  &:focus {
    color: $color-primary;
    fill: $color-primary;
  }
}

ul {
  padding-left: 20px;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

.site-inner {
  @include mq($until: 'large') {
    padding-top: 5.5rem;
  }
}

.hidden-title {
  @extend %visually-hidden;
}
