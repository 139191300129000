.facts__title {
  margin-top: 0;
  margin-bottom: 4rem;
  font-size: 1.8rem;
  text-transform: uppercase;

  @include mq(large) {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.facts__list {
  display: flex;
  flex-wrap: wrap;
}

.facts__item {
  margin-bottom: 5rem;

  @include mq(medium) {
    width: 50%;
    margin-bottom: 9rem;
    padding-right: 7.5rem;
  }

  @include mq(xlarge) {
    padding-right: 10rem;
  }
}

.facts__item__icon {
  margin-bottom: 3rem;
  width: 6rem;
  height: 6rem;
  fill: $cello;

  @include mq(large) {
    width: 10rem;
    height: 10rem;
  }
}

.facts__item__title {
  @extend %ff-open-sans;

  margin-top: 0;
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 28px;

  @include mq(large) {
    margin-bottom: 2rem;
    padding-right: 10rem;
    font-size: 2.4rem;
    line-height: 1.5;
  }

  @include mq(xlarge) {
    padding-right: 14rem;
  }
}

.facts__item__text {
  font-size: 1.4rem;
  line-height: 2.2rem;

  @include mq(large) {
    padding-right: 10rem;
  }

  @include mq(xlarge) {
    font-size: 1.5rem;
    line-height: 2.4rem;
    padding-right: 14rem;
  }
}
