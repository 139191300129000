
.article-single {
  margin-top: $spacing * 2.5;
  color: $cello;
  font-size: 1.5rem;
  line-height: 1.6;

  h2 {
    margin: 0 0 $spacing;
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  @include mq(ml) {
    max-width: 80rem;
    margin: $spacing * 2.5 auto 0;
  }

  @include mq(large) {
    h2 {
      margin-bottom: $spacing * 1.5;
    }
  }
}
