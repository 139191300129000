.grid-categories {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem auto;
  max-width: 70rem;
}

.grid-categories__category {

  width: 100%;

  @include mq('medium') {
    width: 33.33%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

.grid-categories__category:first-child {
  
  @include mq('medium') {
    width: 66.66%;
  }

}
