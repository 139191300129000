.picto {
  fill: transparent;
  stroke: $cello;

  .page-template-shoebox & {

    height: 5rem;
  }
}

#picto-hygiene,
#picto-safety,
#picto-energy,
#picto-smartopening,
#picto-trafficflow {
  fill: $cello;
  stroke: transparent;
}

.block-picto__title {
  margin-top: 1.5rem;
}

.block-picto__baseline {
  margin-top: .7rem;
  line-height: 1.7rem;
}