.discounted-popup,
[class*='discounted-popup--'] {
  @include get-all-space;

  position: fixed;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.discounted-popup__bg {
  @include get-all-space;

  z-index: 1;
  background-color: $cello;
  opacity: 0.9;
}

.discounted-popup__close {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 2;
  padding: 0.5rem;
  border: 0;
  line-height: 1;
  background-color: transparent;

  &.inner {
    display: none;
  }

  [class*='discounted-popup--'][class*='--soon'] & {
    display: none;

    &.inner {
      top: 1rem;
      right: 1rem;
      display: block;
    }

    svg {
      fill: $cello;
    }
  }

  svg {
    width: 3rem;
    height: 3rem;
    fill: $white;
  }
}

.discounted-popup__content {
  @include scrollbars(4px, $cello);

  position: relative;
  overflow: auto;
  z-index: 2;
  width: 100%;
  max-width: 61.5rem;
  max-height: 97vh;
}

.discounted-popup__old {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #e8e8e8;

  @include mq(xlarge) {
    padding: 5rem 2rem 6.5rem;

    [class*='discounted-popup--'][class*='--soon'] & {
      padding: 5rem 2rem;
    }
  }

  @include mq(xxxlarge) {
    padding: 6rem 2rem 7.5rem;

    [class*='discounted-popup--'][class*='--soon'] & {
      padding: 6rem 2rem;
    }
  }
}

.discounted-popup__old__headline {
  margin-left: auto;
  margin-bottom: 2rem;

  [class*='discounted-popup--'][class*='--soon'] & {
    display: none;
  }

  @include mq(xlarge) {
    margin-bottom: 5rem;
  }
}

.discounted-popup__old__title {
  @extend %ff-av-next-cond;
  @extend %fw-bold;

  color: $nevada;
  font-size: 3rem;
  line-height: 32px;
  text-transform: uppercase;
}

.discounted-popup__old__baseline {
  @extend %ff-av-next-cond;
  @extend %fw-bold;

  color: $nevada;
  font-size: 2.4rem;
  line-height: 1;
  text-transform: uppercase;
}

.discounted-popup__old__picture {
  position: absolute;
  bottom: 0;
  height: 100%;
  max-height: 13rem;
  opacity: 0.15;

  [class*='discounted-popup--'][class*='--soon'] & {
    @include center-y;
  }

  @include mq(xlarge) {
    bottom: 3rem;
    max-height: 18rem;

    [class*='discounted-popup--'][class*='--soon'] & {
      bottom: auto;
    }
  }
}

.discounted-popup__replacement {
  padding: 4rem 2rem;
  background-color: $white;

  .product-discontinued__headline {
    @extend %fw-bold;

    color: $picton-blue;
    font-size: 2rem !important;
  }

  @include mq(xlarge) {
    padding: 5.5rem 2rem;
  }

  @include mq(xxxlarge) {
    padding: 6.5rem 2rem;
  }
}
