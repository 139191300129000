.launchpage-accessories {
  overflow: hidden;
}

.launchpage-accessories__title {
  margin-bottom: $spacing * 2;
  text-align: center;

  @include mq(large) {
    margin-bottom: $spacing * 3;
  }
}

.launchpage-accessories-container {
  position: relative;
  height: 37rem;
}

.launchpage-accessories__list {
  @extend %list-nostyle;

  display: flex;
  height: 100%;

  .flickity-viewport {
    overflow: visible;
    height: 100%;
    width: 100%;
  }
}

.launchpage-accessories__item {
  height: 100%;

  @include mq($until: 'large') {
    &:not(:last-child) {
      padding-right: $spacing;
    }
  }
}

.launchpage-accessory {
  width: 28rem;
  max-width: 60vw;
  height: 100%;
  padding: $spacing * 4 $spacing * 2 $spacing * 2.5;
  background: $color-lp-bg;
  text-align: center;
}

.launchpage-accessory__picture {
  display: block;
  width: 100%;
  height: 10rem;
  margin-bottom: $spacing * 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.launchpage-accessory__baseline {
  color: $bombay;
}

.launchpage-accessories__nav {
  @extend %list-nostyle;

  display: none;
}

.launchpage-accessories__control,
.launchpage-accessories__nav__item__button {
  @extend %button-nostyle;

  .label {
    @extend %visually-hidden;
  }
}

.launchpage-accessories__controls {
  @include center-x;

  bottom: -13rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .no-slider & {
    display: none;
  }
}

.launchpage-accessories__control {
  padding: 4rem;
  color: $cello;
  transition: opacity $ease-lp;

  .icon {
    fill: currentColor;
    width: 4rem;
    height: 7rem;
  }

  &:first-child {
    .icon {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    color: $color-secondary;
  }

  &:disabled,
  &[disabled] {
    opacity: 0;
  }
}

.slidy-nav {
  @extend %list-nostyle;
}

@include mq(large) {
  .launchpage-accessories {
    &.no-slider {
      padding-bottom: 7rem;
    }
  }

  .launchpage-accessories-container {
    height: 37rem;
  }

  .launchpage-accessories__list {
    @include get-all-space;

    z-index: 1;
    margin: 0 10%;

    .no-slider & {
      display: flex;
      justify-content: center;
    }
  }

  .launchpage-accessories__item {
    position: absolute;
    display: none;
    width: 30%;
    height: 100%;
    left: 0;
    opacity: 0;

    &:nth-child(3n + 2) {
      left: 35%;
    }

    &:nth-child(3n + 3) {
      left: unset;
      right: 0;
    }

    .no-slider & {
      position: static;

      &:not(:first-child) {
        margin-left: 5%;
      }
    }
  }

  .launchpage-accessory {
    width: 100%;
    max-width: none;
    height: 100%;
  }

  .launchpage-accessory__picture {
    margin-bottom: $spacing * 3.5;
  }

  .launchpage-accessories__controls {
    @include center-xy;

    width: calc(100% + 8rem);
  }

  .launchpage-accessories__nav {
    @include center-x;

    bottom: -9rem;
    display: flex;
  }

  .launchpage-accessories__nav__item__button {
    position: relative;
    width: 3.8rem;
    height: 1rem;
    margin: 1rem;

    &:hover::before,
    .is-active &::before {
      background: $color-secondary;
    }

    &::before {
      @include center-xy;

      content: '';
      width: 100%;
      height: 0.3rem;
      background: $alto;
    }
  }
}

@for $i from 0 to 10 {
  .launchpage-accessories[data-current-group='#{$i}'] {
    .launchpage-accessories__item[data-group='#{$i}'] {
      display: block;
      opacity: 1;
    }

    .launchpage-accessories__nav__item__button[data-group='#{$i}'] {
      &::before {
        background: $color-secondary;
      }
    }
  }
}
