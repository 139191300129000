.careers-list {
  &.is-gray {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;

    @include mq(large) {
      padding-top: $spacing * 4.5;
      padding-bottom: $spacing * 4.5;
    }

    @include mq(xlarge) {
      padding-top: $spacing * 6;
      padding-bottom: $spacing * 6;
    }

    @include mq(xxlarge) {
      padding-top: $spacing * 8;
      padding-bottom: $spacing * 8;
    }

    background-color: $careers-gray-2;
  }
}

.section-title {
  .is-gray & {
    margin-top: 0;
  }
}

.careers-list-inner {
  max-width: 96rem;

  @include mq(large) {
    margin-inline: auto;
  }
}

.careers-list__list {
  @extend %list-nostyle;

  margin-top: 5rem;
  border-bottom: 1px solid $color-gray-light;
}

.careers-list__warning {
  border: 1px solid $color-gray;
  padding: 1rem;
  color: $color-gray;
}

.careers-list__list__title {
  @extend %text-uppercase;

  margin: 3rem 0 2rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: $av-next-cond;
  font-weight: 700;
  color: $cello;

  .is-gray & {
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include mq(large) {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }
}

.careers-list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid $color-gray-light;
  padding: 2.6rem 0 3.2rem;

  .is-gray & {
    padding: 3rem 0;

    @include mq($until: large) {
      flex-direction: row;
      align-items: center;
      padding: 1rem 0;
    }
  }

  @include mq('small-xl') {
    flex-direction: row;
    align-items: center;
  }
}

.careers-list__item__title {
  flex-grow: 1;
  color: $cello;
  line-height: 1.2em;
  transition: color 0.2s;

  .careers-list__item:hover &,
  .careers-list__item:focus & {
    .is-gray & {
      color: $picton-blue;
    }
  }

  .is-gray & {
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include mq(large) {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }
}

.careers-list__item__btn {
  flex-shrink: 0;

  .is-gray & {
    width: 3rem;
    height: 3rem;
    display: flex;
    border-radius: 3rem;
    background-color: white;

    svg {
      fill: black;
      transition: fill 0.2s;
    }
  }

  .careers-list__item:hover &,
  .careers-list__item:focus & {
    .is-gray & {
      svg {
        fill: $picton-blue;
      }
    }
  }

  @include mq($until: 'small-xl') {
    align-self: flex-end;
    margin-top: $spacing / 2;
    .is-gray & {
      margin-top: 0;
    }
  }

  @include mq('small-xl') {
    margin-left: $spacing;
  }
}

.careers-list__item__link {
  @include get-all-space();
  z-index: 2;
}

// Update Workday
.careers-list__intro {
  margin-bottom: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $cello;
}

.careers-list__intro__picture {
  width: 16.8rem;
  margin-bottom: 3.6rem;
}

.careers-list__intro__title {
  @extend %ff-open-sans;

  margin-top: 0;
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 28px;

  @include mq(large) {
    margin-bottom: 2rem;

    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.careers-list__intro__text {
  font-size: 1.4rem;
  line-height: 2.2rem;
  max-width: 72rem;

  @include mq(xlarge) {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}
