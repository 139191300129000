$bp: 'large';

.tech-corner-technical {
  padding: $spacing * 1.5 0;
  background: $careers-gray-2;

  @include mq($bp) {
    padding: $spacing * 3.2 0;
  }

  @include mq(xlarge) {
    padding: $spacing * 6 0;
  }
}

.tech-corner-technical-inner {
  position: relative;

  @include mq($bp) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.tech-corner-technical__heading__title {
  margin: 0;
}

.tech-corner-technical__heading__subtitle {
  @extend %ff-av-next-cond;

  margin-top: $spacing * 0.5;
  color: $color-primary;
  font-weight: 300;
}

.tech-corner-technical__filters {
  margin: $spacing 0;
  padding-bottom: $spacing * 1.5;

  @include mq($bp) {
    margin: 0;
    padding-bottom: $spacing * 3.2;
  }

  @include mq(xlarge) {
    padding-bottom: $spacing * 6;
  }
}

.tech-corner-technical__filters__title {
  color: $color-primary;
  font-size: 1.5rem;
}

.tech-corner-technical__filters__switcher {
  @extend %button-nostyle;
  @extend %fw-bold;

  display: inline-block;
  background: none;
  padding: $spacing * 0.4 $spacing * 0.8;
  color: $color-gray-medium;
  letter-spacing: 0.1167rem;
  border: 0.1rem $color-gray-medium solid;
  transition: 0.2s;

  &:hover,
  &:focus-visible,
  &.is-active {
    color: $color-primary;
    border-color: $color-primary;
  }

  & + & {
    margin-left: $spacing * 0.5;
  }
}

.tech-corner-technical__filters__title {
  color: $color-primary;
  margin-bottom: $spacing * 0.75;
}

.tech-corner-technical__filters__item {
  margin-top: $spacing;

  &:not(.is-visible) {
    display: none;
  }

  @include mq($bp) {
    margin-top: $spacing * 2;
  }
}

.tech-corner-technical__filters__select,
input.tech-corner-technical__filters__input {
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: $spacing * 0.6;
  padding: 0.3rem 0;
  font-size: 1.3rem;
  background: none;
  border: 0;
  border-bottom: 0.2rem $color-primary solid;
  border-radius: 0;

  @include mq($bp) {
    grid-row: 2/3;
    margin-top: 0;
  }
}

.tech-corner-technical__loader {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: calc(50% - 2rem);

  .tech-corner-technical__list + & {
    bottom: 10rem;
  }
}

.tech-corner-technical__list {
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacing * 0.5;
  padding: 0;
  list-style-type: none;
  transition: opacity 0.2s;

  .tech-corner-technical.is-loading & {
    opacity: 0.1;
  }

  @include mq($bp) {
    grid-column: 1/3;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing;
  }

  @include mq(xlarge) {
    gap: $spacing * 1.5;
  }
}

.tech-corner-technical__item {
  @extend %fw-normal;

  position: relative;

  &.is-hidden {
    display: none;
  }
}

.tech-corner-technical__item__poster {
  position: relative;
  margin: 0;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 0.8rem;
  background: $color-gray-lighter;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $duration-default $ease-default;
  }

  .tech-corner-technical__item:hover &,
  .tech-corner-technical__item:focus-within & {
    img {
      transform: scale(1.05);
    }
  }
}

.tech-corner-technical__item__duration {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0 0.6rem;
  color: $white;
  background: $color-primary;
  border-radius: 0.3rem;
  
  .icon {
    width: 1rem;
    height: 1rem;
    fill: $color-secondary;
  }
}

.tech-corner-technical__item__title {
  @extend %ff-av-next-cond;
  @extend %text-nodecoration;
  
  display: block;
  margin-top: $spacing * 0.5;
  color: $color-primary;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.25;
  text-transform: none;

  &::after {
    @include get-all-space;

    content: '';
    z-index: 1;
  }

  @include mq(xxlarge) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.tech-corner-technical__item__tags {
  @extend %list-nostyle;

  grid-column: 1/3;
  grid-row: 3/4;
  color: $color-gray;
  font-size: 1.2rem;

  li {
    display: inline-block;
    margin-right: 0.7rem;
  }

  li + li {
    &::before {
      content: '•';
      display: inline-block;
      margin-right: 0.7rem;
    }
  }

  @include mq(xxlarge) {
    font-size: 1.3rem;
  }
}

.tech-corner-technical__text {
  padding: $spacing;
  background: $color-gray-light;

  @include mq($bp) {
    grid-column: 1/3;
  }
}

.tech-corner-technical__load-more {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  margin-top: $spacing;
}