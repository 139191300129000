.breadcrumb {
  padding: 1rem 3rem;
  background-color: $color-gray-lighter;
}

.breadcrumb__list {
  @extend %list-nostyle;
  max-width: 118rem;
  margin: 0 auto;
}

.breadcrumb__item {
  display: inline-block;
}

.breadcrumb__item__link {
  color: $color-primary;

  &::after {
    content: '';
    position: relative;
    top: -1px;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><g fill="#1F325B"><path d="M5.858 1.04L4.249 2.606 9.534 7.75l-5.285 5.144 1.609 1.566 6.893-6.71z" fill-rule="evenodd"/></g></svg>');
    background-size: cover;
    vertical-align: middle;
  }
}
