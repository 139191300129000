.leads-generation-hero {
  position: relative;
  padding-block: 9rem;
  background: $picton-blue;

  @include mq('medium') {
    margin-bottom: $spacing * 5;
  }
}

.leads-generation-hero-inner {
  margin-inline: auto;
  width: 90%;
  position: relative;
  z-index: 2;
  color: $white;

  @include mq('medium') {
    max-width: 110rem;
  }
}

.leads-generation-hero__content {
  @include mq('medium') {
    max-width: 50%;
  }
}

.leads-generation-hero__headline {
  margin-bottom: 0.8rem;
  color: $white;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-transform: uppercase;

  @include mq('medium') {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.leads-generation-hero__title {
  margin-top: 0;
  margin-bottom: 2.5rem;
  color: $white;
}

.leads-generation-hero__infos {
  span {
    display: inline-flex;
    align-items: center;
    padding-left: $spacing * 0.5;

    &::after {
      content: '';
      display: block;
      width: 0.1rem;
      height: 1.6rem;
      background-color: $white;
      margin-left: $spacing * 0.5;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &::after {
    content: '';
    margin-top: $spacing;
    display: block;
    width: 3.5rem;
    height: 0.3rem;
    background-color: $picton-blue;
  }
}

.leads-generation-hero__intro {
  margin-top: $spacing;
  margin-bottom: $spacing * 4;
}

.leads-generation-hero__background {
  @include get-all-space();
  @include object-fit(cover);
  width: 100%;
  height: 100%;
}
