.toggle-list,
[class*="toggle-list--"] {

}

[class*="toggle-list--"][class*="--product"] {
  margin-bottom: $spacing * 3;
}

[class*="toggle-list--"][class*="--space"] {
  margin-top: $spacing * 3;
  margin-bottom: $spacing * 3;
}

.toggle-list__list {
  @extend %list-nostyle;

  border-bottom: 1px solid $color-gray-light;
}

.toggle-list__warning {
  border: 1px solid $color-gray;
  padding: 1rem;
  color: $color-gray;
}

.toggle-list__term {
  position: relative;
  border-top: 1px solid $color-gray-light;
  padding: 1em $spacing * 2 .8em 0;

  strong {
    display: inline-block;
    margin-left: 0.6em;
    padding: 0.4em 0.25em 0.25em;
    vertical-align: text-bottom;
    color: $white;
    background-color: $color-primary;
    font-size: 0.8em;
    line-height: 1;
  }

  .js & {
    cursor: pointer;
  }
}

.toggle-list__term__btn {
  position: absolute;
  top: 1.25em;
  right: 0;
  width: 15px;
  height: 15px;
  border: 0;
  padding: 0;
  background-color: $transparent;
  line-height: 15px;
  transform-origin: 50% 50%;
  transition: transform $duration-default $ease-default;

  svg {
    width: 15px;
    height: 15px;
  }

  .is-open & {
    transform: rotate(-45deg);
  }
}

.toggle-list__def {
  margin: 0;

  .cta--bordered--corpo {
    margin: $spacing * 2 0 $spacing;
  }
}

.toggle-list__def-inner {
  border: 1px solid $color-primary;
  border-width: 1px 0;
  padding: 1em 2em;
  background-color: $color-gray-lighter;

  .toggle-list--product & {
    padding: 0;
    background-color: $transparent;
  }
}

