.ctas-combo {
  display: flex;
  align-items: center;
  justify-content: center;

  .page-template-teams & {
    align-items: stretch;
  }

  @include mq($until: large) {
    flex-direction: column;
  }

  > * + * {
    margin-top: $spacing;

    @include mq(large) {
      margin-left: $spacing;
      margin-top: 0;
    }
  }
}
