.card-articles {
  position: relative;
  border: 1px solid $color-gray-medium;
  padding: 1rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: $careers-gray-2;
    border-color: $careers-gray-2;
  }

  @include mq(large) {
    padding: 2rem;
    gap: 2rem;
  }
}

.card-articles__content {
  display: flex;
  flex-direction: column;

  @include mq($until: large) {
    padding: 1rem;
  }
}

.card-articles__picture {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  background-color: $careers-gray-2;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: transform 0.2s ease-out;

    .card-articles:hover & {
      transform: scale(1.1);
    }
  }
}

.card-articles__headline {
  font-size: 1.3rem;
  color: $picton-blue;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.card-articles__title {
  @extend %ff-av-next-cond;

  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 1.6rem;

  @include mq(large) {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 1.8rem;
  }
}

.card-articles__text {
  font-size: 1.3rem;
  margin: 0;
}

.card-articles__cta {
  //overflow: hidden;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  gap: 0.8rem;
  background-color: $careers-gray;
  border-radius: 4px;
  padding: 0.8rem;
  height: 4rem;
  width: 4rem;
  transition: width 0.3s ease-out;
  text-decoration: none;
  max-width: fit-content;
  color: $careers-gray-2;

  &::before {
    @include get-all-space;

    content: '';
  }

  &:hover {
    width: 100%;
    color: $white;
    background-color: $cello;

    span {
      opacity: 1;
      transform: translateX(0);
    }

    svg path {
      fill: $white;
      opacity: 1;
    }
  }

  span {
    min-width: max-content;
    opacity: 0;
    transform: translateX(1.5rem);
    transition: all 0.3s ease-in-out;
  }

  svg {
    width: 2.4rem;
    min-width: 2.4rem;
  }

  svg path {
    fill: $cello;
    opacity: 0.5;
  }
}

.card-articles-bottom {
  display: flex;
  align-items: center;
  margin-top: 4rem;

  @include mq(large) {
    margin-top: 7rem;

    .card-documents & {
      margin-top: 4rem;
    }
  }
}

.related-slider__controls {
  margin-right: 0;
  margin-top: 0;
}
