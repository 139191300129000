.popup-call {
  position: fixed;
  z-index: 101;
  top: $menu-header-height;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  @include mq(large) {
    top: 0;
  }
}

.popup-call__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.8);
}

.popup-call__close {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  fill: $white;
  width: 4rem;
  height: 4rem;
  justify-content: center;

  svg {
    align-self: center;
  }

  &:hover {
    fill: $white !important;
  }

  @include mq(large) {
    top: 1rem;
    right: 1rem;
  }
}

.popup-call__container {
  width: calc(100% - 4rem);
  max-width: 30rem;
  padding: 6rem 2.5rem;
  position: relative;
  background: $cello;
  color: $white;

  @include mq(small-xl) {
    width: 100%;
  }

  @include mq(large) {
    max-width: 50rem;
    padding: 8rem 10rem;
  }
}

.popup-call__title {
  margin-bottom: 2rem;
  color: $white;
  font-family: 'Avenir Next LT Pro Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 3.8rem;
  line-height: 3.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;

  @include mq(large) {
    margin-bottom: 3rem;
    font-size: 4.8rem;
  }
}

.popup-call__text {
  margin-bottom: 3rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.2rem;
  text-align: center;

  @include mq(large) {
    margin-bottom: 4rem;
  }
}

.popup-call__items {
  display: flex;
  justify-content: space-between;
}

.popup-call__item {
  width: 49%;
  padding: 1.2rem 1.4rem;
  font-family: 'Avenir Next LT Pro Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.3rem;

  letter-spacing: 0.3px;
  color: $cello;
  background-color: $white;

  @include mq(large) {
    padding: 1.4rem 1.6rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.popup-call__item__label {
  text-transform: uppercase;
}

.popup-call__item__phone {
  font-weight: 700;
  color: $cello;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: $picton-blue;
  }
}
