.hr-careers {
  margin-bottom: $spacing * 3;
  background-color: $careers-gray-2;

  .duo__card {
    margin-bottom: 0;

    @include mq(large) {
      flex-direction: row-reverse;
    }
  }

  .duo__card__content {
    @include mq(large) {
      margin-right: 0;
    }

    @include mq(xlarge) {
      margin-right: 0;
    }
  }

  .duo__card__media {
    @include aspect-ratio(70, 58);

    @include mq(large) {
      margin-right: $spacing * 3.5;
    }

    @include mq(xlarge) {
      margin-right: $spacing * 5;
    }
  }

  @include mq(large) {
    margin-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    margin-bottom: $spacing * 8;
  }
}

.hr-careers-inner {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 3;

  @include mq(large) {
    padding-top: $spacing * 4.5;
    padding-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    padding-top: $spacing * 8;
  }
}
