.launchpage-scrolling-section {
  position: relative;
  margin: 0 auto;
  height: calc(100vh * 4); // 100vh * items count
}

.launchpage-scrolling-section__title {
  position: absolute;
  z-index: 1;
  top: 50vh;
  left: 0;
  opacity: 0;

  h2.h2--underline {
    max-width: 8em;
    margin: 0;
    color: $white;
  }

  @include mq(medium) {
    top: 30vh;
  }
}

.launchpage-scrolling-section__list {
  @extend %list-nostyle;

  position: sticky;
  top: 0;
  height: 100vh;
  transform: rotate(0deg);
}

.launchpage-scrolling-section__item {
  @include get-all-space;

  width: 100%;
  height: 100%;

  &:first-child {
    z-index: 2;
  }
}

.launchpage-scrolling-section__item__description {
  position: absolute;
  z-index: 1;
  top: 60vh;
  left: 0;
}

.launchpage-scrolling-section__item__picture {
  @include get-all-space;

  &::before {
    @include get-all-space;

    content: '';
    position: absolute;
    z-index: 1;
    background: $gradient-overlay-lp;
  }

  img {
    @include get-all-space;
    @include object-fit(cover);

    width: 100%;
    max-width: none;
    height: 100%;
  }
}

.launchpage-scrolling-section__item__description {
  position: absolute;
  z-index: 1;
  top: 70vh;
  left: 0;
  padding-right: 10%;
  color: $white;

  &::before {
    content: '';
    display: block;
    margin-bottom: 2.4rem;
    width: 3.5rem;
    height: 4px;
    background-color: $color-secondary;
  }

  .launchpage-scrolling-section__item:first-child & {
    top: 65vh;

    &::before {
      content: none;
    }
  }

  @include mq(small-l) {
    padding-right: 30%;
  }

  @include mq(medium) {
    padding-right: 60%;

    .launchpage-scrolling-section__item:first-child & {
      top: 45vh;
    }
  }

  @include mq(xlarge) {
    padding-right: 80rem;
  }
}

@include mq(xlarge) {
  .launchpage-scrolling-section {
    .grid-container {
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }
  }
}

// OLD

// .launchpage-scrolling-section {
//   position: relative;
//   margin: 0 auto;
//   background: mintcream;

//   // &.is-fixed {
//   //   .launchpage-scrolling-section__item__picture,
//   //   .launchpage-scrolling-section__item__description {
//   //     position: fixed;
//   //     opacity: 0;
//   //   }

//   //   .launchpage-scrolling-section__item {
//   //     position: sticky;
//   //     top: 0;
//   //     height: 100vh;
//   //     transform: rotate(0deg);
//   //   }
//   // }

//   @include mq(large) {
//     margin: 0 auto;
//   }
// }

// .launchpage-scrolling-section__title {
//   position: absolute;
//   z-index: 2;
//   top: 50vh;
//   left: 0;
//   // opacity: 0;
//   transition: opacity 0.5s;

//   h2.h2--underline {
//     max-width: 8em;
//     margin: 0;
//     color: $white;
//   }

//   // .is-fixed & {
//   //   position: fixed;
//   //   opacity: 1;
//   // }

//   @include mq(medium) {
//     top: 30vh;
//   }
// }

// .launchpage-scrolling-section__list {
//   @extend %list-nostyle;

//   // DEV
//   position: sticky;
//   top: 200px;
//   transform: translateX(0);
//   // DEV
// }

// .launchpage-scrolling-section__item {
//   // position: relative;
//   top: 0;
//   width: 100%;
//   transition: opacity 1s;

//   // DEV
//   // position: static;
//   // DEV

//   // &.is-visible {
//   //   z-index: 1;
//   //   transform: rotate(0deg);
//   // }

//   // .ready & {
//   //   position: sticky;
//   // }

//   // .is-fixed &:last-child {
//   //   .launchpage-scrolling-section__item__picture {
//   //     opacity: 1;
//   //   }
//   // }

//   // .is-fixed .launchpage-scrolling-section__item + &.is-visible {
//   //   z-index: 3;
//   // }
// }

// .launchpage-scrolling-section__item__spacer {
//   position: relative;
//   z-index: -1;
//   height: 100vh;
// }

// .launchpage-scrolling-section__item__picture {
//   @include center-x;

//   top: 0;
//   width: 100%;
//   height: 100%;
//   transition: opacity 1s;

//   // DEV
//   position: fixed;
//   height: 100vh;
//   // DEV

//   img {
//     @include get-all-space;
//     @include object-fit(cover);

//     width: 100%;
//     max-width: none;
//     height: 100%;
//   }

//   // .is-fixed .is-visible & {
//   //   height: 100vh;
//   //   opacity: 1;
//   // }

//   // &::before {
//   //   @include get-all-space;

//   //   content: '';
//   //   position: absolute;
//   //   z-index: 1;
//   //   background: $gradient-overlay-lp;
//   // }

//   //DEV
//   .launchpage-scrolling-section__item:first-child & {
//     z-index: 1;
//   }
//   //DEV
// }

// .launchpage-scrolling-section__item__description {
//   position: absolute;
//   z-index: 1;
//   top: 70vh;
//   left: 0;
//   padding-right: 10%;
//   color: $white;
//   // opacity: 0;
//   transition: opacity 1s;

//   &::before {
//     content: '';
//     display: block;
//     margin-bottom: 2.4rem;
//     width: 3.5rem;
//     height: 4px;
//     background-color: $color-secondary;
//   }

//   .is-fixed & {
//     opacity: 1;
//   }

//   .launchpage-scrolling-section__item:first-child & {
//     top: 65vh;

//     &::before {
//       content: none;
//     }
//   }

//   .is-fixed .is-visible & {
//     z-index: 5;
//     opacity: 1;
//   }

//   @include mq(small-l) {
//     padding-right: 30%;
//   }

//   @include mq(medium) {
//     padding-right: 60%;

//     .launchpage-scrolling-section__item:first-child & {
//       top: 45vh;
//     }
//   }

//   @include mq(xlarge) {
//     padding-right: 80rem;
//   }
// }
