.footer {
  background-color: $color-gray-light;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.footer__container {
  padding: $spacing 0;
  display: flex;
  flex-wrap: wrap;

  @include mq($until: 'medium') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer__logos {
  @include mq($until: 'large') {
    width: 14rem;
  }
}

.footer__logos__bea {
  width: 7rem;
}

.footer__logos__halma {
  width: 14rem;
  max-width: calc(100% - 2rem);
}

.footer__infos__socials {
  max-width: 19rem;
}

.footer__primary,
.footer__secondary,
.footer__legals,
.footer__infos__socials__networks {
  @extend %list-nostyle;
}

.footer__primary,
.footer__secondary {
  @include mq($until: 'large') {
    display: none;
  }
}

.footer__primary__link,
.footer__secondary__link,
.footer__legals__link {
  line-height: 3rem;
}

.footer__legals {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq($until: 'large') {
    align-items: flex-start;
    margin: $spacing 0;
  }

  @include mq('medium', 'large') {
    align-items: flex-end;
  }
}

.footer__legals__link-outer {
  @include mq($until: 'large') {
    & + & {
      margin-top: $spacing / 4;
    }
  }
}

.footer__infos {
  display: flex;
  flex-direction: column;
  margin-top: $spacing / 2;

  @include mq($until: 'large') {
    width: 100%;
  }
}

// .footer__infos__socials__networks {
//   display: flex;
//   justify-content: flex-start;
// }

// .footer__infos__socials__networks__link-outer {
//   margin: 0 10px;

//   &:first-child {
//     margin-left: 0;
//   }

//   &:last-child {
//     margin-right: 0;
//   }
// }

// .footer__infos__newsletter__link {
//   @extend %text-nodecoration;
//   @extend %text-uppercase;
//   @extend %ff-av-next-cond;
//   color: $picton-blue;
//   font-size: 1.4rem;

//   transition: color 0.2s ease-out;

//   &:hover {
//     color: $cello;
//   }
// }

// .footer__infos__newsletter__title,
// .footer__infos__socials__title {
//   margin: $spacing / 2 0 $spacing / 4;
// }

// .footer__infos__newsletter__form {
//   position: relative;
//   padding-bottom: 1.5em;
// }

// .footer__infos__newsletter__form__input {
//   width: 150px;
//   padding-left: 0.5em;
// }

@include mq('large') {
  .footer__primary,
  .footer__secondary,
  .footer__legals {
    flex: none;
    // width: calc(25% - 100px);
    padding-right: $spacing * 4;
    margin-bottom: 5rem;
  }

  .footer__primary,
  .footer__secondary {
    border-right: 1px solid rgba($color-gray, 0.5);
    margin-right: $spacing * 3;
  }

  .footer__logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-height: 12rem;
    max-width: 30%;
  }

  .footer__legals {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer__infos {
    min-width: 360px;
  }

  .footer__infos__newsletter__title {
    margin-top: 0;
  }
}
