.toggle-versions,
[class*="toggle-versions--"] {
  @extend %list-nostyle;

  color: $color-primary;
}

.toggle-versions__item {
  margin-bottom: $cell-spacing / 2;
  padding: $cell-spacing;
  background-color: $color-gray-lighter;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq('medium') {
    display: flex;
    align-items: center;
  }
}

.toggle-versions__item__picture {
  max-width: 32rem;
  width: 100%;

  @include mq($until: 'medium') {
    margin: 0 auto;
  }

  @include mq('medium') {
    max-width: 18rem;
    margin-right: $spacing;
  }
}

.toggle-versions__item__content {
  > :first-child {
    margin-top: 0;

    // @include mq('medium') {
    //   margin-top: .5em;
    // }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: $cell-spacing * .25;
  }

  @include mq('medium') {
    flex-grow: 1;
  }
}

.toggle-versions__item__content__title {
  margin-bottom: 0;
}

.toggle-versions__item__cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0;

  @include mq('medium') {
    justify-content: flex-end;
    min-width: 25%;
    margin: 0;
  }

}
