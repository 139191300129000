[class*="header--"],
.header {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 50%;

  .page-template-solution &,
  .page-template-tools & {
    @include mq($until: medium) {
      padding-top: 100%;
    }
  }

  @include mq(large) {
    height: 48rem;
    padding-top: 0;

    .page-template-solution &,
    .page-template-tools & {
      height: 100vw;
      max-height: 72rem;
    }
  }
}

.header__background {
  @include get-all-space();
  @include object-fit(cover);
  width: 100%;
  height: 100%;
}

.header-inner {
  @include center-xy;
  z-index: 2;
  width: 90%;

  .page-template-solution &,
  .page-template-tools & {
    width: 90%;
  }

  @include mq('medium') {
    @include center-xy;
    max-width: 110rem;
  }

  // .header--white & {
  //   color: $white;
  // }
}

.header__title,
[class*='header__title--'] {
  margin: 0;
  color: $white;

  &[class*='--blue'] {
    color: $cello;
  }

  .page-template-solution &,
  .page-template-tools & {
    position: relative;
    max-width: 60rem;
    margin: 0 0 .5em;
    padding: 0 0 .5em;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 3.5rem;
      height: 4px;
      background-color: $picton-blue;
    }
  }
}

.header__baseline,
[class*='header__baseline--'] {
  max-width: 30rem;
  color: $white;
  font-weight: normal;

  &[class*='--gray'] {
    color: $nevada;
  }

  .page-template-solution &,
  .page-template-tools & {
    @extend %text-uppercase;

    font-family: $ff-default;
  }
}
