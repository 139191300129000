.colleagues {
  margin-bottom: $spacing * 3;

  @include mq(large) {
    margin-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 8;
  }
}

.colleagues__header {
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colleagues__title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.8rem;
  text-transform: uppercase;

  @include mq(large) {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.colleagues__controls {
  display: flex;
  align-items: flex-start;
  align-items: center;
}

.colleagues__control {
  @extend %button-nostyle;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    transition: fill 0.2s;
  }

  &:first-child {
    margin-top: 1px;
    .icon {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    fill: $picton-blue;
  }

  &:disabled,
  &[disabled] {
    .icon {
      fill: #b4b4b4;
    }
  }
}

.colleagues__list {
  @include mq('tiny', 'medium') {
    margin-left: $spacing;
  }

  @include mq('medium', 'xlarge') {
    margin-left: $spacing * 2;
  }

  @include mq('xlarge') {
    max-width: calc(100% - ((100% - (1280px - #{4 * $spacing})) / 2));
    margin-right: 0;
    margin-left: auto;
  }
}

.colleagues__list__cell {
  width: 100%;
  max-width: 18rem;
  margin-right: $spacing;

  @include mq(large) {
    max-width: 30rem;
    margin-right: 7.5rem;
  }

  @include mq(xlarge) {
    max-width: 40rem;
    margin-right: $spacing * 5;
  }
}

.colleagues__card {
  @include aspect-ratio(18, 27);
  max-width: 18rem;
  border-radius: 15px;
  overflow: hidden;

  @include mq(large) {
    max-width: 30rem;
  }

  @include mq(xlarge) {
    max-width: 40rem;
  }
}

.colleagues__card-inner {
  overflow: hidden;
  display: flex;
  background-color: $careers-gray;

  &:before {
    @include get-all-space();

    content: '';
    z-index: 1;
    background: linear-gradient(
      189.64deg,
      rgba($cello, 0) 58.95%,
      $cello 91.68%
    );
  }
}

.colleagues__picture {
  @include get-all-space();
  @include object-fit(cover);

  width: 100%;
  height: 100%;

  transition: transform 0.2s;

  .colleagues__card:hover &,
  .colleagues__card:focus & {
    transform: scale(1.1);
  }
}

.colleagues__card__content {
  z-index: 2;
  margin-top: auto;
  padding: $spacing 1.8rem;
  color: white;

  @include mq(large) {
    padding: $spacing;
  }

  @include mq(xlarge) {
    padding: $spacing * 2;
  }
}

.colleagues__card__quote {
  display: none;
  margin-bottom: $spacing * 2;
  font-size: 2.4rem;
  line-height: 3.6rem;

  @include mq(xlarge) {
    display: block;
  }
}

.colleagues__card__author {
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  @include mq(large) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.colleagues__link {
  @include get-all-space();
  z-index: 2;
}

.colleagues__card__play {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  background-color: white;

  @include mq($until: 'large') {
    @include center-xy;
  }

  @include mq(large) {
    right: $spacing;
    bottom: $spacing;
  }

  @include mq(xlarge) {
    right: $spacing * 2;
    bottom: $spacing * 2;
  }
}

.colleagues__card__play__icon {
  width: 1.6rem;
  fill: $cello;
  transition: fill 0.2s;

  .colleagues__card:hover &,
  .colleagues__card:focus & {
    fill: $picton-blue;
  }
}
