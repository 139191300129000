.logos__list {
  @extend %list-nostyle;
}

.logos__item {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 12rem;
  }

}
