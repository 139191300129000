[class*='segments-all--'],
.segments-all {
  //
}

.segments-all__title {
  margin: 3rem 0;
}

.segments-all__segments {
  @extend %list-nostyle;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4rem;

  .segments-all &,
  [class*='segments-all--']:not([class*='--redux']) & {
    @include mq('large') {
      flex-wrap: nowrap;
    }
  }
}

.segments-all__segments__segment {
  position: relative;
  width: 100%;
  border-top: 1px solid #ddd;

  .segments-all &,
  [class*='segments-all--']:not([class*='--redux']) & {
    @include mq('large') {
      border-top: 0.4rem solid $color-primary;
      margin: 0 1rem;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      @each $segment, $color in $segment-colors {
        &.#{$segment} {
          border-color: $color;
        }
      }
    }
  }
}

.segments-all__segments__segment__header {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .icon {
    display: none;
    padding: 1rem;
    fill: $cello;
    transition: all 0.2s ease-out;

    @include mq(large) {
      display: block;
      height: 4.6rem;
      width: 4.6rem;
      border-radius: 100%;
      background-color: $careers-gray;
      transform: rotate(90deg);

      .is-open &,
      &:hover {
        fill: $careers-gray;
        background-color: $picton-blue;
        transform: rotate(-90deg);
      }
    }
  }
}

.segments-all__segments__segment__title {
  position: relative;
  display: block;
  padding: 2rem 2rem 2rem 4rem;

  .segments-all &,
  [class*='segments-all--']:not([class*='--redux']) & {
    @include mq('large') {
      padding: 2rem 0;
    }
  }

  @include mq($until: large) {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 2rem;
    display: inline-block;
    width: 0.4rem;
    height: 1rem;
    transform: translateY(-50%);
    background-color: $cello;

    @each $segment, $color in $segment-colors {
      .segments-all__segments__segment.#{$segment} & {
        background-color: $color;
      }
    }

    .segments-all &,
    [class*='segments-all--']:not([class*='--redux']) & {
      @include mq('large') {
        display: none;
      }
    }
  }
}

.segments-all__segments__segment__arrow {
  @include center-y();
  right: 2rem;
  width: 8px;
  height: 8px;

  .segments-all &,
  [class*='segments-all--']:not([class*='--redux']) & {
    @include mq('large') {
      display: none;
    }
  }
}

.segments-all__segments__segment__applications {
  @extend %list-nostyle;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  z-index: 9;
  gap: 2rem;
  columns: 2;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -100%);
  padding: 2rem;
  background-color: $careers-gray;
  box-shadow: 0px 11px 34px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;

  // .segments-all &,
  // [class*='segments-all--']:not([class*='--redux']) &,
  .segments-all__segments__segment.is-open & {
    @include mq('large') {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -105%);
    }
  }
}

.segments-all__segments__segment__applications__sub {
  @extend %list-nostyle;
}

.segments-all__segments__segment__applications__title {
  color: $color-secondary;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-transform: uppercase;
  white-space: nowrap;
}
