.brochure {
  @include get-all-space;
  display: none;
  position: fixed;
  z-index: 10000;
}

.brochure__layer {
  @include get-all-space;
  background: rgba($color-primary, .8);
  cursor: pointer;
}

.brochure__iframe {
  @include center-xy;
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  box-shadow: 0 0px 40px 0px rgba(0,0,0,0.4);
  background: #fff;

  iframe {
    width: 100%;
    height: 100%;
  }
}