.related-applications {
  padding: $spacing * 2 $spacing;
  background-color: $color-gray-light;
}

.related-applications__title {
  margin-bottom: $spacing * 2;
}

.related-applications__list {
  > [class*="card-application"] {
    margin-bottom: $spacing;
  }

  @include mq('large') {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > [class*="card-application"] {
      flex-basis: calc(50% - #{$spacing / 2});
    }

    > [class*="card-application"]:nth-child(odd) {
      margin-right: $spacing / 2;
    }

    > [class*="card-application"]:nth-child(even) {
      margin-left: $spacing / 2;
    }
  }
}
