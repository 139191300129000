$loader-size: $spacing * 2 !default;
$loader-circle-size: $loader-size * .2 !default;
$loader-names: map-keys($segment-colors) !default;
$loader-colors: map-values($segment-colors) !default;

.loader {
  position: relative;
  width: $loader-size;
  height: $loader-size;
  padding: $loader-size / 2 - $loader-circle-size / 2;
  animation: loader-spin 2.5s infinite linear;
  animation: none;
  transition: opacity $duration-default $ease-default;
  opacity: 0;

  .is-loading &,
  &.is-loading {
    animation: loader-spin 2.5s infinite linear;
    opacity: 1;
  }
}

[class*="loader__circle--"] {
  position: absolute;
  width: $loader-circle-size;
  height: $loader-circle-size;

  &::before {
    content: '';
    display: block;
    width: $loader-circle-size;
    height: $loader-circle-size;
    border-radius: 50%;
  }
}

@for $i from 1 through length($loader-names) {
  [class*="loader__circle--#{nth($loader-names, $i)}"] {
    transform: rotate(-#{45deg * ($i - 1)}) translateY(-#{$loader-size / 2 - $loader-circle-size / 2}) scale(#{1 / ($i - (($i - 1) * .8))});

    &::before {
      background-color: nth($loader-colors, $i);
      animation: none;

      .is-loading & {
        animation: loader-bounce 1.25s infinite ease-in-out both #{.1s * $i};
      }
    }
  }

}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(60deg);
  }

  90% {
    transform: rotate(300deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }

  20%, 60% {
    transform: scale(1);
  }
}
