.cta-services,
[class*="cta-services--"] {
  @extend %list-nostyle;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.cta-services__item {
  @extend %text-nodecoration;
  flex-grow: 1;
  width: 100%;
  margin: 1rem;
  text-align: right;

  @include mq('medium') {
    width: calc(50% - 2rem);
  }

  @include mq('large') {
    width: calc(25% - 2rem);
  }

}

html.safari .cta-services__item {
  position: relative;
  height: 100px;

  a {
    @include get-all-space;
  }

}

.cta-services__item__link {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 5rem 4rem 5rem 6rem;
  background-color: $color-primary;
  color: $white;

  &::before {
    @include center-y;
    content: '';
    bottom: 1px;
    left: 4rem;
    display: inline-block;
    width: .5rem;
    height: calc(100% - 6rem);
    background-color: $color-secondary;
    transition: width .3s, background-color .3s;
  }

  .cta-services__item:hover & {
    color: $color-primary !important;
  }

  .cta-services__item:hover &::before {
    width: calc(100% - 4rem);
    background-color: $white;
  }

}

.cta-services__item__link .cta__label {
  position: relative;
  z-index: 2;
}

.cta-services__item__link .cta__arrow {
  @include center-y;
  right: 2rem;
  fill: $white;

  .cta-services__item:hover & {
    fill: $color-primary !important;
  }

}
