.block-services {
  margin-bottom: 3rem;
  padding-top: 5.2rem;
  padding-bottom: 3rem;
}


.block-services__title {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  text-transform: uppercase;
}

.block-services__text {
  font-size: 1.3rem;
}


