.cta-call {
  display: inline-block;
  color: $color-primary;
  min-width: 180px;
  max-width: 180px;
  min-height: 36px;
  border: 1px solid $color-primary;
  padding: .75em 1.25em;

  strong {
    display: block;
  }

  &.cta--highlighted {
    display: inline-block;
    color: $color-primary;
    background: $white;

    &:hover,
    &:focus-visible {
      color: $white;
      border-color: $color-secondary;
    }
  }
}
