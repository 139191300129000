.members,
[class*='members--'] {
  position: relative;
}

.members__list__item {
  &.is-hidden {
    display: none;
  }
}

.members__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: white;
  visibility: hidden;
  opacity: 0;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
}

.members__loader__spinner {
  display: block;
  width: 100%;
  max-width: 10rem;
  margin: 5rem auto 0;
}
