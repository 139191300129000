.tools-block {
  position: relative;
  width: 100%;
  padding: 6rem 0 12rem;
  background-color: $color-gray-light;
}

.tools-block__head {
  position: relative;
  margin-bottom: 10rem;

  @include mq('medium') {
    display: flex;
    flex-direction: row-reverse;
  }
}

.tools-block__head__video-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  max-height: 35rem;

  @include mq('medium') {
    width: 60%;
    max-height: 65rem;
  }
}

.tools-block__head__video {
  @include object-fit(cover);
  @include get-all-space;

  width: 100%;
  height: 100%;
}

.tools-block__head__card {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  width: 80%;
  max-width: 60rem;
  margin: -4rem auto;
  box-shadow: 0 15px 30px 0 rgba($black, .15);
  background-color: $white;

  @include mq('medium') {
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0;
    transform: translateY(-50%);
  }
}

.tools-block__head__card-inner {
  padding: $spacing;

  @include mq('medium') {
    padding: $spacing * 3;
  }
}

.tools-block__head__card__title {
  position: relative;
  margin: 0 0 .7em;
  padding: 0 0 .7em;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 3.5rem;
    height: 4px;
    background-color: $picton-blue;
  }
}

.tools-block__head__text {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.6;
}

.tools-block__head__card__cta {
  padding: $spacing;
  background-color: $cello;
  color: $white;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;

  @include mq('medium') {
    padding: $spacing * 1.5 $spacing * 3;
  }
}

.tools-block__head__card__cta__title {
  color: $white;
  font-size: 2.4rem;
}

.tools-block__head__card__cta__items {
  display: flex;
  align-items: center;
  margin-top: $spacing;
}

.tools-block__head__card__cta__item {
  & + & {
    margin-left: $spacing;
  }
}

.tools-block__head__card__products {
  border-bottom: 0;
}

.tools-block__items {
  display: inline-flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: 0 auto;

  .common-card {
    margin: 2rem;
  }
}
