.common-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  transition: box-shadow .2s ease-out;

  &:hover {
    box-shadow: 0 15px 30px 0 rgba($black, .15);
  }

  @include mq('medium') {
    max-width: 36rem;
  }

  .card-solution__products {
    margin-right: $spacing * 1.5;
    margin-left: $spacing * 1.5;

    .products__product {
      max-width: 50%;
    }
  }

  .no-cta {
    margin-top: auto;
  }

  .cta--product--bordered {
    margin: auto $spacing * 1.5 $spacing;
  }
}

.common-card__content {
  padding: $spacing;
  padding-bottom: 0;

  @include mq('medium') {
    padding: $spacing * 1.5;
    padding-bottom: 0;
  }
}

.common-card__title {
  position: relative;
  margin: 0 0 .7em;
  padding: 0 0 .7em;
  font-size: 2.4rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 3.5rem;
    height: 4px;
    background-color: $picton-blue;
  }
}

.common-card__logo-outer {
  position: relative;
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  max-height: 35rem;
  margin: $spacing;
  margin-bottom: 0;
  border-radius: 2rem;

  @include mq('medium') {
    width: 10rem;
    height: 10rem;
    margin: $spacing * 1.5;
    margin-bottom: 0;
  }
}

.common-card__logo {
  @include object-fit;
  @include get-all-space;

  width: 100%;
  height: 100%;
}

.common-card__video-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 28rem;
}

.common-card__video {
  @include object-fit(cover);
  @include get-all-space;

  width: 100%;
  height: 100%;
}
