.launchpage-introduction {
  margin-top: $spacing * 4.5;
  margin-bottom: $spacing * 4.5;
  text-align: center;
}

.launchpage-introduction__picture,
.launchpage-introduction__video {
  width: 60%;
  margin: 0 auto $spacing * 2;

  &.format-large {
    width: 90%;
  }

  img {
    width: 100%;
  }
}

.launchpage-introduction__video {
  video {
    width: 100%;
  }
}

.launchpage-introduction__title {
  max-width: 13em;
  margin: $spacing auto;
}

.launchpage-introduction__text {
  max-width: 60rem;
  margin: 0 auto $spacing * 2;
}

.launchpage-introduction__cta {
  @extend %button-nostyle;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-decoration: none;
}

.launchpage-introduction__cta__play {
  @extend %color-secondary;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  margin-top: $spacing;
  transition: 1s ease-out;

  .icon {
    position: relative;
    left: 1px;
    fill: $white;
    width: 1.2rem;
    height: 1.2rem;
    transition: 1s ease-out;
  }

  .circle {
    @include center-xy;

    z-index: -1;
    fill: currentColor;
    transition: fill 1s $ease-lp;
  }

  .launchpage-introduction__cta:hover &,
  .launchpage-introduction__cta:focus & {
    .icon {
      transform: scale(1.2);
    }
  }
}

@keyframes lp-play-path {
  0% {
    stroke: rgba($white, 0);
    stroke-dashoffset: -190;
  }
  100% {
    stroke: $white;
    stroke-dashoffset: 0;
  }
}

@include mq(large) {
  .launchpage-introduction {
    margin: $spacing * 6 auto;
  }

  .launchpage-introduction__picture,
  .launchpage-introduction__video {
    width: 50rem;
    margin: 0 auto $spacing * 5;

    &.format-large {
      width: 80rem;
    }
  }

  .launchpage-introduction__text {
    margin: $spacing * 1.2 auto $spacing * 3;
  }
}
