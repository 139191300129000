.block-404 {
  position: relative;
  padding-top: $spacing * 1.5;
  display: flex;
  height: 75vh;

  @include mq(large) {
    padding-top: 0;
    align-items: center;
  }
}

.block-404-inner {
  position: relative;
  width: 100%;
  color: $color-primary;

  font-family: $av-next-cond;
  font-weight: 700;
}

.block-404-inner > *:first-child {
  margin-top: 0;
}

.block-404-inner > *:last-child {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  justify-content: flex-start;
}

.block-404__picture {
  @include fit(cover);
}

.block-404__video {
  @include fit(contain);

  @include mq($until: large) {
    object-position: bottom;
  }
}

.block-404__title {
  margin-bottom: 0.5rem;
  font-size: 6.8rem;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $picton-blue;

  @include mq(large) {
    font-size: 9.6rem;
  }
}

.block-404__baseline {
  // max-width: 55rem;
  width: 100%;
  font-size: 2.8rem;
  line-height: 1;
  text-transform: uppercase;
  color: $cello;

  @include mq(large) {
    max-width: 50%;
    font-size: 4.8rem;
  }
}

.block-404__ctas {
  margin-top: 3rem;

  svg {
    fill: white;
  }
}
