.links-list,
[class*="links-list--"] {

}

[class*="links-list--"][class*="--space"] {
  margin-top: $spacing * 3;
  margin-bottom: $spacing * 3;
}

.links-list__block__title {
  margin: $spacing 0;
  font-size: 2rem;
  text-align: center;
}

.links-list__block__list {
  @extend %list-nostyle;

  margin: $spacing * 2 0 $spacing * 4;
}

.links-list__block__item {
  border-top: 1px solid $color-gray-light;

  // &:last-child {
  //   border-bottom: 1px solid $color-gray-light;
  // }
}

.links-list__block__item-inner {
  position: relative;
  display: block;
  padding: $spacing 0;

  > * {
    transition: color 0.35s ease-in-out, fill 0.35s ease-in-out;
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: $color-primary;
  }

  &:hover {
    > * {
      color: $color-secondary;
      fill: $color-secondary;
    }
  }
}

.links-list__block__item__label {
  font-size: 1.8rem;
  line-height: 1.2;
}

.links-list__block__item__url {
  color: $color-gray-dark;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
