.nav-shoebox,
[class*='nav-shoebox--'] {
  position: relative;
  padding: 0;

  .back-to-environments {
    display: flex;
    align-items: center;
    color: $bombay;
    transition: color .3s ease;

    &::after {
      display: none;
    }

    .icon {
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
      fill: $color-primary;
      transition: transform .3s ease;
    }

    &:hover {
      color: $color-primary;

      .icon {
        transform: translateX(-.5rem);
      }
    }

    @include mq(large) {
      position: absolute;
      top: 50%;
      left: 3rem;
      transform: translateY(-50%);
    }
  }
}
