.en16005-choice__list {
  @extend %list-nostyle;

  @include mq(medium) {
    display: flex;
    justify-content: center;
  }
}

.en16005-choice__item-outer {
  & + & {
    margin-top: $spacing;
  }

  @include mq(medium) {
    & + & {
      margin: 0 0 0 $spacing;
    }
  }
}

.en16005-choice__item {
  @extend %button-nostyle;
  @extend %color-primary;

  width: 100%;
  padding:  $spacing;
  border: 1px solid $color-gray-light;
  border-radius: 24px;
  transition: 0.2s;

  &.is-active,
  &:hover {
    color: $white;
    background: $color-secondary;
    border-color: $color-secondary;
  }

  @include mq(medium) {
    width: auto;
    height: 100%;
    padding: $spacing * 1.8 $spacing * 4.5;
  }

  @include mq(large) {
    padding: $spacing * 1.8 $spacing * 4.5;

    .en16005-risk & {
      padding: $spacing * 2.5;
    }
  }
}

.en16005-choice__item__icon {
  @extend %color-primary;

  display: block;
  width: 10rem;
  height: auto;
  margin: 0 auto $spacing;
  fill: currentColor;

  @include mq(large) {
    width: 12rem;
  }
}

.en16005-choice__item__picture {
  display: block;
  width: 16rem;
  margin: 0 auto 2.5rem;
  mix-blend-mode: multiply;

  @include mq(large) {
    width: 20rem;
    margin-bottom: $spacing * 2.5;
  }
}

.en16005-choice__item__title {
  @extend %ff-av-next-cond;
  @extend %fw-semibold;
  @extend %text-uppercase;

  display: block;
  font-size: 1.6rem;

  @include mq(large) {
    font-size: 2.4rem;
  }
}

.en16005-choice__item__text {
  margin-top: $spacing / 2;
  line-height: 1.4;
  color: $color-gray-dark;

  .en16005-choice__item.is-active &,
  .en16005-choice__item:hover & {
    color: $white;
  }
}

.en16005-choice__item__check {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing;
  padding: 0.5rem;
  color: rgba($color-primary, 0);
  background: $color-gray-light;
  border-radius: 50%;
  transition: color 0.15s;

  svg {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 1.5;
  }

  .en16005-choice__item.is-active & {
    color: $color-primary;
  }
}

.en16005-choice__validation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing;

  @include mq(medium) {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: $spacing * 3;
  }
}

.en16005-choice__validation__back {
  flex: 1;
}
