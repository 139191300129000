.product-faqs {
  margin-top: $spacing * 3;
  margin-bottom: $spacing * 10;
}

.product-faqs__faq {
  position: relative;

  & + & {
    margin-top: 0;
  }
}

.product-faqs__faq__header {
  position: relative;
  border-top: 1px solid $alto;
  padding: $spacing * 1.25 $spacing * 3.5 $spacing * 1.25 0;
  cursor: pointer;
}

.product-faqs__faq__header__label {
  @extend %fw-normal;
  @extend %ff-open-sans;

  margin: 0;
  color: $color-primary;
  font-size: 1.8rem;
  line-height: 1.2em;
}

.product-faqs__faq__header__icon-outer {
  position: absolute;
  top: 20px;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: $color-primary;
}

.product-faqs__faq__header__icon {
  @include center-xy;

  width: 16px;
  height: 16px;
  fill: $white;
}


.product-faqs__faq-inner {
  overflow: hidden;
  height: 0;
}

.product-faqs__faq__wysiwyg {
  @extend %ff-open-sans;
  @include owl;

  margin-bottom: $spacing;
  color: $nevada;
  font-size: 1.5rem;
  line-height: 1.6em;

  .product-video-shortcode {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 2;

    @include mq(medium) {
      float: left;
      width: calc(50% - #{$spacing});

      &:nth-of-type(even) {
        margin-left: $spacing * 2;
      }

      &:nth-of-type(n + 3) {
        margin-top: 0;
      }
    }

    & + *:not(.product-video-shortcode) {
      clear: both;
    }
  }
}
