.en16005-videos {
  @extend %color-primary;

  position: relative;
  margin-top: 8.5rem;
  padding: $spacing * 2 $spacing;
  border: 1px solid $color-gray-light;

  &.exceptions {
    background: $color-gray-light;
    border: 0;
  }

  @include mq(medium) {
    padding: $spacing * 4;
  }

  @include mq(large) {
    padding: 8rem 13rem;
  }
}

.en16005-videos__title {
  @extend %ff-av-next-cond;
  @extend %fw-semibold;
  @extend %text-uppercase;
  @include center-xy;

  top: 0;
  padding: 0.5rem 3rem 0.2rem;
  color: $white;
  font-size: 1.5rem;
  line-height: 2rem;
  background: $color-primary;
}

.en16005-videos__text {
  @extend %fw-light;
  @extend %text-center;

  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 0 0 $spacing;

  @include mq(large) {
    margin-bottom: $spacing * 2;
  font-size: 2.4rem;
  line-height: 3.6rem;
  }

  @include mq(xxlarge) {
    margin-bottom: $spacing * 3;
  }
}

.en16005-videos__list {
  @extend %list-nostyle;

  @include mq(large) {
    display: flex;
    justify-content: space-between;
  }
}

.en16005-videos__item {
  & + & {
    margin-top: $spacing * 2;
  }

  @include mq(large) {
    flex-basis: 36rem;

    & + & {
      margin: 0;
    }

    &:only-child {
      margin: 0 auto;
    }
  }
}

.en16005-videos__item__picture {
  position: relative;
  height: 0;
  padding-bottom: 50%;

  img {
    @include image-fit();

    width: 100%;
    height: 100%;
  }

  @include mq(large) {
    padding-bottom: 80%;
  }
}

.en16005-videos__item__picture__cta {
  @include center-xy;
}

.en16005-videos__item__title {
  @extend %ff-av-next-cond;
  @extend %fw-semibold;
  @extend %text-uppercase;

  margin: $spacing / 2 0;
  letter-spacing: 0.05em;
}

.en16005-videos__item__text {
  color: $color-gray;
}
