.launchpage-cta {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.14);

  &.hidden {
    display: none;
    opacity: 0;
  }
}

.launchpage-cta__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  background: $white;
  width: 100%;
  color: $cello;

  // &:hover {
  //   .launchpage-cta__link__icon {
  //     color: $white;

  //     &::before {
  //       width: 100%;
  //     }
  //   }
  // }
}

.launchpage-cta__link__icon {
  position: relative;
  margin: 1rem 0;
  transition: color 0.2s;

  .icon {
    position: relative;
    z-index: 1;
    fill: currentColor;
    width: 6.2rem;
    height: 6.2rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: $cello;
    transition: width 0.2s;
  }
}

.launchpage-cta__link__label {
  @extend %ff-av-next-cond;
  @extend %fw-bold;

  display: block;
  padding: 2.4rem 2rem 2.6rem;
  color: $cello;
  font-size: 1.8rem;
  line-height: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.launchpage-cta__button {
  @extend %button-nostyle;

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  padding: 1rem;
  color: $color-primary;
  transition: color 0.2s;

  .icon {
    width: 1.4rem;
    height: 1.4rem;
    fill: currentColor;
    transform: rotate(45deg);
  }

  &:hover {
    color: $color-secondary;
  }
}

.launchpage-cta__button__label {
  @extend %visually-hidden;
}

@include mq(medium) {
  .launchpage-cta {
    right: 6rem;
    bottom: 6rem;
    width: fit-content;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  }

  .launchpage-cta__link {
    align-items: stretch;
    padding-right: 4rem;
  }

  .launchpage-cta__link__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem;
    width: 9rem;

    .icon {
      color: $white;
    }

    &::before {
      width: 100%;
    }
  }

  .launchpage-cta__link__label {
    max-width: 14rem;
    padding: 2.7rem 0 2.3rem 2.2rem;
  }
}
