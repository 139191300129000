.perks {
  padding-bottom: $spacing * 3;

  @include mq(large) {
    padding-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    padding-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    padding-bottom: $spacing * 8;
  }
}

.perk-section {
  margin-bottom: $spacing * 3;
  padding-top: $spacing;
  border-top: 1px solid #b4b4b4;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(large) {
    margin-bottom: $spacing * 6;
    padding-top: $spacing * 2;
    display: flex;
  }
}

.perk-section__header {
  @include mq(large) {
    margin-right: $spacing;
    flex: 0 0 30rem;
  }
  @include mq(xlarge) {
    margin-right: $spacing * 5;
  }
}

.perk-section__header__icon {
  width: 5rem;
  height: 5rem;
  margin-bottom: $spacing;
}

.perk-section__header__headline {
  margin-bottom: $spacing * 0.5;
  color: $picton-blue;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  @include mq(large) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.perk-section__header__title {
  margin-top: 0;
  color: $cello;
  font-size: 2.4rem;
  line-height: 3.8rem;
  font-weight: 400;

  @include mq(large) {
    font-size: 2.4rem;
    line-height: 3.8rem;
  }
}

.perk-section__list {
  margin: 0;
  padding: 0;

  @include mq(large) {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
}

.perk-section__list__item {
  margin-bottom: $spacing;
  flex: 0 0 50%;
  display: flex;

  span {
    padding-top: 2px;
    font-size: 1.4rem;
    line-height: 2.2rem;

    @include mq(large) {
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 1.5;
  }
}

.perk-section__list__item__icon {
  margin-right: $spacing * 0.75;
  display: flex;
  flex: 0 0 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: $careers-gray;

  svg {
    margin: auto;
    width: 2rem;
    fill: $picton-blue;
  }
}
