.values--grayed {
  padding-top: 5.6rem;
  padding-bottom: 4.9rem;
  background: $alabaster;
}

.values__title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
