.tools-thermotool {
  position: relative;
  width: 100%;
  margin-bottom: 10rem;

  @include mq('medium') {
    // margin-right: -$spacing * 2;
    margin-bottom: 12rem;
    // margin-left: -$spacing * 2;
  }

  // @include mq('xlarge') {
  //   margin-right: -$spacing * 6;
  //   margin-left: -$spacing * 6;
  // }
}

.tools-thermotool__video-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  max-height: 35rem;
  background-color: $new-light-blue;

  @include mq('medium') {
    max-height: 65rem;
  }
}

.tools-thermotool__video {
  @include object-fit(contain);
  @include get-all-space;

  width: 100%;
  height: 100%;

  @include mq(large) {
    left: -30%;
  }
}

.tools-thermotool__card {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  width: 80%;
  max-width: 50rem;
  margin: -4rem auto;
  box-shadow: 0 15px 30px 0 rgba($black, .15);
  background-color: $white;

  // @include mq('medium') {
  //   margin: -45rem 0 0 30%;
  // }

  @include mq('large') {
    margin: -45rem 0 0 40%;
  }


  @include mq('xlarge') {
    margin: -45rem 0 0 54%;
  }
}

.tools-thermotool__card__content {
  padding: $spacing;

  @include mq('medium') {
    padding: $spacing * 3;
  }
}

.tools-thermotool__card__title {
  position: relative;
  margin: 0 0 .7em;
  padding: 0 0 .7em;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 3.5rem;
    height: 4px;
    background-color: $picton-blue;
  }
}

.tools-thermotool__card__cta {
  padding: $spacing;
  background-color: $cello;
  color: $white;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: $picton-blue !important;

    svg {
      fill: $picton-blue !important;
    }
  }
}

.tools-thermotool__card__products {
  border-bottom: 0;
}
