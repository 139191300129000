.timeline {
  background: $alabaster2;
  padding: 5rem 0;
}

.timeline__content__cell {
  width: 100%;
  padding: 0 3rem;
  max-width: 40rem;
}

.timeline__dates {
  margin: 3rem 0 2rem 0;
}

.timline__dates__cell {
  display: block;
  width: 10rem;
  text-align: center;
  color: $bombay;
  font-size: 1.2rem;
  letter-spacing: 1px;
  transition: color 0.4s, font 0.4s;
  user-select: none;

  &:not(.is-selected) {
    cursor: pointer;
  }

  span {
    padding-top: 1rem;
    display: inline-block;
  }

  // square
  span:before {
    @include center-x;
    top: 0.1rem;
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background: $bombay;
    z-index: 10;
    transform: transform 0.4s, background 0.4s;
  }

  // line
  span:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0.3rem;
    width: 100%;
    height: 0.1rem;
    background: $gallery2;
  }
}

.timline__dates__cell:first-child {
  span:after {
    left: 50%;
  }
}

.timline__dates__cell:last-child {
  span:after {
    width: 50%;
  }
}

.timline__dates__cell.is-selected {
  color: $cello;
  font-size: 1.4rem;
  font-weight: bold;

  span:before {
    background: $cello;
    transform: translateX(-50%) scale(1.3);
  }
}

@include mq(medium) {
  .timeline {
    overflow: hidden;
  }

  .timeline .flickity-slider {
    margin-left: 70px;
  }

  .timeline .flickity-prev-next-button {
    display: none;
  }

  .timeline__content__cell {
    max-width: 49rem;
    padding: 0;
  }
}
