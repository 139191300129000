[class*='product-title--'],
.product-title {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing * 3;

  @include mq(large) {
    margin-top: $spacing * 5;
  }
}

.product-title__text {
  width: 100%;
  margin: 0;
  font-size: 1.8rem;
  line-height: 1.2em;

  @include mq(large) {
    font-size: 3.6rem;
  }

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    margin-top: $spacing / 2;
    background-color: $picton-blue;

    @include mq(large) {
      width: 50px;
      height: 4px;
      margin-top: $spacing;
    }
  }
}

.product-title__description {
  margin-top: $spacing;
  font-size: 1.5rem;
  line-height: 1.6em;
  max-width: 860px;
  padding-bottom: $spacing * 2;

  @include mq(large) {
    margin-top: $spacing * 2;
  }
}