.launchpage-downloads {
  .en16005-products + & {
    padding-top: 0;
  }
}

.launchpage-downloads__title {
  margin-top: 0;
  margin-bottom: $spacing * 2;
  text-align: center;

  @include mq(medium) {
    margin-bottom: $spacing * 4;
  }
}

.launchpage-downloads__list {
  @extend %list-nostyle;

  @include mq(small-l) {
    padding: 0 10%;
  }

  @include mq(medium) {
    display: flex;
    justify-content: center;
  }
}

.launchpage-downloads__item {
  & + & {
    margin-top: $spacing;
  }

  @include mq(medium) {
    flex: 1;
    max-width: 50%;

    & + & {
      margin: 0 0 0 $spacing * 2;
    }
  }
}

.launchpage-download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: $spacing * 0.7;
  border: 1px solid $color-primary;
  color: $white;
  background: $color-primary;
  text-decoration: none;
  transition: background 0.2s;

  .icon {
    flex-shrink: 0;
    width: 4.2rem;
    height: 4.2rem;
    fill: currentColor;
  }

  .h5 {
    flex: 1;
    display: block;
    margin: 0 $spacing * 1.5;
    color: currentColor;
    text-transform: uppercase;
  }

  .arrow {
    flex-shrink: 0;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
    fill: currentColor;
  }

  &:hover,
  &:focus {
    background: transparent;
  }

  @include mq(medium) {
    .mini-subtitle {
      padding-right: 20%;
    }
  }

  @include mq(large) {
    width: 100%;
    padding: $spacing * 1.5 $spacing * 2;

    .mini-subtitle {
      padding-right: 25%;
    }
  }
}
