.results-list {
  margin-bottom: 7rem;
}

.grid-container--has-downloads {
  margin-bottom: 0;
}

.results-list__header {
  margin-top: 4rem;
  padding: 2rem 0;
  display: flex;
  align-items: center;
}

.results-list__header.downloads {
  margin-top: 0;
}

.results-list__header__title {
  flex-grow: 1;
  small {
    color: $color-gray;
  }
}

.results-list__header__search {
  position: relative;
  width: 18rem;
  margin-left: 2.5rem;
}

.results-list__header__search__label,
.results-list__header__search__input {
  display: block;
}

.results-list__header__search__label {
  color: $color-primary;
}

.results-list__header__search__input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid $color-gray;
  padding-bottom: .75rem;
  padding-right: 3rem;
}

.results-list__header__search button {
  position: absolute;
  bottom: .4rem;
  right: 0;
  background: transparent;
  border: 0;
}

.results-list__list {
  @extend %list-nostyle;
}
