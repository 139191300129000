.related-slider {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.related-slider-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @include mq(large) {
    margin-bottom: 6rem;
  }
}

.related-slider__item {
  transition: transform 0.2s ease-out;

  // max-width: 18rem;
  border-radius: 15px;
  overflow: hidden;
  width: 80%;

  @include mq(large) {
    max-width: 30rem;
  }

  @include mq(xlarge) {
    max-width: 40rem;
  }

  &:hover {
    transform: scale(1.03);
  }
}

.related-slider__infos {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.4rem;

  @include mq(large) {
    margin-bottom: 9rem;
  }
}

.related-slider__label {
  color: $cello;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.6rem;
}

.related-slider__title {
  margin-bottom: 0 !important;
}

.related-slider-carousel {
  padding-left: inherit !important;
}

.related-slider-carousel ul {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0;
  margin: 0;
  list-style: none;
}

.related-slider-carousel li {
  position: relative;

  article {
    position: relative;
    aspect-ratio: 9 / 13;
    border-radius: 1.6rem;
    overflow: hidden;
  }
}

.related-slider__item__picture {
  transition: transform 0.2s ease-out;
  height: 100%;

  .related-slider__item:hover & {
    transform: scale(1.1);
  }
}

.related-slider__item__title {
  position: absolute;
  bottom: 4.4rem;
  top: inherit;
  right: inherit;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  font-size: 2rem;
  color: $white;
  text-transform: uppercase;

  .related-slider__item:hover & {
    &::before {
      width: 100%;
    }
  }

  &::before {
    transition: width 0.2s ease-in-out;
    content: '';
    position: absolute;
    bottom: -0.4rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.6rem;
    height: 4px;
    background-color: $picton-blue;
  }

  @include mq(large) {
    font-size: 3.4rem;
  }
}

.related-slider-list__item {
  width: 100%;
  max-width: 30rem;
  margin-right: 2rem;

  @include mq(xlarge) {
    max-width: 42rem;
    margin-right: 6rem;
  }
}

.related-slider__item__link {
  @include get-all-space();

  span {
    @extend %visually-hidden;
  }
}
