/**
 * Dev, based on $env (from gulp)
 */
@if $env != 'production' {
  // show all breakpoints
  $mq-show-breakpoints: map-keys($mq-breakpoints);
  @include mq-show-breakpoints($mq-show-breakpoints);
}

.site-content > * {
  // display: none;
}

.product-flatscan {
  display: block !important;
}
