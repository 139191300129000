[class*='product-nav--'],
.product-nav {
  position: sticky;
  border-top: 1px solid $alto;
  border-bottom: 1px solid $alto;
  z-index: 99;
  top: -1px;
  background-color: $white;

  @include mq($until: large) {
    top: 5rem;
  }
}

.product-nav__back {
  // position: absolute;
  clip-path: inset(0 80% 0 0);
  transition: clip-path 0.5s ease-out;

  .icon {
    width: 2em !important;
    height: 2em !important;
  }

  @include mq($until: large) {
    display: none;
  }
}

.product-nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-nav__name {
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  position: relative;
  display: flex;
  align-items: center;
  margin-right: $spacing;
  padding: $spacing 0;
  padding-top: 25px;
  color: $cello;
  font-size: 1.5rem;
  line-height: 1em;
  margin-top: 4px;

  @include mq($until: large) {
    display: none;
  }
}

.product-nav__name__link {
  text-decoration: none;
  color: $cello;

  &:hover {
    color: $picton-blue;
  }
}

.product-nav__list {
  @extend %list-nostyle;

  display: flex;
  position: relative;

  .single-accessory & {
    display: none;
  }

  @include mq($until: large) {
    flex-direction: column;
    height: 6.4rem;
    width: 100%;
    overflow: hidden;
  }
}

.product-nav__item {
  position: relative;

  &::after {
    @include center-x;

    content: '';
    z-index: 999;
    display: block;
    width: 100%;
    height: 4px;
    bottom: -3px;
    transform: translate(-50%, -50%);
    background-color: transparent;
    transition: background-color .2s ease-out;
  }

  &.is-active {
    pointer-events: none;
    font-weight: 700;
    &::after {
      background-color: $cello;
    }

    @include mq($until: large) {
      order: -1;

      &::after {
        display: none;
      }
    }
  }

  &:hover {
    &::after {
      background-color: $color-secondary;
    }
  }

  & + & {
    margin-left: $spacing * 2;
    @include mq($until: large) {
      margin-left: 0;
    }
  }
}

.product-nav__cta {
  @include mq($until: large) {
    display: none !important;
  }
}

.product-nav__link {
  @extend %text-nodecoration;

  display: block;
  padding: $spacing 0;
  color: $cello;
  font-size: 1.5rem;
  line-height: 1.3em;
  padding-top: 2.5rem;

  .product-nav__item:hover & {
    color: $color-secondary;
  }
}

.product-nav__arrow{
  position: absolute;
  top: 2.5rem;
  right: $spacing;
  // height: 2.5rem;
  // width: 2.5rem;
  padding: 0.5rem;
  border: 1px solid $nevada;
  transform: rotate(90deg);

  .is-open & {
    transform: rotate(-90deg);
  }
}

.product-nav__arrow-outer {
  @include mq('large') {
    display: none;
  }
}

.product-nav__progress {
  position: absolute;
  z-index: 10;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-image: linear-gradient(
    to left,
    $picton-blue 50%,
    $mercury 50%,
    $mercury 100%
  );
  background-size: 200%;
}