// Categories
.blog__categories {
  border-bottom: 1px solid $careers-gray;
}

.blog__categories__list {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
}

.blog__categories__list__item {
  a {
    position: relative;
    display: block;
    padding: $spacing;
    color: $cello;
    font-size: 1.5rem;
    line-height: 2rem;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      color: $picton-blue;
    }

    &.active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $cello;
    }
  }
}

// Highlighted
.blog-highlighted {
  @include mq(large) {
    max-width: 100rem;
    margin-right: auto;
    margin-left: auto;
  }
}

// Articles
.blog__articles {
  margin-top: $spacing * 2;

  @include mq(large) {
    margin: $spacing * 7 auto $spacing * 9;
    max-width: 100rem;
  }
}

.blog__articles__list {
  @extend %list-nostyle;

}

.blog__articles__list__item {
  & + & {
    margin-top: $spacing * 4;
  }

  @include mq(large) {
    & + & {
      margin-top: $spacing * 8;
    }
  }
}