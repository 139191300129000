$megamenu-p-vertical: 6.4rem;
$megamenu-p-horizontal: 6rem;
$megamenu-xl-width: 132rem;

[class*='megamenu--'] {
  position: absolute;
  z-index: 998;
  top: 0;
  left: 100%;
  display: none;
  width: 100%;
  background-color: $white;
  transition: transform 0.3s;

  &.is-open {
    display: block;
  }

  &.sub-open {
    transform: translateX(-100%);
  }

  @include mq($until: 'large') {
    height: calc(100vh - #{$menu-header-height});
  }
}

.megamenu__back,
.megamenu__back--sub {
  z-index: 1;
  margin: 0;
  padding: 0 2rem;
  line-height: 1;
  background: $gallery;

  .link-back {
    @extend .link--bg;

    display: inline-flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 1.2rem 0 1rem;
    color: $color-primary;
    font-weight: bold;

    &::before,
    &::after {
      content: none;
    }

    .icon {
      height: 1rem;
      fill: $color-primary;
      margin-right: 0.5rem;
    }
  }
}

.megamenu__back--sub {
  margin-bottom: 3rem;
}

@include mq('large') {
  $megamenu-p: 4.3rem;

  .megamenu,
  [class*='megamenu--'] {
    top: $menu-bottom-height + $menu-top-height;
    left: 0;
    width: 100%;
    background: $white;
    box-shadow: 0 20px 20px 0 rgba($black, 0.15);
    transition: none;

    &.is-open {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .megamenu__back-outer,
  .megamenu__back {
    display: none;
  }
}

@include mq('xxlarge') {
  .megamenu,
  [class*='megamenu--'] {
    left: 50%;
    width: auto;
    min-width: $megamenu-xl-width;
    transform: translateX(-50%);

    &.is-open {
      flex-wrap: nowrap;
    }
  }
}
