.launchpage-comparator-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  touch-action: none;
  user-select: none;
}

.launchpage-comparator__picture {
  @include get-all-space;

  transition: opacity 1s $ease-lp;
  img {
    @include get-all-space;
    @include object-fit(cover);

    z-index: -1;
    width: 100%;
    max-width: none;
    height: 100%;
    touch-action: none;
    user-select: none;
    -webkit-touch-callout: none;
  }

  &::after {
    @include get-all-space;

    z-index: 1;
    content: '';
    background: linear-gradient(to left, rgba($black, 0.2), rgba($black, 0));
    pointer-events: none;
  }

  &:first-child {
    z-index: 2;

    &::after {
      background: linear-gradient(to right, rgba($black, 0.2), rgba($black, 0));
    }
  }

  &.hidden {
    opacity: 0;
  }
}

.launchpage-comparator__picture__legend {
  position: absolute;
  right: $spacing;
  bottom: $spacing;
  color: $white;
  user-select: none;

  span {
    display: inline-block;
    color: $white;
  }

  .label {
    @extend %fw-normal;

    margin-left: 0.5rem;
  }

  .launchpage-comparator__picture:first-child & {
    right: initial;
    left: $spacing;
  }
}

.launchpage-comparator__button {
  @extend %button-nostyle;

  display: none;

  .label {
    @extend %visually-hidden;
  }

  .icon {
    @include center-y;

    right: -$spacing * 2;
    width: 1.4rem;
    height: 1.4rem;
    fill: $white;
  }

  .left {
    right: initial;
    left: -$spacing * 2;
    transform: translateY(-50%) rotate(180deg);
  }
}

.launchpage-comparator__label {
  margin: $spacing * 1.5 auto;
  text-align: center;
  touch-action: none;
  user-select: none;

  .icon {
    width: 4.2rem;
    height: 4.2rem;
    margin-right: 1.3rem;
    padding: 1rem;
    background: $color-secondary;
    fill: $white;
    border-radius: 50%;
    vertical-align: middle;
  }
}

@include mq(ml) {
  .launchpage-comparator__label {
    display: none;
  }

  .launchpage-comparator__picture {
    &::before {
      @include get-all-space;

      content: '';
      opacity: 0;
      background: rgba($black, 0.2);
      transition: opacity 1s $ease-lp;
    }

    &:first-child {
      z-index: 0;
    }

    &:nth-child(2) {
      clip-path: inset(0% 0% 0% 50%);
    }

    &.hidden {
      opacity: 1;

      &::before {
        opacity: 1;
      }
    }
  }

  .launchpage-comparator__button {
    @include center-xy;

    z-index: 2;
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background: $white;
    cursor: grab;

    &::after {
      @include center-xy;

      content: '';
      width: calc(100% + 2.6rem);
      height: calc(100% + 2.6rem);
      border: 1px solid $white;
      border-radius: 50%;
      transition: 0.5s $ease-lp;
    }

    &:hover,
    &:focus {
      &::after {
        width: calc(100% + 3rem);
        height: calc(100% + 3rem);
      }
    }

    &:active:hover {
      cursor: grabbing;
    }
  }
}
