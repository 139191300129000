.homepage-news {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 4rem;

  @include mq("large") {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}

.homepage-news__title {
  margin-bottom: $spacing / 2;
}

.homepage-news__list-outer {
  @include mq("medium") {
    display: flex;
    gap: 4%;
  }
}

.homepage-news__list {
  @extend %list-nostyle;


  @include mq("medium") {
    width: 48%;
  }
}

.homepage-news__item {
  margin-bottom: $spacing;
  padding-bottom: $spacing;
  border-bottom: 1px solid $alto;

  @include mq("medium") {
    &:first-child {
      border: 0;
      grid-row: 1 / 3;

      .homepage-news__item__date {
        padding-bottom: $spacing;
        border-bottom: 1px solid $alto;
      }
    }
  }
}

.homepage-news__item__title {
  margin-bottom: $spacing / 2;
  font-size: 1.8rem;
  font-weight: normal;
  color: $cello;
  text-decoration: none;

  // &:hover,
  // &:focus {
  //   color: $picton-blue;
  // }

  @include mq("medium") {
    .homepage-news__item.is-highlighted & {
      font-size: 2.4rem;
    }
  }
}

.homepage-news__item__date {
  color: $color-gray-dark;
}

.homepage-news__link {
  margin: $spacing 0;
  text-align: right;

  @include mq("medium") {
    margin-top: $spacing;
  }
}

.homepage-news__newsletter {
  position: relative;
  display: inline-block;
  padding: $spacing $spacing * 3 $spacing $spacing * 2;
  background: $alabaster2;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: $picton-blue;
    width: 4px;
    height: 100%;
    transition: width 0.2s;
  }

  &:hover,
  &:focus {
    &::before {
      width: 100%;
    }
  }

  @include mq("medium") {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.homepage-news__newsletter__title {
  @extend %fw-normal;
  @extend %ff-default;

  position: relative;
  z-index: 2;
  font-size: 1.8rem;
  transition: color 0.2s;

  .homepage-news__newsletter:hover &,
  .homepage-news__newsletter:focus & {
    color: $cello;
  }
}

.homepage-news__newsletter__label {
  position: relative;
  z-index: 2;
  color: rgba($cello, 0.5);
}
