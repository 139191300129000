$picture-mobile-height: 25rem;
$picture-mobile-height-l: 30rem;
$picture-mobile-height-xl: 45rem;
$button-width: 6rem;

.wtfos {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 4rem;
  box-shadow: 0 0 50px rgba($black, 0.1);
}

.wtfos__main {
  position: relative;
  background: $white;
  margin-top: $picture-mobile-height;
  padding: 2rem;
}

.wtfos__title {
  position: relative;
  margin: 0;
  padding: 2rem 0;
  font-size: 3.6rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 4rem;
    height: 4px;
    background: $picton-blue;
  }
}

.wtfos__list {
  @extend %list-nostyle;

  height: auto !important;
}

.wtfos__item__content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 0;

  h3 {
    margin-top: 0;
  }

  .wtfos__item:not(.is-active):not(.is-next) & {
    @extend %visually-hidden;
  }
}

.wtfos__item__title {
  margin-bottom: 1rem;
  font-size: 2.4rem;
}

.wtfos__item__description {
  flex: 1;
  max-width: none;
  margin-bottom: $spacing;

  @include mq(small-xl) {
    max-width: 80%;
  }
}

.wtfos__item__lottie,
.wtfos__item__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $picture-mobile-height;
  background: $cello;
  overflow: hidden;

  .is-current & {
    z-index: 2;
  }

  .is-next & {
    z-index: 3;
  }

  img {
    @include get-all-space;

    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.3);
  }
}

.wtfos__item__content__link {
  display: inline-block;
}

.wtfos__buttons {
  position: absolute;
  z-index: 3;
  top: $picture-mobile-height - $button-width;
  right: 0;
  display: flex;
}

.wtfos__button {
  position: relative;
  display: inline-block;
  width: $button-width;
  height: $button-width;
  border: 0;
  padding: 0;
  color: $cello;
  background: $gallery;
  border-radius: 0;
  transition: 0.5s;

  &::after {
    @include get-all-space;

    content: '';
    background: $picton-blue;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: opacity 0.5s;
  }

  .icon {
    @include center-xy;

    z-index: 1;
    width: 3rem;
    height: 3rem;
    fill: currentColor;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    background: $mercury;
  }

  &.is-active {
    background: $cello;
    color: $white;

    &::after {
      opacity: 1;
      transform: scaleX(1);
      transition: transform 8s;
    }
  }
}

.wtfos__button__label {
  @extend %visually-hidden;
}

@include mq('small-l') {
  .wtfos__main {
    margin-top: $picture-mobile-height-l;
  }

  .wtfos__item__lottie,
  .wtfos__item__picture {
    height: $picture-mobile-height-l;
  }

  .wtfos__buttons {
    top: $picture-mobile-height-l - $button-width;
  }
}

@include mq('small-xl') {
  .wtfos__main {
    margin-top: $picture-mobile-height-xl;
  }

  .wtfos__item__lottie,
  .wtfos__item__picture {
    height: $picture-mobile-height-xl;
  }

  .wtfos__buttons {
    top: $picture-mobile-height-xl - $button-width;
  }
}

@include mq('medium') {
  .wtfos__main,
  .wtfos__item__content {
    padding: 4rem;
  }

  .wtfos__item__content {
    padding-top: 0;
  }
}

@include mq('large') {
  .wtfos {
    position: relative;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .wtfos__main,
  .wtfos__item__content {
    padding: 6rem;
  }

  .wtfos__main {
    flex: 1;
    margin-top: 0;
    margin-right: 50%;
  }

  .slidy-outer,
  .wtfos__list {
    flex: 1;
  }

  .wtfos__item__lottie,
  .wtfos__item__picture {
    right: 0;
    left: unset;
    width: 50%;
    height: 100%;
  }

  .wtfos__item__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding-top: 0;
    height: 32rem;
    width: 50%;

    [lang="fr-FR"] & {
      height: 36rem;
    }

    [lang="ja"] & {
      height: 40rem;
    }
  }

  .wtfos__item__description {
    flex: 1;
    max-width: none;
  }

  .wtfos__buttons {
    top: 0;
    left: 50%;
    flex-direction: column;
  }
}

@include mq('xlarge') {
  .wtfos__main {
    margin-right: 65%;
  }

  .wtfos__item__lottie,
  .wtfos__item__picture {
    width: 65%;
  }

  .wtfos__item__content {
    width: 35%;

    [lang="ja"] & {
      height: 45rem;
    }
  }

  .wtfos__buttons {
    left: 35%;
  }
}