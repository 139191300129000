.cta-contact {
  position: fixed;
  z-index: 19;
  right: 0;
  bottom: 5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px 0px 0px 16px;
  width: fit-content;
  background-color: $white;
  transform: translateX(70%);
  transition: transform 0.2s ease-out;

  .hidden {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &:hover,
  &.show {
    transform: translateX(0);

    .hidden {
      opacity: 1;
    }

    .cta-contact-arrow {
      opacity: 0;
    }
  }

  @include mq(large) {
    padding: 2rem;
    transform: translateX(calc(100% - 10rem));
  }
}

.cta-contact__picture {
  width: 4rem;
  aspect-ratio: 1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;

  @include mq(large) {
    width: 6rem;
  }
}

.cta-contact__content {
  display: flex;
  flex-direction: column;
}

.cta-content__headline,
.cta-content__title {
  text-transform: uppercase;
  font-weight: 700;
}

.cta-content__headline {
  color: $picton-blue;
  font-size: 1.1rem;
  line-height: 1.6rem;
  letter-spacing: 1px;

  @include mq(large) {
    font-size: 1.3rem;
  }
}

.cta-content__title {
  font-size: 1.8rem;
  color: $cello;

  @include mq(large) {
    font-size: 2.4rem;
  }
}

.cta-contact__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  aspect-ratio: 1;
  margin-left: 1rem;
  border-radius: 0.4rem;
  background-color: $picton-blue;

  @include mq(large) {
    &:before {
      @include get-all-space;
      content: '';
    }
  }

  span {
    @extend %visually-hidden;
  }
}

.cta-contact-arrow {
  position: absolute;
  left: 5.5rem;
  top: 50%;
  transform: translateY(-50%);

  @include mq(large) {
    opacity: 0;
  }
}
