.product-tools {
  padding: $spacing * 5 0;
  background-color: $alabaster2;

  @include mq(large) {
    padding: $spacing * 7.5 0;
  }
}

.product-tools__title {
  @extend %text-center;
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  color: $color-primary;
  font-size: 1.8rem;
  line-height: 1.2em;

  @include mq(large) {
    font-size: 3.6rem;
  }
}

.product-tools__duos {
  margin-top: $spacing * 2;
  padding: 0;

  @include mq(large) {
    margin-top: $spacing * 3;
  }
}
