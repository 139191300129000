.configurator {
  .wrapper {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1120px;
  }

  .intro-desktop {
    display: none;
    margin: 30px 0 100px 0;

    h2 {
      font-weight: 400;
      font-size: 26px;
      margin: 0;
    }

    h1 {
      font-size: 54px;
      font-weight: 700;
      margin: 15px 0;
    }

    h3 {
      font-weight: 400;
      font-size: 14px;
      margin: 0;
    }
  }

  .intro {
    text-align: center;
    margin: 70px 0 30px 0;

    h2 {
      font-weight: 400;
      font-size: 18px;
      margin: 0;
    }

    h1 {
      font-size: 28px;
      font-weight: 700;
      margin: 15px 0;
    }

    h3 {
      font-weight: 400;
      font-size: 13px;
      margin: 0;
    }
  }

  .view {
    position: relative;

    &.flip .image {
      transform: scaleX(-1);
    }

    .image {
      position: relative;

      &.black .img-black { opacity: 1; }
      &.silver .img-silver { opacity: 1; }
      &.white .img-white { opacity: 1; }

      .def {
        opacity: 1!important;
      }

      img {
        opacity: 0;
        transition: opacity .5s;
      }

      img:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  section {
    margin-top: 60px;

    &.disabled {
      opacity: .4;
      pointer-events: none;
    }

    h4 {
      display: inline-flex;
      align-items: center;
      font-family: 'Open Sans';
      font-size: 13px;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      margin-bottom: 15px;
    }
  }

  .btn.active,
  li.active {
    border: 1px solid red;
  }

  .form {
    max-width: 420px;
    margin: 0 auto;
  }

  @include mq(large) {
    .configurator__layout {
      display: flex;
      flex-direction: row-reverse;
      margin: 100px 0;
    }

    .form {
      width: 420px;
    }

    .view {
      flex: 1;
      position: sticky;
      top: 20px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .intro {
      display: none;
    }

    .intro-desktop {
      display: block;
    }
  }
}

.configurator .multi {
  display: flex;
  width: 100%;
  justify-content: space-between;

  > * {
    flex-basis: 48%;
  }

  &.three {
    > * {
      flex-basis: 31%;
    }

    .choice {
      text-align: center;
      flex-direction: column;
      justify-content: center;
    }

    .choice span {
      display: block;
      font-size: 15px;
      margin-top: 4px;
    }
  }
}

.configurator .choice {
  background: transparent;
  border: 0;
  padding: 0;
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  height: 84px;
  display: flex;
  align-items: center;
  padding: 15px;

  &.center {
    justify-content: center;
  }

  .text {
    text-align: left;
    margin-left: 10px;
    line-height: 1.3;

    span {
      display: block;
      font-size: 16px;
    }
  }

  @include mq(large) {
    height: 105px;
  }

  &.active {
    border: 1px solid #59C3F0;
  }
}

.configurator .tooltip {
  position: relative;
  margin-left: 7px;
  top: 1px;
  text-transform: none;

  svg {
    cursor: pointer;
  }

  svg:hover + .tooltip__expand {
    display: block
  }

  .tooltip__expand {
    @include center-y;
    width: 250px;
    left: 30px;
    background: #FFFFFF;
    padding: 5px 10px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
    border-radius: 9px;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #1F325B;
    display: none;
  }
}

.configurator .mounting {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #1F325B;
    margin-left: 15px;
    flex: 1;
  }
}

.configurator .accessory {
  list-style: none;
  padding: 0;
  margin: 0;

  .accessory__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .accessory__item + .accessory__item {
    margin-top: 20px;
  }

  .accessory__picture {
    position: relative;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    height: 80px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: contain;
    }
  }

  .accessory__text {
    margin-left: 10px;
    flex: 1;
    padding-right: 20px;

    p {
      margin: 0;
    }
  }

}

.configurator .actions {
  display: block;
  width: 260px;
  margin: 50px auto;

  &.disabled {
    opacity: .4;
    pointer-events: none;
  }

  > * {
    display: block;
    width: 100%;
    height: 58px;
    max-width: none;
  }

  .cta--highlighted {
    line-height: 43px;
  }

  .cta__icon {
    position: relative;
    top: 5px;
  }
}

.configurator .inputnumber {
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  width: 71px;
  height: 48px;

  input {
    border: 0;
    background: transparent;
    height: 48px;
    line-height: 48px;
    width: 100%;
    font-size: 14px;
    color: #979797;
    padding: 5px 8px;
    padding-left: 25px;
  }
}

.configurator .whatsin {
  font-family: 'Avenir Next LT Pro Condensed';
  display: none;
  background: #59C3F0;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  padding: 14px 19px;
  color: #FFFFFF;
  text-decoration: none;
  width: 180px;
  height: 58px;
  position: relative;
  margin-top: 10px;

  &:hover {
    opacity: .8;
  }

  svg {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
  }

  @include mq(large) {
    display: inline-block;
  }
}

.configurator .support {
  display: none;
  margin-top: 20px;
  text-decoration: none;

  div {
    padding-left: 10px;
  }

  p {
    margin: 0;
    font-family: 'Avenir Next LT Pro Condensed';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
/* identical to box height, or 171% */


color: #1F325B;

  }

  strong {
    font-family: 'Avenir Next LT Pro Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #1F325B;
  }

  @include mq(large) {
    display: inline-flex;
    align-items: center;
  }
}

.configurator .whatsinside {
  background: #EFEFEF;
  padding: 50px 0 70px 0;
  text-align: center;

  .list {
    margin: 40px 0;
    text-align: left;

    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px;

      @include mq(small-xl) {
        columns: 2;
      }
    }

    li + li {
      margin-top: 10px;
    }
  }

  .contentpack  {
    width: 100%;

    img {
      width: 100%;
    }
  }
}