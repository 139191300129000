.launchpage-contact {
  --button-width: 5.6rem;
  --button-margin: 0;

  position: fixed;
  z-index: 4;
  top: 7rem;
  right: 0;
  opacity: 0;
  transition: opacity 1s $ease-lp;

  &.is-visible,
  &.single-article__contact {
    opacity: 1;
  }

  &.single-article__contact {
    position: sticky;
    top : initial;
    bottom: 2rem;
    margin-top: calc(var(--button-width) * -1);
    pointer-events: none;
  }

  @include mq(xlarge) {
    top: $spacing * 2;
    right: $spacing * 2;

    &.single-article__contact {
      --button-width: 9rem;
      --button-margin: 12rem;

      top: initial;
      right: initial;
      bottom: var(--button-margin);
      width: auto;
      margin-top: calc(calc(var(--button-width) * -1) - var(--button-margin));
    }
  }
}

.launchpage-contact-inner {
  position: relative;
  display: block;
  width: var(--button-width);
  height: var(--button-width);
  color: $white;
  border-radius: 50%;
  background: $color-secondary;
  transition: 1s ease-out;
  transition-property: color, background;

  // &::after {
  //   @include get-all-space;

  //   z-index: -1;
  //   content: '';
  //   background: linear-gradient(
  //     to bottom right,
  //     rgba($color-primary, 0.8),
  //     $color-secondary 70%
  //   );
  //   border-radius: 60px;
  //   opacity: 0;
  //   filter: blur(15px);
  //   transform: translate(10px, 10px);
  //   transition: 1s $ease-lp;
  // }

  &:hover {
    color: $white;
    background: $cello;

    // &::after {
    //   opacity: 1;
    // }
  }

  .single-article__contact & {
    margin-left: auto;
    pointer-events: auto;
  }

  @include mq(xlarge) {
    .single-article__contact & {
      margin-bottom: var(--button-margin);
    }
  }
}

.launchpage-contact__label {
  @extend %visually-hidden;
}

.launchpage-contact__icon {
  @include center-xy;

  width: 3rem;
  height: 3rem;
  fill: currentColor;

  @include mq(xlarge) {
    .single-article__contact & {
      width: 5rem;
      height: 5rem;
    }
  }
}
