.leads-generation-form {
  z-index: 2;
  position: relative;
  margin-inline: auto;
  padding: $spacing * 1.5;
  background: #fff;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.08);
  margin-top: $spacing * -4;
  margin-bottom: $spacing * 2.5;
  transition: margin-top 0.2s ease-in-out;

  .form-item {
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include mq('medium') {
    position: sticky;
    top: $spacing * 3;
  }

  @include mq('large') {
    padding: $spacing * 3;
  }
}

.leads-generation-form__title {
  margin-top: 0;
  margin-bottom: $spacing * 2;
  color: $cello;
  font-family: $av-next-cond;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.8rem;
  text-transform: uppercase;
}

.leads-generation-form__items {
  display: flex;
  gap: 0 4rem;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-item,
  [class*='form-item--'] {
    flex-basis: 100%;
  }

  [class*='form-item--'][class*='--duo'] {
    flex-basis: 100%;

    @include mq('large') {
      flex-basis: calc(50% - #{$spacing});
    }
  }
}
