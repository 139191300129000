.filter {
  background: $alabaster2;
  position: relative;
  color: $cello;
}

.filter__wrapper > * {
  position: relative;
  padding: 1rem 2rem;
  border-bottom: 1px solid $gallery;

  svg {
    vertical-align: middle;
    height: 1.4rem;
  }
}

.filter__wrapper {
  max-width: calc(1280px - 8rem);
  margin-left: auto;
  margin-right: auto;
}


.filter__reset {
  display: none;
  text-align: center;
  color: $bombay;

  svg {
    fill: $bombay;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .filter__reset__link {
    @include get-all-space;
    z-index: 10;
  }
}

.filter__by {
  cursor: pointer;
  user-select: none;

  .icon--filter {
    fill: $picton-blue;
  }

  .icon--arrow-redux-bottom {
    width: 0.8rem;
    fill: $cello;
    margin-left: 0.6rem;
  }

  strong {
    display: inline-block;
    vertical-align: middle;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-left: 1rem;
  }
}

.filter__categories {
  position: absolute;
  background: $white;
  z-index: 99;
  display: none;

  .go-back {
    position: relative;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .go-back svg {
    @include center-y;
    width: 1rem;
    position: absolute;
    left: -1.5rem;
    fill: $cello;
  }
}

.filter__accessory {
}

.filter--selected {
  .filter__reset {
    display: block;
  }

  .filter__categories {
    top: 8.8rem;
  }
}

@include mq($until: large) {
  .filter__categories {
    top: 4.4rem;
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.2);
    overflow: hidden;


    &.detail .segments-all__segments {
      transform: translateX(-100%);
    }

    .segments-all__segments {
      transition: transform 0.4s;
      transform: translateX(0%);
      // transition: translateX(-100%);
    }

    .segments-all__segments__segment__applications {
      transform: translateX(100%);
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: $white;
      z-index: 10;
      padding: 2rem 3.5rem;

      .segments-all__segments__segment__arrow {
        display: none;
      }
    }
  }
}

@include mq(large) {
  .filter__categories {
    @include center-x;
    top: 5rem!important;
    max-width: 114rem;
    width: 100%;
    box-shadow: 0px 22px 34px 0px rgba(0,0,0,0.2);
    padding: 0 2rem 2rem 2rem;

    .segments-all__segments__segment__applications {
      display: block!important;
    }

    .go-back {
      display: none;
    }
  }
}

@include mq(large) {
  .filter {
    height: 5rem;
  }

  .filter__wrapper {
    padding: 0 4rem;
  }

  .filter__wrapper > * {
    display: inline-block;
    border: 0;
    padding: 0;
    line-height: 5rem;
  }

  .filter__reset {
    display: none;
  }

  .filter--selected {
    .filter__reset {
      display: inline-block;
    }
  }

  .filter__accessory {
    float: right;
  }

  .filter__reset:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 2rem;
    width: 0.1rem;
    margin: 0 1.5rem;
    background: $bombay;
  }

  .filter [class*="link-list--"][class*="--title"] {
    cursor: default;

    &:hover {
      color: $color-primary;
    }
  }

}
