[class*="card-category--"],
.card-category {
  padding: .5rem;
}

.card-category-inner {
  border: 1px solid $color-gray-light;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  @include mq('medium') {
    padding: 1rem;
  }

}

.card-category__content__picto {
  width: 8rem;
  height: 8rem;
}

.card-category__content__title {
  margin: 0;
}

.card-category__content__intro ul {
  margin: 1rem 0;
  padding-left: 1.6rem;
}


[class*="card-category--"][class*="--highlighted"] {
  .card-category-inner {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-lighter;

    @include mq('medium') {
      justify-content: space-around;
    }

  }

  .card-category__picto {
    width: 13rem;
    height: 13rem;
    margin-right: 2rem;
  }

  .card-category__content {
    text-align: left;
  }
}
