$content-width: 48rem;

.tech-corner-ondemand {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background: $cello;

  @include mq(medium) {
    display: block;
  }
}

.tech-corner-ondemand__content-outer {
  @include mq(medium) {
    padding-top: 23rem;
  }
}

.tech-corner-ondemand__content {
  position: relative;
  padding: $spacing;
  background: $white;

  @include mq(medium) {
    z-index: 1;
    width: $content-width;
    margin-left: auto;
    padding: $spacing * 3;
    box-shadow: 0 3rem 3rem 0 rgba($black, 0.1);
  }
}

.tech-corner-ondemand__content__title {
  position: relative;
  text-transform: uppercase;

  &::after {
    content: '';
    display: block;
    width: 2rem;
    height: 0.2rem;
    margin-top: $spacing * 0.6;
    background-color: $picton-blue;
  }

  @include mq(medium) {
    &::after {
      width: 3.5rem;
      height: 0.4rem;
    }
  }
}


.tech-corner-ondemand__content__text {
  margin-bottom: 0;
}

.tech-corner-ondemand__content__button {
  @extend %button-nostyle;
  @extend %text-center;
  
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75em 2em 0.75em 1.25em;
  color: $white;
  background-color: $color-secondary;
  transform: translateY(100%);
  transition: all $duration-default $ease-default;

  .icon {
    fill: $white;
  }

  &.is-active,
  &:hover,
  &:focus {
    background-color: $color-primary;
  }
}

.tech-corner-ondemand__picture {
  margin-top: 0;
  margin-bottom: $spacing;

  img {
    width: 100%;
  }

  @include mq(medium) {
    position: absolute;
    top: 4rem;
    left: 4rem;
    width: 50%;
    height: calc(100% - 8rem);
    margin: 0;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  @include mq(xlarge) {
    @include center-xy;

    width: 100%;
    margin: 0;
    max-width: calc(1280px - #{4 * $spacing});
    height: calc(100% - 15rem);
    padding-right: calc(#{$content-width} + #{$spacing});

  }
}