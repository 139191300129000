.launchpage-icons {
  background: $color-lp-bg;
  color: $cello;
}

.launchpage-icons__list {
  @extend %list-nostyle;

  padding: $spacing * 2 0;

  @include mq(small) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @include mq(medium) {
    flex-wrap: nowrap;
  }
}

.launchpage-icons__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: $spacing * 2;
  text-align: center;

  @include mq(large) {
    margin: 0 $spacing * 2;
  }
}

.launchpage-icons__item__picture {
  margin: 0 auto $spacing;
  width: 6.7rem;
  height: 6.7rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  @include mq(large) {
    width: 9rem;
    height: 9rem;
  }
}