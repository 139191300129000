.card-solution,
[class*="card-solution--"] {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  @include mq('medium') {
    width: 48%;
    margin-bottom: 10rem;
  }
}

[class*="card-solution--"][class*="--no-background"] {
  background-color: transparent;
}

[class*="card-solution--"][class*="--small"] {
  min-height: 200px;
  background-color: $white;
}

.card-solution,
[class*="card-solution--"]:not([class*="--small"]) {
  position: relative;

  @include mq('medium') {

    display: flex;

    &[class*="--reverse"] {
      flex-direction: row-reverse;
    }
  }

  .single-segment &,
  .page-template-trainings &,
  .page-template-where-to-find-us & {
    padding-bottom: $spacing;

    @include mq('medium') {
      padding-bottom: $spacing * 2;
    }
  }
}


.card-solution__visual {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 30rem;
  pointer-events: none;

  &.has-video {
    pointer-events: initial;
    cursor: pointer;

    &::before {
      @include center-xy;

      content: '';
      z-index: 9;
      display: block;
      width: 4rem;
      height: 4rem;
      background-color: $cello;
      pointer-events: none;
    }

    &::after {
      @include center-xy;

      content: '';
      z-index: 9;
      display: block;
      width: 0;
      height: 0;
      border-width: 8.5px 0 8.5px 14.7px;
      border-style: solid;
      border-color: transparent transparent transparent #ffffff;
      pointer-events: none;
    }
  }


  .card-solution &,
  [class*="card-solution--"]:not([class*="--small"]) & {
    @include mq('medium') {
      flex-shrink: 0;
      width: 100%;
    }
  }

  &:hover {
    .card-solution__visual__picture {
      transform: scale(1.05);
    }
  }
}

.card-solution__visual__picture {
  @include object-fit;

  display: block;
  width: 100%;
  height: 100%;
  transition: transform .2s ease-out;

  &:hover {
    // transform: scale(1.05);
  }
}

.card-solution__icon {
  position: relative;
  z-index: 3;
  display: block;
  width: $application-icon-width;
  height: $application-icon-width;
  margin: (-$application-icon-width / 2) auto 0;
  background-color: $color-primary;
  fill: $white;

  @include mq('tiny', 'small-l') {
    width: $application-icon-width-small;
    height: $application-icon-width-small;
    margin: (-$application-icon-width-small / 2) auto 0;
  }

  [class*="card-solution--"][class*="--small"] & {
    @include mq('small-l') {
      flex-shrink: 0;
      margin: ($application-icon-width / 2) 0 0 (-$application-icon-width / 2);
    }
  }

  .card-solution &,
  [class*="card-solution--"]:not([class*="--small"]) & {
    @include mq('tiny', 'medium') {
      position: absolute;
      left: calc(50% - #{$application-icon-width-small / 2});
      margin-top: (-$application-icon-width-small / 2) - $spacing;
    }

    @include mq('small-l', 'medium') {
      margin-top: (-$application-icon-width / 2) - $spacing;
    }

    @include mq('medium') {
      position: absolute;
      top: $application-icon-width;
      left: calc(53% - #{$application-icon-width / 2});
      margin: 0;
    }

    @include mq('large') {
      left: calc(48% - #{$application-icon-width / 2});
    }

    @include mq('xlarge') {
      left: calc(43% - #{$application-icon-width / 2});
    }
  }

  [class*="card-solution--"][class*="--reverse"]:not([class*="--small"]) & {
    @include mq('medium') {
      right: calc(53% - #{$application-icon-width / 2});
      left: auto;
    }

    @include mq('large') {
      right: calc(48% - #{$application-icon-width / 2});
    }

    @include mq('xlarge') {
      right: calc(43% - #{$application-icon-width / 2});
    }
  }

  @each $slug, $color in $segment-colors {
    [class*="card-solution--"][class*="--#{$slug}"] & {
      background-color: $color;
    }
  }
}

.card-solution__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  // height: 60rem;
  padding: $spacing;
  background-color: $white;

  @include mq('xlarge') {
    height: 60rem;
  }

  [class*="card-solution--"][class*="--small"] & {
    @include mq('small-l') {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 90%;
    }
  }

  .card-solution &,
  [class*="card-solution--"]:not([class*="--small"]) & {

    // box-shadow: 0 15px 30px 0 rgba($black, .15);

    @include mq('tiny', 'medium') {
      margin: -$spacing $spacing $spacing;
      padding-top: $application-icon-width;
    }

    @include mq('medium') {
      flex-shrink: 0;
      margin: -$spacing * 2 0 0 -22%;
      padding: $spacing * 3;
      padding-bottom: 0;
    }

    @include mq('large') {
      margin: -$spacing * 2 0 0 -17%;
    }

    @include mq('xlarge') {
      width: 90%;
      max-width: 700px;
      margin: -$spacing * 2 0 0 -10%;
    }
  }

  [class*="card-solution--"][class*="--reverse"]:not([class*="--small"]) & {
    @include mq('medium') {
      margin-right: -22%;
      margin-left: 0;
      padding-right: $spacing * 3;
      padding-left: $spacing * 2;
    }

    @include mq('large') {
      margin-right: -17%;
    }

    @include mq('xlarge') {
      margin-right: -12%;
    }
  }
}

.card-solution__content__title {
  position: relative;
  margin: 0 0 .7em;
  padding: 0 0 .7em;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 3.5rem;
    height: 4px;
    background-color: $picton-blue;
  }

  @each $slug, $color in $segment-colors {
    [class*="card-solution--"][class*="--#{$slug}"] & {
      color: $color;
    }
  }

  small {
    display: block;
  }
}

.card-solution__content__cta {
  margin-top: $spacing * 2;
}

.card-solution__products {
  margin-top: auto;
  border-bottom: 0;
}
