.fc-icons__grid {
  padding-block: 2.4rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing * 1.5;
}

.fc-icons__item__icon {
  display: flex;

  .icon {
    margin-inline: auto;
    width: 5rem;
    height: 5rem;
  }
}

.fc-icons__item__title {
  margin-block: 0;
  color: $dark-grey;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  text-align: center;
}

.fc-icons__item__text {
  color: $dark-grey;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: center;
}
