.big-cta {
  position: relative;
  display: flex;
  position: relative;
  border-radius: 0.8rem;
  overflow: hidden;

  &:hover {
    .big-cta__picture {
      transform: scale(1.05);
    }
  }

  @include mq($until: large) {
    margin-top: 4rem;
    margin-bottom: 4rem;
    flex-direction: column;
    border-radius: 0.8rem;
  }

  @include mq(large) {
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding: 4rem;
    justify-content: flex-end;
    min-height: 64rem;
  }
}

.big-cta__picture {
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  transition: transform 0.2s ease-in-out;

  @include mq($until: large) {
    aspect-ratio: 16 / 10;
  }

  @include mq(large) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.big-cta__content {
  display: flex;
  flex-direction: column;
  background-color: #1f325b;
  gap: 1.6rem;
  border-radius: 0 0 0.8rem;
  padding: 2.4rem;
  width: 100%;

  .big-cta__headline,
  .big-cta__title,
  .big-cta__text {
    color: #fff;
  }

  @include mq(large) {
    width: 35rem;
    padding: 4rem;
    background: rgba(31, 50, 91, 0.6);
    backdrop-filter: blur(27px);
    border-radius: 0.8rem;
  }
}

.big-cta__title {
  margin: 0 !important;
}

.big-cta a {
  margin-top: auto;
}
