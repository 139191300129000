.tech-corner-cta {
  @extend %ff-av-next-cond;
  @extend %text-nodecoration;

  position: relative;
  display: block;
  padding: $spacing * 2 0;
  background-color: $color-primary;
  color: $white;
  font-size: 1.6rem;
  line-height: 1.15;
  transition: color $duration-default $ease-default;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: $spacing;
    height: 100%;
    background-color: $color-secondary;
    transition: width $duration-default * 2 $ease-default;
  }

  &:hover,
  &:focus {
    color: $white;

    &::before {
      width: 100%;
    }
  }
}

.tech-corner-cta-inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: transform $duration-default $ease-default;

  .tech-corner-cta:hover &,
  .tech-corner-cta:focus & {
    transform: translateX(#{$spacing / 2});
  }
}

.tech-corner-cta__picture {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  width: 10rem;
  height: 10rem;

  img {
    @include image-fit;

    border-radius: 50%;
  }

  & + & {
    z-index: 0;
    margin-left: -5rem;
    opacity: 0.5;
  }
}


.tech-corner-cta__content {
  padding-left: $spacing;

  strong {
    @extend %text-uppercase;

    display: block;
    margin-top: .5em;
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: 1px;
  }
}
