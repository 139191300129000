.en16005-solutions {
  margin: 5rem 0 8rem;

  @include mq(xlarge) {
    margin: 16rem 0 7rem;
  }
}

.en16005-solutions__title {
  @extend %text-center;

  max-width: 12em;
  margin: 0 auto $spacing * 2;

  @include mq(large) {
    margin-bottom: $spacing * 3;
  }
}

.en16005-solutions__intro {
  @extend %text-center;

  max-width: 7rem auto 2.4rem;
  margin: 0 auto $spacing * 3.5;

  h3 {
    margin-bottom: $spacing;
  }

  @include mq(xlarge) {
    margin: $spacing * 3 auto;
  }
}

// Transitions

.en16005-fade-enter-active,
.en16005-fade-leave-active {
  transition: opacity 0.15s ease-out;
}

.en16005-fade-enter,
.en16005-fade-leave-to {
  opacity: 0;
}
