.sensorio-news__title {
  text-transform: none;
  margin-bottom: 20px;
  font-size: 2rem;
}

.sensorio-news__date {
  margin-bottom: 10px;
}

.sensorio-news__type {
  margin: 0 0.4rem 0.4rem 0;
  padding: 0.2rem 0.6rem;
  display: inline-flex;
  color: #1f325b;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.2rem;
  border: 1px solid #b4b4b4;
  border-radius: 3px;

  @include mq(medium) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
