.cta-video {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  @extend %color-primary;
  @extend %text-uppercase;

  position: relative;
  display: flex;
  align-items: end;
  padding: 1.2rem 1rem 1.3rem 2.2rem;
  background: $white;
  text-decoration: none;
  line-height: 1.6rem;
  letter-spacing: 0.3px;
  transition: 0.2s ease-out;

  &::after {
    @include get-all-space;

    content: '';
    background: $color-secondary;
    width: 0;
    transition: 0.2s ease-out;
  }

  &:hover,
  &:focus {
    box-shadow: 1rem 1rem 2rem rgba($black, 0.2);

    &::after {
      width: 5px;
    }

    .cta-video__icon {
      color: $color-secondary;
      margin-left: 1.5rem;
    }
  }
}

.cta-video__icon {
  width: 1.7rem;
  margin-left: 1rem;
  fill: currentColor;
  transition: 0.2s ease-out;
}

.cta-video-testimonies {
  position: absolute;
  z-index: 20;
  width: fit-content;
  padding: 2rem 3rem;
  border-radius: 5rem;
  align-items: center;
  gap: 1rem;

  &:after {
    display: none;
  }

  .cta-video__icon {
    margin-left: 0;
    aspect-ratio: 1;
    width: 2rem;
    height: inherit;
  }

  .cta-video__label {
    transition: all 0.2s ease-in-out;
    transform-origin: left;
  }

  &:hover,
  &:focus {
    .cta-video__icon {
      margin-left: 0;
    }

    .cta-video__label {
      color: $color-secondary;
      transform: scale(1.02);
    }
  }

  @include mq($until: large) {
    padding: 2rem;
    aspect-ratio: 1;
  }
}
