.launchpage-background {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.launchpage-background__picture {
  @include get-all-space;

  z-index: -1;

  img {
    @include get-all-space;
    @include object-fit(cover);

    width: 100%;
    max-width: none;
    height: 100%;
  }

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: $gradient-overlay-lp;
  }
}

.launchpage-background__content {
  .launchpage-content__title,
  .launchpage-content__text {
    color: $white;
  }

  @include mq(xlarge) {
    width: 100%;
  }
}

.launchpage-background__video {
  position: static;
  margin-top: $spacing;
  width: 100%;

  .cta-video {
    width: fit-content;
    margin-right: auto;
  }

  @include mq(xlarge) {
    margin-top: $spacing * 2;
  }
}