.segments-block {
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.segments-block__cover {
  
  @include mq($until: 'medium') {
    display: none;
  }
  
  @include mq('medium') {
    @include fit(cover);
  }
}

.segments-block__segments-all {
  @include mq('medium') {
    background-color: $white;
    position: relative;
    width: 36rem;
    margin: $spacing * 2 0 $spacing * 2 auto;
    padding: $spacing;
  }
}
