/*
 * Helpers
 */

/*
 * Clearfix: contain floats
 * http://cssmojo.com/latest_new_clearfix_so_far/
 */
%cf {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */
%hidden {
  display: none !important;
  visibility: hidden;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
%visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

/**
 * Typo
 */
%fw-light {
  font-weight: 200;
}

%fw-normal {
  font-weight: 400;
}

%fw-bold {
  font-weight: 700;
}

%fw-semibold {
  font-weight: 600;
}

%fw-extrabold {
  font-weight: 800;
}

%text-left {
  text-align: left;
}

%text-center {
  text-align: center;
}

%text-right {
  text-align: right;
}

%text-justify {
  text-align: justify;
}

%text-uppercase {
  text-transform: uppercase;
}

%text-notransform {
  text-transform: none;
}

%text-nodecoration {
  text-decoration: none;
}

%list-nostyle {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%button-nostyle {
  padding: 0;
  border: 0;
  background: none;
}

%link-small {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

/*
 * Colors
 */

%color-white {
  color: $white;
}

%color-black {
  color: $black;
}

%color-dark {
  color: $color-dark;
}

%color-light {
  color: $color-light;
}

%color-primary {
  color: $color-primary;
}

%color-secondary {
  color: $color-secondary;
}

/**
 * Project specific
 */

%ff-default {
  font-family: $ff-default;
}

%ff-av-next {
  font-family: $av-next;
}

%ff-av-next-cond {
  font-family: $av-next-cond;
}

%ff-open-sans {
  font-family: $open-sans;
}
