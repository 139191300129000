.panels-shoebox-item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &.is-active {
    z-index: 10;
    pointer-events: all;
  }
}

.panels-shoebox__close-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba($black, 0.2);

  transition: opacity 0.4s ease-in-out;
  transform: scale(0);

  .is-active & {
    transform: scale(1);

    @include mq($until: 'large') {
      opacity: 1;
    }
  }
}

.panels-shoebox-item__image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;
  opacity: 0;
  filter: blur('10px');

  img {
    position: absolute;
    top: 0;
    // left: -50%;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // transform: translate(-50%, -50%);
  }
}

// Trigger
.panels-shoebox-item__trigger {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  cursor: pointer;

  transform: scale(1);
  will-change: transform;

  body.zoomed &,
  body.zoomed & * {
    pointer-events: none;
  }

  &:hover {
    .panels-shoebox-item__icon {
      transform: scale(1.2);
    }
    .panels-shoebox-item__icon::after {
      transform: scale(1);
    }

    .ripple {
      animation: rippling-back 0.4s ease-in-out;

      &:nth-child(2) {
        animation: rippling-back 0.4s ease-in-out;
      }
    }
  }

  .info-opened & {
    pointer-events: none;
  }
}

.panels-shoebox-item__icon {
  position: relative;
  z-index: 10000;
  width: 3.5rem;
  height: 3.5rem;
  background: $cello;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    transform: scale(0);
    transition: all 0.2s ease-in-out;
  }

  .panels-shoebox-items__icon__bg {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 50%;
    border: 2px solid;
    pointer-events: none;

    transform: scale(0);
    animation: rippling 1.8s ease-in-out infinite;
    will-change: transform;
    &:nth-child(2) {
      animation: rippling 1.8s 2.4s ease-in-out infinite;
    }
  }

  @include mq($until: 'medium') {
    z-index: 0;
    width: 3rem;
    height: 3rem;
  }
}

@keyframes rippling {
  0% {
    transform: scale(0);
  }

  60% {
    opacity: 1;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes rippling-back {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.panel-icon {
  position: absolute;
  top: 3.5rem;
  left: -3.5rem;
  display: block;
  width: 7rem;
  height: 7rem;
  margin-right: 1rem;
  padding: 1rem;
  fill: $white;
  stroke: $white;
  background: $cello;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;

  @include mq($until: 'medium') {
    // display: none;
    pointer-events: none;
    top: -3.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.panel-icon-plus {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  color: $white;
  font-size: 2rem;
  text-align: center;
  line-height: 3.5rem;
  transition: opacity 0.2s ease-out;

  @include mq($until: 'medium') {
    line-height: 3rem;
  }
}

.panels-shoebox-item__name {
  position: absolute;
  top: 0;
  left: 0.1rem;
  display: inline-block;
  white-space: nowrap;
  height: 3.4rem;
  padding: 1.3rem 1rem 1.3rem 4.3rem;
  font-family: $av-next-cond;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: bold;
  color: rgba($cello, 0);
  text-transform: uppercase;
  border-top-left-radius: 3.5rem;
  border-bottom-left-radius: 3.5rem;

  transform: scale(0);
  transition: transform 0.4s 0.3s, color 0.4s ease-out, background 0s 0.7s;
  transform-origin: center left;

  .reversed & {
    left: auto;
    right: 0.1rem;
    padding: 1.3rem 4.3rem 1.3rem 1rem;
    border-top-right-radius: 3.5rem;
    border-bottom-right-radius: 3.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform-origin: center right;
  }

  .panels-shoebox-item__trigger:hover & {
    color: $cello;
    background: $white;
    transform: scale(1);

    transition: transform 0.4s, color 0.4s 0.3s ease-out;
  }

  @include mq($until: 'large') {
    display: none;
  }
}

// Infos
.panels-shoebox-item__infos {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 53.3rem;
  height: auto;
  max-height: calc(100% - 4rem + $shoebox-panel-link-height);
  opacity: 0;
  pointer-events: none;

  background: $white;
  transform: translateX(120%); // IE fallback
  transform: translateX(calc(100% + 2rem));
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  will-change: opacity, transform;

  .is-active & {
    pointer-events: all;
    opacity: 1;
    transform: translateX(0) translateY(0);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.5rem;
    height: 3.5rem;
    background: $cello;
    border: 0;
    color: $white;
    font-size: 2rem;

    span {
      display: block;
      transform-origin: center center;
      transform: rotate(45deg);
    }
  }

  @include mq($until: 'xlarge') {
    width: 70rem;
  }

  @include mq($until: 'large') {
    width: 90%;
    top: calc(100% - 4rem);
    right: 5%;
    max-height: none;

    transform: translateX(0) translateY(3rem);
  }
}

.panels-shoebox-item__infos__inner {
  padding: 5rem;
  pointer-events: none;

  .info-opened & {
    pointer-events: initial;
  }
}

.panels-shoebox-item__infos__title {
  font-size: 3.4rem;
  text-transform: none;

  @include mq($until: 'medium') {
    font-size: 2.4rem;
  }
}

.panels-shoebox-item__infos__related-products {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 2rem;
  list-style: none;
  border-top: 1px solid $gallery;

  @include mq($until: 'medium') {
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
  }
}

.panels-shoebox-item__infos__related-products__el {
  position: relative;
  flex-basis: 50%;
  width: 50%;
  padding: $spacing * 1.5 0 $spacing * 0.5;
  text-align: center;

  .page-template-offline-shoebox & {
    .product-text__link {
      display: none;
    }
  }

  & + &:last-child {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.1rem;
      height: 80%;
      background: $gallery;

      transform: translateY(-50%);

      @include mq($until: 'medium') {
        display: none;
      }
    }
  }

  @include mq($until: 'medium') {
    flex-basis: 100%;
    width: 100%;
  }
}

.product-picture {
  position: relative;
  width: 50%;
  margin: auto;
}

.product-picture__headline {
  @include center-xy;

  top: -0.5rem;
  left: 0;
}

.product-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: fill;
}

.product-text__title {
  margin: 0;
  font-size: 1.6rem;
}

.product-text__subtitle {
  // flex: 1;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  color: $nevada;

  @include mq($from: 1024px, $until: 1140px) {
    display: none;
  }
}

.product-text__link {
  text-decoration: none;

  @include mq($from: 1024px, $until: 1140px) {
    display: none;
  }
}

.panels-shoebox-items__infos__link {
  position: absolute;
  bottom: -$shoebox-panel-link-height;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $shoebox-panel-link-height;
  background: $cello;
  font-family: $av-next-cond;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;

  span {
    position: relative;
    z-index: 10;
    color: $white;
    transition: color 0.4s ease-out;
  }

  .page-template-offline-shoebox & {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: $white;
    transform-origin: center left;
    transition: transform 0.4s ease-out;

    transform: scaleX(0);
  }

  &:hover {
    span {
      color: $cello;
    }

    .cta__arrow {
      fill: $cello;
      stroke: $cello;
    }

    .panel-icon {
      fill: $cello;
      stroke: $cello;
    }

    &::before {
      transform: scaleX(1);
    }
  }
}
