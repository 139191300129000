[class*="header-small--"],
.header-small {
  padding: 3.5rem 0 5rem;
  margin: 0;
  background-color: $color-primary;
  color: $white;

  strong {
    display: block;
    margin-top: 1rem;
  }
}
