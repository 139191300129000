.en16005__nav {
  border-top: 2px solid $gallery;
}

.en16005__nav__list {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}

.en16005__nav__item {
  @extend %button-nostyle;
  @extend %text-left;

  position: relative;
  padding: $spacing / 4 $spacing / 2;

  &.is-active {
    &::before {
      content: '';
      position: absolute;
      top: -3px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $color-secondary;
    }
  }

  &:focus-visible {
    outline: $color-secondary 5px solid;
  }
}

.en16005__nav__item__label,
.en16005__nav__item__title {
  @extend %ff-av-next-cond;
  @extend %fw-semibold;
  @extend %text-uppercase;

  color: $color-gray;
  font-size: 1.5rem;
  line-height: 1.1;
  letter-spacing: 1px;
  transition: color 0.1s;

  .en16005__nav__item.is-active &,
  .en16005__nav__item:not(:disabled):hover & {
    color: $color-secondary;
  }
}

.en16005__nav__item__title {
  display: none;
  font-size: 1.8rem;

  .en16005__nav__item.is-active &,
  .en16005__nav__item:not(:disabled):hover & {
    color: $color-primary;
  }
}

.en16005__nav__item__value {
  @extend %color-secondary;

  display: none;
  position: absolute;
}

@include mq(ml) {
  .en16005__nav {
    margin-bottom: $spacing * 6;
  }

  .en16005__nav__list {
    justify-content: space-between;
  }

  .en16005__nav__item {
    padding: $spacing / 2 $spacing;

    &.is-active::before {
      top: -2px;
    }
  }

  .en16005__nav__item__title,
  .en16005__nav__item__value {
    display: block;
  }
}
