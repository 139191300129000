.slider-testimonies {
  padding-top: 8rem;
  padding-bottom: 8rem;
  overflow: hidden;

  .card-testimonies {
    position: absolute;
    transition: all 0.4s ease-out;

    &.is-selected {
      z-index: 19;
    }

    &.is-selected + .card-testimonies {
      left: 5% !important;
      opacity: 0.5;
      z-index: 4;
      transform: scale(0.95) !important;

      @include mq(large) {
        transform: scale(0.9) !important;
        left: 7% !important;
        filter: blur(7px);
      }
    }

    &.is-selected + .card-testimonies + .card-testimonies {
      z-index: 2;
      opacity: 0.4;
      left: 10% !important;
      transform: scale(0.9) !important;

      @include mq(large) {
        left: 14% !important;
        transform: scale(0.8) !important;
        filter: blur(10px);
      }
    }

    &.is-before-selected {
      opacity: 0;
      filter: blur(7px);
      z-index: 20;
      transform: translateX(-105%);
    }
  }
}

.slider-testimonies__items {
  position: relative;
  aspect-ratio: 9 / 15;

  @include mq(large) {
  }

  @include mq('medium') {
    aspect-ratio: 16 / 9;
  }
}

.flickity-viewport {
  overflow: visible;
}

.testimonies-controls {
  margin: auto;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.5rem;
    font-weight: 400;
    color: $cello;
  }
}

.testimonies-controls__numbers {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 2rem;
}
