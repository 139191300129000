[class*="schedule--"],
.schedule {
  padding: 2rem;
}

.schedule-inner {

  margin-top: 2rem;

  .schedule--wrapped & {
    border-top: 2px solid $color-gray;
    border-bottom: 2px solid $color-gray;
    background-color: lighten($color-gray-light, 5%);
  }
}

.schedule__intro {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.schedule__intro-inner {
  margin-right: 2rem;
  flex-grow: 1;
  // order: 2;
}

.schedule__intro__picture {
  width: auto;
  height: 9rem;
}

.schedule__dates {
  @extend %list-nostyle;
}

.schedule__dates__date {
  display: flex;
  align-items: center;
  padding: 2rem;
  border-top: 1px solid $color-gray-light;

}

.schedule__dates__date__day-outer {
  flex-grow: 1;
}
