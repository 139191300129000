.anchors {
  position: sticky;
  top: 5.5rem;
  z-index: 5;
  padding: $spacing;
  background-color: white;
  border-top: 1px solid $careers-gray;
  border-bottom: 1px solid $careers-gray;

  @include mq(large) {
    top: 0;
  }
}

.anchors-inner {
  display: flex;
  justify-content: center;
}

.anchors__item {
  color: $cello;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  opacity: 0.4;
  transition: all 0.2s;

  &:first-child {
    margin-right: $spacing;
  }

  &:last-child {
    margin-left: $spacing;
  }

  &:hover {
    color: $picton-blue;
    border-bottom-color: $picton-blue;
    opacity: 1;
  }

  &.is-active {
    border-bottom-color: $cello;
    opacity: 1;

    &:hover {
      border-bottom-color: $picton-blue;
    }
  }
}
