.cta-product {
  position: relative;
  display: flex;
  padding: 2rem 0;
  border-top: 1px solid $gallery;
  border-bottom: 1px solid $gallery;
  box-shadow: 0px 10px 35px 0px rgba(transparent, 0);
  overflow: hidden;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 10px 35px 0px rgba($color-primary, 0.1);
  }

  @include mq($until: medium) {
    & + & {
      border-top: 0;
    }
  }
}

.cta-product__image {
  flex-basis: 37%;
  align-self: center;
  padding: 0 1.5rem;
  text-align: center;
  transition: transform 0.3s;

  .cta-product:hover & {
    transform: scale(1.1);
  }
}

.cta-product__text {
  flex-basis: 63%;
  align-self: center;
  padding-right: 4rem;
  transition: transform 0.3s;

  .cta-product:hover & {
    transform: translateX(20px);
  }

  strong {
    display: block;
    margin: 0 0 0.5rem 0;
  }

  p {
    margin: 0;
  }
}

.cta-product__link {
  @include get-all-space;
  z-index: 10;

  svg {
    @include center-y;
    right: 2rem;
    width: 1rem;
    fill: $picton-blue;
  }
}

.cta-product .link-more--corpo {
  display: none;
}

@include mq(medium) {
  .cta-product {
    border: 1px solid $gallery;
    padding: 4rem 0;
  }

  .cta-product__image {
    flex-basis: 50%;
  }

  .cta-product__text {
    flex-basis: 50%;
  }

  .cta-product .link-more--corpo {
    display: inline-block;
    margin-top: 1rem;
  }

  .cta-product__link svg {
    display: none;
  }
}

.cta-product__text__label,
[class*="cta-product__text__label--"] {
  @extend %text-uppercase;
  @extend %fw-bold;

  display: inline-block;
  padding: 0.2rem 0.7rem 0.2rem 0.8rem;
  font-size: 1rem;
  background-color: $cello;
  color: $white;
  border-radius: 0.5rem;
  margin-bottom: $spacing / 4;
}

.cta-product__subtitle {
  margin-bottom: 1.2rem;
  color: $cello;
  font-size: 1.1rem;
  line-height: 1.6rem;

  @include mq(medium) {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

.cta-product__tag {
  margin: 0 0.4rem 0.4rem 0;
  padding: 0.2rem 0.6rem;
  display: inline-flex;
  color: #b4b4b4;
  font-size: 0.9rem;
  line-height: 1.2rem;
  border: 1px solid #b4b4b4;
  border-radius: 3px;

  @include mq(medium) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

.cta-product__text__link {
  svg {
    display: none;
  }
}

.cta-product__arrow {
  display: none;

  @include mq($until: medium) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

.cta-product__text__label--discontinued {
  background-color: $alizarin-crimson;
}
