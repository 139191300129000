.submenu--services {

  @include mq('large') {
    top: $menu-bottom-height;
    left: 0;
    width: 21rem;
    background-color: gold;
    background-color: $white;
    border: 1px solid $color-gray-light;
    border-top: none;
    z-index: 99;
    display: none;
    margin-top: -1px;
  }
}
