.article-footer {
  padding-top: $spacing * 2.5;

  .article-card & {
    padding-top: 0;
  }
}

.article-footer-inner{
  display: flex;
  gap: $spacing * 0.5;
  padding-top: $spacing;
  border-top: 1px solid $careers-gray;
}

.article-footer__date {
  flex: 1;
  color: $bombay;
}

.article-footer__categories {
  @extend %list-nostyle;

  display: flex;
  gap: $spacing * 0.5;
}