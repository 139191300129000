.checkbox {
  input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      user-select: none;
      padding-left: 2rem;
      cursor: pointer;
    }

    &:checked + label .checkbox-box svg {
      display: block;
    }
  }

  .checkbox-box {
    @include center-y;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid $picton-blue;
    margin-right: 1rem;
    left: 0;

    svg {
      display: none;
      position: absolute;
      left: -0.2rem;
      top: -0.2rem;
      height: 1.4rem;
    }
  }
}
