.product-step {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    width: 1px;
    height: 100%;
    background-color: $alto;
  }

  &:first-child::before {
    top: 40px;
    height: calc(100% - 40px);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 80px;
    width: calc(100% - 80px);
    height: 1px;
    background-color: $alto;
  }
}

.product-step__header {
  display: flex;
  align-items: flex-start;
  padding: $spacing 0;
}

.product-step__header__duration {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 23px;
  margin-right: $spacing * 1.5;
  border: 1px solid $alto;
  background-color: $white;
  color: $color-primary;
  font-size: 1.3rem;
  line-height: 1em;
  white-space: nowrap;
  border-radius: 2px;
  transition-property: border, background-color, color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-step.is-open & {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $white;
  }
}

.product-step__header__step {
  @extend %text-uppercase;
  @extend %fw-bold;

  color: $color-primary;
  font-size: 1.3rem;
  line-height: 1.2em;
}

.product-step__header__title {
  margin-top: $spacing / 10;
  color: $color-primary;
  font-size: 1.8rem;
  line-height: 1.2em;
}

.product-step__header-inner {
  flex-grow: 1;
}

.product-step__header__actions__play {
  margin-right: $spacing / 2;
}

.product-step__header__actions__play,
.product-step__header__actions__trigger {
  position: relative;
  top: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.product-step__header__actions__trigger__icon {
  @include center-xy;

  width: 16px;
  height: 16px;
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

 .product-step__header__actions__trigger:hover & {
    fill: $color-secondary;
  }
}

.product-step__header__actions__play__icon {
  @include center-xy;

  width: 32px;
  height: 32px;
  fill: $color-secondary;
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

 .product-step__header__actions__play:hover & {
   fill: $color-primary;
 }
}

.product-step-inner {
  overflow: hidden;
  max-width: 740px;
  height: 0;
  padding-right: 100px;
  padding-left: 80px;

  .product-step.is-open & {
    height: auto;
  }

  > *:first-child {
    padding-top: $spacing;
  }

  > *:last-child {
    padding-bottom: $spacing * 2.5;
  }
}

.product-step__text {
  @include owl;
}

.product-step__pdfs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $spacing * 1.5;
}

.product-step__pdfs__item {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
  padding: $spacing * .5 0;
}

.product-step__pdfs__item__icon {
  position: relative;
  top: -1px;
  width: 18px;
  height: 18px;
  margin-right: $spacing / 2;
  fill: $color-primary;

  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-step__pdfs__item:hover & {
    fill: $color-secondary;
  }
}

.product-step__pdfs__item__label {
  @extend %ff-open-sans;

  color: $color-primary;
  font-size: 1.4rem;
  line-height: 1em;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-step__pdfs__item:hover & {
    color: $color-secondary;
  }
}
