.product-description__picture-outer {
  position: relative;
  flex-shrink: 0;
  width: 100%;

  @include mq($until: large) {
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: (9 / 16) * 100%;
    }
  }

  @include mq(large) {
    height: 500px;
  }
}

.product-description__picture {
  @include fit;

  user-select: none;
  pointer-events: none;
}

.product-description__video-outer {
  @include mq(large) {
    @include center-x;

    position: absolute;
    bottom: 80px;
    display: flex;
    width: 100%;

    margin-inline: 0;
  }

  @include mq(xlarge) {
    margin-inline: auto;
  }
}

.product-description__video {
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  @include mq($until: large) {
    @include center-xy;

    padding: $spacing * 0.75;
    background-color: $color-primary;

    &:hover {
      background-color: $color-secondary;
    }
  }

  @include mq(large) {
    display: flex;
    align-items: center;
    margin-left: 4rem;
    padding: $spacing $spacing * 2;
    background-color: $white;

    &:hover {
      background-color: $color-primary;
    }
  }

  @include mq(xlarge) {
    margin-left: 0;
  }
}

.product-description__video__label {
  @extend %text-uppercase;
  @extend %ff-av-next-cond;
  @extend %fw-bold;

  position: relative;
  top: 1px;
  color: $color-primary;
  font-size: 1.4rem;
  line-height: 1em;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  .product-description__video:hover & {
    color: $white;
  }

  @include mq($until: large) {
    display: none;
  }
}

.product-description__video__icon {
  display: block;
  width: 17px;
  height: 17px;
  fill: $white;
  transition-property: fill;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  @include mq($until: large) {
    .product-description__video:hover & {
      fill: $color-primary;
    }
  }

  @include mq(large) {
    margin-left: $spacing * 0.75;
    fill: $color-primary;

    .product-description__video:hover & {
      fill: $white;
    }
  }
}

.product-description-inner {
  @include mq(large) {
    display: flex;
    justify-content: flex-end;

    .no-picture & {
      justify-content: flex-start;
    }
  }
}

.product-description__content {
  position: relative;
  padding: $spacing * 3 $spacing;
  background-color: $white;

  @include mq(large) {
    width: 100%;
    max-width: 660px;
    margin-top: -400px;
    padding: 6rem;
    box-shadow: 2px 2px 10px 5px rgba($c-region-active, 0.2);

    .no-picture & {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      margin-top: 0;
      box-shadow: none;
      padding: 6rem 6rem 6rem 0;
    }
  }
}

.product-description__content__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  margin: 0;
  color: $color-primary;
  font-size: 1.8rem;
  line-height: 1.3em;

  @include mq(large) {
    font-size: 3.6rem;
  }

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    margin-top: $spacing / 2;
    background-color: $picton-blue;

    @include mq(large) {
      width: 50px;
      height: 4px;
    }
  }
}

.product-description__content__subtitle {
  @extend %ff-open-sans;

  margin-top: $spacing;
  color: $color-primary;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.2em;
  text-transform: none;
}

.product-description__content__text {
  margin-top: $spacing;
  font-size: 1.5rem;
  line-height: 1.6em;

  @include mq($until: large) {
    max-width: 440px;
  }

  @include mq(large) {
    margin-top: $spacing * 2;

    .no-picture & {
      flex-basis: 50%;
      max-width: 540px;
    }
  }
}

.product-description__content__ctas {
  margin-top: $spacing * 2;

  @include mq(large) {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing;
  }
}

.product-description__content__ctas__cta {
  margin-top: $spacing;

  @include mq(large) {
    margin-right: $spacing;
  }
}
