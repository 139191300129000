.product-accessories {
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 4;

  @include mq(large) {
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 8;
  }
}

.product-accessories__list {
  @include mq(small-xl) {
    display: flex;
    flex-wrap: wrap;
  }
}

.product-accessories__item {
  @extend %text-nodecoration;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-shadow: 0 10px 30px rgba($black, 0);
  transition-property: box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  &:hover {
    box-shadow: 0 10px 30px rgba($black, .1);
  }

  @include mq($until: small-xl) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(small-xl, large) {
    width: calc(50% - #{$spacing});

    &:nth-child(odd) {
      margin-right: $spacing * 2;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(large) {
    width: calc(33.33% - #{$spacing * 2});

    &:nth-child(3n+1),
    &:nth-child(3n+2) {
      margin-right: $spacing * 3;
    }

    &:nth-child(n+4) {
      margin-top: $spacing * 5;
    }
  }
}

.product-accessories__item__picture-outer {
  position: relative;
  width: 100%;
  background-color: $alabaster2;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (21 / 36 * 100%);
  }
}

.product-accessories__item__picture {
  @include fit;
}

.product-accessories__item-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  border: 1px solid $alabaster2;
  padding: $spacing * 1.5 $spacing $spacing;

  @include mq(medium) {
    padding: $spacing * 2 $spacing * 2 $spacing * 1.25;
  }

  @include mq(large, xlarge) {
    padding: $spacing * 1.5 $spacing $spacing;
  }
}

.product-accessories__item__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  width: 100%;
  color: $color-primary;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.product-accessories__item__subtitle {
  @extend %ff-open-sans;

  width: 100%;
  flex-grow: 1;
  margin-top: $spacing / 4;
  color: $color-primary;
  font-size: 1.5rem;
  line-height: 1.2em;
}

.product-accessories__item__headline {
  @include center-y;

  top: 0;
  left: $spacing;
}

.product-accessories__item__see {
  display: flex;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  margin-top: $spacing * 1.5;
}

.product-accessories__item__see__icon {
  position: relative;
  top: 1px;
  width: 20px;
  height: 20px;
  margin-right: $spacing / 4;
  fill: $color-primary;
}

.product-accessories__item__see__label {
  @extend %text-uppercase;

  color: $color-primary;
  opacity: .5;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-accessories__item:hover & {
    opacity: 1;
  }
}

// .product-accessories__item__title {
//   @extend %text-uppercase;
//   @extend %fw-bold;
//   @extend %ff-av-next-cond;

//   margin-top: $spacing;
//   color: $color-primary;
//   font-size: 1.6rem;
//   line-height: 1.2em;
//   transition-property: color;
//   transition-duration: .2s;
//   transition-timing-function: ease-out;

//   .product-accessories__item:hover & {
//     color: $color-secondary;
//   }
// }

// .product-accessories__item__duration {
//   @extend %ff-open-sans;

//   margin-top: $spacing / 4;
//   font-size: 1.3rem;
//   line-height: 1.2em;
// }
