.cta__arrow {
  width: 9px;
  height: 9px;
  margin-left: 0.25em;
  transition: fill $duration-default $ease-default;
}

@each $slug, $color in $segment-colors {
  [class*='cta--'][class*='--#{$slug}'] {
    color: $color;
    fill: $color;

    &.is-active,
    &:hover,
    &:focus {
      color: $color-primary;
      fill: $color-primary;
    }
  }
}

[class*='cta--'][class*='--corpo'] {
  color: $color-corpo;
  fill: $color-corpo;

  &.is-active,
  &:hover,
  &:focus {
    color: $color-primary;
    fill: $color-primary;
  }
}

[class*='cta--'][class*='--bordered'],
[class*='cta--'][class*='--highlighted'] {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 36px;
  // transition: none;

  .cta__label {
    flex-grow: 1;
    margin-top: 4px;
    padding-right: 0.5em;
  }

  .cta__icon {
    flex-shrink: 0;
    width: 0;
    transition: all $duration-default $ease-default $duration-default;
  }

  &.is-active,
  &:hover,
  &:focus {
    .cta__icon {
      width: 20px;
      margin-right: 0.5em;
      fill: $white;
      transition: all $duration-default $ease-default;
    }
  }

  .cta__arrow {
    @include center-y;
    // position: absolute !important;
    right: 1.5rem;
  }
}

[class*='cta--'][class*='--call'] {
  background-color: $white;
  color: $color-primary;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid $color-primary;
  font-weight: 100;

  &:hover {
    color: $white;
    background-color: $color-primary;
  }
}

[class*='cta--'][class*='--bordered'],
.cta--bordered {
  min-width: 150px;
  max-width: 200px;
  padding: 0 1.5em 0 1.25em;
  transition: color $duration-default $ease-default,
    padding-left $duration-default * 2 $ease-default;

  & > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: $border-spacing;
    height: 100%;
    background-color: $color-primary;
    transition: width $duration-default $ease-default $duration-default;
  }

  &.is-active,
  &:hover,
  &:focus,
  .homepage-slider__item:hover & {
    color: $white;
    fill: $white;
    transition: color $duration-default $ease-default $duration-default / 2;

    .cta__label {
      transform: translateX(-0.5em);
    }

    &::before {
      width: 100%;
      transition: width $duration-default * 2 $ease-default;
    }
  }

  .page-template-solution & {
    max-width: 230px;
    margin-top: 0;
    padding: 2.5rem;
    background-color: $color-primary;
    color: $white;
    fill: $white;

    &::before {
      background-color: $color-secondary;
    }

    &.is-active,
    &:hover,
    &:focus {
      color: $color-primary;
      fill: $color-primary;
    }
  }

  .cta__label {
    flex-grow: 0;
    width: 80%;
    transform: translateX(0);
    transition: transform $duration-default $ease-default;
  }
}

[class*='cta--'][class*='--bordered'][class*='--dark'],
[class*='cta--'][class*='--bordered'][class*='--light'],
.cta--bordered--dark {
  color: $white;
  fill: $white;
  background: $color-primary;
  width: fit-content;
  min-width: initial;
  max-width: none;

  .cta__label {
    padding-right: 2rem;
    white-space: nowrap;
  }

  &::before {
    width: 0;
    background: $color-secondary;
  }

  &:hover,
  &:focus {
    &::before {
      width: 100%;
    }
  }
}

[class*='cta--'][class*='--bordered'][class*='--light'] {
  color: $color-primary;
  fill: $color-primary;
  background: $white;
}

@each $slug, $color in $segment-colors {
  [class*='cta--'][class*='--bordered'][class*='--#{$slug}'] {
    color: $color-primary;
    fill: $color-primary;

    &::before {
      background-color: $color;
    }

    &.is-active,
    &:hover,
    &:focus {
      color: $white;
    }
  }

  [class*='cta--'][class*='--bordered'][class*='--mono'][class*='--#{$slug}'] {
    color: $color;
    fill: $color;

    &.is-active,
    &:hover,
    &:focus {
      color: $white;
      fill: $white;
    }
  }
}

[class*='cta--'][class*='--bordered'][class*='--corpo'] {
  color: $color-primary;
  fill: $color-primary;

  &::before {
    background-color: $color-corpo;
  }

  &.is-active,
  &:hover,
  &:focus {
    color: $white;
  }
}

[class*='cta--'][class*='--bordered'][class*='--white'] {
  color: $white;
  fill: $white;

  &::before {
    background-color: $color-secondary;
  }

  &.is-active,
  &:hover,
  &:focus,
  .homepage-slider__item:hover & {
    color: $white;
  }
}

[class*='cta--'][class*='--bordered'][class*='--mono'][class*='--corpo'] {
  color: $color-corpo;
  fill: $color-corpo;

  &.is-active,
  &:hover,
  &:focus {
    color: $white;
    fill: $white;
  }
}

[class*='cta--'][class*='--highlighted'] {
  max-width: 180px;
  padding: 0.75em 2em 0.75em 1.25em;
  background-color: $color-primary;
  color: $white;
  transition: all $duration-default $ease-default;

  &.is-active,
  &:hover,
  &:focus {
    background-color: $color-secondary;
    fill: $color-primary;
  }
}
