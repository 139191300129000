.region-switcher {
  // margin-top: 5.5rem;
  position: fixed;
  z-index: 101;
  top: $menu-header-height;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  @include mq(large) {
    top: 0;
  }

  // background: $cello;
  // color: $white;
  //overflow: hidden;
}

.region-switcher__header {
  margin-bottom: 2rem;

  &.is-visible {
    margin-bottom: 0;
  }
}

.region-switcher__header__wrong-region {
  display: none;
  margin: 0;
}

.region-switcher__header__wrong-region {
  .region-switcher__header.is-visible & {
    display: block;
  }
}

.region-switcher__header__switch {
  margin: 0;

  .region-switcher__header.is-visible & {
    display: none;
  }
}

.region-switcher__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.8);
}

.region-switcher__container {
  width: calc(100% - 4rem);
  max-width: 30rem;
  padding: 4rem 2rem;
  position: relative;
  background: $cello;
  color: $white;

  @include mq(small-xl) {
    width: 100%;
  }

  @include mq(large) {
    max-width: 50rem;
  }
}

.region-switcher__form {
  margin-top: 2rem;

  @include mq(large) {
    display: none;
  }
}

.region-switcher__form__label {
  position: relative;
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  opacity: 0.2;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background: $white;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.region-switcher__form__fields {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .form-item {
    width: 100%;
    max-width: calc(50% - 0.5rem);
    margin: 0;
    border-bottom: 0.1rem solid $white;
    padding: 0 0 0.5rem;

    .dropdown {
      width: 100%;
      appearance: none;
    }
  }

  .icon {
    display: none;
  }

  a {
    width: 9rem;
    min-width: auto !important;
    // color: $white;
  }

  a:before {
    background-color: $white !important;
  }

  a:hover {
    color: $white;
    fill: $white;

    .cta__icon {
      fill: $cello !important;
    }
  }

  .dropdown {
    option {
      color: $cello !important;
    }
  }
}

.region-switcher__form__icon {
  position: absolute;
  right: 0;
  bottom: 1rem;
  width: 0.5rem;
  height: 0.4rem;
  fill: $white;
}

.region-switcher__form__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 1rem 2rem;
  background-color: $white;
  color: $cello;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.5;
}

.region-switcher__form__stay {
  width: 100%;
  align-self: center;
}

.region-switcher__form__submit {
  flex-basis: 100%;
  margin-top: 2rem;
  border: 0.1rem solid $white;
  background: $cello;
  color: $white;
}

.region-switcher__close {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  fill: $white;
  width: 4rem;
  height: 4rem;
  justify-content: center;

  svg {
    align-self: center;
  }

  &:hover {
    fill: $white !important;
  }

  @include mq(large) {
    top: 1rem;
    right: 1rem;
  }
}

.region-switcher__regions {
  margin-top: 3rem;

  @include mq($until: large) {
    display: none;
  }
}

.region-switcher__region {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.region-switcher__region__title {
  width: 12rem;
  font-family: $av-next-cond;
  font-size: 1.3rem;
}

.region-switcher__region__langs {
  position: relative;
  margin: 0;
  padding: 0;
  columns: 3;
}

.region-switcher__region__langs__item {
  width: 8rem;
  list-style-type: none;
  line-height: 2;

  a {
    color: $white;
    font-family: $av-next-cond;
    font-size: 1.3rem;
    text-decoration: none;
    line-height: 1.5;
    letter-spacing: 0.7px;
    vertical-align: top;
  }

  .icon {
    width: 0.6rem;
    height: 0.6rem;
    margin-bottom: 0.6rem;
    fill: $white;
    transform: translateX(-0.3rem);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &:hover {
    .icon {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

// .cookie .form-item {
//   margin: 0;
// }

.region-switcher__text {
  margin-top: 3rem;
  max-width: 50rem;
}

@include mq(small-xl) {
  .region-switcher__form__fields {
    // .form-item {
    //   padding: 0;
    //   padding-right: 3rem;
    //   flex-basis: 35%;
    // }

    button {
      align-self: flex-end;
      padding-left: 3rem;
    }
  }
}

@include mq(large) {
  .region-switcher {
    margin: 0;
  }

  .region-switcher__container {
    padding: 6rem;
  }

  .region-switcher__form__fields {
    display: none;
    flex-basis: 60%;
    align-self: center;

    // .form-item {
    //   flex-basis: 38%;
    // }

    button {
      align-self: center;
    }
  }

  .region-switcher__text {
    flex-basis: 40%;
    margin: 0;
    padding: 3rem 0 3rem 3rem;
    border-left: 1px solid $bunting;
  }
}

// Select

.cookie select {
  position: relative;
  z-index: 9999;
  width: 120px;
  border-bottom: 1px solid $white;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
}
.cookie select option {
  color: $black;
}
