.article-share {
  @include mq($from: ml, $until: xlarge) {
    max-width: 80rem;
    margin: $spacing * 3 auto 0;
  }

  @include mq(xlarge) {
    position: sticky;
    bottom: 1rem;
    height: 8rem;
    margin-top: -8rem;
    pointer-events: none;
  }
}

.article-share-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include mq(xlarge) {
    display: block;
    width: fit-content;
    margin-left: auto;
    pointer-events: auto;
  }
}

.article-share__label {
  @extend %text-uppercase;
  
  width: 10rem;
  color: $color-primary;
  line-height: 1.6rem;

  @include mq(xlarge) {
    width: 9rem;
  }
}

.article-share__link {
  @extend %text-nodecoration;

  display: block;
  padding: 1rem;

  span {
    @extend %visually-hidden;
  }

  @include mq(xlarge) {
    display: inline-block;
    padding: 1rem 1rem 0 0;

    & + & {
      padding: 1rem 0 0 1rem;
    }

    svg {
      width: 3.2rem;
      height: auto;
    }
  }
}