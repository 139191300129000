// .h1,
// .h2,
// .h3,
// .h4,
// .h5 {
//   color: $color-primary;
// }

.section-headline {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  display: block;

  + .section-title {
    margin-top: $spacing;
  }
}

.section-title {
  margin-top: $spacing * 4;
  margin-bottom: $spacing * 3;
  font-size: 3.8rem;
  line-height: 4rem;
  text-align: center;

  @include mq(large) {
    margin-top: 9rem;
    margin-bottom: 9rem;
    font-size: 6.4rem;
    line-height: 7.2rem;
  }

  @include mq(xlarge) {
    margin-top: $spacing * 6;
    margin-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    margin-top: $spacing * 8;
  }

  strong {
    color: $picton-blue;
  }
}
