.tech-corner-ctas {
  background: $careers-gray-2;
}

.tech-corner-ctas__list {
  display: grid;
  gap: $spacing * 0.5;
  margin-top: 0;
  margin-bottom: 0;
  padding: $spacing * 3.5 0 $spacing * 1.5;
  list-style-type: none;

  @include mq(medium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: $spacing * 3.2 0;
  }

  @include mq(xxlarge) {
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing * 1.5;
    padding: $spacing * 6 0;
  }
}

.tech-corner-ctas__item {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
  padding: $spacing;
  color: $color-primary;
  background: $white;
  transition: all $duration-default $ease-default;

  &:hover,
  &:focus-visible {
    color: $white;
    background: $color-secondary;
  }

  @include mq(medium) {
    position: relative;
    display: block;
  }

  @include mq(xxlarge) {
    padding: $spacing * 2;
  }
}

.tech-corner-ctas__item__icon {
  width: 4rem;
  height: 4rem;
  fill: $color-primary;

  @include mq(xxlarge) {
    width: 4.8rem;
    height: 4.8rem;
  }
}

.tech-corner-ctas__item__label {
  flex: 1;
  margin: 0 $spacing;
  font-size: 1.8rem;

  @include mq(medium) {
    display: block;
    margin: 5rem 0 0;
    padding-right: $spacing * 2;
  }

  @include mq(xxlarge) {
    margin-top: 6.8rem;
    font-size: 2.4rem;
  }
}

.tech-corner-ctas__item__arrow {
  width: 1rem;
  height: 1.5rem;
  fill: currentcolor;
  transition: transform $duration-default $ease-default;

  .tech-corner-ctas__item:hover &,
  .tech-corner-ctas__item:focus-visible & {
    transform: translateX(0.5rem);
  }

  @include mq(medium) {
    position: absolute;
    right: $spacing;
    bottom: $spacing;
  }

  @include mq(xxlarge) {
    right: $spacing * 2;
    bottom: $spacing * 2;
  }
}