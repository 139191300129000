$product-heading-width: 16rem;

.en16005-products {
  margin-top: $spacing * 3.5;
  margin-bottom: $spacing * 4;
}

.en16005-products__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $spacing * 2;

  .icon {
    width: 7.5rem;
    height: auto;
    margin-bottom: $spacing / 2;
    fill: none;
    stroke: currentColor;
  }
}

.en16005-products__list {
  @extend %list-nostyle;
}

.en16005-products__item {
  @extend %color-primary;

  position: relative;

  & + & {
    margin-top: $spacing * 2;
    padding-top: $spacing * 2;
    border-top: 1px solid $color-gray-light;
  }
}

.en16005-products__item__picture {
  display: block;
  max-width: 24rem;
  margin: 0 auto;
}

.en16005-products__item-content {
  margin-top: $spacing;
  padding-bottom: $spacing * 2;
  text-align: center;

  @include mq(large) {
    position: static;
    margin: $spacing * 2;
    padding: 0;
  }
}

.en16005-products__item__title {
  @extend %ff-av-next-cond;
  @extend %fw-semibold;
  @extend %color-primary;

  font-size: 1.8rem;
  margin: $spacing * 1.5 0 $spacing / 2;

  @include mq($until: large) {
    .en16005-products__item-heading & {
      display: none;
    }
  }
}

.en16005-products__item__video {
  position: relative;
  height: 0;
  margin-right: -$spacing;
  margin-left: -$spacing;
  padding-bottom: 80%;

  .cta-video {
    @include center-xy;
  }

  @include mq(small-xl) {
    padding-bottom: 50%;
  }

  @include mq(medium) {
    margin-right: -$spacing * 2;
    margin-left: -$spacing * 2;
  }
}

.en16005-products__item__video__picture {
  @include image-fit;

  z-index: -1;
  width: 100%;
  height: 100%;
}

.en16005-products__item__video__norm {
  position: absolute;
  top: $spacing;
  left: $spacing;
  max-width: 9rem;
}

.en16005-products__item__specs {
  @extend %list-nostyle;

  margin-top: $spacing * 2;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  li + li {
    margin-top: $spacing / 2;
  }
}

.en16005-products__item__specs__picture {
  position: relative;
  flex: 1;
  margin-right: $spacing * 1.5;
  text-align: right;

  .picture {
    width: 6rem;
  }

  .check {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.en16005-products__item__specs__label {
  @extend %fw-semibold;
  @extend %ff-av-next-cond;
  @extend %text-uppercase;

  flex: 1;
  font-size: 1.3rem;
  letter-spacing: 0.03em;
  text-align: left;
}

.en16005-products__item__link {
  @extend %text-uppercase;

  z-index: 1;
  display: inline-flex;
  margin-top: $spacing * 2;
  margin-right: auto;

  .en16005-button__label {
    font-size: 1.4rem;
    line-height: 1.2;
  }
}

.en16005-products__item__accessory {
  margin-top: $spacing * 3;
}

@include mq(large) {
  .en16005-products {
    overflow: hidden;
    margin-top: $spacing * 3.5;
    padding-bottom: 13rem;
  }

  .en16005-products__title {
    font-size: 3.6rem;
    margin-bottom: 4.5rem;
  }

  .en16005-products__list {
    position: relative;
    height: 70rem;
    margin: auto;
    transition: height 0.5s;

    &.has-accessory {
      height: 95rem;
    }
  }

  .en16005-products__item {
    position: static;
    width: $product-heading-width;

    & + & {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;

      .en16005-products__item-heading {
        border-top: 0;
      }
    }
  }

  .en16005-products__item-heading {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    width: $product-heading-width;
    height: $product-heading-width;
    color: $color-gray-dark;
    text-align: center;
    background: $color-gray-lighter;
    border: 1px solid $color-gray-light;
    cursor: pointer;

    &:hover {
      background: rgba($color-gray-lighter, 0.5);
    }

    .is-active &,
    .is-active &:hover {
      color: $color-primary;
      background: $white;
      border-right: 0;

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -1px;
        width: calc(100% + 1px);
        height: 0.4rem;
        background: $color-secondary;
      }
    }

    .en16005-products__item__title {
      font-size: 1.3rem;
      letter-spacing: 0;
      margin: 0;
    }

    .en16005-products__item__picture {
      width: 10rem;
    }
  }

  .en16005-products__item-content {
    position: absolute;
    top: 0;
    left: $product-heading-width;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - #{$product-heading-width});
    height: 100%;
    margin: 0;
    padding: $spacing * 4 $spacing * 3.5 $spacing * 2.5;
    text-align: left;
    background: $white;
    border: 1px solid $color-gray-light;
    box-shadow: 1.5rem 1rem 6rem rgba($black, 0.1);
    opacity: 0;
    visibility: hidden;

    .is-active & {
      opacity: 1;
      visibility: visible;
    }

    h4.en16005-products__item__title {
      font-size: 2.4rem;
      text-transform: uppercase;
      margin-top: 0;
    }
  }

  .en16005-products__item__video {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50vw;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;

    .is-active & {
      opacity: 1;
      visibility: visible;
    }
  }

  .en16005-products__item__video__norm {
    top: $spacing * 2;
    left: $spacing * 2;
  }

  .en16005-products__item__specs {
    flex: 1;

    li + li {
      margin-top: $spacing / 2;
    }
  }

  .en16005-products__item__specs__picture {
    flex: auto 0 0;
    text-align: left;

    .picture {
      width: 8rem;
    }
  }

  .en16005-products__item__specs__label  {
    font-size: 1.5rem;
  }

  .en16005-products__item__link {
    margin-top: $spacing;
  }
}
