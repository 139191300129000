.product-installation {
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 4;

  @include mq(large) {
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 8;
  }

  .ytp-show-cards-title,
  .ytp-impression-link {
    display: none !important;
  }
}

.product-installation__iframe-outer {
  position: relative;
  width: 100%;
  margin-top: $spacing;

  @include mq($until: medium) {
    width: calc(100% + 40px);
    margin-right: -42px;
    margin-left: -20px;
  }

  @include mq(medium, xlarge) {
    width: calc(100% + 80px);
    margin-right: -40px;
    margin-left: -40px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.product-installation__iframe {
  @include get-all-space;

  width: 100%;
  height: 100%;



}

.product-installation__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing * 2;
  border-bottom: 1px solid $alto;
  padding-bottom: $spacing * 1.25;
}

.product-installation__header__title {
  @extend %fw-bold;

  color: $color-primary;
  font-size: 3.6rem;
  line-height: 1.2em;
}

.product-installation__header__sharing {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: $c-region-north-central;
  font-weight: 600;

  > * {
    padding-left: 1rem;
  }
}

.product-installation__content {
  margin-top: $spacing * 3;

  @include mq(medium) {
    display: flex;
    align-items: flex-start;
  }
}

.product-installation__content__list {
  width: 100%;
  max-width: 800px;
}

.product-installation__content__links {
  @include mq($until: medium) {
    margin-top: $spacing * 2;
  }

  @include mq(medium) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: 10%;
  }
}

.product-installation__content__links__link {
  & + & {
    margin-top: $spacing * .75;
  }
}
