.block-products,
[class*="block-products--"] {
  background-color: $color-gray-lighter;

  @include mq('medium') {
    display: flex;
    margin-bottom: 20rem;

    &[class*="--reverse"] {
      flex-direction: row-reverse;
    }

  }

  & + & {
    margin-top: 8rem;

    @include mq('medium') {
      margin-top: 0;
    }
  }

}

[class*="block-products--"][class*="--reverse"] {
  padding: 0;
}

.block-products__card {
  background-color: $white;

  @include mq('medium') {
    top: 10rem;
    align-self: flex-start;
    margin-left: -4rem;
    box-shadow: 0 10px 40px 0 rgba($black, .1);

    [class*="block-products--"][class*="--reverse"] & {
      margin-right: -4rem;
      margin-left: 0;
    }

  }

}

.block-products__picture-outer,
.block-products__card {

  @include mq('medium') {
    width: 50%;
  }
}

.block-products__picture-outer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq($until: 'medium') {
    height: 20rem;
  }

}

.block-products__picture {
  @include fit(cover);
  max-height: 100%;
}
