.map-sales-inner {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: $spacing * 2 $spacing * 2;

  @include mq(large) {
    display: flex;
    align-items: center;

    .map-sales.has-searched & {
      display: block;
    }
  }
}

// Map
// ---------------------------------------------------------------------------

.map-sales__map {
  position: relative;
  width: calc(70% - #{$spacing * 2});
  max-width: 430px;
  margin-right: $spacing * 2;
  fill: $cello;

  .map-sales.has-searched & {
    display: none;
  }

  @include mq($until: large) {
    display: none;
  }
}

.map-sales__map svg {
  display: block;
}

.map-sales__map img {
  display: block;
  width: 100%;
}

// Select
// ---------------------------------------------------------------------------

.map-sales__select-outer {
  width: 30%;
  max-width: 460px;

  .map-sales.has-searched & {
    width: 100%;
    margin: 0 auto $spacing * 4;
  }
}

.map-sales__select-title {
  font-family: $av-next-cond;
  margin-bottom: $spacing * 1.5;
  color: $cello;
  font-size: 2.0rem;
  line-height: 1.2em;
  font-weight: 700;
}

.map-sales__select {
  width: 100%;
}

// Infos
// ---------------------------------------------------------------------------

.map-sales__infos-inner {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.map-sales__infos__title {
  margin: 0 auto $spacing;
  max-width: 460px;
  color: $cello;
  font-family: $av-next-cond;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.2em;
  text-align: center;
  text-transform: uppercase;
}

.map-sales__infos__employees__list {
  @extend %list-nostyle;
  max-width: 460px;
  margin: $spacing * 2 auto 0;
}

.map-sales__infos__employees__item {
  display: flex;
  align-items: center;

  & + & {
    margin-top: $spacing;
  }
}

.map-sales__infos__employees__picture-outer {
  position: relative;
  width: 150px;
  margin-right: $spacing;
  border: 1px solid $color-gray-light;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.map-sales__infos__employees__picture {
  @include object-fit;
  @include get-all-space;
  display: block;
}

.map-sales__infos__employees__item-inner {
  display: flex;
  flex-direction: column;
}

.map-sales__infos__employees__name {
  margin-bottom: $spacing / 4;
  color: $cello;
  font-family: $av-next-cond;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2em;
}

.map-sales__infos__employees__job {
  margin-bottom: $spacing / 2;
}

.map-sales__infos__employees__phone,
.map-sales__infos__employees__email {
  font-size: 1.3rem;
}
