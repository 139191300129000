.product-more-container {
  margin-bottom: $spacing * 3;

  .product-title-inner,
  .product-wysiwyg-inner {
    max-width: 800px;
    margin: 0 $spacing;

    @include mq(medium) {
      margin: 0 $spacing * 2;
    }

    @media (min-width: 880px) {
      margin: 0 auto;
    }
  }

  .product-video-shortcode {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 2;

    @include mq(medium) {
      float: left;
      width: calc(50% - #{$spacing});

      &:nth-of-type(even) {
        margin-left: $spacing * 2;
      }

      &:nth-of-type(n + 3) {
        margin-top: 0;
      }
    }

    & + *:not(.product-video-shortcode) {
      clear: both;
    }
  }
}

/**
 *  Headlines
 *  aka "new" labels on products
 */
.product-headline {
  @extend %text-uppercase;
  @extend %fw-semibold;

  padding: .1rem .6rem .2rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  background-color: $cello;
  color: $white;
  border-radius: .3rem;
}