.user-slider {
  overflow: hidden;
}

.user-slider-list {
  .flickity-viewport {
    overflow: visible;
    height: 100%;
    width: 100%;
  }
}

.user-slider-container {
  padding-left: 2rem;

  @include mq(large) {
    padding-left: 6rem;
  }
}

.user-slider__controls {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
  width: fit-content;

  .user-slider__control:first-child {
    svg {
      transform: rotate(180deg);
    }
  }
}

.user-slider__control {
  background-color: $careers-gray;
  width: 4rem;
  border: none;
  border-radius: 0.4rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}
