.launchpage-duo {
  position: relative;
  background: $color-lp-bg;

  @include mq($until: medium) {
    padding-top: $spacing / 2;
  }
}

.launchpage-duo__picture {
  position: relative;
  overflow: hidden;
  height: 0;
  margin: $spacing / 2 $spacing;
  padding-bottom: 50%;

  img {
    @include get-all-space;
    @include object-fit(cover);

    width: 100%;
    max-width: none;
    height: 100%;
  }
}

.launchpage-duo__video {
  @include center-xy;
}

@include mq(medium) {
  .launchpage-duo__picture {
    position: absolute;
    right: 0;
    width: 60%;
    height: calc(100% - 14.4rem);
    margin: 0;
    padding: 0;
  }

  .launchpage-duo__content {
    padding-top: 10rem;
    padding-bottom: 13rem;
  }

  .launchpage-duo:nth-of-type(2n + 1) {
    .launchpage-duo__picture {
      right: initial;
      left: 0;
    }

    .launchpage-content-inner {
      padding: 0 0 0 65%;
    }
  }
}

@include mq(large) {
  .launchpage-duo__picture {
    height: calc(100% - 26rem);
  }

  .launchpage-duo__content {
    padding-top: 15rem;
    padding-bottom: 23rem;
  }
}

@include mq(xxlarge) {
  .launchpage-duo__picture {
    width: 55%;
  }

  .launchpage-duo:nth-of-type(2n + 1) {
    .launchpage-content-inner {
      // padding-left: 40%;
    }
  }
}
