.product-related-applications {
  padding: $spacing * 3 0 $spacing * 3;
  background-color: $alabaster2;

  @include mq(large) {
    padding: $spacing * 6 0 $spacing * 4;
  }

  .related-applications__title {
    @extend %text-uppercase;

    margin: 0;
  }

  .related-applications__list {
    margin: $spacing * 1.5 0 0;
    padding: 0;
  }
}

.product-related-applications__related {
  padding: 0;
  background-color: transparent;
}
