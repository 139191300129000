.product-video,
.product-video-shortcode {
  width: 100%;
  cursor: pointer;
}

.product-video__picture-outer {
  position: relative;
  background-color: $black;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (21 / 36 * 100%);
  }
}

.product-video__picture {
  @include fit;

  background-color: $alto;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-video-shortcode:hover &,
  .product-video:hover & {
    opacity: .8;
  }
}

.product-video__icon {
  @include center-xy;

  width: 64px;
  height: 45px;

  &.youku {
    fill: $color-secondary;
  }
}

.product-video__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  margin-top: $spacing;
  color: $color-primary;
  font-size: 1.6rem;
  line-height: 1.2em;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-video-shortcode:hover &,
  .product-video:hover & {
    color: $color-secondary;
  }
}

.product-video__duration {
  @extend %ff-open-sans;

  margin-top: $spacing / 4;
  font-size: 1.3rem;
  line-height: 1.2em;
}
