.homepage-slider {
  position: relative;
  overflow: hidden;
}

.homepage-slider__items {
  @extend %list-nostyle;
  position: relative;
  overflow: hidden;
  height: 26rem;

  @include mq('medium') {
    height: 40rem;
  }

  @include mq('large') {
    height: 56rem;
  }

}

.homepage-slider__item {
  @include get-all-space();
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homepage-slider__item__alllink {
  @include get-all-space();

  z-index: 9;
}

.homepage-slider__item img {
  @include fit(cover);

  z-index: -1;
}

.homepage-slider__item-inner {
  position: relative;

  @include mq(xlarge) {
    width: calc(100% - #{4 * $spacing});
  }

  @include mq(xxlarge) {
    width: 100%;
  }
}

.homepage-slider__item__title,
.homepage-slider__item__subtitle {
  color: $white;
  margin: 0;
}

.homepage-slider__item__subtitle__categories {
  @extend %list-nostyle;

  display: inline-block;
  margin-left: $spacing * 0.5;
  padding-left: $spacing * 0.5;
  border-left: 1px solid $white;
}

.homepage-slider__item__subtitle__category {
  display: inline-block;

  a {
    color: $white;

    &:hover,
    &:focus-visible {
      color: $picton-blue;
    }
  }

  & + & {
    margin-left: $spacing * 0.5;
  }
}

.homepage-slider__item__cta {
  margin-top: 2rem;
}

.homepage-slider__item__author {
  display: flex;
  align-items: center;
  margin-top: $spacing;
}

.homepage-slider__item__author__picture {
  position: relative;
  overflow: hidden;
  width: 5.8rem;
  height: 5.8rem;
  margin: 0 1.5rem 0 0;
  border-radius: 50%;

  img {
    @include fit(cover);
  }
}

.homepage-slider__item__author__content {
  color: $color-gray-medium;
  letter-spacing: 0.065rem;

  strong {
    display: block;
    color: $white;
    font-size: 1.6rem;
    font-family: $av-next-cond;
    line-height: 1;
    letter-spacing: 0;
  }
}

.homepage-slider .slidy-controls {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 99;

  @include mq('medium') {
    right: 4rem;
    bottom: 4rem;
  }

}

.homepage-slider .slidy-controls__item--next,
.homepage-slider .slidy-controls__item--prev {
  width: 4rem;
  height: 4rem;
  background-color: $white;
  border: 0;
  padding: 0;
  color: $color-primary;

  &:hover {
    background-color: $color-primary;
    color: $white;
  }
}

.homepage-slider .slidy-controls__item--next {
  margin-left: .2rem;
}


// Sliders Styles

.homepage-slider {

  .slidy__item {
    opacity: 0;

    &.is-next {
      z-index: 1;
    }

    &.is-current {
      z-index: 2;
    }

    &.is-next,
    &.is-current {
      opacity: 1;
    }

  }
}
