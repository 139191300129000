.block-intro,
[class*="block-intro--"] {
  position: relative;
  padding: $spacing * 2 2rem;

  @include mq('medium') {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 3.5;

    .page-template-solution & {
      padding: $spacing * 6 2rem;
    }
  }

  &[class*="--about"] {
    padding-bottom: $spacing;
  }

  &[class*="--separator"] {
    border-top: 1px solid $color-gray;
  }

  .lead {
    .page-template-solution &,
    .page-template-tools & {
      @extend %ff-av-next-cond;

      color: $cello;
      font-size: 2rem;
      line-height: 1.5;
    }
  }
}

.block-intro__title {

  [class*="block-intro--"][class*="--about"] & {
    margin-bottom: $spacing / 2;
  }

  [class*="block-intro--"][class*="--separator"] & {
    margin-bottom: $spacing;
  }

  @each $slug, $color in $segment-colors {
    [class*="block-intro--"][class*="--#{$slug}"] & {
      color: $color;
    }
  }
}

.block-intro__main {
  @include mq('small-xl') {
    display: flex;

    .page-template-solution & {
      display: block;
    }
  }

  [class*='block-intro--'][class*='--center'] & {
    justify-content: center;
  }
}

.block-intro__main__keypoints {
  @extend %list-nostyle;

  display: flex;
  justify-content: space-between;
  margin: 5rem 0 6.5rem;
}

.block-intro__main__keypoints__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-template-solution & {
    width: 33%;
  }

}

.block-intro__main__keypoints__item__title {

  .page-template-solution & {
    margin: 1em 0 !important; //sass-lint:disable-line no-important
    font-size: 1.4rem;
  }
}

.block-intro__main__keypoints__item__icon {
  width: 6rem;
  height: 6rem;
  fill: $bunting;
}

.block-intro__main__description {
  flex-grow: 1;
  max-width: 64rem;

  .lead {
    .page-template-solution &,
    .page-template-tools & {
      @extend %ff-av-next-cond;

      color: $cello;
      font-size: 2rem;
    }
  }

  .page-template-solution &,
  .page-template-tools & {
    font-size: 1.6rem;
  }
}

.block-intro__main__ctas {
  margin-top: $spacing;

  @include mq('small-xl') {
    margin-top: 0;
    margin-left: $spacing * 8;

    .page-template-solution & {
      margin-top: $spacing * 2.5;
    }
  }

  [class*="block-intro--"][class*="--application"] & {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      margin-left: -$spacing * 4;
      background-color: $color-gray-light;
    }
  }

  .page-template-solution & {
    margin-left: 0;
  }

  [class*='cta--'] {
    .page-template-solution & {
      margin: 0 auto;
    }
  }
}


@include mq(small-xl) {
  [class*="block-intro--"][class*="--padding-right"] {
    padding-right: 20rem;
  }
}

@include mq(xlarge) {
  [class*="block-intro--"][class*="--padding-right"] {
    padding-right: 20rem;
    .page-template-tools & {
      padding-right: 60rem;
    }
  }
}
