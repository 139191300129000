[class*='product-duo--'],
.product-duo {
  display: flex;

  @include mq($until: large) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @include mq(large) {
    flex-direction: row;
    align-items: center;

    &[class*='--reverse'] {
      flex-direction: row-reverse;
    }
  }

  .flickity-slider & {
    margin-right: 6rem;
    margin-top: 0;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 27%;

    @include mq($until: large) {
      width: 66%;
    }
  }
}

.product-duo-inner {
  z-index: 2;
  flex-shrink: 0;

  @include mq($until: large) {
    width: 100%;
    padding: $spacing * 1.5;
  }

  @include mq(large) {
    width: 50%;
    padding: $spacing * 4;
    background-color: $white;
  }

  .flickity-slider & {
    width: 100%;
    padding: $spacing * 1.5;
  }
}

.product-duo__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  margin: 0;
  color: $color-primary;
  font-size: 2.4rem;
  line-height: 1.2em;
}

.product-duo__text {
  margin-top: $spacing;
}

.product-duo__apps {
  margin-top: $spacing * 2;
}

.product-duo__apps__title {
  @extend %text-uppercase;
  @extend %ff-av-next-cond;
  @extend %fw-bold;

  color: $color-primary;
  font-size: 1.5rem;
  line-height: 1.2em;
}

.product-duo__apps-inner {
  display: flex;
  margin-top: $spacing / 2;
}

.product-duo__apps__app {
  @extend %text-nodecoration;

  & + & {
    margin-left: $spacing / 2;
  }
}

.product-duo__picture-outer {
  position: relative;

  @include mq($until: large) {
    width: 100%;
  }

  @include mq(large) {
    width: 60%;
    margin-left: -10%;

    [class*='product-duo--'][class*='--reverse'] & {
      margin-right: -10%;
      margin-left: 0;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (3 / 4) * 100%;

    @include mq(medium) {
      padding-top: (1 / 2) * 100%;
    }

    @include mq(large) {
      padding-top: (59 / 73) * 100%;
    }

    .flickity-slider & {
      padding-top: (3 / 4) * 100%;
    }
  }

  .flickity-slider & {
    width: 100%;
    margin-left: 0;
  }
}

.product-duo__picture {
  @include fit(cover);
}

.product-duo__link {
  margin-top: $spacing;
}
