$number-radius-xs: 3rem;
$number-radius-l: 4rem;

.en16005-safety {
  position: relative;
}

.en16005-safety__list {
  @extend %list-nostyle;

  counter-set: safety-items;
}

.en16005-safety__item {
  display: flex;
  align-items: flex-start;
  padding: $spacing 0 $spacing * 1.5;
  line-height: 1.4;
  border-bottom: 1px solid $color-gray-light;
  counter-increment: safety-items;

  &::before {
    content: counter(safety-items);
    flex-shrink: 0;
    width: 2.4rem;
    margin-right: $spacing;
    color: $white;
    font-weight: bold;
    line-height: 2.4rem;
    text-align: center;
    background: $color-primary;
    border-radius: 50%;
    transition: 0.2s;
  }
}

.en16005-safety__item__number {
  @extend %fw-bold;

  position: absolute;
  top: inherit;
  left: inherit;
  width: $number-radius-xs;
  color: $white;
  line-height: $number-radius-xs;
  text-align: center;
  background: $color-primary;
  border-radius: 50%;
}

.en16005-safety__item__content {
  @extend %color-primary;

  .title {
    @extend %ff-av-next-cond;
    @extend %fw-semibold;
    @extend %text-uppercase;
  }
}

.en16005-safety__item__content__picture {
  position: relative;
  width: 6rem;
  margin-bottom: $spacing / 2;

  img {
    margin-top: -1rem;
  }
}

@include mq(ml) {
  .en16005-safety {
    position: relative;
  }

  .en16005-safety__item {
    position: absolute;
    z-index: 1;
    display: block;
    padding: 0;
    border: 0;

    &::before,
    &::after {
      @include get-all-space;

      content: '';
      z-index: -1;
      width: 100%;
      margin: 0;
      background: $white;
      border-radius: 50%;
      opacity: 0;
      transform: scale(0);
      transition: 0.15s;
      pointer-events: none;
      animation: safety-number 2s infinite;
    }

    &::after {
      animation-delay: 0.5s;
    }

    &:hover {
      &::before,
      &::after {
        animation: none;
      }
    }
  }

  .en16005-safety__item__number {
    position: relative;
    z-index: 1;
    top: initial;
    left: initial;
    width: $number-radius-l;
    margin: 0;
    color: $color-primary;
    font-size: 2.4rem;
    line-height: $number-radius-l;
    background: $white;
    transition: 0.15s;

    .en16005-safety__item:hover & {
      color: $white;
      background: $color-primary;
    }
  }

  .en16005-safety__item__content {
    position: absolute;
    top: $number-radius-l / 2;
    left: $number-radius-l / 2;
    display: flex;
    align-items: flex-start;
    width: 40rem;
    padding: 2.5rem;
    background: $white;
    clip-path: inset(0% 100% 0% 0%);
    pointer-events: none;
    transition: clip-path 0.3s ease-in;

    &.reverse {
      right: $number-radius-l / 2;
      left: initial;
      clip-path: inset(0% 0% 0% 100%);
    }

    .en16005-safety__item:hover & {
      clip-path: inset(0%);
      pointer-events: all;
      transition: clip-path 0.4s ease-out;
    }
  }

  .en16005-safety__item__content__picture {
    flex-shrink: 0;
    flex-grow: 0;
    width: 8rem;
    margin: 0 $spacing 0 0;
  }
}

@keyframes safety-number {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}
