.launchpage-content__title {
  max-width: 8em;
  margin-bottom: 1rem;
}

@include mq(small-l) {
  .launchpage-content-inner {
    padding-right: 30%;
  }
}

@include mq(medium) {
  .launchpage-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 70%;
  }
}
