[class*="download-row--"] ,
.download-row {
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  border-top: 1px solid $color-gray-light;

  &[class*="--child"] {
    // padding-left: 5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $color-gray-light;
    // margin-left: 6rem;
  }

  @include mq('medium') {
    padding: 1.5rem 2rem;

    &[class*="--child"] {
      margin-left: 6rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      & + & {
        border-top: 1px solid $white;
      }

    }
  }

}

.download-row__icon {
  width: 2rem;
  height: 2rem;
  margin-right: 2rem;
}

.download-row-inner {
  flex: 1;
}

.download-row__title {
  @extend %text-nodecoration;
  color: $color-primary;
  transition: color .3s;

  &:hover {
    color: $color-secondary;
  }

  [class*="download-row--"][class*="--child"] & {
    color: $color-gray-dark;
  }
}
