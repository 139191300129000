/*
** Launchpage hero
*/

.launchpage-hero {
  position: relative;
  z-index: 5;
  padding-top: 10rem;
  margin-bottom: $spacing * 2.5;
  background: $white;
  text-align: center;
}

.launchpage-hero__headline {
  padding-bottom: $spacing * 0.6;
}

h1.launchpage-hero__title {
  position: relative;
  z-index: 1;
  margin: 0;
}

.launchpage-hero__picture-outer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 87rem;
  margin-top: -$spacing * 2;

  .page-template-en16005 & {
    margin-top: $spacing * 2;
  }
}

.launchpage-hero__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img,
  video {
    @include get-all-space;
    @include object-fit(cover);

    width: 100%;
    max-width: none;
    height: 100%;
    transform: scale(1.1);
  }
}

.launchpage-hero__cta {
  @include center-xy;

  margin-top: $spacing * 5;

  .page-template-en16005 & {
    margin-top: 0;
  }
}

@include mq(medium) {
  .launchpage-hero__picture-outer {
    flex-direction: row;
    height: 90vh;
  }
}

@include mq(large) {
  .launchpage-hero {
    padding-top: 10rem;
    margin-bottom: $spacing * 6;
  }

  .launchpage-hero__headline {
    padding-bottom: $spacing * 2;
  }

  .launchpage-hero__picture-outer {
    margin-top: -$spacing * 6;

    .page-template-en16005 & {
      margin-top: $spacing * 3;
    }
  }
}
