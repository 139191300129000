.hero-slider {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #f9f9f9;
}

.hero-slider__item {
  @include aspect-ratio(18, 27);

  transition: transform 0.2s ease-out;

  // max-width: 18rem;
  border-radius: 15px;
  overflow: hidden;

  @include mq(large) {
    max-width: 30rem;
  }

  @include mq(xlarge) {
    max-width: 40rem;
  }

  &:hover {
    transform: scale(1.03);
  }
}

.hero-slider__infos {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.4rem;

  @include mq(large) {
    margin-bottom: 9rem;
  }
}

.hero-slider__label {
  color: $cello;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.6rem;
}

.hero-slider__title {
  color: $picton-blue;
  font-size: 4.8rem;
  margin-top: 1rem;
  margin-bottom: 0;

  @include mq(large) {
    margin-top: 1.6rem;
    font-size: 9.6rem;
  }
}

.hero-slider-carousel ul {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0;
  margin: 0;
  list-style: none;
}

.hero-slider-carousel li {
  position: relative;

  article {
    position: relative;
    aspect-ratio: 9 / 13;
    border-radius: 1.6rem;
    overflow: hidden;
  }
}

.hero-slider__item__picture {
  transition: transform 0.2s ease-out;
  height: 100%;
  object-position: center;
  object-fit: cover;
  width: 100%;

  .hero-slider__item:hover & {
    transform: scale(1.1);
  }
}

.hero-slider__item__title {
  width: max-content;
  text-align: center;
  position: absolute;
  bottom: 4.4rem;
  top: inherit;
  right: inherit;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  font-size: 2rem;
  color: $white;
  text-transform: uppercase;

  .hero-slider__item:hover & {
    &::before {
      width: 100%;
    }
  }

  &::before {
    transition: width 0.2s ease-in-out;
    content: '';
    position: absolute;
    bottom: -0.4rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.6rem;
    height: 4px;
    background-color: $picton-blue;
  }

  @include mq(large) {
    font-size: 3.4rem;
  }
}

.hero-slider-list__item {
  width: 100%;
  max-width: 23rem;
  margin-right: 1rem;

  @include mq(large) {
    max-width: 30rem;
  }

  @include mq(xlarge) {
    max-width: 40rem;
    margin-right: 2rem;
  }
}

.hero-slider__controls {
  margin-left: auto;
  margin-right: 2rem;
  margin-top: 2.4rem;
}

.hero-slider__item__link {
  @include get-all-space();

  span {
    @extend %visually-hidden;
  }
}
