.cta-full,
[class*="cta-full--"] {
  position: relative;
  display: block;
  padding: $spacing * 2 0;
  background-color: $color-primary;
  color: $white;
  fill: $white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: $spacing;
    height: 100%;
    background-color: $color-secondary;
    transition: width $duration-default * 2 $ease-default;

  }

  &:hover,
  &:focus {
    color: $white;
    fill: $white;

    .cta-full-inner {
      transform: translateX(#{$spacing / 2});
    }

    &::before {
      width: 100%;
    }
  }
}

@each $slug, $color in $segment-colors {
  [class*="cta-full--"][class*="--#{$slug}"] {
    &::before {
      background-color: $color;
    }
  }
}

.cta-full-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: transform $duration-default $ease-default;
}

.cta-full__content {
  padding-left: $spacing;

  strong {
    display: block;
    margin-top: .5em;
  }
}

.cta-full__arrow {
  width: 80px;
  height: 80px;

  @include mq('small-xl') {
    margin-left: $spacing * 4;
  }
}
