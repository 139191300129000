.jump-to,
[class*="jump-to--"] {
  background-color: $color-secondary;

  .page-template-tools & {
    background-color: $cello;
  }

  @include mq('small-xl') {
    position: absolute;
    z-index: 3;
    right: 0;
    // top: 0;
    max-width: 220px;
    transform: translateY(#{$spacing * 2});
  }
}

@each $slug, $color in $segment-colors {
  [class*="jump-to--"][class*="--#{$slug}"] {
    background-color: $color;
  }
}

.jump-to__title {
  padding: $spacing * 1.25 $spacing * 1.25 $spacing * .75;

  .page-template-tools & {
    color: $white;
  }
}

.jump-to__list {
  @extend %list-nostyle;

  padding-bottom: $spacing * 1.25;
}

.jump-to__item__link {
  position: relative;
  display: block;
  padding: $spacing * 0.25 $spacing * 1.5 $spacing * 0.25 $spacing * 1.25;
  color: $white;

  &::after {
    @include center-y;
    right: 0;
    content: '';
    display: block;
    margin-top: .2em;
    width: 15px;
    height: $border-spacing;
    background-color: $white;
  }

  &:hover,
  &:focus {
    .page-template-tools & {
      color: $color-secondary;
    }
    &::after {
      background-color: $color-primary;

      .page-template-tools & {
        background-color: $color-secondary;
      }
    }
  }
}
