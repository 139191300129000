.terms-conditions,
[class*="terms-conditions--"] {
  //
}

.terms-conditions-inner {
  position: relative;
  display: flex;

  @include mq($until: large) {
    flex-direction: column;
    padding: $spacing * 2 0;
  }

  @include mq(large) {
    padding: $spacing * 4 0;
  }
}

.terms-conditions__nav {
  @include mq(large) {
    flex-shrink: 0;
    width: 250px;
  }
}

.terms-conditions__nav__link__list {
  @extend %list-nostyle;

  @include mq($until: large) {
    display: none;
  }
}

.terms-conditions__nav__link__item {
  & + & {
    margin-top: $spacing / 2;
  }
}

.terms-conditions__nav__link {
  max-width: none !important;
}

.terms-conditions__nav__select {
  @include mq(large) {
    display: none;
  }
}

.terms-conditions__content {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  @include mq(large) {
    padding-left: $spacing * 2;
  }

  @include mq(xlarge) {
    padding-left: $spacing * 4;
  }
}


