.tools-sizers {
  position: relative;
  width: 100%;
  padding: 10rem 0;
  background-color: $gallery;
}

.tools-sizers__content {
  max-width: 54rem;
}

.tools-sizers__title {
  position: relative;
  margin: 0 0 .7em;
  padding: 0 0 .7em;
  font-size: 3.6rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 3.5rem;
    height: 4px;
    background-color: $picton-blue;
  }
}

.tools-sizers__subtitle {
  margin: .5em 0;
  font-size: 2rem;
  font-weight: 500;
}

.tools-sizers__items {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: 0 -2rem;

  .common-card {
    margin: 2rem;

    @include mq('xlarge') {
      margin: 2.6rem;
    }
  }
}

.tools-sizers__load {
  width: 100%;
  margin-top: $spacing * 3;
  text-align: center;

  .cta__arrow {
    transition: transform .3s ease-out;
    transform: rotate(90deg);

    &.is-open {
      transform: rotate(-90deg);
    }
  }

  &.is-open {
    .cta__arrow {
      transform: rotate(-90deg);
    }
  }

  .cta__label {
    color: $cello;
  }

  .icon {
    fill: $picton-blue;
  }
}

.tools-sizers__load-inner {
  display: inline-block;
  border: 1px solid $c-region-north-central;
  padding: $spacing;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.tools-sizers__items__more {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;

  &.is-open {
    height: auto;
  }
}