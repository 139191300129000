.product-situation {
  max-width: 1280px;
  margin: $spacing * 6 $spacing * 4 0;

  @include mq(xlarge) {
    margin: $spacing * 6 auto 0;
  }
}

.product-situation__title {
  @extend %text-center;
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  color: $color-primary;
  font-size: 2.4rem;
  line-height: 1.2em;
}

.product-situation__slider {
  margin-top: $spacing * 2;
}
