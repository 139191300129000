.toggle-detection-field,
[class*="toggle-detection-field--"] {
  //
}

.toggle-detection-field__feature-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-lighter;
  text-align: center;
}

.toggle-detection-field__feature-item {
  width: 100%;
  margin: $spacing 0;

  @include mq(medium) {
    width: calc(50% - #{$spacing / 2});

    &:nth-of-type(even) {
      margin-left: $spacing;
    }
  }
}

.toggle-detection-field__feature__picture-outer {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.toggle-detection-field__feature__picture {
  @include get-all-space;
  @include fit(cover);
}

.toggle-detection-field__feature__description {
  padding: 0 $spacing;
  color: $color-primary;
}
