.downloads-list {
  margin: 4rem auto;
}

.downloads-list__sorter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: $spacing * 2 $spacing $spacing;
  padding: 0;

  @include mq(xlarge) {
    margin: $spacing * 2 0 $spacing;
  }

}

.downloads-list__sorter .form-label {
  margin: 0;
  margin-right: $spacing;
  flex-shrink: 0;
}

.downloads-list__sorter .form-select {
  position: relative;
}

.downloads-list__sorter select {
  padding-right: $spacing * 1.5;
}

.downloads-list__downloads {
  @extend %list-nostyle;
}
