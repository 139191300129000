.duo-end-users {
  padding-top: 4rem;

  .section-title {
    margin-bottom: 0;
  }

  &.duo-solo {
    .duo__card__media {
      border-radius: 16px;
      overflow: hidden;
    }
  }

  .duo__card__media-one {
    .duo__card__media__picture {
      mask: none;
    }
  }
}
