$bp: 'medium';

.article-card {
  position: relative;

  @include mq($bp) {
    display: flex;
    gap: $spacing * 5;
  }
}

.article-card__content {
  color: $cello;

  h2 {
    margin: 0 0 $spacing;
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 1px;
    transition: color 0.2s;

    .article-card:hover & {
      color: $picton-blue;
    }
  }

  @include mq($bp) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.article-card__content__excerpt {
  @include mq($bp) {
    flex: 1;
  }
}

.article-card__content__footer {
  position: relative;
  z-index: 1;
  margin: $spacing 0 0;
  padding: 0;
  max-width: none;
}

.article-card__picture {
  position: relative;
  overflow: hidden;
  margin-top: $spacing;
  width:100%;
  aspect-ratio: 3/2;

  img {
    @include get-all-space;
    @include image-fit;
    
    transition: transform 0.2s;

    .article-card:hover & {
      transform: scale(1.05);
    }
  }

  @include mq($bp) {
    flex: 1;
    margin-top: 0;
  }
}

.article-card__link {
  @include get-all-space;
}

.article-card__link__label {
  @extend %visually-hidden;
}