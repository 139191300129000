.product-versions {
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 4;

  @include mq(large) {
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 8;
  }
}

.product-versions__list {
  @include mq(large) {
    display: flex;
    flex-wrap: wrap;
  }
}

.product-versions__item {
  @extend %text-nodecoration;

  border: 1px solid $alabaster2;
  display: flex;
  align-items: center;
  width: 100%;

  html.js &:nth-child(n+5) {
    display: none;
  }

  @include mq($until: large) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(large) {
    width: calc(50% - #{$spacing});

    &:nth-child(odd) {
      margin-right: $spacing * 2;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 2;
    }
  }
}

.product-versions__item__picture-outer {
  position: relative;
  flex-shrink: 0;
  width: 45%;
  background-color: $alabaster2;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1 * 100%);
  }
}

.product-versions__item__picture-inner {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.product-versions__item__picture {
  @include fit(contain);
}

.product-versions__item-inner {
  padding: $spacing;

  @include mq(small-xl) {
    padding: $spacing * 2;
  }
}

.product-versions__item__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  width: 100%;
  color: $color-primary;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.product-versions__item__description {
  @extend %ff-open-sans;
  @include owl;

  flex-grow: 1;
  width: 100%;
  max-width: 240px;
  margin-top: $spacing / 4;
  color: $color-primary;
  font-size: 1.5rem;
  line-height: 1.2em;
}

.product-versions__item__version {
  @extend %ff-open-sans;
  @include owl;

  flex-grow: 1;
  width: 100%;
  max-width: 240px;
  margin-top: $spacing * 2;
  color: $color-primary;
  font-size: 1.5rem;
  line-height: 1.2em;
}

.product-versions__more {
  display: none;
  justify-content: center;
  margin-top: $spacing * 2;

  @include mq(small-xl) {
    margin-top: $spacing * 4;
  }
}

.product-versions__more__btn {
  display: flex;
  align-items: center;
  border: 1px solid $color-primary;
  padding: $spacing / 1.5 $spacing;
  cursor: pointer;
  transition-property: border;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-versions__more:hover & {
    border: 1px solid $color-secondary;
  }
}

.product-versions__more__btn__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  margin-right: $spacing;
  color: $color-primary;
  transition-property: color;
  transition-duration: .2s;
  line-height: 1;
  margin-top: 3px;
  transition-timing-function: ease-out;

  .product-versions__more:hover & {
    color: $color-secondary;
  }
}

.product-versions__more__btn__icon {
  position: relative;
  top: -1px;
  width: 8px;
  height: 11px;
  fill: $color-secondary;
}
