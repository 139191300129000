.gmap__map {
  height: 45rem;
  max-height: 75vh;
}

.gmap__card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 70rem;
  margin: -5rem 4rem 0;
  padding: 2rem 3rem;
  border: 1px solid $color-gray-lighter;
  box-shadow: 0 10px 15px 0 rgba($color-gray, .2);
  background-color: $color-gray-lighter;
  color: $color-primary;

  @include mq('medium') {
    margin-right: auto;
    margin-left: auto;
  }
}

.gmap__card__title {
  width: 100%;
  font-size: 1.8rem;
  line-height: 1;
}

.gmap__card__address,
.gmap__card__contact {
  flex-basis: 45%;
  margin-bottom: 1rem;
}

.gmap__card__contact {
  list-style-type: none;

  li {
    display: flex;
  }

  strong {
    flex-basis: 25%;
    text-transform: uppercase;
  }
}
