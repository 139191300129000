.video-lightbox {
  @include get-all-space();
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-primary, 0.9);
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: opacity 0.3s;

  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}

.video-lightbox__iframe-outer {
  position: relative;
  width: calc(100% - #{$spacing * 4});
  max-width: 1600px;
  max-height: calc(100vh - #{$spacing * 6});
  box-shadow: 0 0 40px 0 rgba($black, 0.4);

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.video-lightbox__iframe {
  @include get-all-space;

  width: 100% !important;
  height: 100% !important;
}

.video-lightbox__icon {
  position: absolute;
  top: calc(#{$menu-header-height} + #{$spacing / 2});
  right: $spacing / 2;
  width: 3rem;
  height: 3rem;
  fill: $white;
  pointer-events: none;

  @include mq(large) {
    top: $spacing / 2;
  }
}
