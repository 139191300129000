.product-discontinued {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.product-discontinued__headline {
  @extend %ff-av-next-cond;

  margin: 0 0 1.5rem;
  font-size: 1.5rem !important;
  line-height: 1;
  text-align: center;
}

.product-discontinued__title {
  margin-bottom: 2rem !important;
}

.product-discontinued__picture {
  max-height: 15rem;

  @include mq(xlarge) {
    max-height: 19rem;
  }
}
