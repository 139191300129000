.contact-confirmation,
[class*="contact-confirmation--"] {
  padding: $spacing * 2.5 0 $spacing * 3;
  margin: $spacing * 3 0;
  border: 1px solid $cello;

}

.contact-confirmation__icon {
  width: 40px;
  height: 40px;
  margin-top: 0;
  fill: $cello;
}

.contact-confirmation__title {
  margin: $spacing / 2 auto;
}

.contact-confirmation__baseline {
  max-width: 250px;
  margin: $spacing / 2 auto 0;
}
