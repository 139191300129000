.duo-cards {
  padding-top: 9rem;
  padding-bottom: 4rem;
}

.duo-cards__title {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 2rem;

  @include mq(large) {
    margin-bottom: 6rem;
  }
}

.duo-cards__items {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include mq(large) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
  }
}
