.careers-some {
  display: flex;
  flex-direction: column;

  padding-bottom: $spacing * 3;

  @include mq(large) {
    padding-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    padding-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    padding-bottom: $spacing * 8;
  }

  .socials-links {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .socials-links__list {
    margin-inline: auto;
  }

  .socials-links__list__link-outer {
    margin-inline: $spacing * 1.5;
  }

  .socials-links__list__link-outer .icon {
    width: 2.6rem;
    height: 2.6rem;

    @include mq(large) {
      width: 5rem;
      height: 5rem;
    }
  }
}
