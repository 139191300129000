.product-downloads {
  margin: $spacing * 2.5 0 $spacing * 12;
}

.product-downloads__section {
  @include mq(large) {
    display: flex;
    align-items: flex-start;
  }

  & + & {
    margin-top: $spacing * 2;
  }
}

.product-downloads__section__title {
  @extend %ff-av-next-cond;
  @extend %text-uppercase;
  @extend %fw-bold;

  color: $color-primary;
  font-size: 1.8rem;
  line-height: 1.2em;

  @include mq(large) {
    flex-shrink: 0;
    width: 30%;
    margin-right: 5%;
  }
}

.product-downloads__section-inner {
  @include mq($until: large) {
    margin-top: $spacing / 2;
  }

  @include mq(large) {
    width: 65%;
  }
}

.product-downloads__section__row {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $alto;
  padding: $spacing * .85 $spacing / 2;
}

.product-downloads__section__row__icon {
  position: relative;
  top: -2px;
  width: 18px;
  height: 18px;
  margin-right: $spacing / 2;
  fill: $color-primary;
}

.product-downloads__section__row__title {
  @extend %ff-open-sans;

  flex-grow: 1;
  margin-right: $spacing * 2;
  color: $color-primary;
  font-size: 1.4rem;
  line-height: 1em;
}

.product-downloads__section__row__download {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
}

.product-downloads__section__row__download__icon {
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  margin-right: $spacing / 4;
  fill: $color-primary;
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-downloads__section__row__download:hover & {
    fill: $color-secondary;
  }
}

.product-downloads__section__row__download__label {
  @extend %text-uppercase;

  color: $color-primary;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-downloads__section__row__download:hover & {
    color: $color-secondary;
  }
}

// OVERLAPING LINK FOR ALL CLICKABLE ZONE
.product-downloads__section__row__download__clickable {
  @include get-all-space;

  text-decoration: none;
}