.product-hero {
  position: relative;
  margin-top: $spacing * 2;
  padding-bottom: $spacing * 4;
  background: linear-gradient(180deg, $white 44.09%, rgba(246, 246, 246, 1) 100%);
}

.product-hero__back {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
}

.product-hero__back__icon {
  position: relative;
  top: 1px;
  width: 20px;
  height: 20px;
  margin-right: $spacing / 5;
  fill: $color-primary;
  transform: rotate(180deg);
}

.product-hero__back__label {
  color: $bombay;
  font-size: 1.3rem;
  line-height: 1em;
  white-space: nowrap;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-hero__back:hover & {
    color: $color-primary;
  }
}

.product-hero__title {
  @extend %ff-av-next-cond;
  @extend %text-uppercase;
  @extend %text-center;

  color: $color-primary;
  font-size: 4.8rem;
  line-height: 1.4em;

  @include mq($until: medium) {
    margin-top: $spacing * 2;
  }

  strong {
    @extend %fw-bold;

    display: block;
    font-size: 1.5em;
  }
}

.product-hero__subtitle {
  @extend %text-uppercase;
  @extend %text-center;
  @extend %fw-normal;
  @extend %ff-open-sans;

  color: $color-primary;
  font-size: 1.5rem;
  line-height: 1.3em;
}

.product-hero__slider {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: $spacing auto 0;
}

.product-hero__slider__list {
  @extend %list-nostyle;

  position: relative;
  width: calc(100% - #{$spacing * 5});
  max-width: 600px;
  margin: $spacing * 1.5 auto 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (35 / 42) * 100%;
  }
}

.product-hero__slider__item {
  @include get-all-space;

  display: none;

  &.is-active {
    display: block;
  }
}

.product-hero__slider__item__picture {
  @include get-all-space;
  @include object-fit(contain, center center);

  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

[class*='product-hero-slidy-controls__item--'] {
  @include center-y;

  width: 20px;
  border: 0;
  padding: 0;
  background: transparent;

  @include mq(medium) {
    width: 30px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (11 / 6) * 100%;
  }

  &[class*='--prev'] {
    left: 0;
  }

  &[class*='--next'] {
    right: 0;
  }
}

.product-hero-slidy-controls__item__arrow {
  @include get-all-space;

  fill: $color-primary;
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  [class*='product-hero-slidy-controls__item--']:hover & {
    fill: $color-secondary;
  }

  [class*='product-hero-slidy-controls__item--'][class*='--next'] & {
    transform: rotate(180deg);
  }
}

.product-hero__slider__dots {
  @include center-x;

  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.product-hero__slider__dots__dot {
  width: 6px;
  height: 6px;
  background-color: #c4c4c4;
  border-radius: 50%;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  &.is-active {
    background-color: $color-primary;
  }

  & + & {
    margin-left: $spacing / 2;
  }
}

.product-hero__extras {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $spacing * 6;

  @include mq(large) {
    padding-left: 180px;
    padding-right: 180px;
  }

  @include mq(xxlarge) {
    padding: 0 200px;
  }
}

.product-hero__extra {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mq($until: medium) {
    width: 50%;

    &:nth-child(n+3) {
      margin-top: $spacing * 1.5;
    }
  }

  @include mq(medium) {
    width: 33.33%;

    &:nth-child(n+4) {
      margin-top: $spacing * 1.5;
    }
  }
}

.product-hero__extra__label {
  @extend %text-uppercase;
  @extend %ff-open-sans;

  color: $color-primary;
  font-size: 1.1rem;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 0.5px;
}

.product-hero__extra__value {
  @extend %ff-open-sans;

  margin-top: $spacing / 2;
  color: $color-primary;
  font-size: 1.2rem;
  line-height: 1em;

  @include mq(small-xl) {
    font-size: 1.8rem;
  }
}

.product-hero__colors__list {
  @extend %list-nostyle;

  display: flex;
  align-items: flex-start;
}

.product-hero__colors__item {
  position: relative;
  width: 26px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.product-hero__colors__item__icon {
  @include get-all-space;

  stroke: $alto;
}

.product-hero__popup-trigger {
  position: relative;
  margin-bottom: $spacing * 2;

  @include mq($until: large) {
    display: flex;
    justify-content: center;
    margin-top: $spacing * 2;
  }

  &.is-open {
    .product-hero__popup {
      display: block !important;
    }
  }
}

.product-hero__popup-trigger__thumbnail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 120px;
  border: 1px solid $alto;
  padding: $spacing / 2;
  border-radius: 10px;
  cursor: pointer;

  @include mq(small-xl) {
    width: 160px;
  }

   @include mq(large) {
    position: absolute;
    bottom: 0;
  }

  &:hover {
    border: 1px solid $color-secondary;
  }
}

.product-hero__popup-trigger__thumbnail__picture-outer {
  position: relative;
  width: calc(100% - #{$spacing});

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.product-hero__popup-trigger__thumbnail__picture {
  @include fit(contain);
}

.product-hero__popup-trigger__thumbnail__title {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-left: $spacing / 2;
}

.product-hero__popup-trigger__thumbnail__title__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-open-sans;

  margin-right: $spacing / 2;
  color: $color-primary;
  font-size: 1rem;
  line-height: 1em;
  white-space: nowrap;
}

.product-hero__popup-trigger__thumbnail__title__icon {
  width: 20px;
  height: 20px;

  .icon {
    fill: $color-primary;
  }
}


.product-hero__popup {
  @include get-all-space;

  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  background-color: rgba($color-primary, .9);
  opacity: 0;
  display: none;
}

.product-hero__popup-inner {
  @include center-xy;

  width: calc(100% - #{$spacing * 4});
  height: calc(100% - #{$spacing * 10});
  background-color: $white;
  margin-left: 0 !important;

  &::before {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: -1rem;
    width: 2rem;
    height: .2rem;
    transform: translateY(-1rem) rotate(45deg);
    background-color: $white;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: -1rem;
    width: .2rem;
    height: 2rem;
    transform: translate(-.9rem, -1.9rem) rotate(45deg);
    background-color: $white;
  }

  @include mq(medium) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}

.product-hero__popup__picture-outer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 25rem;

  @include mq(medium) {
    width: 60%;
  }
}

.product-hero__popup__picture {
  @include get-all-space;
  @include object-fit(contain, center center);

  width: 100%;
  height: 100%;
}

.product-hero__popup__content {
  // margin-top: $spacing * 5;
  margin-right: $spacing * 5;
}

.product-hero__popup__content__title {
  @extend %ff-av-next-cond;
  @extend %text-uppercase;

  color: $color-primary;
  font-size: 3.6rem;
  line-height: 1.4em;

  @include mq($until: medium) {
    margin-top: $spacing * 2;
  }

  strong {
    @extend %fw-bold;

    display: block;
    font-size: 1.25em;
  }
}

.product-hero__popup__content__text {
  @extend %ff-open-sans;

  color: $color-primary;
  margin-top: $spacing * 2;
  font-size: 1.5rem;
  line-height: 1.3em;
}

.product-hero__popup-trigger__label {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: absolute;
  top: 0;
  color: $color-primary;
  transform: translate(-10px, -110%);

  @include mq(medium) {
    margin-right: 10px;
  }
}

.product-hero__display__devices {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.product-hero__display__device {
  position: relative;
  width: 5rem;
  height: auto;

  & + & {
    margin-left: 1rem;
  }
}

.product-hero__display__device__logo {
  // @include image-fit(contain);
}