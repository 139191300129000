.hero-simple {
  background-color: $careers-gray-2;
  padding-top: 2.4rem;

  @include mq(large) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.hero-simple__infos {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hero-simple__label {
  color: $cello;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.6rem;
}

.hero-simple__title {
  color: $picton-blue;
  font-size: 4.8rem;
  margin-top: 1rem;
  margin-bottom: 0;

  @include mq(large) {
    margin-top: 1.6rem;
    font-size: 9.6rem;
  }
}

.hero-simple__picture {
  object-fit: cover;
  overflow: hidden;
  object-position: center;

  @include mq($until: large) {
    width: calc(100% - 4rem);
    margin-left: 2rem;
    margin-top: 2.4rem;
    aspect-ratio: 1;
    border-radius: 1.2rem;
  }

  @include mq(large) {
    width: calc(100% - 12rem);
    aspect-ratio: 16 / 7;
    margin-left: 6rem;
    margin-top: 9rem;
    border-radius: 1.6rem;
  }
}

.hero-simple__ctas {
  display: flex;
  flex-direction: column;

  @include mq(large) {
    gap: 5rem;
    margin-top: 9rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.hero-simple__ctas-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  gap: 1.5rem;

  &::before {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: $cello;

    @include mq(large) {
      right: -2.5rem;
      width: 1px;
      height: 100%;
    }
  }

  &:last-of-type {
    &::before {
      display: none;
    }
  }

  @include mq(large) {
    flex-direction: column;
    padding: 3rem;
  }
}

.ctas-item__picture {
  width: 8rem;
  aspect-ratio: 1;
  object-position: left;
  object-fit: cover;

  @include mq(large) {
    margin-bottom: 1.5rem;
  }
}

.ctas-item__headline,
.ctas-item__text {
  color: $cello;
}

.ctas-item__headline {
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.ctas-item__text {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 0;

  @include mq(large) {
    font-size: 2.4rem;
  }
}
