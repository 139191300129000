.product-specifications {
  margin-top: $spacing;
  margin-bottom: $spacing * 7.5;

  @include mq(large) {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 15;
  }
}

.product-specifications-inner {
  position: relative;
}

.product-specifications__arch {
  @extend %text-nodecoration;

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 200px;
  padding: $spacing / 2 $spacing * .75;
  background-color: $color-primary;
  transform: translateY(calc(-100% - 40px));
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  &:hover {
    background-color: $color-secondary;
  }
}

.product-specifications__arch__icon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: $spacing * .75;
  fill: $white;
}

.product-specifications__arch__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  flex-grow: 1;
  margin-right: $spacing * .75;
  color: $white;
  font-size: 1.4rem;
  line-height: 1.3em;
}

.product-specifications__arch__arrow {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-right: $spacing / 4;
  fill: $color-secondary;
  transform: rotate(-90deg);
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-specifications__arch:hover & {
    fill: $white;
  }
}

.product-specifications__legend {
  @include owl;

  margin-top: $spacing;
  color: #979797;
  font-size: 1.3rem;
  line-height: 1.6em;
}
