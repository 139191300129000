// Container
.ctas-careers {
  padding-bottom: $spacing * 3;
  background-color: $careers-gray-2;

  @include mq(large) {
    padding-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    padding-bottom: $spacing * 6;
  }

  @include mq(xxlarge) {
    padding-bottom: $spacing * 8;
  }
}

.ctas-careers-inner {
  @include mq(large) {
    display: flex;
    justify-content: space-between;
  }
}

// Card
.cta-careers {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 24.6rem;
  background-color: $picton-blue;
  border-radius: 15px;

  &:before {
    @include get-all-space();

    content: '';
    z-index: 1;
    background: linear-gradient(
      86.2deg,
      rgba(31, 50, 91, 0.6) 35.84%,
      rgba(32, 51, 92, 0) 88.98%
    );
  }

  @include mq($until: large) {
    margin-bottom: 2.5rem;
  }

  @include mq(large) {
    width: calc(50% - 2.5rem);
    height: 32.6rem;
  }

  @include mq(xlarge) {
    height: 35.6rem;
  }
}

.cta-careers__picture {
  @include get-all-space();
  @include object-fit(cover);

  width: 100%;
  height: 100%;
  // mix-blend-mode: multiply;

  transition: transform 0.2s;

  .cta-careers:hover &,
  .cta-careers:focus & {
    transform: scale(1.1);
  }
}

.cta-careers__content {
  position: relative;
  z-index: 2;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  @include mq(large) {
    padding: 5rem;
  }
}

.cta-careers__headline {
  margin-bottom: 0.8rem;
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-transform: uppercase;

  @include mq(large) {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.cta-careers__title {
  margin-top: 0;
  margin-bottom: 1.6rem;
  color: white;
  font-size: 2.4rem;
  line-height: 2.8rem;

  @include mq(large) {
    margin-bottom: $spacing * 2;
    font-size: 4.8rem;
    line-height: 5.6rem;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 4;
  }

  strong {
    color: $picton-blue;
  }
}

.cta-careers__cta {
  display: inline-flex;
  margin-top: auto;
  padding: 1.2rem 2.4rem;
  color: $cello;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: white;
  border-radius: 4px;
  transition: background-color 0.2s;

  .cta-careers:hover &,
  .cta-careers:focus & {
    background-color: $picton-blue;
  }
}

.cta-careers__link {
  @include get-all-space();
  z-index: 2;
}
