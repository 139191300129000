.product-flatscan {
  color: $white;
  background: linear-gradient(180deg, rgba($white, 1) 0%, rgba($white, 1) 50%, rgba($alabaster2, 1) 50%);
}

.product-flatscan-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #1F325B;

  @include mq($until: 'large') {
    margin: 0 !important;
  }

  @include mq('large') {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.product-flatscan__picture-outer {
  position: relative;

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotate(180deg);
  }

  @include mq(large) {
    width: 50%;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (3 / 4) * 100%;

    @include mq(medium) {
      padding-top: (9 / 16) * 100%;
    }
  }
}

.product-flatscan__picture {
  @include fit(cover);

  user-select: none;
  pointer-events: none;
}

.product-flatscan__content {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $spacing * 3;
}

.product-flatscan__content__logo {
  width: 150px;
  height: 35px;
  user-select: none;
  pointer-events: none;

  @include mq(medium) {
    width: 300px;
    height: auto;
  }
}

.product-flatscan__content__text {
  @extend %ff-av-next-cond;
  margin-top: $spacing;
  font-size: 2.2rem;
  line-height: 1.2em;

  @include mq($until: medium) {
    max-width: 240px;
  }
}

.product-flatscan__content__btn {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
  margin-top: $spacing * 2;
  padding: $spacing * .75;
  background-color: $color-secondary;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  &:hover {
    background-color: $white;
  }
}

.product-flatscan__content__btn__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  flex-grow: 1;
  margin-right: $spacing * .75;
  color: $white;
  font-size: 1.4rem;
  line-height: 1.3em;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-flatscan__content__btn:hover & {
    color: $color-primary;
  }
}

.product-flatscan__content__btn__arrow {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  fill: $white;
  transform: rotate(-90deg);
  transition-property: fill;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-flatscan__content__btn:hover & {
    fill: $color-primary;
  }
}
