.specifications-table,
[class*="specifications-table--"] {
  width: 100%;
  color: $color-primary;
  border-collapse: separate;

  tbody {
    display: block;
  }

  tr {
    display: flex;
    width: 100%;
    background-color: $alabaster2;
    border-bottom: 1px solid #d8d8d8;

    &:nth-child(even) {
      background-color: $white;
    }
  }

  th,
  td {
    padding: $spacing * .75;
    vertical-align: top;

    p {
      margin: 0;
    }
  }

  th {
    width: 40%;
    text-align: left;

    &:first-child {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  td {
    flex-basis: calc(75% / 2);
    // flex-grow: 1;
    // flex-shrink: 0;
  }

  @include mq(large) {
    th {
      width: 25%;
    }
  }
}

td {
  .is-common & {
    flex-basis: 75%;
  }
  .is-triple & {
    flex-basis: 25%;
  }
}

// Mobile display (2 tables)

[class*="specifications-table--"][class*="--first"],
[class*="specifications-table--"][class*="--second"] {
  width: 100%;
  margin: 0;
  border-spacing: 0;

  tr {
    display: flex;
    flex-direction: column;
  }

  th {
    width: 100%;
    padding-bottom: .5em;
  }

  td {
    width: auto;
    padding-top: 0;
  }
}

[class*="specifications-table--"][class*="--second"] {
  border-top: 1px solid $color-primary;
  padding-top: $cell-spacing / 2;
}
