.megamenu-ctas {
  border-top: 1px solid $alto;
  padding: 3rem 2rem;
}

.megamenu-cta {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 17rem;
  padding: 2rem;
  font-size: 2.4rem;
  line-height: 1;
  color: $white;
  background: rgba($cello, 0.5);
  text-decoration: none;
  overflow: hidden;

  & + & {
    margin-top: 1rem;
  }

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: linear-gradient(45deg, $color-primary 35%, transparent);
    background-size: 120%;
    background-position-x: 30%;
    opacity: 0.5;
    mix-blend-mode: multiply;
    transition: 0.2s;
  }

  &:hover,
  &:focus {
    color: $white;

    &::before {
      opacity: 0.3;
      // background-position-x: 90%;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
  }

  @each $segment, $color in $segment-colors {
    &[class*='is-#{$segment}'] {
      &::after {
        background-color: $color;
      }
    }
  }
}

.megamenu-cta__label {
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 70%;
  transition: transform 0.2s;

  .megamenu-cta:hover &,
  .megamenu-cta:focus & {
    transform: translateX(1rem);
  }
}

.megamenu-cta__picture {
  @include fit(cover);
  transition: transform 0.2s;

  .megamenu-cta:hover &,
  .megamenu-cta:focus & {
    transform: scale(1.1);
  }
}

@include mq('large') {
  $megamenu-p: 4rem;

  .megamenu-ctas {
    display: flex;
    flex: 1;
    padding: $megamenu-p;
    background: $gallery;
    border-top: 0;
    border-left: 1px solid $alto;
  }

  .megamenu-cta {
    flex: 1;
    width: 30rem;
    max-width: calc(50% - 0.5rem);
    height: 17.5rem;

    & + & {
      margin: 0 0 0 1rem;
    }
  }
}

@include mq('xxlarge') {
  .megamenu-ctas {
    flex-direction: column;
    padding: 4rem 6rem;
  }

  .megamenu-cta {
    flex: none;
    max-width: none;

    & + & {
      margin: 1rem 0 0 0;
    }

    .ie11 & {
      width: 100%;
    }
  }
}
