.find-us {
  padding: 3rem 0;
  background-color: $color-gray-light;
}

.find-us__controller {
  height: 3rem;
  margin: 1rem auto 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.find-us__controller__control {
  margin: 0 .5rem;
}

.find-us__cards {
  @extend %list-nostyle;
  max-width: 1680px;
  margin: 0 auto;
}

.find-us__cards__card {
  width: 100%;

  @include mq('large') {
    display: flex;
    justify-content: flex-end;
  }

}

.find-us__cards__card-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0 2rem;
  @include mq('large') {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
}

.find-us__cards__card__picture-outer {
  position: relative;
  height: 220px;
  width: 100%;
  margin-bottom: -2rem;

  @include mq('large') {
    position: absolute;
    top: 0;
    left: 0;
    width: 65%;
    height: 100%;
  }

}

.find-us__cards__card__picture {
  @include fit(cover);
  width: 100%;
  height: 100%;
}

.find-us__cards__card__content {
  position: relative;
  padding: 0 3rem 3rem;
  margin: 0 3rem 3rem;
  background-color: $white;

  @include mq('large') {
    width: 540px;
    padding: 5rem;
    margin: 9rem 3rem 9rem 0;
  }

}

.find-us__cards__card__content__title {

  position: relative;
  margin: 0;

  @include mq($until: 'large') {
    top: -2rem;
  }

  svg {
    width: 5rem;
    height: 5rem;
    fill: $white;
    background-color: $color-primary;
    margin: 0 auto;

    @include mq('large') {
      position: absolute;
      top: 0;
      left: -7.5rem;
    }

  }

}

.find-us__cards__card__content__description > *:first-child {
  margin-top: 0;
}
