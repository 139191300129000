.js [data-switcher="trigger"] {
  cursor: pointer;
}

.js [data-switcher="target"] {
  display: none;

  &.is-active {
    display: block;
  }
}
