.anchors-endusers {
  .anchors__item {
    margin: 0 !important;
  }

  .menu-container {
    position: relative;
    height: inherit;
    min-height: 1.8rem;
  }
}

.anchors-endusers {
  .anchors-inner {
    gap: 2rem;

    @include mq(xlarge) {
      gap: 7rem;
    }
  }
}

.anchors__back {
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: $cello;
}
