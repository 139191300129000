.product-expert {
  padding: $spacing * 3 0;
  background-color: $color-primary;
  color: $white;
}

.product-expert-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq(small-xl) {
    flex-direction: row;
  }
}

.product-expert__picture-outer {
  position: relative;
  width: 130px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.product-expert__picture {
  @include fit(cover);
}

.product-expert__content {
  @include mq($until: small-xl) {
    margin-top: $spacing * 1.5;
  }

  @include mq(small-xl) {
    margin-left: $spacing * 2.5;
  }
}

.product-expert__content__title {
  @extend %text-uppercase;
  @extend %ff-av-next-cond;

  font-size: 2.4rem;
  line-height: 1.3em;

  strong {
    display: block;
    font-size: 1.5em;
    line-height: 1.3em;
  }
}

.product-expert__content__link {
  margin-top: $spacing * .75;
}
