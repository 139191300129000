#captcha {
  margin-bottom: 2rem;
}

.form {
  color: $color-primary;
}

.form-group {
  margin: $spacing * 2 0;
}

.form-item,
[class*="form-item--"] {
  position: relative;
  flex-grow: 1;
  margin-bottom: $spacing;
  padding-bottom: 2em;

  &[class*='--checkbox'] {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-basis: 100%;
    margin-bottom: $spacing * .75;
    padding-bottom: 0;
    align-items: center;
  }

  &[class*='--nopadding'] {
    padding-bottom: 0;
    margin-bottom: 0;
  }


  &.is-hidden {
    display: none;
  }
}

.form-label {
  display: block;
  margin-bottom: .5em;

  [class*='form-item--'][class*='--checkbox'] & {
    position: relative;
    top: .2em;
    margin-bottom: 0;
    margin-left: $spacing / 2;
    line-height: 1em;
    order: 2;
  }
}

.form-required {
  vertical-align: top;
}

.form-input {
  display: block;
  width: 100%;
  padding-bottom: .5rem;
  border: 0;
  border-bottom: 1px solid $color-primary;

  &[type='checkbox'] {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    order: 1;
  }
}

.form-radio {
  + label {
    margin-right: 1.2em;
    margin-left: .4em;
  }
}

.form-radio-outer {
  white-space: nowrap;

  @include mq('small-l') {
    display: inline-block;
  }
}

.form-textarea {
  width: 100%;
  padding: 1rem;
}

.form-select,
[class*="form-select--"] {

  select {
    width: 100%;
    padding-bottom: .5rem;
    border-bottom: 1px solid $color-primary;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    cursor: pointer;
  }

  .icon {
    @include center-y;
    right: 0;
    width: 16px;
    height: 16px;
    z-index: -1;
  }
}

.form-validation,
.form-error,
.form-success {
  position: absolute;
  height: fit-content;
  bottom: 0;
  left: 0;
  display: block;
  margin-top: .5em;
  color: $color-error;
  opacity: 0;

  [class*='form-item--'][class*='--checkbox'] & {
    position: relative;
    order: 3;
    margin-left: $spacing / 2;
    margin-top: 0;
  }

  &.is-visible {
    opacity: 1;
  }
}

.form-validation.is-error,
.form-error {
  color: $color-error;
}

.form-validation.is-success,
.form-success {
  color: $color-success;
}

.form .ss-dropdown {
  max-height: 30rem;
  overflow-y: scroll;
}

.form-submit[disabled] {
  background-color: $bombay;
}

