.toggle-videos,
[class*="toggle-videos--"] {
  padding: $cell-spacing;
  background-color: $color-gray-lighter;
  color: $color-primary;

  @include mq('large') {
    padding: $cell-spacing * 2;
  }
}

.toggle-videos__wrapper {
  position: relative;
  height: 0;
  margin-bottom: $spacing;
  padding-top: 56.25%;

  @include mq('medium') {
    width: 640px;
    height: 360px;
    padding-top: 0;
  }

  @include mq('large') {
    margin-bottom: $spacing * 2;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}
