.icon,
[class*="icon--"] {
  width: 20px;
  height: 20px;
  fill: inherit;
}

[class*="icon--"][class*="--inline"] {
  margin-top: -3px;
  vertical-align: middle;
}

[class*="icon--"][class*="--small"] {
  width: 12px;
  height: 12px;
}

[class*="icon--"][class*="--flip"] {
  transform: scaleX(-1);
}
