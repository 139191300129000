.article-picture {
  margin-top: $spacing * 2.5;
  color: $cello;

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  @include mq(ml) {
    max-width: 80rem;
    margin: $spacing * 2.5 auto 0;
  }
}

.article-picture__caption {
  margin-top: $spacing;
  font-size: 1.5rem;
  line-height: 1.6;
}

.article-picture__caption__title {
  @extend %fw-bold;
  @extend %ff-av-next-cond;
  @extend %text-uppercase;

  margin-bottom: $spacing * 0.03;
  font-size: 1.8rem;
}