.block-reveal {
  @extend %text-center;

  position: relative;
  margin-top: $spacing * 5;
  margin-bottom: $spacing * 5;

  h2.block-reveal__title {
    @extend %ff-default;
    @extend %text-uppercase;
    @extend %color-primary;

    font-size: 1.3rem;
    letter-spacing: 1px;
  }

  @include mq('medium') {
    padding: $spacing * 5;
  }

  @include mq('large') {
    padding: 18rem 13rem;
    margin: $spacing * 6 auto $spacing * 8;
  }
}

.block-reveal__text {
  @extend %color-secondary;

  font-size: 1.6rem;
  line-height: 1.3;
  letter-spacing: 1px;

  @include mq('medium') {
    font-size: 2.6rem;
    font-weight: 200;
  }
  @include mq('large') {
    font-size: 3.6rem;
  }
}

.block-reveal__picture {
  margin: 0 calc(#{$spacing} * -1) $spacing * 2;

  @include mq(medium) {
    @include get-all-space;

    z-index: -1;
    margin: 0;
    background: $gallery;

    img {
      @include image-fit();

      width: 100%;
      height: 100%;
    }
  }
}
