[class*="header-border--"],
.header-border {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 50%;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 60%, rgba($black, .6) 100%);
    z-index: 1;
  }

  @each $segment, $color in $segment-colors {
    &[class*=--#{$segment}] .header-border-inner::before {
      background-color: $color;
    }

    &[class*=--#{$segment}] .header-border__icon-outer {
      background-color: $color;
    }

    // &[class*=--#{$segment}] .header-border__title {
    //   color: $color;
    // }
  }

  @include mq(large) {
    height: 56rem;
    padding-top: 0;

    .js .single-application & {
      opacity: 0;
    }
  }
}

.header-border__background {
  @include get-all-space();
  @include object-fit(cover);
  width: 100%;
  height: 100%;
}

.header-border-inner {
  position: absolute;
  bottom: 4rem;
  left: 0;
  width: fit-content;
  // height: 6rem; // exact height needed for SVG ratio/rendering
  padding: 2rem 1em 1rem 4rem;
  background-color: rgba($white, .2);
  color: $white;
  z-index: 2;
  transform: none;
  @include mq('medium') {
    height: 7rem; // exact height needed for SVG ratio/rendering
    padding: 2rem 1em 1rem 7rem;
  }

  @include mq('large') {
    height: 8rem; // exact height needed for SVG ratio/rendering
    padding: 2rem 1em 1rem 10rem
  }

  [class*='header-border--'][class*='no-height'] & {
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1rem;
    height: 100%;
    background-color: $color-primary;

    @include mq('medium') {
      width: 1.5rem;
    }
    @include mq('large') {
      width: 2rem;
    }

  }

  [class*="header-border--"][class*="--icon"] & {
    padding: 2rem 1rem 1rem 10rem;

    @include mq('medium') {
      padding-left: 15rem;
    }

    @include mq('large') {
      padding-left: 20rem;
    }

    &::before {
      display: none;
    }
  }

}

.header-border__icon-outer {
  display: none;

  [class*="header-border--"][class*="--icon"] & {
    position: absolute;
    top: 0;
    left: 2rem;
    display: block;
    width: 6rem;
    height: 100%;
    background-color: $color-primary;

    @include mq('medium') {
      left: 6rem;
      width: 7rem;
    }

    @include mq('large') {
      left: 10rem;
      width: 8rem;
    }

  }

  @each $segment, $color in $segment-colors {
    [class*="header-border--"][class*="--#{$segment}"] & {
      background-color: $color;
    }
  }

  .icon {
    @include center-xy;
    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.header-border__title {
  margin: 0;
  color: $white;
}

[class*="header-border--"][class*="--icon"] {
  .header-border__title {
    color: $white;
  }
}
