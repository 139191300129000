.styleguide--max {
  padding: 3rem;
}

.styleguide--max__h2 {
  @extend .h2;
  font-size: 2rem;

  &::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 1em;
    margin-right: 5px;
    background-color: $color-primary;
    vertical-align: middle;
  }
}

.styleguide--max__links {
  max-width: 30rem;
  margin: 0 auto;
}

.awesomplete {
  display: block;
}

.iframe-wrapper {
  position: relative;
  padding-top: (9 / 16) * 100%;
  height: 0;
}

.iframe-wrapper iframe {
  @include get-all-space;
  width: 100%;
  height: 100%;
}

[data-wysiwyg-content] iframe {
  html.js & {
    opacity: 0;
  }
}
