/*
** Specific global styles for launchpage
** Typography, spacing etc
*/

$color-lp-bg: $alabaster;
$ease-lp: cubic-bezier(0, 0.91, 0, 1);
$gradient-overlay-lp: linear-gradient(
  104.72deg,
  rgba(107, 106, 120, 0.62) 31.89%,
  rgba(238, 238, 238, 0.3) 80.93%
);

%lp-text-spacing {
  letter-spacing: 1px;
}

%lp-heading {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  // @extend %color-primary;
  @extend %text-uppercase;
  @extend %lp-text-spacing;

  line-height: 1;
}

.launchpage-section {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;

  @include mq(large) {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
}

.page-template-launchpage,
.page-template-en16005 {
  @extend %ff-open-sans;

  font-size: 1.3rem;
  line-height: 2.4rem;
  color: $nevada;

  h1,
  .h1 {
    @extend %lp-heading;

    font-size: 7rem;
  }

  h2,
  .h2,
  [class*='h2--'] {
    @extend %lp-heading;

    font-size: 3.6rem;
  }

  .h2--underline {
    @extend %lp-heading;

    position: relative;
    margin-bottom: 2.2rem;

    &::after {
      content: '';
      display: block;
      margin-top: 2.4rem;
      width: 3.5rem;
      height: 4px;
      background-color: $color-secondary;
    }
  }

  h3,
  .h3 {
    @extend %lp-heading;

    font-size: 3rem;
  }

  h4,
  .h4 {
    @extend %lp-heading;

    font-size: 1.8rem;
  }

  h5,
  .h5 {
    @extend %lp-heading;

    font-size: 1.6rem;
    line-height: 2.2rem;
    text-transform: none;
    letter-spacing: 0;
  }

  .subtitle {
    @extend %lp-heading;
    @extend %color-secondary;

    font-size: 1.7rem;
    line-height: 1.375;
  }

  .mini-subtitle {
    @extend %lp-heading;

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .lead {
    font-size: 1.4rem;
  }
}

.page-template-launchpage {
  .footer,
  .socials {
    margin-top: 0;
  }
}

@include mq(large) {
  .page-template-launchpage,
  .page-template-en16005 {
    font-size: 1.5rem;

    h1,
    .h1 {
      font-size: 18rem;
    }

    h2,
    .h2 {
      font-size: 5.6rem;
    }

    h3,
    .h3,
    .h2---underline {
      font-size: 4.6rem;
    }

    h4,
    .h4 {
      font-size: 3rem;
    }

    h5,
    .h5 {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 2.4rem;
    }

    .lead {
      font-size: 1.8rem;
      line-height: 3rem;
    }
  }
}
