.article-gallery {
  margin-top: $spacing * 2.5;

  @include mq(large) {
    margin: $spacing * 2.5 auto 0;
    max-width: 100rem;
  }
}

.article-gallery__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $spacing;

  @include mq(large) {
    margin: 0 auto;
    max-width: 80rem;
  }
}

.article-gallery__list__item {
  width: 100%;

  figure {
    margin: 0;
  }

  img {
    display: block;
    // aspect-ratio: 16/9; // crop images
  }


  @include mq(medium) {
    width: calc(calc(100% - #{$spacing}) * 0.5);
  }


  @include mq(large) {
    width: calc(calc(100% - #{$spacing * 2}) * 0.33);
  }
}

.article-gallery__list__item__caption {
  margin-top: $spacing * 0.5;
  color: $cello;
  font-size: 1.3rem;
  line-height: 1.6;
}

.article-gallery__list__item__caption__title {
  @extend %fw-bold;
  @extend %ff-av-next-cond;
  @extend %text-uppercase;

  font-size: 1.5rem;
}