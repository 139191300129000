.product-case {
  position: relative;
  margin: $spacing * 6 auto 0;

  // .flickity-slider {
  //   left: -35% !important;
  // }

  .flickity-prev-next-button {
    top: initial;
    bottom: -8rem;
    left: 5rem;
    height: 4rem;
    width: 8rem;
    border: 1px solid $bombay;

    &.previous {
    }

    &.next {
      left: 4.9rem;
      transform: translate(100%, -50%);
    }
  }
}

.product-case-inner {
  padding-bottom: $spacing * 4;
}

.product-case__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  color: $color-primary;
  font-size: 2.4rem;
  line-height: 1.2em;
}

.product-case__duo {
  margin-top: $spacing * 4;
}

.product-case__list {
  margin-top: $spacing * 2;
}