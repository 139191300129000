.product-videos {
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 4;

  @include mq(large) {
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 8;
  }

  // .flickity-slider {
  //   left: -35% !important;
  // }

  .flickity-prev-next-button {
    height: 5rem;
    width: 5rem;

    &.previous {
      left: -5rem;
    }

    &.next {
      right: -5rem;
    }
  }
}

.product-videos__list {
  min-height: 270px;
  // @include mq(small-xl) {
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: flex-start;
  // }

  // .flickity-viewport {
  //   width: 100%;
  // }
}

.product-videos__item {
  width: 100%;
  cursor: pointer;

  @include mq($until: small-xl) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(small-xl, xlarge) {
    width: calc(50% - #{$spacing});
    margin-right: $spacing * 2;
  }

  @include mq(xlarge) {
    width: calc(33.33% - #{$spacing * 2});
    margin-right: $spacing * 3;
  }
}

.product-videos__item__picture-outer {
  position: relative;
  background-color: $black;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (21 / 36 * 100%);
  }
}

.product-videos__item__picture {
  @include fit;

  background-color: $alto;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-videos__item:hover & {
    opacity: .8;
  }
}

.product-videos__item__icon {
  @include center-xy;

  width: 64px;
  height: 45px;
}

.product-videos__item__title {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-av-next-cond;

  margin-top: $spacing;
  color: $color-primary;
  font-size: 1.6rem;
  line-height: 1.2em;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  .product-videos__item:hover & {
    color: $color-secondary;
  }
}

.product-videos__item__duration {
  @extend %ff-open-sans;

  margin-top: $spacing / 4;
  font-size: 1.3rem;
  line-height: 1.2em;
}
