[class*="header-intro--"],
.header-intro {
  //
}

.header-intro__background {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;

  img {
    @include fit(cover);
  }

  @include mq(large) {
    height: 48rem;
    padding-bottom: 0;
  }
}

.header-intro__content {
  position: relative;
  max-width: 54rem;
  margin: 0 auto;
  padding: 5rem 2rem;
  background-color: $white;


  @include mq('medium') {
    margin: -9rem auto 0;
    padding: 5rem 10rem;
  }
}

.header-intro__content__title {
  margin: 0;
}

.header-intro__content__cta {
  text-align: center;

  [class*="link--"] {
    display: inline-block;
  }
}
