.block-custom {
  position: relative;
  padding: $spacing * 2 $spacing;
  color: $white;
  margin: 0;
}

.block-custom__background {
  @include get-all-space;

  width: 100%;
  height: 100%;
}

.block-custom-inner {
  position: relative;
  z-index: 1;
}

.block-custom__title {
  color: $white;
  small {
    display: block;
  }
}
