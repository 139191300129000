.btn,
[class*="btn--"] {
  border: 0;
  padding: 0.75em 2.5em;
  background-color: $color-primary;
  color: $white;
  text-decoration: none;
  letter-spacing: 1px;
}

[class*="btn--"][class*="--centered"] {
  display: block;
  margin: 0 auto;
}

[class*="btn--"][class*="--small"] {
  padding: 1px 0.5em 0;
  vertical-align: top;
}

[class*="btn--"][class*="--light"] {
  color: $color-primary;
  background-color: $white;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: $picton-blue;
  }
}
