.product-features {
  margin-top: $spacing * 4;
  margin-bottom: $spacing * 3;
}

.product-features__title {
  font-size: 2.4rem;
  line-height: 1.2em;
}

.product-features__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
}

.product-features__item {
  padding-bottom: $spacing * 2;
  border-bottom: 1px solid $alto;

  @include mq($until: medium) {
    width: 100%;

    & + & {
      margin-top: $spacing * 2.5;
    }
  }

  @include mq(medium) {
    width: calc(50% - #{$spacing});

    &:nth-child(odd) {
      margin-right: $spacing * 2;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 2.5;
    }
  }

  @include mq(large) {
    width: calc(50% - #{$spacing * 2});

    &:nth-child(odd) {
      margin-right: $spacing * 4;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 3;
    }
  }

  @include mq(xlarge) {
    width: calc(50% - #{$spacing * 3});

    &:nth-child(odd) {
      margin-right: $spacing * 6;
    }

    &:nth-child(n+3) {
      margin-top: $spacing * 3.5;
    }
  }
}

.product-features__item__picture-outer {
  position: relative;
  background-color: $alto;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 2) * 100%;
  }
}

.product-features__item__picture {
  @include fit(cover);
}

.product-features__item__title {
  @extend %ff-open-sans;

  margin-top: $spacing;
  color: $color-primary;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.2em;
  text-transform: none;
}

.product-features__item__description {
  @include owl;

  margin-top: $spacing;
}
