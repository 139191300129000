$bp: medium;

.comparator-request {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
}

.comparator-request__bg {
  @include get-all-space;

  z-index: 1;
  background-color: $cello;
  opacity: 0.3;
}

.comparator-request__content {
  @include center-xy;
  @include scrollbars($cello);

  z-index: 2;
  overflow: auto;
  width: 90%;
  max-width: 48rem;
  height: auto;
  max-height: 90%;
  padding: 4rem;
  background-color: $white;
  border-radius: 10px;

  @include mq($bp) {
    padding: 6rem;
  }
}

.comparator-request__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 3rem;
    height: 3rem;
    fill: $cello;
  }
}

.comparator__content__header {
  margin-bottom: 4rem;
  text-align: center;
}

.comparator__content__header__title {
  display: block;
  margin-top: 2rem;
  color: $cello;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: $av-next-cond;
  text-transform: uppercase;
  letter-spacing: 0.2px;

  @include mq($bp) {
    margin-top: 4rem;
    font-size: 2.8rem;
  }
}

.comparator-request__form {
  margin-bottom: 0;
}

.comparator-request__form__label {
  margin-bottom: 1rem;
  color: $cello;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
}

.comparator-request__form__input {
  position: relative;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid $alto;
  padding-left: 3rem;

  input {
    width: 100%;
    height: 4rem;
    border: 0;
    color: $cello;
    font-size: 1.6rem;
  }

  svg {
    @include center-y;

    left: 0;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.comparator-request__form__checkbox {
  position: relative;
  display: block;
  margin-bottom: 4rem;
  padding-left: 4rem;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  input:checked + label::after {
    opacity: 1;
  }
}

.comparator-request__form__checkbox__label {
  &::before,
  &::after {
    @include center-y;

    content: '';
    left: 0;
  }

  &::before {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid $alto;
  }

  &::after {
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background: $cello;
    opacity: 0;
    border-radius: 3px;
    transition: opacity 0.15s;
  }
}

.comparator-request__form__submit {
  display: flex !important;
  margin: 0 auto;
  border: 1px solid $white !important;

  &:hover {
    border: 1px solid $cello;
  }
}

.comparator-request__form__feedback {
  margin: 2rem 0 0;
  text-align: center;
  font-size: 1.6rem;
  color: $cello;

  &.error {
    color: $alizarin-crimson;
  }
}
