.applications-grid-outer {
  @include mq("large") {
    display: flex;
    flex-direction: column;
  }
}

.applications-grid {
  @extend %list-nostyle;
  // display: flex;
  // flex-wrap: wrap;
  // flex-grow: 1;
  padding: 1rem 1.5rem;
}

// .applications-grid__application-outer {
//   width: 50%;

//   @include mq("large") {
//     width: 33.33%;
//   }
// }

.applications-grid__application {
  @extend %text-nodecoration;
  // position: relative;
  // display: block;
  // overflow: hidden;
  // margin: 1rem;
  // border: 1px solid $color-gray-light;
  // padding: 1rem;
  display: flex;
  align-items: center;
  color: $cello;

  @include mq("large") {
    margin: 0;
    border-color: $transparent;

    // &:hover {
    //   border-color: $color-gray;
    // }
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: transparent;
  // }
}

@each $segment, $color in $segment-colors {
  .link--#{$segment} {
    + .submenu--application {
      .applications-grid__application:hover {
        // border-color: $color;

        .applications-grid__application__icon {
          background-color: $color;
          fill: $white;
        }
      }
    }
  }
}

.applications-grid__application__icon {
  // display: block;
  width: 3.6rem;
  height: 3.6rem;
  margin-right: 1.3rem;
  padding: 0.5rem;
  fill: $color-gray-dark;
  border-radius: 50%;
  transition: 0.2s;
}

// .applications-grid__application__label {
// display: block;
// font-size: 1rem;
// line-height: 1em;
// color: $color-gray-dark;
// max-width: 10rem;
// margin: 0 auto;
// }

.applications-grid__see-all {
  display: block;
  padding: 1rem 0;
  background-color: $white;
}
