// Import fonts

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./../fonts/open-sans-condensed-bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./../fonts/open-sans-condensed-regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./../fonts/open-sans-condensed-semibold.ttf');
  font-style: light;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./../fonts/open-sans-condensed-medium.ttf');
  font-style: lighter;
  font-weight: 200;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/open-sans-bold.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/open-sans-bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/open-sans-bold.woff2') format('woff2'),
    /* Modern Browsers */ url('./../fonts/open-sans-bold.woff') format('woff'),
    /* Modern Browsers */ url('./../fonts/open-sans-bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./../fonts/open-sans-bold.svg')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/open-sans-extrabold.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/open-sans-extrabold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/open-sans-extrabold.woff2') format('woff2'),
    /* Modern Browsers */ url('./../fonts/open-sans-extrabold.woff')
      format('woff'),
    /* Modern Browsers */ url('./../fonts/open-sans-extrabold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./../fonts/open-sans-extrabold.svg')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/open-sans-regular.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/open-sans-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/open-sans-regular.woff2') format('woff2'),
    /* Modern Browsers */ url('./../fonts/open-sans-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./../fonts/open-sans-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./../fonts/open-sans-regular.svg')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/open-sans-semibold.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/open-sans-semibold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/open-sans-semibold.woff2') format('woff2'),
    /* Modern Browsers */ url('./../fonts/open-sans-semibold.woff')
      format('woff'),
    /* Modern Browsers */ url('./../fonts/open-sans-semibold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./../fonts/open-sans-semibold.svg')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/open-sans-light.ttf');
  font-style: light;
  font-weight: 200;
}

html {
  font-size: calc(1em * 0.625);
}

body {
  font: 1.3rem / 1.75 $ff-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Global Typography Styles
h1,
.h1,
h2,
.h2,
h3,
.h3,
[class*='h3--'][class*='--subtitle'],
h4,
.h4,
h5,
.h5 {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  @extend %text-uppercase;
  line-height: 1em;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.4em;
  text-transform: none;
}

h1,
.h1 {
  font-size: 4rem;
  @include mq('medium') {
    font-size: 5rem;
  }
  @include mq('large') {
    font-size: 6rem;
  }
}

h2,
.h2 {
  font-size: 2.8rem;
  @include mq('medium') {
    font-size: 3.8rem;
  }
  @include mq('large') {
    font-size: 4.6rem;
  }
}

h3,
.h3 {
  font-size: 1.6rem;
  @include mq('medium') {
    font-size: 2.6rem;
  }
  @include mq('large') {
    font-size: 3.6rem;
  }
}

[class*='h3--'][class*='--subtitle'] {
  @extend %fw-normal;
  font-size: 1.6rem;
}

h4,
.h4 {
  font-size: 2rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.lead {
  font-size: 1.15em; // /13px -> 15px
}

.uppercase-title,
%uppercase-title {
  @extend %ff-default;
  @extend %fw-bold;
  @extend %text-uppercase;

  font-size: 1.3rem;
  color: $picton-blue;
  letter-spacing: 1px;
}

/**
 * Links
 */

[class*='link'] {
  font-size: 1.4rem;
}

.link-menu,
[class*='link-menu--'],
.link-submenu,
[class*='link-submenu--'],
[class*='link-list--'] {
  text-transform: none;
}

.link-secondary,
[class*='link-submenu--'][class*='--icon'],
.link-more,
[class*='link-more--'],
.link-back,
[class*='link--'][class*='--icon'],
[class*='link--'][class*='--underlined'],
.link-top {
  font-size: 1.2rem;
}

[class*='link-list--'][class*='--title'] {
  font-weight: 600;
}

.link-top,
.link-menu,
[class*='link-menu--'],
[class*='link--'][class*='--icon'],
[class*='link--'][class*='--underlined'] {
  @extend %text-uppercase;
}

[class*='link--'][class*='--underlined'] {
  @extend %text-center;
}

/**
 * CTAs
 */

.cta,
[class*='cta--'],
.cta-services,
[class*='cta-services--'],
.cta-call {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %text-nodecoration;

  font-size: 1.4rem;
  line-height: 1.15;
}

[class*='cta--'][class*='--bordered'] {
  font-size: 1.3rem;
}

.cta-services__item__link {
  @extend %text-nodecoration;
}

.cta-call {
  font-weight: normal;
}

.cta-full,
[class*='cta-full--'] {
  @extend %ff-av-next-cond;
  @extend %text-nodecoration;

  font-size: 1.6rem;
  line-height: 1.15;
}

.cta-full__content {
  strong {
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: 1px;
  }
}

.cta-more {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  @extend %text-nodecoration;
  @extend %text-uppercase;

  font-size: 2rem;
  text-align: center;
}

/**
 * Header
 */

.header__baseline {
  @extend %text-uppercase;
  font-weight: 400;
}

.header-small {
  @extend %text-center;
  @extend %fw-normal;
  font-size: 1.4rem;

  strong {
    font-size: 2.5em;
    line-height: 1em;
  }
}

.header-products__display__name__title,
.header-products__display__name__subtitle,
.header-products__display__name__extras-infos {
  @include mq('tiny', 'large') {
    text-align: center;
  }
}

.header-products__display__name__subtitle {
  @extend %text-uppercase;
}

.header-products__display__name__extras-infos__label,
.header-products__display__name__colors__label {
  @extend %text-uppercase;
  @extend %fw-bold;
}

/**
 * Jump to
 */

.jump-to__item__link {
  @extend %text-nodecoration;

  font-size: 1.2rem;
  line-height: 1.2;
}

/**
 * Block-picto
 */

.block-picto {
  text-align: center;
}

.block-picto__title {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  font-size: 1.4rem;
  line-height: 1;
}

/**
 * Products
 */

.product__name {
  @extend %ff-av-next-cond;
  @extend %text-uppercase;
  font-size: 1.6rem;
}

.card-products__description__title {
  font-size: 1.8rem;
  line-height: 1.5;
  text-transform: none;
}

.toggle-downloads__cat__title {
  @extend %fw-bold;
}

.toggle-downloads__cat__item__label {
  @extend %text-nodecoration;
}

/**
 * Applications
 */

.card-application__content__title {
  @extend %fw-bold;
  @extend %text-uppercase;

  small {
    font-weight: normal;
  }

  @include mq('tiny', 'medium') {
    font-size: 2.4rem;
  }
}

.related-applications__title {
  @extend %text-center;
}

/**
 * Schedule
 */

.schedule__intro__title {
  @extend %fw-bold;
  font-size: 1.4rem;
}

.schedule__dates__date__day {
  @extend %fw-semibold;
}

/**
 * Segments-all
 */

.segments-all__title {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  @extend %text-center;
  @extend %text-uppercase;
  font-size: 1.4rem;
}

.segments-all__segments__segment__title {
  line-height: 1.3em;
}

/**
 * Form
 */

input,
.form-input,
.form-textarea,
.form-label,
.form-radio + label {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}

.form-label {
  @extend %fw-semibold;

  letter-spacing: 1px;
}

.form-required {
  font-size: 0.8em;
}

.form-validation,
.form-error {
  font-size: 0.9em;
}

.contact__form__header,
.contact__form__header__title {
  text-transform: uppercase;
}

.contact__form__header__title {
  font-size: 1.4rem;
  line-height: 1.5;
}

.contact__form__section__title {
  width: 100%;
  text-transform: unset;
  font-size: 1.8rem;
  line-height: 1.5;
  color: $cello;
  font-family: $open-sans;
  font-weight: 400;
  margin: 4rem 0 3rem;
}

/**
 * Contact confirmation
 */

.contact-confirmation__title {
  font-size: 3.6rem;
  line-height: 1em;
}

.contact-confirmation__baseline {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4em;
  text-transform: initial;
}

/**
 * btn
 */
.btn,
[class*='btn--'] {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  @extend %text-uppercase;

  font-size: 1.4rem;
  letter-spacing: 1px;
}

/**
 * Breadcrumb
 */
.breadcrumb__item {
  @extend %fw-bold;
}

.breadcrumb__item__link {
  @extend %fw-normal;
  @extend %text-nodecoration;

  font-size: 1.2rem;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/**
 * About us
 */
.block-custom-inner {
}

.block-custom__title {
  @extend %text-center;

  small {
    font-size: 1.8rem;
  }
}

/**
 * Careers
 */
.careers-list__item,
.toggle-list__term {
  @extend %ff-av-next-cond;

  font-size: 1.8rem;
}

/**
 * Downloads
 */
.download-row__title {
  @extend %text-uppercase;
  @extend %ff-av-next-cond;
  font-size: 1.8rem;
  line-height: 1.1em;

  [class*='download-row--'][class*='--child'] & {
    font-size: 1.4rem;
  }
}

.download-row__details {
  @extend %text-uppercase;
  font-size: 1.2rem;
}

/**
 * Results-list
 */

.results-list__header__title {
  line-height: 2em;
}

.results-list__header__title * {
  font-size: 3.6rem;
  @extend %fw-normal;
}

.results-list__header__title strong {
  @extend %fw-bold;
}

.results-list__header__search__label {
  @extend %fw-bold;
  @extend %ff-av-next-cond;
  @extend %text-uppercase;
  font-size: 1.4rem;
}

/**
 * 404
 */

.block-404__error {
  margin-bottom: 0;
}

.block-404__title {
  @extend %fw-bold;
  @extend %ff-av-next-cond;
  @extend %text-uppercase;

  font-size: 2rem;
}

/**
 * Map
 */

.map__card {
  font-size: 1.2rem;
}

.map__card__region__headline,
.map__card__region__title,
.map__card__region__country__infos__label,
.map__card__region__button,
.map__headquarters__button {
  @extend %fw-bold;
}

.map__card__region__title,
.map__card__region__button,
.map__headquarters__button {
  @extend %text-uppercase;
}

.map__card__region__title {
  @extend %ff-av-next-cond;
  font-size: 2rem;
  line-height: 1em;

  @include mq('medium') {
    font-size: 3rem;
  }
}

.map__card__company__headline,
.map__card__company__area {
  line-height: 1em;
}

.map__card__select ~ .style-select .ss-selected-option {
  @extend %text-center;
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  font-size: 1.4rem;
}

/**
 * Find us
 */

.find-us__cards__card__content__title {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  text-align: center;
  font-size: 2.4rem;

  @include mq('large') {
    text-align: left;
    font-size: 3.6rem;
  }
}

/**
 *  Keypoints grid & items
 */

.keypoints-grid__item {
  @extend %ff-av-next-cond;
  @extend %text-uppercase;
  font-size: 2.4rem;
}

.keypoints-grid__item,
.keypoints-grid__item > * {
  line-height: 1.2em;
}

.keypoints-grid__item small,
.keypoints-grid__item strong {
  @extend %fw-bold;
}

.keypoints-grid__item small {
  text-transform: lowercase;
  font-size: 1.4rem;
}

.keypoints-grid__item strong {
  font-size: 4rem;
}

/**
 *  Highlights grid & items
 */

.highlights-grid__item__content__category,
.highlights-grid__item__content__title {
  @extend %text-uppercase;
  line-height: 1em;
}

.highlights-grid__item__content__category {
  font-size: 1.2rem;
}

.highlights-grid__item__content__title {
  @extend %ff-av-next-cond;
  @extend %fw-bold;
  font-size: 1.6rem;
}

/**
 *  Homepage Slider
 */

.homepage-slider__item__subtitle {
  @extend %ff-av-next-cond;
  font-size: 1.8rem;
}

/**
 *  Footer
 */

.footer__legals__link-outer {
  @include mq($until: 'large') {
    line-height: 1.2em;
  }
}
