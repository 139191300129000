@import './request-popup';

.drift-zoom-pane {
  width: 100% !important;
  height: 100% !important;

  @include mq(large) {
    width: 400px !important;
    height: 400px !important;
  }
}

.drift-zoom-pane.drift-inline {
  border-radius: 0;
}

.comparator__header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 26rem 2rem 6rem;
  background-color: $cello;

  &::before {
    @include get-all-space;

    content: '';
    z-index: 2;
    background-color: rgba($black, 0.5);
  }

  @include mq(medium) {
    padding: 15rem 2rem 13rem;
  }

  @include mq(xlarge) {
    padding: 18rem 2rem 17rem;
  }
}

.comparator__header__picture {
  @include fit($position: top left);

  z-index: 1;

  @include mq(small-xl) {
    @include fit($position: center);
  }
}

.comparator__header__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.comparator__header__title {
  margin: 0 0 3rem;
  color: $white;
  line-height: 1.2;
  text-align: center;

  @include mq(medium) {
    margin: 0 0 4rem;
  }
}

.comparator__header__baseline {
  margin-bottom: 2.5rem;
  color: $white;
  font-family: $av-next-cond;
  font-size: 1.8rem;
  line-height: 1;
  text-transform: uppercase;

  @include mq(medium) {
    margin-bottom: 3rem;
    font-size: 2rem;
  }
}

.comparator__header__categories {
  display: flex;
  justify-content: center;
  width: 100%;
}

.comparator__header__categories__item {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  border: 1px solid rgba($white, 0.3);
  padding: 2rem 3rem;
  background-color: rgba($white, 0.1);
  border-radius: 12px;
  transition: background-color 0.125s, border-color 0.125s;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover,
  &.is-active {
    background-color: $white;

    span {
      color: $cello;
    }

    img {
      filter: invert(1);
    }
  }

  img {
    max-width: 6rem;
    max-height: 4rem;
    margin-right: 1.5rem;
  }

  span {
    color: $white;
    font-size: 1.4rem;
    text-align: left;
  }

  @include mq(medium) {
    img {
      margin-right: 3rem;
    }
  }
}

.comparator__products {
  padding: 8rem 0 6rem;

  @include mq(xlarge) {
    padding: 12rem 0 10rem;
  }
}

.comparator__products__title {
  margin: 0 0 4.5rem;
  font-size: 2.6rem;
  line-height: 1.1;
  text-align: center;
  letter-spacing: 1.5px;

  @include mq(medium) {
    margin: 0 0 7.5rem;
    font-size: 3.6rem;
  }
}

.comparator__products__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1.5rem;
}

.comparator__product {
  width: calc(50% - 3rem);
  max-width: 23rem;
  margin: 1.5rem;
  border: 0;
  background-color: transparent;
  text-align: center;

  &:disabled {
    opacity: 0.4;
  }

  img {
    display: block;
  }
}

.comparator__product__zoom {
  position: absolute;
  top: 70px;
  width: 20px;
  height: 20px;
  fill: $alto;
}

.comparator__product__picture {
  display: block;
  margin: 0 auto 1.5rem;

  .comparator__table__sticky & {
    margin: 0 0 1.5rem;
  }
}

.comparator__product__title {
  display: block;
  margin-bottom: 1.5rem;
  color: $cello;
  font-family: $av-next-cond;
  font-size: 1.6rem;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.75px;
  white-space: nowrap;

  .comparator__table__sticky & {
    text-align: left;
  }

  @include mq(medium) {
    margin-bottom: 3rem;
    font-size: 2.2rem;
  }
}

.comparator__product__check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
  border: 1px solid #d8d8d8;
  padding: 3px;
  border-radius: 50%;

  svg {
    display: none;
    fill: $white;
  }

  .is-active & {
    border: 1px solid $picton-blue;
    background-color: $picton-blue;

    svg {
      display: block;
    }
  }
}

.comparator__table {
  position: relative;
  width: 100%;
  table-layout: fixed;
}

.comparator__table__tr {
  &:nth-child(even) {
    background: $white;
  }
}

.comparator__table__td {
  padding: 1rem;
}

.comparator__table__sticky {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 50%;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 2rem;
  box-shadow: 0 20px 10px -15px rgba($black, 0.06);
  background-color: $white;
  transform: translate(-50%, -100%);
  transition: transform 0.25s;

  .comparator__product__title {
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  .comparator__product__picture {
    max-height: 7rem;
  }

  &.is-visible {
    transform: translate(-50%, 55px);
  }

  @include mq(medium) {
    padding: 0 4rem;
  }

  @include mq(large) {
    &.is-visible {
      transform: translate(-50%, 0);
    }
  }

  @include mq(xlarge) {
    padding: 0;
  }
}

.comparator__table__sticky__nav {
  overflow: auto;
  border-bottom: 2px solid $alabaster2;
  padding: 2rem;
  text-align: center;
}

.comparator__feature__table__tr {
  border-top: 1px solid $alto;
}

.comparator__table__cell,
.comparator__feature__table__cell {
  width: 50%;
  padding: 1.5rem 1.5rem 1.5rem 0;
  vertical-align: top;

  @include mq(medium) {
    padding: 2.5rem 2.5rem 2.5rem 0;
  }

  @include mq(large) {
    width: 33.33%;
  }
}

.comparator__table__cell {
  position: relative;
}

.comparator__feature__table__cell {
  position: relative;
  font-size: 1.3rem;
  line-height: 1.6;

  @include mq(medium) {
    padding: 1.5rem 2.5rem 1.5rem 0;
  }
}

.comparator__feature__table__title {
  margin: 0 0 5rem;
  padding: 0 1.5rem;
  font-size: 2.2rem;
  text-align: center;

  @include mq(medium) {
    margin: 0 0 8rem;
    font-size: 3.6rem;
  }
}

.comparator__feature__table__category {
  margin: 5rem 2.5rem 1rem 0;
  font-size: 1.6rem;

  @include mq(medium) {
    margin: 8rem 2.5rem 2rem 0;
    font-size: 2rem;
  }
}

.comparator__selection {
  display: flex;
  margin-top: 10rem;
}

.comparator__selection__item {
  flex-basis: 25%;
  width: 25%;
}

.comparator__nav {
  display: flex;
  align-items: center;
  overflow: auto;
  border-bottom: 1px solid $alabaster2;
  padding: 2rem;

  @include mq(large) {
    justify-content: center;
  }
}

.comparator__scrollbar {
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 20px 10px -15px rgba($black, 0.06);

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    width: 3.5rem;
    height: 100%;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, $white, transparent);
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, $white, transparent);
  }
}

.comparator__nav__item {
  margin-right: 5rem;
  color: $cello;
  font-size: 1.5rem;
  text-decoration: none;
  white-space: nowrap;

  &.is-active,
  &:hover {
    color: $picton-blue;
  }

  &.last {
    border: 1px solid $cello;
    padding: 0.8rem 2rem;
    background-color: $cello;
    color: $white;
    line-height: 1;
    border-radius: 30px;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      background-color: $white;
      color: $cello;
    }
  }

  @include mq(medium) {
    margin-right: 6.5rem;
  }
}

.comparator__nav__popup,
[class*='comparator__nav__popup--'] {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  height: 100%;

  &[class*='--center'] {
    top: 50%;
    height: auto;
    transform: translateY(-50%);
  }

  @include mq($until: xlarge) {
    display: none !important;
  }
}

.comparator__selected {
  padding-bottom: 6rem;
  text-align: center;

  @include mq(medium) {
    padding-top: 5rem;
    padding-bottom: 12rem;
  }
}

.comparator__selected__close {
  border: 0;
  padding: 1rem;
  background-color: transparent;

  svg {
    width: 2rem;
    height: 2rem;
    fill: $cello;
  }

  @include mq(medium) {
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
}

.comparator__selected__title {
  margin-bottom: 1rem;
}

.comparator__selected__subtitle {
  display: block;
  margin-bottom: 1.5rem;
  color: $cello;
  font-size: 1.3rem;
  font-weight: 400;

  @include mq(medium) {
    margin-bottom: 2.5rem;
  }
}

.comparator__selected__link {
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    svg {
      fill: $cello;
    }
  }

  svg {
    width: 8px;
    margin-top: 1px;
    margin-left: 0.5rem;
    fill: $picton-blue;
    transition: 0.2s fill ease-in-out;
  }
}

.comparator__selected__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24rem;
  margin-top: 6rem;
  border: 1px dashed #d8d8d8;
  padding: 1rem;
  background: transparent;
  color: $cello;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 20px;

  @include mq(medium) {
    margin-top: 8rem;
  }
}

.comparator__table__label {
  color: $cello;
  font-family: $av-next-cond;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  &.is-mobile {
    display: block;
    height: 4rem;
  }

  @include mq(large) {
    &.is-mobile {
      display: none;
    }
  }
}

.comparator__table__feature {
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.comparator__table__feature-cell {
  @include mq($until: large) {
    display: none;
  }
}

.comparator__table__feature__label {
  display: block;
  margin: 0 0 0.5rem;
  font-size: 1.6rem;
}

.comparator__table__feature__text {
  color: $cello;
  font-size: 1.3rem;
  line-height: 1.4;

  @include mq(medium) {
    font-size: 1.4rem;
  }
}

.comparator__cta {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10rem;
  margin-bottom: 4rem;
  background-color: $cello;

  @include mq(small-xl) {
    flex-direction: row;
  }

  @include mq(medium) {
    margin-top: 15rem;
  }
}

.comparator__cta__content {
  position: relative;
  z-index: 10;
  padding: 5rem 3rem;
  color: $white;

  @include mq(medium) {
    width: 60%;
    padding: 5rem 0 5rem 8rem;
  }

  @include mq(large) {
    padding: 9rem 0 6rem 12rem;
  }
}

.comparator__cta__title {
  margin: 0 0 1rem;
  color: $white;
  font-family: $av-next-cond;
  font-weight: normal;
}

.comparator__cta__baseline {
  display: block;
  font-size: 1.6rem;
}
.comparator__cta__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 3rem;
}

.comparator__cta__link,
[class*='comparator__cta__link--'] {
  display: inline-flex;
  align-items: center;
  padding: 2rem;
  background-color: $picton-blue;
  color: $white;
  font: bold 1.5rem $av-next-cond;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s, background-color 0.2s;
  border: 0;

  &[class*='--small'] {
    padding: 1.5rem;
    font-size: 1.2rem;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin: -4px 0 0 1rem;
    fill: $white;
    transition: fill 0.2s;
  }

  &:hover {
    background-color: $white;
    color: $cello;

    svg {
      fill: $cello;
    }
  }

  @include mq(medium) {
    padding: 2rem 3rem;
  }
}

.comparator__cta__picture {
  position: relative;
  margin: 0;

  &::before,
  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 40%;
    height: 100%;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, $cello, transparent);
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, $cello, transparent);
  }

  @include mq(small-xl) {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;

    &::before,
    &::after {
      content: '';
    }

    img {
      @include fit;
    }
  }
}

.comparator__zoom {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 250px);
  pointer-events: none;
}

.comparator__zoom-icon {
  position: absolute;
  bottom: 1.5rem;
  left: 2.5rem;
  width: 1.5rem;

  @include mq(large) {
    width: 2rem;
  }
}

.comparator__popup {
  @include get-all-space;

  position: fixed;
  z-index: 100;
}

.comparator__popup__bg {
  @include get-all-space;

  z-index: 1;
  background-color: rgba($color-primary, 0.9);
}

.comparator__popup__image {
  @include center-xy;
  z-index: 2;
  width: 100%;
  max-width: 95vw;
  padding: 1rem;
  background-color: $white;

  img {
    width: 100%;
    transform: scale(0);
  }

  .is-visible & {
    img {
      transform: scale(1);
    }
  }

  @include mq(large) {
    max-width: 102.4rem;
  }
}

.comparator__popup__close {
  position: absolute;
  top: -3rem;
  right: 0;
  border: 0;
  padding: 1rem;
  background: transparent;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 2rem;
    height: 0.2rem;
    background: $white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
