.en16005-button,
[class*='en16005-button--'] {
  @extend %button-nostyle;
  @extend %ff-av-next-cond;
  @extend %text-nodecoration;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 2rem 2rem 1.8rem 0;
  color: $color-gray;
  transition: 0.2s;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: $color-primary;
  }

  &.next {
    position: relative;
    flex-direction: row;
    margin-top: $spacing;
    padding: 2rem 2rem 1.8rem;
    color: $white;

    &::before {
      @include get-all-space;

      content: '';
      z-index: -1;
      background: $color-secondary;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: $color-primary;
      transition: 0.2s ease-in;
    }

    &:disabled {
      color: $color-gray;

      &::after {
        background: $color-gray-light;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: $white;

      &::after {
        width: 5px;
      }
    }

    @include mq(medium) {
      &:not(.en16005-products__item__link) {
        margin-top: 0;
      }

      .en16005-button + & {
        margin: 0 0 0 $spacing;
      }
    }
  }

  .en16005-choice__validation__back & {
    height: 100%;
  }
}

.en16005-button__label {
  @extend %fw-semibold;

  position: relative;
  z-index: 1;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.03rem;
  transition: transform 0.2s 0.1s;

  .next:not(:disabled):hover &,
  .next:not(:disabled):focus & {
    transform: translateX(5px);
  }
}

.en16005-button__sublabel {
  @extend %fw-normal;
  @extend %text-left;

  display: block;
}

.en16005-button__icon {
  position: relative;
  z-index: 1;
  height: 1.2rem;
  margin-right: $spacing;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 10;
  transform: rotate(-180deg);
  transition: 0.2s;

  .next & {
    @extend %color-secondary;

    margin: 0 0 0 $spacing;
    transform: none;
  }

  .en16005-button:not(:disabled):hover &,
  .en16005-button:not(:disabled):focus & {
    transform: rotate(-180deg) translateX(5px);
  }

  .next:not(:disabled):hover &,
  .next:not(:disabled):focus & {
    color: $white;
    transform: translateX(5px);
  }

  :disabled & {
    color: $color-gray;
    pointer-events: none;
  }
}
