.en16005__switcher {
  position: absolute;
  z-index: 1;
  display: flex;
  background: $color-gray-light;
  border-radius: 5px;
}

.en16005__switcher__button {
  @extend %button-nostyle;
  @extend %ff-av-next-cond;
  @extend %fw-semibold;
  @extend %text-uppercase;

  position: relative;
  z-index: 1;
  padding: 0.5rem 2rem 0.2rem;
  color: $color-gray;
  white-space: nowrap;
  letter-spacing: 1px;
  transition: color 0.2s;

  &.is-active {
    color: $white;
    transition: color 0.2s 0.2s;
  }

  &:focus-visible {
    outline: $color-secondary 5px solid;
  }

  &:not(.is-active):hover,
  &:not(.is-active):focus {
    color: $color-primary;
  }

  @include mq(large) {
    padding: 1rem 2.7rem 0.8rem;
  }
}

.en16005__switcher__selector {
  @include center-y;

  height: 100%;
  background: $color-primary;
  border-radius: 5px;
}
