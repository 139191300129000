.article-related,
[class*='article-related--'] {
  overflow: hidden;
  margin-top: $spacing * 2.5;
  
  @include mq($until: large) {
    margin-right: 0;
    margin-left: 0;
  }
  
  @include mq(large) {
    &.no-slider {
      padding-bottom: 7rem;
    }
  }
}

.article-related__title {
  @extend %text-uppercase;

  margin-bottom: $spacing * 2;
  text-align: center;

  @include mq(large) {
    margin-bottom: $spacing * 3;
  }
}

.article-related-container {
  position: relative;
  height: 45rem;

  .article-related--products & {
    height: 30rem;
  }

  @include mq(ml) {
    max-width: 80rem;
    margin: $spacing * 2.5 auto 0;
  }

  @include mq(large) {
    height: 40rem;
  }
}

.article-related__list {
  @extend %list-nostyle;

  display: flex;
  height: 100%;

  .flickity-viewport {
    overflow: visible;
    height: 100%;
    width: 100%;
  }

  @include mq(large) {
    @include get-all-space;

    z-index: 1;
    margin-right: 0;
    margin-left: 0;

    .no-slider & {
      display: flex;
      justify-content: center;
    }
  }
}

// ITEM
.article-related__item {
  position: relative;
  height: 100%;

  @include mq($until: 'large') {
    &:not(:last-child) {
      padding-right: $spacing;
    }
  }

  @include mq(large) {
    position: absolute;
    display: none;
    width: 32%;
    height: 100%;
    left: 0;
    opacity: 0;

    &:nth-child(3n + 2) {
      left: 35%;
    }

    &:nth-child(3n + 3) {
      left: unset;
      right: 0;
    }

    .no-slider & {
      position: static;

      &:not(:first-child) {
        margin-left: 5%;
      }
    }
  }
}

.article-related__item-inner {
  display: flex;
  flex-direction: column;
  width: 36rem;
  max-width: 60vw;
  height: 100%;

  @include mq(large) {
    position: relative;
    width: 100%;
    max-width: none;
    height: 100%;
  }
}

.article-related__item__picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: auto;
  aspect-ratio: 3/2;
  margin: 0;

  img {
    @include fit(cover);

    transition: transform 0.2s;
  }

  .article-related__item:hover &,
  .article-related__item:focus-within & {
    img {
      transform: scale(1.05);
    }
  }
}

.article-related__item__content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr;
  flex: 1;
  padding: $spacing;
  border: 0.1rem $careers-gray solid;
  border-top: 0;
}

.article-related__item__title {
  grid-column: 1/3;
  margin: 0;
  font-size: 1.8rem;
  line-height: 2.4rem;

  a {
    color: $color-primary;
    text-decoration: none;
    transition: color 0.2s;

    &::before {
      @include get-all-space;

      content: '';
    }

    &:hover,
    &:focus {
      color: $color-secondary;
    }
  }
}

.article-related__item__text {
  grid-column: 1/3;

  p {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 4; 
   -webkit-box-orient: vertical;
  }
}

.article-related__item__date {
  grid-column: 1/2;
  margin-right: 1rem;
}

.article-related__item__categories {
  @extend %list-nostyle;

  grid-column: 2/3;
  justify-self: flex-end;
  text-align: right;

  li {
    display: inline-block;
  }

  a {
    position: relative;
    z-index: 1;
  }
}

.article-related__item__tags {
  grid-column: 2/3;
}

// CONTROLS
.article-related__controls {
  @include center-x;

  bottom: -13rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .no-slider & {
    display: none;
  }

  @include mq(large) {
    @include center-xy;

    width: calc(100% + 22rem);
  }
}

.article-related__control {
  @extend %button-nostyle;

  padding: 4rem;
  color: $cello;
  transition: opacity 0.2s;

  .label {
    @extend %visually-hidden;
  }

  .icon {
    fill: currentColor;
    width: 4rem;
    height: 7rem;
  }

  &:first-child {
    .icon {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    color: $color-secondary;
  }

  &:disabled,
  &[disabled] {
    opacity: 0;
  }
}

.article-related__nav {
  @extend %list-nostyle;

  display: none;

  @include mq(large) {
    display: flex;
    justify-content: center;
    margin: $spacing * 2 auto 0;
    max-width: 80rem;
  }
}

.article-related__nav__item__button {
  @extend %button-nostyle;

  .label {
    @extend %visually-hidden;
  }

  @include mq(large) {
    position: relative;
    width: 3.8rem;
    height: 1rem;
    margin: 1rem;

    &:hover::before,
    .is-active &::before {
      background: $color-secondary;
    }

    &::before {
      @include center-xy;

      content: '';
      width: 100%;
      height: 0.3rem;
      background: $alto;
    }
  }
}

// Show items based on slider
@for $i from 0 to 10 {
  [data-launchpage-accessories-root][data-current-group='#{$i}'] {
    .article-related__item[data-group='#{$i}'] {
      display: block;
      opacity: 1;
    }

    .article-related__nav__item__button[data-group='#{$i}'] {
      &::before {
        background: $color-secondary;
      }
    }
  }
}
