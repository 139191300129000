.popup {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, 0.8);
  opacity: 0;
  transition: opacity .3s ease-out;

  .is-open & {
    display: block;
    opacity: 1;
  }
}

.popup__container {
  @include center-xy;
  width: 80vh;
  height: 80vh;
  background-color: white;
  border-radius: .5rem;

  @include mq($until: medium) {
    width: 80vw;
    height: 80vw;
  }
}

.popup__container__picture-outer {
  @include center-xy;

  width: 50%;
  height: 50%;
}

.popup__container__picture {
  @include center-xy;

  width: 50%;
  margin: 0 auto;
}