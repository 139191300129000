.grid-container,
[class*="grid-container--"],
.grid-flex,
[class*="grid-flex--"] {
  @include mq('tiny', 'medium') {
    margin-right: $spacing;
    margin-left: $spacing;
  }

  @include mq('medium', 'xlarge') {
    margin-right: $spacing * 2;
    margin-left: $spacing * 2;
  }

  @include mq('xlarge') {
    max-width: calc(1280px - #{4 * $spacing});
    margin-right: auto;
    margin-left: auto;
  }
}

[class*="grid-flex--"][class*="--nospace"],
[class*="grid-container--"][class*="--nospace"] {
  max-width: 1680px;
  margin-right: auto;
  margin-left: auto;
}

[class*="grid-flex--"][class*="--redux"],
[class*="grid-container--"][class*="--redux"] {
  max-width: 620px;

  @include mq('tiny', 'medium') {
    margin-right: $spacing;
    margin-left: $spacing;
  }

  @include mq('medium') {
    margin-right: auto;
    margin-left: auto;
  }
}

[class*="grid-flex--"][class*="--full"],
[class*="grid-container--"][class*="--full"] {
  margin-right: 0;
  margin-left: 0;
}

.grid-flex,
[class*="grid-flex--"] {
  @extend %list-nostyle;

  @include mq('small-l') {
    display: flex;
    flex-wrap: wrap;
  }
}

[class*="grid-flex--"][class*="--centered"] {
  justify-content: center;
}

[class*="grid-flex--"][class*="--col"],
[class*="grid-flex--"][class*="--col2"] {
  > * {
    @include mq('small-l') {
      flex-basis: 50%;
    }
  }
}

[class*="grid-flex--"][class*="--spaced"] {
  > * {
    margin: $spacing;

    @include mq('small-l', 'medium') {
      flex-basis: calc(50% - #{$spacing * 2});
    }
  }
}


[class*="grid-flex--"][class*="--col3"] {
  > * {
    @include mq('medium') {
      flex-basis: 33.3%;
    }
  }

  &[class*="--spaced"] > * {
    @include mq('medium') {
      flex-basis: calc(33.3% - #{$spacing * 2});
    }
  }
}

[class*="grid-flex--"][class*="--col4"] {
  > * {
    @include mq('medium') {
      flex-basis: 25%;
    }
  }

  &[class*="--spaced"] > * {
    @include mq('medium') {
      flex-basis: calc(25% - #{$spacing * 2});
    }
  }
}

