.card-products,
[class*='card-products--'] {
  position: relative;

  & > *:last-child {
    margin-bottom: 0;
  }
}

.card-products__icon {
  position: absolute;
  top: 0;
  left: 50%;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  transform: translate(-50%, -50%);

  @include mq('medium') {
    top: 6rem;
    left: 0;
    transform: translate(-50%, 0%);

    [class*='block-products--'][class*='--reverse'] & {
      right: 0;
      left: auto;
      left: initial;
      transform: translate(50%, 0%);
    }
  }
}

.card-products__description {
  // padding: 6rem 4rem;
  padding: 4rem 4rem 2rem;

  @include mq(medium) {
    padding: 6rem 7rem 3rem;
  }
}

.card-products__description__title {
  color: $color-corpo;

  @each $slug, $color in $segment-colors {
    [class*='block-products--'][class*='--#{$slug}'] & {
      color: $color;
    }
  }
}

.card-products__description__link {
  margin-top: 2rem;
}

.card-products__cta {
  @extend %text-center;
  display: block;
  padding: 2rem;
}
