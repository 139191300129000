$megamenu-p: 2rem;

.megamenu-section,
[class*='megamenu-section--'] {
  position: relative;
  padding: $megamenu-p 0;
  background: $color-gray-light;

  &:nth-child(2) {
    width: 100%;
    background: $white;
  }
}

.megamenu-section-title {
  min-height: 3.6rem;
  margin: 0 2rem 2.4rem;
  color: $color-secondary;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.8rem;
  text-transform: uppercase;
}

.megamenu-section__links {
  @extend %list-nostyle;
}

.megamenu-section__link {
  .icon {
    display: none;
  }
}

.megamenu-section__link__new {
  @include center-y;

  color: $white;
  text-transform: uppercase;
  background: $cello;
}

.megamenu-section__view-all,
.megamenu-section__sub__view-all {
  position: absolute;
  top: $megamenu-p;
  right: $megamenu-p;
}

.megamenu-section__application {
  position: relative;
  width: 100%;
  font-size: 1.3rem;
  border: 0;
  background: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 4px;
    height: 33.33%;
    background-color: $color-primary;
    transform: translateY(-50%);
  }

  .icon {
    transform: rotate(90deg);
  }

  &.megamenu-section__sub__link {
    .arrow {
      transform: rotate(0) !important;
    }

    &::after {
      display: none;
    }
  }

  &:hover {
    color: $color-primary;
    background: $color-gray-light;
  }

  .arrow,
  .icon {
    display: block;
    pointer-events: none;
  }

  span {
    pointer-events: none;
  }

  @each $segment, $color in $segment-colors {
    [class*='s--#{$segment}'] &::after {
      background-color: $color;
    }
  }
}

// Second level menus (submenus)

// .megamenu-section__sub {
//   position: absolute;
//   z-index: 1;
//   top: -34px;
//   left: 100%;
//   display: none;
//   width: 100%;
//   height: 100vh;
//   background: $white;

//   &.is-open {
//     display: block;
//   }
// }

.megamenu-section__sub {
  z-index: 1;
  display: none;
  width: 100%;
  background: $white;

  &.is-open {
    display: block;
  }
}

.megamenu-section__sub__sub {
  position: absolute;
  z-index: 1;
  top: -34px;
  // padding: 4rem;
  left: 100%;
  display: none;
  width: 100%;
  height: 100vh;
  background: $white;

  &.is-open {
    display: block;
  }
}

.megamenu-section__sub__list,
.megamenu-section__sub__sub__list {
  @extend %list-nostyle;

  padding: 0 $megamenu-p;
}

.megamenu-section__sub__item {
  & + & {
    margin-top: 1rem;
  }
}

.megamenu-section__sub__link {
  justify-content: space-between;
}

.megamenu-section__sub__link,
.megamenu-section__sub__sub__link {
  display: flex;
  align-items: center;
  min-height: 3.8rem;
  line-height: 1.4;
  color: $cello;
  text-decoration: none;

  &.view-all {
    top: 7rem;
  }
}

.megamenu-section__sub__sub__link {
  min-height: 4.8rem;
}

.megamenu-section__sub__link__icon,
.megamenu-section__sub__sub__link__icon {
  position: relative;
  width: 3.6rem;
  height: 3.6rem;
  margin-right: 1em;

  svg {
    position: relative;
    z-index: 1;
    width: 3.6rem;
    height: 3.6rem;
    fill: $cello;
    transition: fill 0.2s;
  }

  &::after {
    @include center-xy;

    content: '';
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    opacity: 0;
    transform-origin: center;
    transition: 0.5s;

    @each $segment, $color in $segment-colors {
      [class*='s--#{$segment}'] & {
        background-color: $color;
      }
    }
  }

  .megamenu-section__sub__link:hover &,
  .megamenu-section__sub__link:focus &,
  .megamenu-section__sub__sub__link:hover &,
  .megamenu-section__sub__sub__link:focus & {
    svg {
      fill: $white;
    }

    &::after {
      opacity: 1;
    }
  }
}

.megamenu-section__sub__view-all {
  top: $megamenu-p * 3;
}

@include mq($until: large) {
  .megamenu-section__view-all a.cta--bordered--dark,
  .megamenu-section__sub__view-all a.cta--bordered--dark {
    min-height: 2.4rem;
    padding: 0 0.5rem 0 1rem;

    .cta__arrow {
      right: 1rem;
    }
  }
}

@include mq('large') {
  $megamenu-p-l: 4rem;

  .megamenu-section,
  [class*='megamenu-section--'] {
    padding: $megamenu-p-l;

    &:nth-child(3) {
      width: 30%;
    }
  }

  .megamenu-section-title {
    margin: 0 1rem 2.4rem 0;
    
    .megamenu-section--applications > & {
      margin-bottom: 3rem;
    }
  }

  .megamenu-section__links {
    .megamenu--products .megamenu-section:nth-child(2) & {
      columns: 3;
      column-rule: 1px solid $alto;
      column-gap: $spacing * 2.5;
      padding-right: 10%;
      padding-bottom: $spacing * 2.5;
    }

    .megamenu--services .megamenu-section:nth-child(2) & {
      display: flex;
      flex-wrap: wrap;

      li {
        width: calc(100% / 3);

        .megamenu-section__view-all {
          position: static;
        }

        &:nth-child(n + 3) {
          margin-bottom: 3rem;
        }
      }
    }
  }

  .megamenu-section__link {
    justify-content: space-between;
    padding: 0;
    line-height: 4rem;
  }

  .megamenu-section__view-all {
    top: initial;
    right: $megamenu-p-l;
    bottom: $megamenu-p-l;

    .ie11 & {
      top: auto;
    }
  }

  .megamenu-section__application {
    padding: 0.5rem 1rem;
    color: $nevada;
    line-height: 1;
    white-space: nowrap;

    &::after {
      left: -$megamenu-p-l;
      width: $megamenu-p-l;
      height: 100%;
      transform: translateY(-50%) scaleX(0.05);
      transform-origin: right;
      transition: transform 0.2s ease-out;
    }

    .arrow {
      width: 9px;
      color: $color-primary;
    }

    &.megamenu-section__sub__link {
      &::after {
        display: none;
      }
    }

    &:hover,
    &.is-open {
      font-weight: 600;
      color: $color-primary;
      background: $color-gray-light;

      .icon {
        color: $color-primary;
      }

      &::after {
        transform: translateY(-50%) scaleX(1);
      }

      &.megamenu-section__sub__link {
        background: transparent;

        .arrow {
          opacity: 1;
        }
      }
    }

    &.is-open {
      .icon {
        opacity: 0;
      }
    }
  }

  .megamenu-section--applications {
    position: relative;
    padding-right: 70%;

    .megamenu-section__links {
      display: block;
    }

    .megamenu-section__link-outer + .megamenu-section__link-outer {
      margin-top: 1rem;
    }
  }

  .megamenu-section__sub {
    // position: relative;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: $megamenu-p-l * 0.5;
    padding-right: 0;

    .megamenu-section-title {
      margin-bottom: 2.2rem;
    }

    &.is-open {
      display: flex;
    }
  }

  .megamenu-section__sub__sub {
    position: absolute;
    z-index: 1;
    top: 0;
    padding: 4rem;
    left: 30%;
    display: none;
    width: 70%;
    height: 100%;
    background: $white;
    border-left: 1px solid $alto;

    &.is-open {
      display: block;
    }
  }

  .megamenu__back--sub {
    display: none;
  }

  .megamenu-section__sub__list,
  .megamenu-section__sub__sub__list {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    padding: 0;
  }

  .megamenu-section__sub__sub__list {
    gap: 2rem;
  }

  .megamenu-section__sub__item {
    width: 100%;
  }

  .megamenu-section__sub__sub__item {
    margin-top: 1rem;
    width: 45%;
  }

  .megamenu-section__sub__view-all {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;

    a {
      align-self: flex-end;
      margin-left: auto;
    }
  }
}

@include mq('xxlarge') {
  $megamenu-p-vertical: 4rem;
  $megamenu-p-horizontal: 4rem;
  $submenu-width: 30rem;

  .megamenu-section,
  [class*='megamenu-section--'] {
    min-width: 30rem;
    padding: $megamenu-p-vertical 0 $megamenu-p-vertical $megamenu-p-horizontal;
  }

  .megamenu-section--applications {
    width: 30rem;
  }

  .megamenu-section:nth-child(2) {
    width: 60rem;
    padding-bottom: 0;

    .megamenu-section__links {
      columns: 2;
      padding-right: 10rem;
    }
  }

  .megamenu-section:nth-child(3) {
    width: auto;
  }

  .megamenu-section__links {
    .megamenu--products .megamenu-section:nth-child(2) & {
      columns: 2;
      padding-right: $spacing * 2.5;
      padding-bottom: $spacing * 5;
    }

    .megamenu--services .megamenu-section:nth-child(2) & {
      padding-right: 0;

      li {
        width: 50%;

        .megamenu-section__view-all {
          position: static;
        }

        &:nth-child(n + 2) {
          margin-bottom: 3rem;
        }
      }
    }
  }

  .megamenu-section__view-all {
    top: initial;
    right: initial;
    left: 4rem;

    .ie11 & {
      top: auto;
      right: auto;
    }
  }

  .megamenu-section__application {
    &::after {
      left: -$megamenu-p-horizontal;
      width: $megamenu-p-horizontal;
    }
  }

  .megamenu-section--applications {
    position: relative;
    min-height: 60rem;
    margin-right: $submenu-width;
    padding-right: 0;
    border-right: 1px solid $alto;

    .view-all {
      bottom: 0;
    }
  }

  .megamenu-section__sub {
    position: inherit;
    left: 0;
    flex-direction: column;
    width: $submenu-width;
    height: 100%;
    padding: $megamenu-p-vertical * 0.5 0 $megamenu-p-vertical * 0.5 2rem;
    border: 0;

    &.is-open {
      display: flex;
    }
  }

  .megamenu-section__sub__sub {
    position: absolute;
    z-index: 1;
    top: -34px;
    left: 100%;
    display: none;
    width: 100%;
    height: 100vh;
    background: $white;

    &.is-open {
      display: block;
    }
  }

  .megamenu-section__sub__list,
  .megamenu-section__sub__sub__list {
    display: block;
  }

  .megamenu-section__sub__sub__item {
    width: auto;
  }

  .megamenu-section__sub__view-all {
    margin-top: $spacing;

    a {
      margin-left: 0;
    }
  }

  .megamenu-section__sub__sub {
    position: absolute;
    width: auto;
    display: none;
    top: 0;
    left: 100%;
    flex-direction: column;
    width: $submenu-width;
    height: 100%;
    padding: $megamenu-p-vertical;
    border: 0;

    &.is-open {
      display: block;
    }
  }
}
