.cta-guide {
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1 !important;
  margin-top: 0;
  margin-bottom: 12rem;
  background-color: #f5f3f3;
  border-radius: 1.6rem;
  overflow: hidden;

  @include mq(large) {
    flex-direction: row;
  }
}

.cta-guide__card {
  display: flex;
  align-items: center;
}

.cta-guide__picture {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 24rem;

  @include mq(large) {
    height: 100%;
    width: 30%;
  }
}

.cta-guide__content {
  margin-top: 24rem;
  padding: 3rem 2rem;

  @include mq('large') {
    margin-top: 0;
    margin-left: 30%;
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 8rem;
    padding-right: 16rem;
  }
}

.cta-guide__label {
  font-size: 1.1rem;
  background-color: #59c3f0;
  color: white;
  text-transform: uppercase;
  padding: 2px 6px;
  border-radius: 0.3rem;
}

.cta-guide__title {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.cta-guide__text {
  font-size: 1.5rem;
  color: #1f325b;
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 2.4rem;
}

.cta-guide__input {
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;

  @include mq($until: 'large') {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.content-section .button-group {
  margin-top: 20px;
}

.cta-guide__input {
  width: fit-content;
}

.cta-guide__input select {
  width: 20rem;
  margin: 0 1.2rem;
}

.cta-guide__input select,
.cta-guide__input button {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.cta-guide__input button {
  cursor: pointer;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.3rem;
  background-color: #1f325b;
  color: #fff;
  border: none;
  font-size: 1.3rem;

  &[disabled] {
    background-color: #cfcfcf;
    color: #868686;
    pointer-events: none;
  }
}
