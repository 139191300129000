.socials {
  overflow: hidden;
  margin-top: 4rem;
  background: $gallery;

  .page-template-contact & {
    margin-top: 0;
  }
}

.socials-links,
.socials-newsletter {
  padding: 4rem 2rem;
}

.socials-newsletter {
  background: $cello;
  color: $white;

  .socials__title {
    color: $white;
  }
}

h2.socials__title {
  display: flex;
  align-items: center;
  margin: 0 0 4rem;
  font-family: 'Open Sans';
  font-size: 2.4rem;
  font-weight: normal;
  text-transform: none;

  .icon {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
    fill: currentColor;
  }

  // Override launchpage/en16005 styles
  .page-template-launchpage &,
  .page-template-en16005 & {
    letter-spacing: 0;

    @include mq(large) {
      font-size: 2.4rem;
    }
  }

}

.socials-links__list {
  @extend %list-nostyle;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.socials-links__list__link-outer {
  & + & {
    margin-left: 2.4rem;
  }

  .icon {
    width: 3.2rem;
    height: 3.2rem;
    vertical-align: middle;
  }
}

.socials-links__list__link {
  display: flex;
  height: 100%;
  align-items: center;
}

.socials-newsletter__link {
  z-index: 1;
}

@include mq($until: 'large') {
  .socials-container {
    margin: 0;
  }
}

@include mq('medium') {
  .socials-links,
  .socials-newsletter {
    padding: 4rem;
  }
}

@include mq('large') {
  .socials-container {
    display: flex;
  }

  .socials-links {
    width: 40%;
    padding: 6rem 12rem 6rem 0;
  }

  .socials-newsletter {
    flex: 1;
    padding: 6rem 0 6rem 6rem;
    position: relative;
    z-index: 1;
    background: transparent;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 100vw);
      height: 100%;
      background: $cello;
      z-index: -1;
    }
  }
}

@include mq('xlarge') {
  .socials-links,
  .socials-newsletter {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .socials-links {
    width: 30%;
  }
}
