.launchpage-product-cta {
  position: relative;
  overflow: hidden;
  padding: 6rem 0;
  background: $color-lp-bg;
}

.launchpage-product-cta__picture {
  display: block;
  width: 60%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.launchpage-product-cta__content {
  margin-top: 4rem;
  text-align: center;
}

.launchpage-product-cta__headline {
  font-weight: normal !important;
}

.launchpage-product-cta__title {
  margin: 1.8rem 0 2.8rem;
  padding: 0;
}

.launchpage-product-cta__cta {
  position: relative;
  display: inline-block;
  padding: 2rem 2.5rem 1.8rem;
  color: $color-primary;
  border-radius: 60px;

  .cta__label {
    position: relative;
    z-index: 3;
    display: inline-block;
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 0.08em;
    color: currentColor;
    transition: 2.5s $ease-lp;
  }

  .cta__arrow {
    position: relative;
    z-index: 3;
    margin-left: 1rem;
    fill: currentColor;
    transition: 1s $ease-lp;
  }

  &::before,
  &::after {
    @include get-all-space;

    z-index: 2;
    content: '';
    border: 3px solid $color-secondary;
    border-radius: 60px;
    transition: 1s $ease-lp;
  }

  &::after {
    border-color: $cello;
    clip-path: inset(0% 0% 0% 100%);
  }

  &:focus,
  &:hover {
    .cta__arrow {
      opacity: 0;
      transform: translateX(5px);
    }
    .cta__label {
      transform: translateX(10px);
    }

    &::after {
      clip-path: inset(0% 0% 0% 0%);
    }
  }

  // OLD EFFECT
  // &::before {
  //   @include get-all-space;

  //   z-index: 2;
  //   content: '';
  //   background: $color-lp-bg;
  //   border: 3px solid $color-secondary;
  //   border-radius: 60px;
  //   transition: 1s $ease-lp;
  // }

  // &::after {
  //   @include get-all-space;

  //   z-index: 1;
  //   content: '';
  //   background: linear-gradient(
  //     to bottom right,
  //     rgba($color-primary, 0.5),
  //     rgba($color-secondary, 0.8) 70%
  //   );
  //   border-radius: 60px;
  //   opacity: 0;
  //   filter: blur(15px);
  //   transform: translate(5px, 10px);
  //   transition: 1s $ease-lp;
  // }

  // &:hover,
  // &:focus {
  //   .cta__arrow {
  //     transform: translateX(5px);
  //   }

  //   &::before {
  //     background: $color-secondary;
  //     transform: scale(1.05);
  //   }

  //   &::after {
  //     opacity: 1;
  //   }
  // }
}

@include mq(large) {
  .launchpage-product-cta {
    padding: 19rem 0 15rem;

    .grid-container {
      position: relative;
    }
  }

  .launchpage-product-cta__picture {
    @include center-xy;

    top: 65%;
    left: 5%;
    width: 80%;
    max-width: 100rem;
    height: auto;
  }

  .launchpage-product-cta__content {
    position: relative;
    margin-top: 0;
    padding-left: 60%;
    text-align: left;
  }
}

@include mq(xxlarge) {
  .launchpage-product-cta__picture {
    left: 0%;
  }
}
