$bp: 'large';

.article-duo {
  margin-top: $spacing * 2.5;

  @include mq($bp) {
    display: flex;
    align-items: center;
    gap: $spacing * 4;
    max-width: 80rem;
    margin: $spacing * 2.5 auto 0;

    &.is-reversed {
      flex-direction: row-reverse;
    }
  }
}

.article-duo__text {
  color: $cello;
  font-size: 1.5rem;
  line-height: 1.6;

  h2 {
    margin: 0 0 $spacing;
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  @include mq($bp) {
    flex: 1;
  }
}

.article-duo__picture {
  margin: 0;
  width: 100%;

  img {
    display: block;
    width: 100%;
  }

  @include mq($bp) {
    flex: 1;
  }
}