$menu-header-height: 55px;
$menu-height: 130px;
$menu-top-height: 40px;
$menu-bottom-height: 90px;
$menu-max-width: 1140px;
$menu-logo-width: 160px;
$menu-main-width: #{$menu-max-width - $menu-logo-width};
$submenu-padding-vertical: 6.4rem;
$submenu-padding-horizontal: 6rem;

.menu {
  position: relative;
  z-index: 100;
}

.menu-container {
  padding: 0 $spacing;
  max-width: $menu-max-width;

  @include mq($until: 'large') {
    position: fixed;
    top: 0;
    padding: 0;
    width: 100%;
    background-color: $white;
    z-index: 999;
  }
}

// HEADER

.menu__header {
  position: relative;
  display: flex;
  // flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  height: $menu-header-height;

  @include mq($until: 'large') {
    box-shadow: 0 10px 5px 0 rgba($color-gray, 0.15);
    z-index: 1;
  }
}

.menu__header__hamburger {
  @include center-y;
  right: $spacing / 2;
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: $cello;
  transition: fill 0.3s;

  &:hover {
    fill: $picton-blue;
  }

  .menu.is-open & {
  }

  @include mq('large') {
    display: none;
  }
}

.menu__header__logo {
  width: 8rem;
  vertical-align: middle;

  @include mq(large) {
    width: 10rem;
  }
}

// CONTENT

.menu__content {
  @include mq($until: 'large') {
    position: relative;
    height: 0;
    overflow: hidden;
    overflow-y: scroll;
    transition: height 0.3s;

    .menu.is-open & {
      height: calc(100vh - 80px);
    }
  }
}

.menu__content-inner {
  // transition: transform .3s;
  @include mq($until: 'large') {
    &.is-slided {
      transform: translateX(-100%);
    }
  }
}

// SUBMENU

[class*='submenu--'] {
  background-color: $white;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  z-index: 99;
  display: none;

  @include mq($until: 'large') {
    height: calc(100vh - #{$menu-header-height});
  }

  &.is-open {
    display: block;
  }
}

.submenu__back {
  background: $gallery;
  margin: 0;
  padding: 1rem 0;
  line-height: 1;

  .link-back {
    @extend .link--bg;

    z-index: 1;
    display: inline-block;
    overflow: hidden;
    margin-left: 2rem;
    padding: 0.25rem 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2.4rem;
    width: auto;
    height: 100%;
    border-radius: 30px;

    &::before {
      border-radius: 30px;
    }
  }

  .icon {
    height: 1rem;
    fill: $white;
  }

  @include mq('large') {
    display: none;
  }
}

// SEARCH

.menu__content__search {
  padding: $spacing / 2 $spacing;

  @include mq($until: 'large') {
    $start-gradient-color: $gallery;
    $stop-gradient-color: lighten($gallery, 3%);
    background: linear-gradient(
      to bottom,
      $start-gradient-color 0%,
      $stop-gradient-color 30%
    );
  }
}

.menu__content__search__form {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0 0 0 0.5rem;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: $color-primary;
    vertical-align: middle;
  }

  .awesomplete {
    width: 100%;
    font-size: 1.3rem;
  }

  @include mq(medium) {
    .awesomplete {
      white-space: nowrap;

      input {
        margin: 0.5rem 0;
      }
    }
  }
}

.menu__content__search__input {
  display: block;
  width: 100%;
  background: transparent;
  color: $color-primary;

  &::placeholder {
    color: $bombay;
  }

  @include mq($until: 'large') {
    padding: $spacing / 3 0;
    border: 0;
  }
}

// PRIMARY - SECONDARY

.menu__content__primary,
.menu__content__secondary {
  @extend %list-nostyle;
}

.menu__content__primary__link-outer,
.menu__content__secondary__link-outer {
  @include mq($until: 'large') {
    padding: $spacing / 2 $spacing;
  }
  @include mq('large') {
    position: relative;
  }
}

.menu__content__primary__link,
.menu__content__secondary__link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    fill: currentColor;
  }
}

// PRIMARY

.menu__content__primary {
  border-bottom: 1px solid $bombay;
}

.menu__content__primary__link {
  @extend %ff-av-next-cond;

  font-weight: 300;
}

// SECONDARY

.menu__content__secondary {
  @extend %list-nostyle;
}

.menu__content__secondary__link {
  text-transform: initial;
}

// APPLICATIONS LIST

.menu__segment-list {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: red;
  z-index: 2;
}

.menu__segment-list-inner {
  @extend %list-nostyle;
}

// PHONE

.menu__content__region,
.menu__content__phone {
  display: block;
  padding: $spacing / 2 $spacing;
  color: $cello;

  &:hover,
  &:focus {
    color: $picton-blue;
  }
}

.menu__content__region {
  display: flex;
  align-items: center;
  color: $color-primary;

  .arrow {
    width: 2rem;
    height: 2rem;
    margin-right: 0;
  }

  @include mq(large) {
    padding: 0 $spacing / 2 0 0;
    // font-size: 1.4rem !important;
    // font-family: $av-next-cond;
    text-transform: none !important;

    .arrow {
      fill: $picton-blue;
      transform: rotate(90deg);
    }

    .ie11 & {
      min-width: 20rem;
    }
  }
}

.menu__content__region__language {
  position: relative;
  flex: 1;
  margin-left: $spacing / 4;

  @include mq($until: 'large') {
    .icon {
      display: none;
    }

    &::before {
      content: '-';
      margin-right: $spacing / 4;
    }
  }
}

// SWITCHERS

.menu__content__lang-switcher + .style-select,
.menu__content__market-switcher + .style-select {
  .ss-selected-option {
    background-color: lighten($gallery, 5%);
    display: flex;
    align-items: center;
    border: 0;
    color: $color-gray;

    @include mq('large') {
      color: $color-primary;
    }

    &::after {
      @include center-y;
      color: $picton-blue;
    }

    @include mq($until: 'large') {
      padding: $spacing / 2 $spacing;
    }
  }

  .ss-dropdown {
    z-index: 1000;
    top: $spacing * 2;
  }

  .ss-option {
    @include mq($until: 'large') {
      padding: $spacing / 2 $spacing;
    }
  }
}

.menu__content__region,
.menu__content__secondary,
.menu__content__phone,
.menu__content__lang-switcher + .style-select .ss-selected-option,
.menu__content__market-switcher + .style-select .ss-selected-option {
  @include mq($until: 'large') {
    background-color: lighten($gallery, 3%);
  }
}

.menu__content__region,
.menu__content__secondary__link-outer,
.menu__content__phone,
.menu__content__lang-switcher + .style-select .ss-selected-option,
.menu__content__market-switcher + .style-select .ss-selected-option {
  @include mq($until: 'large') {
    border-bottom: 1px solid $gallery;
  }
}

@include mq('large') {
  .menu {
    &::before {
      @include center-x;
      content: '';
      z-index: -1;
      top: 0;
      height: $menu-top-height;
      width: 100%;
      background: $gallery;
    }

    &::after {
      @include center-x;

      content: '';
      bottom: 0;
      width: 100%;
      height: 1px;
      background: transparent;
      transition: background 0.2s;
    }

    &.menu-open::after {
      background: $color-gray-light;
    }
  }

  .menu-container {
    position: relative;
    // display: flex;
    height: $menu-height;
    margin: 0 auto;
  }

  .menu__header {
    position: absolute;
    left: 1rem;
    top: $menu-top-height;
    width: $menu-logo-width;
    height: calc(calc(#{$menu-height} - #{$menu-top-height}) + 1rem);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  // .menu__content {
  //   width: $menu-main-width;
  // }

  .menu__content-inner {
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
  }

  [class*='link--'].menu__content__region,
  [class*='link--'].menu__content__phone,
  .menu__content__search__input,
  .menu__content__secondary__link {
    font-size: 1.2rem;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .menu__content__region,
  .menu__content__lang-switcher + .style-select,
  .menu__content__market-switcher + .style-select,
  .menu__content__phone,
  .menu__content__search,
  .menu__content__secondary {
    height: $menu-top-height;
  }

  .menu__content__lang-switcher + .style-select,
  .menu__content__market-switcher + .style-select,
  .menu__content__phone,
  .menu__content__search,
  .menu__content__secondary__link-outer,
  .menu__content__region__language {
    padding: 0 $spacing;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 20px;
      display: block;
      background-color: $alto;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .menu__content__region__language {
    margin-left: $spacing / 2;
    padding: 0 0 0 $spacing / 2;
  }

  .menu__content__lang-switcher + .style-select {
    padding-left: 0;
    border-left: 0;

    &::before {
      display: none;
    }
  }

  .menu__content__secondary__link {
    &.is-open::before {
      @include get-all-space;

      position: fixed;
      z-index: 11;
      content: '';
    }
  }

  .menu__content__secondary__link-outer:last-child {
    padding-right: 0;
  }

  .menu__content__phone {
    order: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq('large') {
      color: $color-primary;
    }
  }

  .menu__content__search {
    display: flex;
    justify-content: flex-end;
    order: 3;
    padding: 0 1rem;
    flex-grow: 1;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .menu__content__search__form {
    flex-grow: 0;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
    transition: 0.5s;

    &::before {
      transition: opacity 0.1s;
    }

    &.expanded {
      flex-grow: 1;

      &::before {
        opacity: 0;
      }
    }
  }

  .menu__content__search__input {
    flex-grow: 1;
    width: auto;
    // padding: 0.25rem 0.5rem;
    border: 0;
    width: 20rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    background: $color-gray-lighter;
    transition: opacity 0.25s ease-out, visibility 0s 0.25s;
    border-radius: 4px;

    &:focus {
      background: $white;
      transition: 0.25s ease-out;
    }
  }

  .menu__content__secondary {
    order: 4;
    display: flex;
    justify-content: flex-end;
  }

  .menu__content__secondary__link-outer,
  .menu__content__primary__link-outer {
    display: flex;
    align-items: center;
  }

  .menu__content__primary {
    // position: relative;
    width: 100%;
    height: $menu-bottom-height;
    order: 5;
    display: flex;
    border-bottom: 0;
    justify-content: space-between;
    margin-left: $menu-logo-width;
    padding-left: 1rem;
  }

  .menu__content__primary__link-outer {
    position: static;
  }

  .menu__content__primary__link {
    position: relative;
    z-index: 11;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0;
    line-height: 2.8rem;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    &.is-open {
      z-index: 10;

      &::before {
        @include get-all-space;

        position: fixed;
        z-index: -1;
        content: '';
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      left: 0;
      background: $picton-blue;
      opacity: 0;
      transition: opacity 0.2s;
    }

    .menu__content__primary__link-outer:last-child & {
      position: relative;
      padding: 0 0.8rem;
      color: $white;
      font-weight: bold;
      font-size: 1.3rem;

      &::before,
      &:after {
        @include center-xy;

        content: '';
        z-index: -1;
        width: 100%;
        height: 30px;
        background: $cello;
        transform: translate(-50%, -55%);
      }

      &:after {
        background: $picton-blue;
        opacity: 1;
        transform: translate(-50%, -55%) scaleX(0);
        transform-origin: left;
        transition: transform 0.2s ease-out;
      }

      &:hover::after,
      &:focus::after {
        transform: translate(-50%, -55%) scaleX(1);
      }
    }

    &.is-open {
      &::after {
        opacity: 1;
      }
    }
  }

  .menu__content__lang-switcher + .style-select,
  .menu__content__market-switcher + .style-select {
    .ss-selected-option {
      background-color: $white;
      height: $menu-top-height;
      padding: 0;
    }
    .ss-dropdown {
      top: $menu-top-height;
    }
  }

  .menu__content__lang-switcher + .style-select {
    width: 115px;
  }

  .menu__content__market-switcher + .style-select {
    width: 165px;
  }

  .menu__content__primary__link-outer.active > a {
    font-weight: bold;
    color: $malibu;
  }

  .menu__content__secondary__link-outer.active > a {
    font-weight: bold;
  }
}

// Trigger Phone BEA US #652
.trigger-phone {
  display: flex;
  align-items: center;
  color: $color-primary;

  .arrow {
    width: 2rem;
    height: 2rem;
    margin-right: 0;
  }

  @include mq(large) {
    padding-inline: 1rem;
    text-transform: none !important;

    .arrow {
      fill: $picton-blue;
      transform: rotate(90deg);
    }

    .ie11 & {
      min-width: 20rem;
    }
  }
}

.trigger-phone__text {
  flex: 1;
}
