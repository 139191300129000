.en16005-result__switcher {
  @include center-x;

  transform: translate(-50%, -50%);
}

.en16005-result .launchpage-download {
  height: auto;
}

.en16005-result__back {
  margin: auto;
}
