.js [data-toggle="trigger"] {
  cursor: pointer;

  + * {
    overflow: hidden;
    height: 0;
  }

  // &.is-open + * {
  //   height: auto;
  // }
}
