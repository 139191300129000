.card-member {
  max-width: 300px;
}

.card-member__img {
  display: block;
  margin-bottom: $spacing;
  width: 100%;
}

.card-member__infos__name {
  display: block;
}
