//https://codepen.io/huijing/details/XBGaNQ
// https://codepen.io/AllThingsSmitty/pen/MyqmdM
.article-table {
  position: relative;
  overflow: auto;
  margin-top: $spacing * 2.5;
  margin-bottom: $spacing * 2.5;
  background:
    linear-gradient(to right, $white 30%, rgba($white,0)),
    linear-gradient(to right, rgba($white,0), $white 70%) 0 100%,
    radial-gradient(farthest-side at 0% 50%, rgba($cello, 0.2), transparent),
    radial-gradient(farthest-side at 100% 50%, rgba($cello, 0.2), transparent) 0 100%;
  background-repeat: no-repeat;
  background-color: $white;
  background-size: 4rem 100%, 4rem 100%, 1.5rem 100%, 1.5rem 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;

  @include mq(ml) {
    max-width: 80rem;
    max-height: 80vh;
    margin: $spacing * 2.5 auto 0;
  }
}

.article-table-inner {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.article-table__header {
  position: sticky;
  z-index: 1;
  top: 0;
  margin-bottom: $spacing * 5;
  background: $white;
}

.article-table__row {
  display: flex;

  tbody & + &  {
    border-top: 0.1rem solid $alto;
  }
}

.article-table__cell,
[class*='article-table__cell--'] {
  @include owl();

  flex: 1;
  width: 70vw;
  padding: $spacing * 0.75 $spacing * 1.25 $spacing * 0.75 0;
  font-size: 1.3rem;
  line-height: 1.6;
  vertical-align: top;

  img {
    display: block;
    margin: $spacing * 0.5 auto $spacing * 0.5 0;
  }

  tbody .article-table__cell--header + & {
    padding-left: $spacing * 0.75;
  }

  @include mq(medium) {
    width: 30rem;
  }
}


.article-table__cell--header {
  position: relative;
  padding-left: 0;
  color: $color-primary;
  font-size: 1.6rem;
  text-align: left;
  background: $white;
  vertical-align: middle;

  thead &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.1rem;
    background: $alto;
  }

  tbody & {
    position: sticky;
    left: 0;
    vertical-align: baseline;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0.1rem;
      height: 100%;
      background: $alto;
    }
  }

  .two-way &:first-child {
    max-width: 15rem;
  }

  @include mq(medium) {
    font-size: 2rem;
  }
}