.toggle-products {

}

.toggle-products__product {

}

.toggle-products__product__header {
  display: flex;
  align-items: center;
  border-top: 1px solid $color-gray;
  min-height: 8rem;
}

.toggle-products__product__header__picture {
  width: 10rem;
  height: auto;
  margin: 0 1rem;

  @include mq('medium') {
    margin: 0 2rem;
  }

  @include mq('large') {
    margin: 0 4rem;
  }

}

.toggle-products__product__header__title-outer {
  position: relative;
  flex-grow: 1;
}

.toggle-products__product__header__title {
  margin: 0;
}

.toggle-products__product__header__icon {
  margin: 0 1rem;
  width: 15px;
  height: 15px;
  transition: transform $duration-default $ease-default;

  .is-open & {
    transform: rotate(-45deg);
  }

  @include mq('medium') {
    margin: 0 2rem;
  }

}

.toggle-products__product__header__link {
  &.faq-sigma {
    @include center-y;

    right: 0;

    @include mq('large') {
      right: 8rem;
    }
  }
}

.toggle-products__product__content__items {
  border-top: 1px solid $color-gray;
}

// .faq-sigma {
//   color: $picton-blue;

//   .icon {
//     fill: $picton-blue;
//   }
// }

