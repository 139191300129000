.duo__card {
  margin-bottom: $spacing * 3;
  display: flex;
  align-items: center;

  @include mq($until: large) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  @include mq(large) {
    margin-bottom: $spacing * 4.5;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing * 6;
  }

  &.is-reverse {
    @include mq(large) {
      flex-direction: row-reverse;
    }

    .duo__card__content {
      @include mq(large) {
        margin-right: 0;
      }

      @include mq(xlarge) {
        margin-right: 0;
      }
    }
    .duo__card__media {
      @include mq(large) {
        margin-right: $spacing * 3.5;
      }

      @include mq(xlarge) {
        margin-right: $spacing * 5;
      }
    }
  }
}

.duo__card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @include mq(large) {
    flex: 0 0 30rem;
    margin-right: $spacing * 3.5;
  }

  @include mq(xlarge) {
    flex: 0 0 40rem;
    margin-right: $spacing * 5;
  }
}

.duo__card__label {
  margin-bottom: 0.8rem;
  color: $picton-blue;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-transform: uppercase;

  @include mq(large) {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.duo__card__title {
  margin-top: 0;
  margin-bottom: 0.9rem;
  font-size: 2.4rem;
  line-height: 2.8rem;

  @include mq(large) {
    margin-bottom: 1.5rem;
    font-size: 4.8rem;
    line-height: 5.6rem;
  }

  strong {
    color: $picton-blue;
  }
}

.duo__card__text {
  font-size: 1.4rem;
  line-height: 2.2rem;

  @include mq(large) {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}

.duo__card__text + .duo__card__link {
  margin-top: $spacing;

  @include mq(large) {
    margin-top: $spacing * 2;
  }
}

.duo__card__link {
  display: inline-flex;
  padding: 1.2rem 2.4rem;
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: $cello;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    color: white;
    background-color: $picton-blue;
  }
}

.duo__card__media {
  @include aspect-ratio(70, 74);

  width: 100%;
  overflow: hidden;

  @include mq($until: large) {
    margin-bottom: $spacing;
  }
}

.duo__card__media-inner {
  display: flex;

  > div {
    display: flex;
    width: 50%;

    &.duo__card__media-one,
    &.duo__card__media-hr {
      width: 100%;
    }

    &:first-child() {
      &:not(.duo__card__media-one) {
        .duo__card__media__picture {
          @include aspect-ratio(350, 580);

          margin: auto;
        }
      }

      &.duo__card__media-hr {
        .duo__card__media__picture {
          @include aspect-ratio(700, 580);

          margin: auto;
        }
      }
    }

    &:nth-child(2) {
      padding-left: 2.5rem;
      display: flex;
      flex-direction: column;

      @include mq(large) {
        padding-left: 3.8rem;
      }

      @include mq(xlarge) {
        padding-left: 5rem;
      }

      .duo__card__media__picture {
        &:first-child() {
          @include aspect-ratio(300, 450);
          margin-bottom: 2.5rem;

          @include mq(large) {
            margin-bottom: 3.8rem;
          }
          @include mq(xlarge) {
            margin-bottom: 5rem;
          }
        }

        &:last-child() {
          @include aspect-ratio(300, 240);
        }
      }
    }
  }
}

.duo__card__media__picture {
  overflow: hidden;
  display: block;
  width: 100%;
  background-color: $careers-gray;
  border-radius: 15px;

  img {
    @include get-all-space();
    @include object-fit(cover);

    width: 100%;
    height: 100%;

    transform: scale(1.1) translateY(-30px);
    transition: transform 2s;

    .is-animated & {
      transform: scale(1.1) translateY(0);
    }
  }
}

// SPEC IF ONLY ONE IMAGE
.duo__card__media-one .duo__card__media__picture {
  mask-size: cover;
  mask-image: url('./../images/duo-mask.svg');
  mask-repeat: no-repeat;
  border-radius: 0;
}

// SPEC IF VIDEOLOOP
.duo__card__media__loop {
  @include fit;

  user-select: none;
  pointer-events: none;
}

.duo__card-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid #b4b4b4;
  gap: 2rem;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-bottom: 2rem;

  a {
    color: #0082c2;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: $cello;

      .duo__card-cta__picture {
        opacity: 0;
      }
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.duo__card-cta__picture {
  width: 40%;
  aspect-ratio: 1;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    transition: transform 0.2s ease-in-out;
  }
}

.duo__card-cta__infos {
  display: flex;
  flex-direction: column;

  span {
    @extend %ff-av-next-cond;
    @extend %fw-bold;

    font-size: 1.8rem;
    color: $cello;
    text-transform: uppercase;
  }
}
