.flatscan {
  padding-bottom: 5rem;

  @include mq(xlarge) {
    position: relative;
    padding-bottom: 0;
    // height: calc(100vh - 115px);
  }
}

// LOADER

.flatscan__loader {
  @include mq($until: xlarge) {
    display: none;
  }

  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 115px);
  background-color: $color-primary;
}

.flatscan__loader__gif {
  @include center-xy;
  display: none;
  width: 200px;
  height: 200px;
}

.flatscan__loader__progress {
  @include center-x;
  bottom: 40px;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.flatscan__loader__progress__bar-outer {
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 1px;
  background-color: $lochmara;
}

.flatscan__loader__progress__bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: $picton-blue;
}

.flatscan__loader__progress__text {
  align-items: center;
  margin-top: $spacing / 2;
  color: $white;
  font-size: 1.3rem;
  line-height: 1em;
  white-space: none;
}

.flatscan__cta {
  position: fixed;
  z-index: 3;
  top: 13rem;
  right: 3rem;
  width: auto !important;

  @include mq($until: xlarge) {
    top: auto;
    right: 1rem;
    bottom: 1rem;
    padding: 1rem 2rem;

    .flatscan-ctas__item__label {
      font-size: 1.3rem;
    }
  }
}

.flatscan-inner {
  @include mq($until: xlarge) {
    margin-top: $spacing * 2;
  }

  @include mq(xlarge) {
    display: none;
  }
}

// SCENE

.flatscan-scene {
  @include mq(xlarge) {
    position: sticky;
    top: 0;
    overflow: hidden;
    height: 100vh;

    .grid-container > * {
      position: absolute;
      top: 0;
    }
  }
}

.flatscan-environment,
.flatscan-volumetric,
.flatscan-minimizing,
.flatscan-opening,
.flatscan-magic,
.flatscan-combination {
  @include mq($until: large) {
    margin-top: $spacing * 2;
    padding-top: $spacing * 2;
  }

  @include mq(large, xlarge) {
    margin-top: $spacing * 3;
    padding-top: $spacing * 3;
  }

  @include mq($until: xlarge) {
    border-top: 1px solid $color-gray-light;
  }
}

.flatscan__loader__video-outer,
.flatscan-intro__video-outer {
  @include mq(xlarge) {
    left: 0;
    overflow: hidden;
    width: 100vw !important; // sass-lint:disable-line no-important
    height: 100vh;

    video {
      height: 100vh;
      object-fit: cover;
    }
  }
}

.flatscan-intro {
  @include mq($until: xlarge) {
    margin-top: $spacing * 4;
  }
}

.flatscan-intro .grid-container {
  @include mq($until: xlarge) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.flatscan-intro__managing,
.flatscan-intro__bringing {
  @include mq($until: xlarge) {
    order: 2;
    margin-top: $spacing * 2;
  }
}

.flatscan-intro__video-outer {
  @include mq($until: xlarge) {
    order: 2;
    width: calc(100% + #{$spacing * 2});
  }
}

.flatscan-intro__title {
  @include mq($until: xlarge) {
    order: 1;
    width: 100%;
    max-width: 260px;
  }
}

.flatscan-intro__title__h1 {
  @extend %ff-open-sans;

  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.7;
}

.flatscan-ctas {
  position: relative;
  overflow: hidden;
  padding: 6rem 0;

  @include mq(large) {
    padding: 19rem 0 15rem;

    .flatscan-ctas-inner {
      position: relative;
    }
  }
}

.flatscan-intro__video-outer,
.flatscan-environment__video-outer,
.flatscan-volumetric__video-outer,
.flatscan-minimizing__video-outer,
.flatscan-opening__video-outer,
.flatscan-magic__video-outer,
.flatscan-combination__video-outer {
  position: relative;

  @include mq($until: xlarge) {
    overflow: hidden;
    margin: $spacing * 4 (-1 * $spacing) 0;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.flatscan-combination__video-outer {
  @include mq(xlarge) {
    video {
      top: 50% !important;
      max-height: 72vh;
      margin-top: 63px;
      transform: translateY(-50%);
    }
  }
}

.flatscan__loader__video,
.flatscan-intro__video,
.flatscan-environment__video,
.flatscan-volumetric__video,
.flatscan-minimizing__video,
.flatscan-opening__video,
.flatscan-magic__video,
.flatscan-combination__video {
  @include get-all-space;
  width: 100%;
  height: 100%;
  outline: none;
}

.video-line-outer {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: $color-gray-light;
}

.video-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: $color-secondary;
}

.flatscan-environment__video-line-outer,
.flatscan-minimizing__video-line-outer,
.flatscan-magic__video-line-outer {
  right: -10px;
}

.flatscan-volumetric__video-line-outer,
.flatscan-opening__video-line-outer {
  left: -10px;
}

.flatscan-intro__managing,
.flatscan-intro__bringing,
.flatscan-environment__everywhere,
.flatscan-volumetric__coverage,
.flatscan-minimizing__removing,
.flatscan-opening__demand,
.flatscan-magic__learning {
  display: flex;
  flex-direction: column;

  @include mq($until: xlarge) {
    align-items: center;
    width: 100%;
  }

  @include mq(xlarge) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

.flatscan-environment__everywhere,
.flatscan-volumetric__coverage,
.flatscan-minimizing__removing,
.flatscan-opening__demand,
.flatscan-magic__learning {
  @include mq(xlarge) {
    min-height: 450px;
  }

  @include mq(xxlarge) {
    min-height: 520px;
  }

  @include mq(xxxlarge) {
    min-height: 675px;
  }
}

.flatscan-intro__managing__title,
.flatscan-intro__bringing__title,
.flatscan-environment__everywhere__title,
.flatscan-volumetric__coverage__title,
.flatscan-minimizing__removing__title,
.flatscan-opening__demand__title,
.flatscan-magic__learning__title,
.flatscan-combination__easy__title,
.flatscan-ctas__title {
  @extend %fw-bold;
  @extend %ff-av-next-cond;
  @extend %text-uppercase;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  color: $color-primary;
  font-size: 2.4rem;
  line-height: 1.2em;
  text-align: center;

  @include mq(medium) {
    font-size: 3.6rem;
  }

  @include mq(large) {
    font-size: 4.6rem;
  }

  @include mq(xlarge) {
    max-width: none;
    text-align: left;
  }
}

.flatscan-intro__managing__title {
  @include mq(xlarge) {
    color: $white;
  }
}

.flatscan-intro__bringing {
  @include mq($until: xlarge) {
    margin-top: $spacing * 2;
  }
}

.flatscan-intro__managing__text,
.flatscan-intro__bringing__text,
.flatscan-environment__everywhere__text,
.flatscan-volumetric__coverage__text,
.flatscan-minimizing__removing__text,
.flatscan-opening__demand__text,
.flatscan-magic__learning__text,
.flatscan-combination__easy__text {
  margin: $spacing / 2 auto 0;
  font-size: 1.3rem;
  line-height: 1.9em;

  @include mq($until: xlarge) {
    max-width: 400px;
    text-align: center;
  }

  @include mq(xlarge) {
    margin-top: $spacing;
    font-size: 1.5rem;
    line-height: 1.6em;

    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 4px;
      margin-bottom: $spacing;
      background-color: $color-secondary;
    }
  }
}

.flatscan-intro__managing__text {
  @include mq(xlarge) {
    color: $white;
  }
}

.flatscan-intro__title {
  text-align: center;

  img {
    max-width: 410px;
    width: 100%;
  }
}

.flatscan-combination__title,
.flatscan-intro__title {
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 4px;
    margin: $spacing auto 0;
    background-color: $color-secondary;
  }

  strong {
    @extend %fw-bold;
    @extend %ff-av-next-cond;
    @extend %text-uppercase;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    color: $color-primary;
    font-size: 2.4rem;
    line-height: 1.2em;
    text-align: center;

    @include mq(medium) {
      font-size: 3.6rem;
    }

    @include mq(large) {
      font-size: 4.6rem;
    }

    @include mq(xlarge) {
      max-width: none;
      text-align: left;
    }
  }

  span {
    @extend %fw-normal;
    display: block;
    margin: $spacing / 2 auto 0;
    font-size: 1.3rem;
    line-height: 1.9em;

    @include mq($until: xlarge) {
      max-width: 400px;
      text-align: center;
    }

    @include mq(xlarge) {
      font-size: 1.5rem;
      line-height: 1.6em;
    }
  }
}

.flatscan-volumetric__coverage__picture-outer,
.flatscan-minimizing__removing__picture-outer,
.flatscan-opening__demand__picture-outer {
  position: relative;
  width: 240px;
  margin-top: $spacing * 2;

  @include mq($until: xlarge) {
    display: none;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (19 / 24) * 100%;
  }
}

.flatscan-volumetric__coverage__picture,
.flatscan-minimizing__removing__picture,
.flatscan-opening__demand__picture {
  @include fit(cover);
  width: 100%;
  height: 100%;
}

// STEP

.flatscan-step {
  color: $color-primary;

  @include mq($until: xlarge) {
    max-width: 400px;
    margin: $spacing * 3 auto 0;
  }
}

.flatscan-step__number {
  @extend %fw-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid $color-secondary;
  font-size: 1.2rem;
  line-height: 1em;
  border-radius: 50%;

  @include mq($until: xlarge) {
    margin: 0 auto;
  }

  @include mq(xlarge) {
    margin-left: $spacing;
  }
}

.flatscan-step-inner {
  margin-top: $spacing / 2;

  @include mq($until: xlarge) {
    text-align: center;
  }

  @include mq(xlarge) {
    border-left: 1px solid $color-secondary;
    padding: $spacing / 2 0 $spacing / 2 $spacing;
  }
}

.flatscan-step__title {
  @extend %fw-bold;
  font-size: 1.8rem;
  line-height: 1.2em;
}

.flatscan-step__text {
  margin-top: $spacing / 2;
  font-size: 1.3rem;
  line-height: 1.4em;
}

.flatscan-step__picture {
  width: 100%;
  margin-top: $spacing;

  img {
    width: 100%;
  }
}

// CTAS

.flatscan-ctas__title {
  margin: 0;
  text-align: left;

  @include mq(medium) {
    max-width: 40rem;
  }
}

.flatscan-ctas__picture {
  display: block;
  width: 60%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include mq(xlarge) {
    position: absolute;
    top: 65%;
    left: 5%;
    width: 80%;
    max-width: 100rem;
    height: auto;
    transform: translate(-25%, -50%);
  }

  @include mq(xxlarge) {
    left: 0%;
  }
}

.flatscan-ctas__grid {
  margin-top: 4rem;
  text-align: center;
  padding: 0 4rem;

  @include mq(xlarge) {
    position: relative;
    margin-top: 0;
    padding-left: 60%;
    padding-right: 8rem;
    text-align: left;
  }
}

.flatscan-ctas__list {
  margin-top: $spacing;

  @include mq(medium) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $spacing;
  }
}

[class*='flatscan-ctas__item--'],
.flatscan-ctas__item {
  @extend %text-nodecoration;
  display: flex;
  width: 100%;
  border: 1px solid $color-primary;
  padding: $spacing;
  background-color: $color-primary;
  transition: background-color 0.2s ease-out;

  &:hover {
    border: 1px solid $color-primary;
    background-color: $white;
  }

  @include mq($until: medium) {
    & + & {
      margin-top: $spacing;
    }
  }

  @include mq(medium) {
    &[class*='--big'] {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  // @include mq(medium, large) {
  //   &:nth-child(3),
  //   &:nth-child(4) {
  //     grid-column-start: 1;
  //     grid-column-end: 3;
  //   }
  // }

  // @include mq(large) {
  //   &:nth-child(1),
  //   &:nth-child(2) {
  //     grid-row-start: 1;
  //     grid-row-end: 3;
  //   }

  //   &:nth-child(3) {
  //     grid-row-start: 1;
  //     grid-row-end: 2;
  //   }

  //   &:nth-child(4) {
  //     grid-row-start: 2;
  //     grid-row-end: 3;
  //   }
  // }
}

.flatscan-ctas__item__icon {
  display: block;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: $spacing * 1.5;
  fill: $white;
  transition: fill 0.2s ease-out;

  [class*='flatscan-ctas__item--']:hover &,
  .flatscan-ctas__item:hover & {
    fill: $color-primary;
  }
}

.flatscan-ctas__item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  @include mq(medium) {
    [class*='flatscan-ctas__item--'][class*='--big'] & {
      align-items: flex-end;
      margin-top: $spacing * 2;
    }
  }
}

.flatscan-ctas__item__label {
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %ff-av-next-cond;
  flex-grow: 1;
  max-width: 180px;
  color: $white;
  font-size: 2rem;
  line-height: 1.2em;
  transition: color 0.2s ease-out;

  [class*='flatscan-ctas__item--']:hover &,
  .flatscan-ctas__item:hover & {
    color: $color-primary;
  }
}

.flatscan-ctas__item__arrow {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: $spacing;
  fill: $white;
  transition: fill 0.2s ease-out;

  [class*='flatscan-ctas__item--']:hover &,
  .flatscan-ctas__item:hover & {
    fill: $color-primary;
  }

  @include mq(medium) {
    [class*='flatscan-ctas__item--'][class*='--big'] & {
      position: relative;
      top: -7px;
    }
  }
}

.flatscan-combination__title {
  @extend %fw-bold;
  @extend %text-center;

  font-size: 3rem;
  line-height: 1.2em;
}

// ARG

.flatscan-arg {
  @include mq($until: xlarge) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing * 3;
    text-align: center;
  }
}

.flatscan-arg__picture {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;

  @include mq(xlarge) {
    margin-left: $spacing;
  }
}

.flatscan-arg__image {
  margin-top: 10px;

  img {
    max-height: 180px;
  }
}

.flatscan-arg-inner {
  margin-top: $spacing / 2;

  @include mq($until: xlarge) {
    position: relative;
    padding-top: $spacing;

    &::before {
      @include center-x;
      content: '';
      top: 0;
      width: 50px;
      height: 1px;
      background-color: $color-secondary;
    }
  }

  @include mq(xlarge) {
    border-left: 1px solid $color-secondary;
    padding-left: $spacing;
  }
}

.flatscan-arg__title {
  @extend %fw-bold;
  @extend %ff-av-next-cond;
  color: $color-primary;
  font-size: 1.8rem;
  line-height: 1.2em;

  @include mq(xlarge) {
    font-size: 2.2rem;
  }
}

.flatscan-arg__text {
  margin-top: $spacing / 4;
  font-size: 1.3rem;
  line-height: 1.9em;

  @include mq($until: xlarge) {
    max-width: 400px;
    text-align: center;
  }

  @include mq(xlarge) {
    font-size: 1.5rem;
    line-height: 1.6em;
  }
}

.flatscan-small-cta {
  @extend %text-nodecoration;
  @extend %ff-av-next-cond;

  position: relative;
  display: flex;
  align-items: center;
  margin-top: $spacing * 1.5;
  border: 1px solid $color-secondary;
  padding: $spacing $spacing * 1.5;
  transition: border 0.2s ease-out;

  &:hover {
    border: 1px solid $color-primary;
  }
}

.flatscan-small-cta__headline {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: absolute;
  top: 2px;
  left: 20px;
  padding: 0 $spacing / 2;
  background-color: $white;
  color: $color-primary;
  font-size: 1.2rem;
  line-height: 1em;
  letter-spacing: 0.1em;
  transform: translateY(-50%);

  .flatscan-small-cta:hover & {
    color: $color-primary;
  }
}

.flatscan-small-cta__icon {
  width: 20px;
  height: 20px;
  margin-right: $spacing / 2;
  fill: $color-secondary;
  transition: fill 0.2s ease-out;

  .flatscan-small-cta:hover & {
    fill: $color-primary;
  }
}

.flatscan-small-cta__label {
  position: relative;
  top: 0.1em;
  color: $color-primary;
  font-size: 1.6rem;
  line-height: 1em;
  white-space: nowrap;
  letter-spacing: 0.1em;

  .flatscan-small-cta:hover & {
    color: $color-primary;
  }
}
